import { isiOS, isiPad, isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { addColorClass, removeColorClass } from "../../cb/colorClasses"
import { setupShowMyCamVideoControlsButton } from "../../cb/components/showMyCam/smcViewer"
import { resizeDebounceEvent } from "../../cb/ui/responsiveUtil"
import { Wrapper } from "../../cb/ui/wrapper"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert } from "../alerts"
import { roomLoaded } from "../context"
import { SubSystemType } from "../debug"
import { Component } from "../defui/component"
import { applyStyles, hoverEvent } from "../DOMutils"
import { EventRouter } from "../events"
import {
    exitFullscreen,
    fullscreenChange,
    fullscreenElement,
    fullscreenEnabled, fullscreenErrorEventName,
    isFullscreen,
    requestFullscreen,
} from "../fullscreen"
import {
    chatWindowRequest,
    openTipCalloutRequest,
    privateWindowRequest,
    userListRequest,
} from "../fullvideolib/userActionEvents"
import { getViewportWidth } from "../mobilelib/viewportDimension"
import { addPageAction, NRSetPlayerVolume } from "../newrelic"
import { JpegPushPlayer } from "../player/jpegPlayer"
import { privateJoinInitiated } from "../privateShow"
import { RoomStatus, roomStatusIsWatching } from "../roomStatus"
import { styleTransition } from "../safeStyle"
import { Slider } from "../slider"
import { i18n } from "../translation"
import { parseQueryString } from "../urlUtil"
import { playerForceMuted, roomListRequest } from "../userActionEvents"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { FullscreenDropdown } from "./fullscreenDropdown"
import {
    hideAllTheaterControlsTooltips,
    TheaterControlsButtonTooltip,
    TheaterControlsIconButton,
    TheaterControlsIconTextButton,
} from "./theaterControlsButtonsUtil"
import { switchedToHLS } from "./userActionEvents"
import { userCountUpdate } from "./userListTab"
import type { ITooltipTrigger } from "./theaterControlsButtonsUtil";
import type { TheaterModePlayer } from "./theaterModePlayer"
import type { VideoQualityModal } from "./videoQualityModal"
import type { IChatConnection, IRoomContext } from "../context";
import type { ISettingsUpdateNotification } from "../messageInterfaces"

export const VIDEO_CONTROLS_BAR_HEIGHT = 40

export let VIDEO_CONTROLS_ARE_COMPACT = false

export const VIDEO_CONTROLS_ICON_PATH = `${STATIC_URL}theaterVideoControlsIcons/`

const theaterModeShowCompactThreshold = 940

const ifsShowCompactThreshold = 920

const defaultVolume = 60

const storageKey = "videoControls"


interface ISubMenusShown {
    videoQualityMenu: boolean,
    switchFullscreenMenu: boolean,
}

export class TheaterVideoControls extends Component {
    public readonly forceHlsPlayerEvent = new EventRouter<{
        roomContext: IRoomContext,
        unmute?: boolean,
    }>("forceHlsPlayerEvent")
    public readonly setPlayerComponentMutedEvents = new EventRouter<boolean>("setPlayerComponentMutedEvents")
    public readonly setPlayerComponentVolumeMutedEvents = new EventRouter<{
        volume: number,
        isMuted: boolean,
    }>("setPlayerComponentVolumeMutedEvents")
    public readonly playerComponentEnterFullScreenModeEvent = new EventRouter<undefined>("playerComponentEnterFullScreenModeEvent")
    public readonly playerComponentReadjustForceHlsOverlayOrderEvent = new EventRouter<HTMLDivElement>("playerComponentReadjustForceHlsOverlayOrderEvent")
    public readonly playerComponentReadjustPlayButtonContainerEvent = new EventRouter<HTMLDivElement>("playerComponentReadjustPlayButtonContainerEvent")
    public readonly showJpegPlayerComponentImage = new EventRouter<undefined>("showJpegPlayerComponentImage")
    public readonly requestQualityModalVisibilityChange = new EventRouter<boolean>("requestVideoQualityButtonVisibilityChange")
    private readonly videoOfflineChange = new EventRouter<boolean>("videoOfflineChange")

    private volumeIconButton: TheaterControlsIconButton
    public volumeSlider: VolumeSlider
    private userListIconTextButton: TheaterControlsIconTextButton
    public videoQualityIconButton: TheaterControlsIconButton
    private isAutoQuality: boolean
    private moreRoomsIconTextButton: TheaterControlsIconTextButton
    private privateIconTextButton: TheaterControlsIconTextButton
    private sendTipIconTextButton: TheaterControlsIconTextButton
    private showMyCamIconTextButton: TheaterControlsIconTextButton
    private chatIconTextButton: TheaterControlsIconTextButton
    private theaterModeIconButton: TheaterControlsIconButton
    private fullscreenIconButton: TheaterControlsIconButton
    private switchFSModeIconButton: TheaterControlsIconButton | undefined
    private switchFSModeDropdown: FullscreenDropdown | undefined
    private opacityTimer?: number
    private hideDebounceTimer?: number
    public fullscreenDiv: HTMLElement
    private state = { volume: defaultVolume, isMuted: true }
    private isOffline = false
    private isAgeVerified = false
    private isFullscreenAvailable = false
    private visible = false
    private locked = false
    private subMenusShown: ISubMenusShown = { videoQualityMenu: false, switchFullscreenMenu: false }
    private isHoveringButton = false
    private browserUserAgent: string
    private leftControls: Wrapper
    private middleControls: Wrapper
    private rightControls: Wrapper
    private forceHlsOverlay: HTMLDivElement | undefined
    private currentRoomContext: IRoomContext | undefined
    private playButtonContainer: HTMLDivElement | undefined
    private forceHlsTriggered = false
    private forceHlsPlayButtonCreated = false
    private hlsWaitingForInteraction = false
    private privateAllowed = false

    private playerElement: HTMLDivElement // TODO Consider how to remove this.
    private playerSupportsAutoplayWithAudio: boolean
    private playerIsJPEG: boolean
    private playerIsHlsPlaceholder: boolean
    private isEmbed: boolean

    private updatePlayerData(player: TheaterModePlayer): void {
        this.playerElement = player.element
        this.playerSupportsAutoplayWithAudio = player.playerComponent.supportsAutoplayWithAudio
        if (player.playerComponent instanceof JpegPushPlayer) {
            this.playerIsJPEG = true
            this.playerIsHlsPlaceholder = player.playerComponent.getIsHlsPlaceholder()
        } else {
            this.playerIsJPEG = false
            this.playerIsHlsPlaceholder = false
        }
        this.isEmbed = player.isEmbed()
        if (this.isEmbed) {
            player.playerComponent.removeFullscreenDropdown?.()
        }
    }

    // eslint-disable-next-line complexity
    constructor(private player: TheaterModePlayer, videoQualityModal: VideoQualityModal) {
        super()

        this.updatePlayerData(player)

        const parsedQueryString = parseQueryString(window.location.search)
        const disableSound = parsedQueryString["disable_sound"]
        if (disableSound !== undefined && disableSound.toLowerCase() === "true" || disableSound === "1") {
            this.state.volume = 0
            this.state.isMuted = true
        } else if (isLocalStorageSupported()) {
            const savedSettings = window.localStorage.getItem(storageKey)
            if (savedSettings !== null) {
                const importedState = JSON.parse(savedSettings)
                this.state = {
                    volume: importedState["volume"],
                    isMuted: this.playerSupportsAutoplayWithAudio ? importedState["isMuted"] : true,
                }
            } else {
                this.state.isMuted = !this.playerSupportsAutoplayWithAudio
            }
        }
        NRSetPlayerVolume(this.state.volume, this.state.isMuted)

        this.browserUserAgent = window.navigator.userAgent

        this.element.classList.add("theater-video-controls")
        this.element.style.overflow = ""
        this.element.style.bottom = "0"
        this.element.style.left = "0"
        addColorClass(this.element, "dark-gradient-bg")
        applyStyles(this.element, {
            zIndex: 3,  // so fullscreen dropdown is above draggable canvas windows
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",
        })
        this.hideElement()
        this.element.style.height = `${VIDEO_CONTROLS_BAR_HEIGHT}px`

        this.initButtons(videoQualityModal)

        // eslint-disable-next-line complexity
        roomLoaded.listen((context) => {
            if (!context.dossier.isAgeVerified) {
                this.privateAllowed = false
                this.showHidePrivateButton(context.chatConnection.status)
            } else {
                this.privateAllowed = context.dossier.allowPrivateShow
                this.showHidePrivateButton(context.chatConnection.status)
                context.chatConnection.event.statusChange.listen(roomStatusChangeNotification => {
                    this.showHidePrivateButton(roomStatusChangeNotification.currentStatus)
                    if (roomStatusChangeNotification.currentStatus === RoomStatus.PrivateSpying) {
                        privateWindowRequest.fire(undefined)
                    }
                    this.showHideHlsPlayButton(roomStatusChangeNotification.currentStatus)
                    if (!roomStatusIsWatching(roomStatusChangeNotification.currentStatus)) {
                        if (videoModeHandler.getVideoMode() === VideoMode.Fullscreen) {
                            this.updateFullScreenButton()
                            exitFullscreen()
                        }
                        this.videoQualityIconButton.icon.src = ""
                        this.maybeHideVideoQualityIconVisibility()
                    }
                    this.isOffline = roomStatusChangeNotification.currentStatus === RoomStatus.Offline
                    this.updateIconsOnOfflineChange(context.chatConnection)
                })
                context.chatConnection.event.settingsUpdate.listen((newSettings: ISettingsUpdateNotification) => {
                    this.privateAllowed = newSettings.allowPrivateShow && context.dossier.isAgeVerified
                    this.showHidePrivateButton(context.chatConnection.status)
                })
            }

            this.currentRoomContext = context

            if (this.playerIsHlsPlaceholder && !this.forceHlsPlayButtonCreated) {
                this.forceHlsPlayButtonCreated = true
                this.createForceHlsPlayButton()
                this.showHideHlsPlayButton(context.dossier.roomStatus)
            }

            this.isAgeVerified = context.dossier.isAgeVerified
            this.isOffline = context.dossier.roomStatus === RoomStatus.Offline

            this.updateVolumeImage()
            if (!this.playerIsJPEG) {
                this.updateVolumeInternal()
            }
            this.updateIcons()
            this.showControls()
        })

        this.player.videoOfflineChange.listen(offline => {
            this.isOffline = offline
        })

        this.player.roomStatusNotifier.displayChanged.listen(() => {
            this.updateFullScreenButton()
        })

        videoModeHandler.changeVideoMode.listen(() => {
            if (videoModeHandler.getVideoMode() === VideoMode.Fullscreen) {
                this.hide()
            } else {
                this.show()
                this.updateIcons()
            }
            hideAllTheaterControlsTooltips.fire()
        })

        resizeDebounceEvent.listen((inProgress) => {
            if (!this.visible || !inProgress) {
                this.showControls()
            }
        })

        playerForceMuted.listen(() => {
            this.mute()
        })

        privateJoinInitiated.listen(() => {
            this.forceHlsOverlayFunc()
        }, false)

        fullscreenChange.listen(() => {
            if (isFullscreen() && fullscreenElement() === this.fullscreenDiv) {
                videoModeHandler.setFireVideoMode(VideoMode.IFS)
            }
        })

        addEventListenerPoly("touchstart", this.playerElement, () => {
            // videoJS now sends click events after touch, so visibility toggle is mostly handled in theaterModeRoot
            if (videoModeHandler.getVideoMode() === VideoMode.Split) {
                this.showControls()
            }
        })

        addEventListenerPoly("touchstart", this.element, () => {
            this.showControls()
        })

        this.hide()
    }

    private initButtons(videoQualityModal: VideoQualityModal): void {
        this.leftControls = new Wrapper()
        applyStyles(this.leftControls, {
            position: "relative",
            display: "flex",
            flexGrow: 0,
            flexShrink: 0,
            alignItems: "stretch",
            justifyContent: "flex-start",
            minHeight: "100%",
            overflow: "visible",
            paddingLeft: "14px",  // use padding so width of wrappers can be computed
            paddingRight: "11px",
        })
        this.addChild(this.leftControls)
        this.createVolumeIcon()
        this.leftControls.addChild(this.volumeIconButton)
        this.createVolumeSlider()
        this.leftControls.addChild(this.volumeSlider)
        this.createVideoQualityIconButton(videoQualityModal)
        this.leftControls.addChild(this.videoQualityIconButton)

        this.middleControls = new Wrapper()
        this.middleControls.element.dataset["paction"] = "TheaterOverlayTabs"
        this.middleControls.element.classList.add("theater-overlay")
        applyStyles(this.middleControls, {
            position: "relative",
            display: "flex",
            flexGrow: 1,
            flexShrink: 0,
            alignItems: "stretch",
            justifyContent: "center",
            minHeight: "100%",
            overflow: "visible",
        })
        this.addChild(this.middleControls)
        this.createPrivateButton()
        this.middleControls.addChild(this.privateIconTextButton)
        this.createShowMyCamButton()
        if (setupShowMyCamVideoControlsButton(this.showMyCamIconTextButton, this.videoOfflineChange)) {
            this.middleControls.addChild(this.showMyCamIconTextButton)
        }
        this.createSendTipButton()
        this.middleControls.addChild(this.sendTipIconTextButton)
        this.createChatButton()
        this.middleControls.addChild(this.chatIconTextButton)
        this.createUserListButton()
        this.middleControls.addChild(this.userListIconTextButton)
        this.createMoreRoomsButton()
        this.middleControls.addChild(this.moreRoomsIconTextButton)

        this.rightControls = new Wrapper()
        applyStyles(this.rightControls, {
            position: "relative",
            display: "flex",
            flexGrow: 0,
            columnGap: "0px",
            paddingRight: "14px",
            flexShrink: 1,
            alignItems: "stretch",
            justifyContent: "flex-end",
            minHeight: "100%",
            overflow: "visible",
        })
        this.addChild(this.rightControls)
        this.createTheaterModeIconButton()
        this.rightControls.addChild(this.theaterModeIconButton)
        this.createFullscreenIconButton()
        this.rightControls.addChild(this.fullscreenIconButton)

        if (!this.isEmbed) {
            this.createSwitchFSModeIconButton()
            this.rightControls.element.appendChild(this.switchFSModeIconButton!.element)
            this.rightControls.element.appendChild(this.switchFSModeDropdown!.element)
            this.initButtonHoverListeners()
        }
    }

    private createVideoQualityIconButton(videoQualityModal: VideoQualityModal): void {
        this.videoQualityIconButton = new TheaterControlsIconButton({
            iconPath: "",
            tooltipText: i18n.videoQualityLabel,
            hideCallback: () => {
                this.requestQualityModalVisibilityChange.fire(false)
            },
        })

        this.videoQualityIconButton.element.dataset.testid = "video-quality-btn"
        applyStyles(this.videoQualityIconButton, {
            margin: "0 6px",
        })
        applyStyles(this.videoQualityIconButton.icon, {
            width: "32px",
        })
        this.isAutoQuality = true

        addEventListenerPoly("click", this.videoQualityIconButton.element, () => {
            if (videoQualityModal.element.style.visibility === "hidden") {
                this.requestQualityModalVisibilityChange.fire(true)
            } else {
                this.requestQualityModalVisibilityChange.fire(false)
            }
        })

        videoQualityModal.notifyVisibilityChanged.listen((visible) => {
            if (visible) {
                this.videoQualityIconButton.tooltip?.hideElement()
                this.videoQualityIconButton.element.style.opacity = "1"
                // don't hide controls when submenus are open
                this.subMenusShown.videoQualityMenu = true
                this.lockShowingControls()
            } else {
                this.videoQualityIconButton.tooltip?.showElement()
                this.videoQualityIconButton.element.style.opacity = ""
                this.subMenusShown.videoQualityMenu = false
                if (this.shouldUnlockControls()) {
                    this.unlockShowingControls()
                }
            }
        })
    }

    private createVolumeSlider(): void {
        const handleDiameter = 12
        this.volumeSlider = new VolumeSlider({
            handleDiameter: handleDiameter,
            barWidth: 50,
        })
        applyStyles(this.volumeSlider, {
            position: "relative",
            display: "inline-flex",
            marginBlock: "auto",
            marginLeft: "6px",
            marginRight:"6px",
        })
        this.volumeSlider.tooltipText = i18n.volumeLabel
        const volumeSliderTooltip = new TheaterControlsButtonTooltip({
            triggerElement: this.volumeSlider,
        })
        applyStyles(volumeSliderTooltip, {
            bottom: `calc(100% + ${(VIDEO_CONTROLS_BAR_HEIGHT - handleDiameter) / 2 + 5}px)`,
        })
        this.volumeSlider.addChild(volumeSliderTooltip)
        this.volumeSlider.element.ariaLabel = i18n.volumeSliderLabel
        this.volumeSlider.element.classList.add("hover-btn")
        this.volumeSlider.element.classList.add("drop-shadow-container")

        this.volumeSlider.element.dataset.testid = "volume-slider"

        const handleSliderBegin = (sliderValue: number) => {
            // This fixes unmuting by dragging the slider up from 0 on Safari
            if (this.playerIsJPEG && this.currentRoomContext !== undefined) {
                this.requestHLS(this.currentRoomContext)
            }
            this.setPlayerComponentMutedEvents.fire(false)
            handleSliderMove(sliderValue)
        }
        const handleSliderMove = (sliderValue: number) => {
            // Because we are listening onmousemove, only update the state when the slider value is different than the
            // stored state value.
            if (sliderValue === this.state.volume) {
                return
            }
            this.state.volume = sliderValue
            this.state.isMuted = this.state.volume === 0
            this.updatePlayerVolume()
            this.updateVolumeImage()
            this.saveSettings()
        }
        const handleSliderEnd = (sliderValue: number) => {
            handleSliderMove(sliderValue)
            if (this.state.volume === 0) {
                this.setPlayerComponentMutedEvents.fire(true)
            }

            addPageAction("ChangeVolume", { "volume": sliderValue })
            this.saveSettings()
        }
        // mouse(down/move/up) is required for older versions of IE
        this.volumeSlider.valueChangeStart.listen(handleSliderBegin)
        this.volumeSlider.valueChanged.listen(handleSliderMove)
        this.volumeSlider.valueChangeEnd.listen(handleSliderEnd)

        if(isiPad()){
            this.volumeSlider.hideElement()
        }
    }

    private createVolumeIcon(): void {
        this.volumeIconButton = new TheaterControlsIconButton({
            iconPath: "",
            tooltipText: i18n.volumeLabel,
        })
        this.volumeIconButton.element.dataset.testid = "volume-button"
        addEventListenerPoly("click", this.volumeIconButton.element, () => {
            // Following fix will stop breaking the video player on IE versions 9 and 10 as HLS supports IE v11+ only.
            if (!this.playerIsJPEG || this.browserUserAgent.indexOf("MSIE 10.0") > -1 || this.browserUserAgent.indexOf("MSIE 9.0") > -1) {
                addPageAction("ToggleMute", { "newState": !this.state.isMuted })
                addPageAction("ChangeVolume", { "volume": this.state.volume })
                if (this.state.isMuted) {
                    this.unmute()
                } else {
                    this.mute()
                }
                this.showControls()
            } else {
                if (this.currentRoomContext === undefined) {
                    error("unexpected switch to hls", {}, SubSystemType.Video)
                    return
                }
                this.requestHLS(this.currentRoomContext)
            }
        })
    }

    private createPrivateButton(): void {
        this.privateIconTextButton = new TheaterControlsIconTextButton({
            iconPath: `${VIDEO_CONTROLS_ICON_PATH}private-show.svg`,
            labelText: i18n.privateText,
        })
        this.privateIconTextButton.element.dataset.testid = "private-show-btn"
        this.privateIconTextButton.setTooltipTextOverride(i18n.waitingToConnect)
        this.privateIconTextButton.disable()
        addEventListenerPoly("click", this.privateIconTextButton.element, () => {
            if (this.privateIconTextButton.isDisabled()) {
                return
            }
            privateWindowRequest.fire(undefined)
        })
    }

    private createShowMyCamButton(): void {
        this.showMyCamIconTextButton = new TheaterControlsIconTextButton({
            iconPath: `${VIDEO_CONTROLS_ICON_PATH}cam-to-cam.svg`,
            labelText: i18n.showMyCamShow,
            noTooltip: true,
        })
        this.showMyCamIconTextButton.element.dataset.testid = "cam-to-cam-button"
    }

    private createSendTipButton(): void {
        this.sendTipIconTextButton = new TheaterControlsIconTextButton({
            iconPath: `${VIDEO_CONTROLS_ICON_PATH}tip-icon.svg`,
            labelText: i18n.sendTipButtonText,
            noTooltip: true,
        })
        this.sendTipIconTextButton.element.id = "send-tip"
        addEventListenerPoly("click", this.sendTipIconTextButton.element, () => {
            openTipCalloutRequest.fire({})
        })
    }

    private createChatButton(): void {
        this.chatIconTextButton = new TheaterControlsIconTextButton({
            iconPath: `${VIDEO_CONTROLS_ICON_PATH}chat.svg`,
            labelText: i18n.chatCapitalized,
            noTooltip: true,
        })
        this.chatIconTextButton.element.id = "chat-btn"
        this.chatIconTextButton.element.style.display = "none"
        addEventListenerPoly("click", this.chatIconTextButton.element, () => {
            chatWindowRequest.fire(undefined)
        })
    }

    private createUserListButton(): void {
        this.userListIconTextButton = new TheaterControlsIconTextButton({
            iconPath: `${VIDEO_CONTROLS_ICON_PATH}users.svg`,
            labelText: i18n.usersText,
            noTooltip: true,
        })
        userCountUpdate.listen((count: number) => {
            this.userListIconTextButton.updateTextLabel(`${i18n.usersText} (${count})`)
            if (VIDEO_CONTROLS_ARE_COMPACT) {
                this.userListIconTextButton.setTooltipTextFromLabel()
            }
        })
        this.userListIconTextButton.element.id = "user-list"
        this.userListIconTextButton.element.dataset["pactionName"] = "Users"
        addEventListenerPoly("click", this.userListIconTextButton.element, () => {
            userListRequest.fire(undefined)
        })
    }

    private createMoreRoomsButton(): void {
        this.moreRoomsIconTextButton = new TheaterControlsIconTextButton({
            iconPath: `${VIDEO_CONTROLS_ICON_PATH}more-rooms.svg`,
            labelText: i18n.moreRoomsText,
            noTooltip: true,
        })
        this.moreRoomsIconTextButton.element.id = "more-rooms"
        addEventListenerPoly("click", this.moreRoomsIconTextButton.element, () => {
            if (this.moreRoomsIconTextButton.isDisabled()) {
                return
            }
            roomListRequest.fire(undefined)
        })
    }

    private createTheaterModeIconButton(): void {
        this.theaterModeIconButton = new TheaterControlsIconButton({
            iconPath: `${VIDEO_CONTROLS_ICON_PATH}theater-mode.svg`,
            tooltipText: i18n.theaterModeLabel,
        })
        this.theaterModeIconButton.changeIconAnimation("scale-wide")
        this.theaterModeIconButton.element.id = "theater-mode-icon"

        addEventListenerPoly("click", this.theaterModeIconButton.element, () => {
            hideAllTheaterControlsTooltips.fire()
            let newVideoMode = VideoMode.Split
            if (videoModeHandler.getVideoMode() === VideoMode.Split) {
                newVideoMode = VideoMode.Theater
            }
            videoModeHandler.setFireVideoMode(newVideoMode)
            this.updateIcons()
        })
    }

    private createFullscreenIconButton(): void {
        this.isFullscreenAvailable = fullscreenEnabled() || isiOS()

        addEventListenerPoly(fullscreenErrorEventName(), document, () => {
            if (!isFullscreen()) {
                this.isFullscreenAvailable = false
                this.updateFullScreenButton()
                modalAlert("Full screen is unavailable.")
            }
        })

        this.fullscreenIconButton = new TheaterControlsIconButton({
            iconPath: `${VIDEO_CONTROLS_ICON_PATH}fullscreen.svg`,
            tooltipText: i18n.fullScreenLabel,
        })

        this.fullscreenIconButton.changeIconAnimation("scale-big")
        this.fullscreenIconButton.element.id = "full-screen-icon"
        this.updateFullScreenButton()

        addEventListenerPoly("click", this.fullscreenIconButton.element, () => {
            hideAllTheaterControlsTooltips.fire()
            this.toggleFullscreen()
        })
        addEventListenerPoly("dblclick", this.player.playerComponent.element, () => {
            // Only toggle fullscreen for Spilt, Theater, & IFS mode
            if (
                !this.isFullscreenAvailable ||
                ![VideoMode.Split, VideoMode.Theater, VideoMode.IFS].includes(videoModeHandler.getVideoMode())
            ) {
                return
            }
            hideAllTheaterControlsTooltips.fire()
            this.toggleFullscreen()
        })
    }

    private createSwitchFSModeIconButton(): void {
        const hideMenuCallback = () => {
            if (this.switchFSModeDropdown?.isShown() === true) {
                this.switchFSModeDropdown?.hideElement()
            }
        }
        this.switchFSModeIconButton = new TheaterControlsIconButton({
            iconPath: `${VIDEO_CONTROLS_ICON_PATH}ellipsis-vertical.svg`,
            tooltipText: i18n.switchFullscreenModeLabel,
            hideCallback: hideMenuCallback,
            disableCallback: hideMenuCallback,
        })

        this.switchFSModeIconButton.element.id = "fullscreen-dropdown"
        this.createSwitchFSModeDropdown()

        this.switchFSModeDropdown!.toggleEvent.listen((toggleEvent) => {
            if (this.switchFSModeIconButton === undefined) {
                return
            }
            if (toggleEvent.isShowing) {
                this.switchFSModeIconButton.tooltip?.hideElement()
                this.switchFSModeIconButton.element.style.opacity = "1"
                // don't hide controls when submenus are open
                this.subMenusShown.switchFullscreenMenu = true
                this.lockShowingControls()
            } else {
                this.switchFSModeIconButton.tooltip?.showElement()
                this.switchFSModeIconButton.element.style.opacity = ""
                this.subMenusShown.switchFullscreenMenu = false
                if (this.shouldUnlockControls()) {
                    this.unlockShowingControls()
                }
            }
        })
    }

    private createSwitchFSModeDropdown(): void {
        this.switchFSModeDropdown = new FullscreenDropdown({
            toggleElement: this.switchFSModeIconButton!.element,
            enterNativeFn: () => this.requestNativeFullscreen(),
            enterInteractiveFn: () => undefined,
        })
    }

    private shouldUnlockControls(): boolean {
        // don't unlock controls if submenus are open or button is being hovered
        return !this.isHoveringButton && Object.values(this.subMenusShown).every((subMenu: boolean) => !subMenu)
    }

    private initButtonHoverListeners(): void {
        // prevent control bar from hiding when hovering over buttons
        const lockControlsHoverListener = (hovering: boolean) => {
            if (hovering) {
                this.isHoveringButton = true
                this.lockShowingControls()
            } else  {
                this.isHoveringButton = false
                if (this.shouldUnlockControls()) {
                    this.unlockShowingControls()
                }
            }
        }

        hoverEvent(this.volumeIconButton, { ignoreTouch: true }).listen(lockControlsHoverListener)
        hoverEvent(this.volumeSlider, { ignoreTouch: true }).listen(lockControlsHoverListener)
        hoverEvent(this.videoQualityIconButton, { ignoreTouch: true }).listen(lockControlsHoverListener)
        hoverEvent(this.privateIconTextButton, { ignoreTouch: true }).listen(lockControlsHoverListener)
        hoverEvent(this.showMyCamIconTextButton, { ignoreTouch: true }).listen(lockControlsHoverListener)
        hoverEvent(this.sendTipIconTextButton, { ignoreTouch: true }).listen(lockControlsHoverListener)
        hoverEvent(this.chatIconTextButton, { ignoreTouch: true }).listen(lockControlsHoverListener)
        hoverEvent(this.userListIconTextButton, { ignoreTouch: true }).listen(lockControlsHoverListener)
        hoverEvent(this.moreRoomsIconTextButton, { ignoreTouch: true }).listen(lockControlsHoverListener)
        hoverEvent(this.theaterModeIconButton, { ignoreTouch: true }).listen(lockControlsHoverListener)
        hoverEvent(this.fullscreenIconButton, { ignoreTouch: true }).listen(lockControlsHoverListener)

        if (this.switchFSModeIconButton !== undefined) {
            hoverEvent(this.switchFSModeIconButton).listen(lockControlsHoverListener)
        }
    }

    // eslint-disable-next-line complexity
    public showHidePrivateButton(roomStatus: RoomStatus): void {
        const keepShowingPrivateStatuses = [RoomStatus.PrivateRequesting, RoomStatus.PrivateSpying, RoomStatus.PrivateWatching]
        if ((!this.privateAllowed && !keepShowingPrivateStatuses.includes(roomStatus))) {
            this.privateIconTextButton.hideElement()
            return
        }
        if (videoModeHandler.getVideoMode() === VideoMode.Split) {
            this.privateIconTextButton.hideElement()
            return
        } else {
            this.privateIconTextButton.showElement()
        }

        switch (roomStatus) {
            case RoomStatus.NotConnected:
                if (this.player.roomStatusNotifier.isShown() && !this.player.roomStatusNotifier.isConnectingStatusShowing()) {
                    this.privateIconTextButton.clearTooltipTextOverride()
                    return
                }
                this.privateIconTextButton.disable()
                this.privateIconTextButton.setTooltipTextOverride(i18n.waitingToConnect)
                break
            case RoomStatus.Unknown:
            case RoomStatus.Offline:
            case RoomStatus.Away:
            case RoomStatus.Hidden:
            case RoomStatus.HiddenWatching:
            case RoomStatus.PasswordProtected:
            case RoomStatus.PrivateNotWatching:
                this.privateIconTextButton.showElement()
                this.privateIconTextButton.disable()
                this.privateIconTextButton.clearTooltipTextOverride()
                break
            default:
                this.privateIconTextButton.showElement()
                this.privateIconTextButton.enable()
                this.privateIconTextButton.clearTooltipTextOverride()
        }
    }

    private updateIcons(): void {
        if (this.isAgeVerified) {
            this.sendTipIconTextButton.showElement()
        } else {
            this.sendTipIconTextButton.hideElement()
        }
        this.moreRoomsIconTextButton.showElement()
        this.userListIconTextButton.showElement()
        this.theaterModeIconButton.showElement()
        this.switchFSModeIconButton?.showElement()
        this.videoQualityIconButton.showVisibility()
        this.chatIconTextButton.showElement()
        this.showMyCamIconTextButton.showElement()
        if (this.currentRoomContext !== undefined) {
            this.showHidePrivateButton(this.currentRoomContext.chatConnection.status)
        }

        this.updateIconsByMode()
        this.maybeHideVideoQualityIconVisibility()
        this.updateFullScreenButton()

        this.showControls()
    }

    private updateIconsByMode(): void {
        this.updateVideoModeButtonAppearance()
        switch (videoModeHandler.getVideoMode()) {
            case VideoMode.Split:
                this.sendTipIconTextButton.hideElement()
                this.userListIconTextButton.hideElement()
                this.chatIconTextButton.hideElement()
                this.privateIconTextButton.hideElement()
                this.showMyCamIconTextButton.hideElement()
                this.moreRoomsIconTextButton.hideElement()
                this.switchFSModeIconButton?.hideElement()
                if (this.isEmbed) {
                    this.theaterModeIconButton.hideElement()
                }
                break
            case VideoMode.VideoOnly:
                this.videoQualityIconButton.hideVisibility()
                this.sendTipIconTextButton.hideElement()
                this.userListIconTextButton.hideElement()
                this.chatIconTextButton.hideElement()
                this.privateIconTextButton.hideElement()
                this.showMyCamIconTextButton.hideElement()
                this.moreRoomsIconTextButton.hideElement()
                this.theaterModeIconButton.hideElement()
                this.switchFSModeIconButton?.hideElement()
                break
            case VideoMode.Theater:
                this.switchFSModeIconButton?.hideElement()
                break
            case VideoMode.IFS:
                this.theaterModeIconButton.hideElement()
                if (this.switchFSModeDropdown !== undefined) {
                    addColorClass(this.switchFSModeDropdown.interactiveOption, "active")
                }
                break
            case VideoMode.Fullscreen:
                if (this.switchFSModeDropdown !== undefined) {
                    addColorClass(this.switchFSModeDropdown.nativeOption, "active")
                }
                break
            default:
                error(`Unexpected VideoMode: ${videoModeHandler.getVideoMode()}`, {}, SubSystemType.Video)
        }
    }

    private updateIconsOnOfflineChange(currentChatConnection: IChatConnection): void {
        if (this.isOffline) {
            this.showMyCamIconTextButton.disable()
            this.moreRoomsIconTextButton.disable()
        } else {
            if (currentChatConnection.status !== RoomStatus.NotConnected) {
                this.showMyCamIconTextButton.enable()
            }
            this.moreRoomsIconTextButton.enable()
        }
    }

    private recalculateControlsFlexBasis(): void {
        // right controls flex basis is set so that the middle icons remain centered
        applyStyles(this.rightControls, { flexBasis: `${this.leftControls.element.offsetWidth - 14}px` })

        // middle controls flex basis is set to prevent longer language translations overflowing controls bar
        if (!VIDEO_CONTROLS_ARE_COMPACT && [VideoMode.Theater, VideoMode.IFS].includes(videoModeHandler.getVideoMode())) {
            applyStyles(this.middleControls, { flexBasis: "625px" })
        } else {
            applyStyles(this.middleControls, { flexBasis: "" })
        }
    }

    private updateVolumeImage(): void {
        const volume = this.volumeSlider.getValue()
        if (volume === 0 || this.state.isMuted) {
            this.volumeIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}volume-mute.svg`)
            this.volumeIconButton.setTooltipText(i18n.unmuteCapitalized)
            this.volumeIconButton.element.id = "volume-mute"
        } else if (volume < 33) {
            this.volumeIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}volume-low.svg`)
            this.volumeIconButton.setTooltipText(i18n.muteLabel)
            this.volumeIconButton.element.id = "volume-low"
        } else if (volume < 66) {
            this.volumeIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}volume-medium.svg`)
            this.volumeIconButton.setTooltipText(i18n.muteLabel)
            this.volumeIconButton.element.id = "volume-medium"
        } else {
            this.volumeIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}volume-high.svg`)
            this.volumeIconButton.setTooltipText(i18n.muteLabel)
            this.volumeIconButton.element.id = "volume-high"
        }
    }

    private updatePlayerVolume(): void {
        this.setPlayerComponentVolumeMutedEvents.fire({ ...this.state })
    }

    private saveSettings(): void {
        if (isLocalStorageSupported()) {
            const exportedState = {
                "volume": this.state.volume,
                "isMuted": this.state.isMuted,
            }
            window.localStorage.setItem(storageKey, JSON.stringify(exportedState))
        }
        NRSetPlayerVolume(this.state.volume, this.state.isMuted)
    }

    private update(): void {
        this.updateVolumeInternal()
        this.saveSettings()
    }

    private updateVolumeInternal(): void {
        this.volumeSlider.setValue(this.state.isMuted ? 0 : this.state.volume)
        this.updatePlayerVolume()
        this.updateVolumeImage()
    }

    private mute(): void {
        this.updateIsMuted(true)
        this.update()
    }

    private unmute(): void {
        this.updateIsMuted(false)
        if (this.state.volume === 0) {
            this.state.volume = defaultVolume
        }
        this.update()
    }

    public toggleMuted(): void {
        if (this.state.isMuted) {
            this.unmute()
        } else {
            this.mute()
        }
    }

    public toggle(): void {
        if (this.visible) {
            this.hideControls()
        } else {
            this.showControls()
        }
    }

    private showControls(): void {
        this.visible = true
        this.maybeShowCompactControls()
        this.recalculateControlsFlexBasis()

        styleTransition(this.element, "100ms")
        this.element.style.opacity = "1"
        this.element.style.visibility = ""

        if (this.hideDebounceTimer !== undefined) {
            clearTimeout(this.hideDebounceTimer)
        }
        if (this.opacityTimer !== undefined) {
            clearTimeout(this.opacityTimer)
        }

        if (!this.locked) {
            this.startHideTimeout()
        }
    }

    private startHideTimeout(): void {
        const hideTimeout = 3000
        const hideDebounceTiming = 100
        this.hideDebounceTimer = window.setTimeout(() => {
            this.hideDebounceTimer = undefined
            this.opacityTimer = window.setTimeout(() => {
                this.opacityTimer = undefined
                this.hideControls()
            }, hideTimeout - hideDebounceTiming)
        }, hideDebounceTiming)
    }

    public lockShowingControls(): void {
        this.locked = true
        this.showControls()
    }

    public unlockShowingControls(): void {
        const wasLocked = this.locked
        this.locked = false
        if (wasLocked) {
            this.startHideTimeout()
        }
    }

    private shouldShowCompactControls(): boolean {
        const videoMode = videoModeHandler.getVideoMode()
        return (
            (videoMode === VideoMode.Theater && getViewportWidth() < theaterModeShowCompactThreshold) ||
            (videoMode === VideoMode.IFS && getViewportWidth() < ifsShowCompactThreshold)
        )
    }

    // eslint-disable-next-line complexity
    private maybeShowCompactControls(): void {
        VIDEO_CONTROLS_ARE_COMPACT = this.shouldShowCompactControls()
        if (VIDEO_CONTROLS_ARE_COMPACT) {
            this.privateIconTextButton.setTooltipTextFromLabel()
            this.privateIconTextButton.hideTextLabel()
            this.showMyCamIconTextButton.setTooltipTextFromLabel()
            this.showMyCamIconTextButton.hideTextLabel()
            this.sendTipIconTextButton.setTooltipTextFromLabel()
            this.sendTipIconTextButton.hideTextLabel()
            this.chatIconTextButton.setTooltipTextFromLabel()
            this.chatIconTextButton.hideTextLabel()
            this.userListIconTextButton.setTooltipTextFromLabel()
            this.userListIconTextButton.hideTextLabel()
            this.moreRoomsIconTextButton.setTooltipTextFromLabel()
            this.moreRoomsIconTextButton.hideTextLabel()
            applyStyles(this.middleControls, {
                columnGap: "6px",
            })
        } else {
            this.privateIconTextButton.clearTooltipText()
            this.privateIconTextButton.showTextLabel()
            this.showMyCamIconTextButton.clearTooltipText()
            this.showMyCamIconTextButton.showTextLabel()
            this.sendTipIconTextButton.clearTooltipText()
            this.sendTipIconTextButton.showTextLabel()
            this.chatIconTextButton.clearTooltipText()
            this.chatIconTextButton.showTextLabel()
            this.userListIconTextButton.clearTooltipText()
            this.userListIconTextButton.showTextLabel()
            this.moreRoomsIconTextButton.clearTooltipText()
            this.moreRoomsIconTextButton.showTextLabel()
            applyStyles(this.middleControls, {
                columnGap: "24px",
            })
        }
    }

    public hideControls(): void {
        if (this.hlsWaitingForInteraction || this.hideDebounceTimer !== undefined || this.locked) {
            return
        }
        this.visible = false
        styleTransition(this.element, "500ms")
        this.requestQualityModalVisibilityChange.fire(false)
        this.element.style.opacity = "0"
        this.element.style.visibility = "hidden"
    }

    public show(): void {
        this.showElement()
        this.showControls()
    }

    public hide(): void {
        if (this.locked) {
            return
        }
        this.hideElement()
    }

    public updateVolume(volume: number): void {
        this.state.volume = volume
        this.volumeSlider.setValue(this.state.isMuted ? 0 : this.state.volume)
        this.updateVolumeImage()
    }

    public updateIsMuted(muted: boolean): void {
        this.state.isMuted = muted
        this.volumeSlider.setValue(this.state.isMuted ? 0 : this.state.volume)
        this.updateVolumeImage()
    }

    public updateAndSaveVolume(volume: number): void {
        this.updateVolume(volume)
        this.saveSettings()
    }

    public updateAndSaveIsMuted(muted: boolean): void {
        this.updateIsMuted(muted)
        this.saveSettings()
    }

    public toggleFullscreen(): void {
        // Need this here because forceHls needs to be called before entering fullscreen mode
        if (this.hlsWaitingForInteraction) {
            this.forceHlsOverlayFunc()
        }
        if (isFullscreen()) {
            exitFullscreen()
        } else {
            // can't type in fullscreen on iPad, & iPhone doesn't support IFS for desktop site so don't use IFS
            if (!isiOS() && !this.isEmbed) {
                this.toggleInteractiveFullScreen()
            } else if (this.isFullscreenAvailable) {
                this.requestNativeFullscreen()
            }
        }
        this.updateIcons()
    }

    private requestNativeFullscreen(): void {
        // Need this here because forceHls needs to be called before entering fullscreen mode
        if (this.hlsWaitingForInteraction) {
            this.forceHlsOverlayFunc()
        }
        if (this.isFullscreenAvailable) {
            // if desktop fullscreen is requested on iOS, its coming from jpegToHls and will ignore the fullscreen request without a timeout
            if (isiOS()) {
                window.setTimeout(() => {
                    this.playerComponentEnterFullScreenModeEvent.fire(undefined)
                }, 250)
            } else {
                // controls disappear slowly on safari when switching to native fullscreen
                this.hide()
                this.playerComponentEnterFullScreenModeEvent.fire(undefined)
            }
        }
    }

    public toggleInteractiveFullScreen(): void {
        if (videoModeHandler.getVideoMode() === VideoMode.IFS) {
            exitFullscreen()
        } else {
            requestFullscreen(this.fullscreenDiv)
        }
    }

    // eslint-disable-next-line complexity
    private updateFullScreenButton(): void {
        const videoMode = videoModeHandler.getVideoMode()
        const isVideoOnly = videoMode === VideoMode.VideoOnly
        const isIFS = videoMode === VideoMode.IFS
        const isShowingNotification = this.player.roomStatusNotifier.isShown()
        const canGoIFS = this.isFullscreenAvailable && (!isVideoOnly || isIFS)
        const canGoNFS = canGoIFS && !isShowingNotification
        const canGoFullscreen = this.isEmbed ? canGoNFS : canGoIFS
        const isChatPlayer = fullscreenElement()?.id === "chat-player"
        if (canGoFullscreen) {
            this.fullscreenIconButton.showElement()
            if (this.switchFSModeIconButton !== undefined && isIFS) {
                this.switchFSModeIconButton.showElement()
                if (isShowingNotification) {
                    this.switchFSModeIconButton.disable()
                    this.switchFSModeIconButton.disableTooltip()
                } else {
                    this.switchFSModeIconButton.enable()
                    this.switchFSModeIconButton.enableTooltip()
                }
            } else {
                this.switchFSModeIconButton?.hideElement()
            }
        } else {
            this.fullscreenIconButton.hideElement()
            if (isFullscreen() && isChatPlayer) {
                exitFullscreen()
            }
        }
    }

    private updateVideoModeButtonAppearance(): void {
        const videoMode = videoModeHandler.getVideoMode()
        if (videoMode === VideoMode.Split || videoMode === VideoMode.Theater) {
            this.fullscreenIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}fullscreen.svg`)
            this.fullscreenIconButton.setTooltipText(i18n.fullScreenLabel)
            this.fullscreenIconButton.changeIconAnimation("scale-big")

            if (videoMode === VideoMode.Split) {
                this.theaterModeIconButton.setTooltipText(i18n.theaterModeLabel)
                this.theaterModeIconButton.changeIconAnimation("scale-wide")
            } else {
                this.theaterModeIconButton.setTooltipText(i18n.defaultViewLabel)
                this.theaterModeIconButton.changeIconAnimation("scale-thin")
            }
        } else {
            this.fullscreenIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}exit-fullscreen.svg`)
            this.fullscreenIconButton.setTooltipText(i18n.exitFullScreenLabel)
            this.fullscreenIconButton.changeIconAnimation("scale-small")
        }
    }

    // eslint-disable-next-line complexity
    public forceHlsOverlayFunc(runForceHlsPlayer = true): void {
        if (this.hlsWaitingForInteraction && this.currentRoomContext !== undefined) {
            if (this.forceHlsOverlay !== undefined && this.forceHlsOverlay.parentElement !== null) {
                this.forceHlsOverlay.parentElement.removeChild(this.forceHlsOverlay)
                this.forceHlsOverlay = undefined
            }

            if (this.playButtonContainer !== undefined && this.playButtonContainer.parentElement !== null) {
                this.playButtonContainer.parentElement.removeChild(this.playButtonContainer)
                this.playButtonContainer = undefined
            }
            this.hlsWaitingForInteraction = false
            this.hideControls()
            this.hide()
            this.videoQualityIconButton.hideVisibility()
            if (!this.forceHlsTriggered && runForceHlsPlayer) {
                this.forceHlsTriggered = true
                addPageAction("ForceHLS")
                this.forceHlsPlayerEvent.fire({
                    roomContext: this.currentRoomContext,
                    unmute: false,
                })
                switchedToHLS.fire(undefined)
            }
        }
    }

    public maybeForceHls(): void {
        if (this.playerIsJPEG && this.hlsWaitingForInteraction) {
            this.forceHlsOverlayFunc()
        }
    }

    private createHlsPlayOverlay(): void {
        this.forceHlsOverlay = document.createElement("div")
        this.forceHlsOverlay.style.width = "100%"
        this.forceHlsOverlay.style.height = "100%"
        this.forceHlsOverlay.style.position = "absolute"
        this.forceHlsOverlay.style.top = "0"
        this.forceHlsOverlay.style.left = "0"
        this.forceHlsOverlay.style.cursor = "pointer"
        addEventListenerPoly("click", this.forceHlsOverlay, () => {
            this.forceHlsOverlayFunc()
        })
        addEventListenerPoly("click", this.element, () => {
            this.forceHlsOverlayFunc()
        })
        this.playerComponentReadjustForceHlsOverlayOrderEvent.fire(this.forceHlsOverlay)
    }

    private showHideHlsPlayButton(status: RoomStatus): void {
        if (this.playButtonContainer !== undefined && this.forceHlsOverlay !== undefined) {
            if (roomStatusIsWatching(status)) {
                this.playButtonContainer.style.display = ""
                this.forceHlsOverlay.style.display = ""
            } else {
                this.playButtonContainer.style.display = "none"
                this.forceHlsOverlay.style.display = "none"
            }
        }
    }

    private createForceHlsPlayButton(): void {
        if (this.playerIsJPEG) {
            this.hlsWaitingForInteraction = true
            this.playButtonContainer = document.createElement("div")
            const parentRect = this.playerElement.getBoundingClientRect()
            const playButton = document.createElement("img")
            playButton.src = `${STATIC_URL}play-inactive.svg`
            playButton.style.width = "60px"
            playButton.style.height = "60px"
            playButton.style.position = "relative"
            playButton.style.top = "40px"
            playButton.style.left = "45px"
            playButton.style.cursor = "pointer"
            addEventListenerPoly("click", playButton, () => { this.forceHlsOverlayFunc() })

            this.playButtonContainer.style.position = "absolute"
            this.playButtonContainer.style.top = `${(parentRect.height / 2) - 70}px`
            this.playButtonContainer.style.left = `${(parentRect.width / 2) - 70}px`
            this.playButtonContainer.style.cursor = "pointer"
            this.playButtonContainer.style.width = "140px"
            this.playButtonContainer.style.height = "140px"
            this.playButtonContainer.style.borderRadius = "50%"
            this.playButtonContainer.style.backgroundColor = "rgba(0,0,0,0.25)"
            this.playButtonContainer.appendChild(playButton)
            this.playerComponentReadjustPlayButtonContainerEvent.fire(this.playButtonContainer)

            this.showJpegPlayerComponentImage.fire(undefined)
            this.createHlsPlayOverlay()
            this.repositionChildren()
        }
    }

    public onForceJPEG(): void {
        this.videoQualityIconButton.hideVisibility()
        if (videoModeHandler.getVideoMode() === VideoMode.Fullscreen && isFullscreen()) {
            // prevent chrome from being stuck in full window when forcing JPEG from VJS fullscreen
            exitFullscreen()
            fullscreenChange.fire()
        }
        this.showElement()
        this.updateIcons()
        this.show()
    }

    protected repositionChildren(): void {
        if (this.playButtonContainer !== undefined) {
            const parentRect = this.playerElement.getBoundingClientRect()
            this.playButtonContainer.style.top = `${(parentRect.height / 2) - 70}px`
            this.playButtonContainer.style.left = `${(parentRect.width / 2) - 70}px`
        }
    }

    public notifySupportsAutoplayWithAudio(supportsAutoplayWithAudio: boolean): void {
        this.playerSupportsAutoplayWithAudio = supportsAutoplayWithAudio
    }

    public notifyIsJPEG(isJPEG: boolean): void {
        this.playerIsJPEG = isJPEG
        if (isJPEG) {
            this.videoQualityIconButton.hideVisibility()
        }
    }

    public notifyIsHlsPlaceholder(isHlsPlaceholder: boolean): void {
        this.playerIsHlsPlaceholder = isHlsPlaceholder
    }

    public notifyVideoOfflineChange(videoOffline: boolean): void {
        this.videoOfflineChange.fire(videoOffline)
    }

    public notifyQualityLevelChanged(level: string): void {
        this.updateVideoQualityIcon(level)
    }

    // eslint-disable-next-line complexity
    private updateVideoQualityIcon(level: string) {
        this.isAutoQuality = level === "auto";
        if (this.isAutoQuality) {
            this.videoQualityIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}quality-gear-auto.svg`)
            return
        }

        const resolution = level.match(/\d+p/) ?? [""]
        switch (resolution[0]) {
            case "240p":
                this.videoQualityIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}quality-gear-240p.svg`)
                break
            case "360p":
                this.videoQualityIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}quality-gear-360p.svg`)
                break
            case "480p":
                this.videoQualityIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}quality-gear-480p.svg`)
                break
            case "540p":
                this.videoQualityIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}quality-gear-540p.svg`)
                break
            case "720p":
                this.videoQualityIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}quality-gear-720p.svg`)
                break
            case "1080p":
                this.videoQualityIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}quality-gear-hd.svg`)
                break
            case "1440p":
                this.videoQualityIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}quality-gear-1440p.svg`)
                break
            case "2160p":
                this.videoQualityIconButton.updateIcon(`${VIDEO_CONTROLS_ICON_PATH}quality-gear-4k.svg`)
                break
            default:
        }
    }

    private maybeHideVideoQualityIconVisibility(): void {
        if (isiPad() || this.playerIsJPEG || this.videoQualityIconButton.icon.getAttribute("src") === "") {
            // use visibility instead of display to maintain button spacing
            this.videoQualityIconButton.hideVisibility()
        }
    }

    public setVideoQualityButtonVisibility(isVisible: boolean): void {
        if (isVisible) {
            // use visibility instead of display to maintain button spacing
            this.videoQualityIconButton.showVisibility()
        } else {
            this.videoQualityIconButton.hideVisibility()
        }
    }

    public getQualityButtonLeft(): number {
        return this.videoQualityIconButton.element.offsetLeft
    }

    public checkIfElementIsInVolumeControls(target: EventTarget | null): boolean {
        for (const c of this.volumeSlider.element.children) {
            if (target === c) {
                return true
            }
        }
        return target === this.volumeIconButton.element ||
            target === this.volumeSlider.element ||
            target === this.volumeSlider.handle
    }

    public getRoomContext(): IRoomContext | undefined {
        return this.currentRoomContext
    }

    private requestHLS(context: IRoomContext): void {
        if (this.hlsWaitingForInteraction) {
            this.forceHlsOverlayFunc(false)
        }
        addPageAction("ForceHLS")
        this.forceHlsPlayerEvent.fire({
            roomContext: context,
        })
        this.unmute()
        switchedToHLS.fire(undefined)
    }
}

class VolumeSlider extends Slider implements ITooltipTrigger {
    // adds properties and methods used by tooltips
    public tooltipText: string | undefined
    public tooltipTextOverride: string | undefined
    public tooltipDisabled = false

    initUI(props?: object) {
        super.initUI(props)
        hoverEvent(this.element, { ignoreTouch: true }).listen((hovering) => {
            if (hovering) {
                addColorClass(this.element, "hovering")
            } else {
                removeColorClass(this.element, "hovering")
            }
        })
    }
    public getTooltipText(): string | undefined {
        return this.tooltipText
    }

    public getTooltipTextOverride(): string | undefined {
        return this.tooltipTextOverride
    }

    public isTooltipDisabled(): boolean {
        return this.tooltipDisabled
    }

    public isHovering(): boolean {
        return this.element.classList.contains("hovering")
    }
}
