import { onlineFollowedStorageEvent } from "../cb/components/followingTab/follow"
import { pageContext } from "../cb/interfaces/context"
import { RoomReload } from "../cb/roomList"
import { showLoginOverlay } from "../cb/ui/loginOverlay"
import { followRoom, unfollowRoom } from "../common/follow"
import { modalConfirm } from "./alerts"
import { i18n } from "./translation"

export class FollowingIcons {
    readonly isAnonymous: boolean
    readonly isMobile: boolean
    readonly roomListContainerID: string

    constructor(isAnonymous: boolean, roomListContainerID: string, isMobile: boolean) {
        this.isAnonymous = isAnonymous
        this.roomListContainerID = roomListContainerID
        this.isMobile = isMobile
        this.initEvents()
    }

    private initEvents(): void {
        this.bindFollowingIcons()
        // Ensure events are re-bound when the page contents get replaced by AJAX call
        RoomReload.getInstance().addOnLoadHandler(() => {
            this.bindFollowingIcons()
        })
        // Ensure up-to-date follow events from other contexts are reflected timely in Django-template follow stars
        onlineFollowedStorageEvent.listen((storage) => {
            // However, skip updating for logged-out users (since followed data may still be in localstorage)
            // and for the followed-cams pages (since we may be viewing offline cams, and since unfollowing a cam
            // from these pages should really be handled by updating the roomlist contents themselves, instead.)
            if (pageContext.current.loggedInUser === undefined || window.location.pathname.startsWith("/followed-cams/")) {
                return
            }
            const followedRooms = new Set(storage.onlineFollowedList.roomList.map((room) => room.room))
            this.updateAllFollowIcons(followedRooms)
        })
    }

    private bindFollowingIcons(): void {
        const roomListContainers = document.querySelectorAll(`#${this.roomListContainerID}`)
        for (const roomListContainer of roomListContainers) {
            const followingIconList = roomListContainer.querySelectorAll<HTMLDivElement>("div.follow_star")
            for (const followingIcon of followingIconList) {
                followingIcon.onclick = () => {
                    this.setFollowUnfollowStar(followingIcon)
                }
            }
        }
    }

    private onFollowClickSuccess(link: HTMLDivElement): void {
        link.classList.remove("icon_update_following")
        link.classList.add("icon_following", "no_hover")
        window.setTimeout(function(): void {
            link.classList.remove("no_hover")
        }, 3000)
        link.title = i18n.unfollowText
    }

    private updateAllFollowIcons(followedRooms: Set<string>): void {
        const roomListContainers = document.querySelectorAll(`#${this.roomListContainerID}`)
        for (const roomListContainer of roomListContainers) {
            // Select only NON-TS follow stars, as TS-rendered ones are updated explicitly via a similar listener in RoomList
            const followingIconList = roomListContainer.querySelectorAll<HTMLDivElement>("div.follow_star:not([ts])")
            for (const followingIcon of followingIconList) {
                const slug = followingIcon.dataset["slug"] ?? ""
                this.updateFollowIcon(followingIcon, followedRooms.has(slug))
            }
        }
    }

    private updateFollowIcon(link: HTMLDivElement, isFollowing: boolean): void {
        link.classList.remove("icon_update_following", "icon_following", "icon_not_following", "no_hover")
        if (isFollowing) {
            link.classList.add("icon_following")
            link.title = i18n.unfollowText
        } else {
            link.classList.add("icon_not_following")
            link.title = i18n.followText
        }
    }

    private defineFollowClick(link: HTMLDivElement): void {
        link.classList.remove("icon_not_following")
        link.classList.add("icon_update_following")
        const linkDataSlug = link.getAttribute("data-slug")

        if (linkDataSlug !== null) {
            const result = followRoom(linkDataSlug, this.isAnonymous)
            if (result !== undefined) {
                result.then((success) => {
                    if (success) {
                        this.onFollowClickSuccess(link)
                    } else {
                        this.defineUnFollowClick(link)
                    }
                }).catch(() => {})
            }
        }
    }

    private onUnFollowRoomDefined(link: HTMLDivElement): void {
        link.classList.remove("icon_update_following")
        link.classList.add("icon_not_following", "no_hover")
        window.setTimeout(function(): void {
            link.classList.remove("no_hover")
        }, 3000)
        link.title = i18n.followText
    }

    private onUnFollowRoomUnDefined(link: HTMLElement): void {
        link.classList.remove("icon_update_following")
        link.classList.add("icon_following")
    }

    private defineUnFollowClick(link: HTMLDivElement): void {
        link.classList.remove("icon_following")
        link.classList.add("icon_update_following")
        const linkDataSlug = link.getAttribute("data-slug")
        if (linkDataSlug !== null) {
            const result = unfollowRoom(linkDataSlug, this.isAnonymous)
            if (result !== undefined) {
                this.onUnFollowRoomDefined(link)
            } else {
                this.onUnFollowRoomUnDefined(link)
            }
        }
    }

    private setFollowUnfollowStar(link: HTMLDivElement): void {
        if (link.classList.contains("icon_following")) {
            this.defineUnFollowClick(link)
        } else if (link.classList.contains("icon_not_following")) {
            if (this.isAnonymous) {
                if (this.isMobile) {
                    modalConfirm("To follow this broadcaster, please create an account or login now.", () => {
                        showLoginOverlay({ fromFeature: true })
                    })
                } else {
                    showLoginOverlay({ fromFeature: true })
                }
            } else {
                this.defineFollowClick(link)
            }
        }
    }
}
