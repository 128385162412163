import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { rgbToHex } from "../colorUtils"
import { EventRouter } from "../events"
import { OverlayComponent } from "../overlayComponent"
import { styleTransition } from "../safeStyle"

function requestCORSIfNotSameOrigin(img: HTMLImageElement, url: string): void {
    if (!url.startsWith("/")) {
        img.setAttribute("crossorigin", "anonymous") // eslint-disable-line @multimediallc/no-set-attribute
    }
}
const transitionTime = 250

export class ColorPickerModal extends OverlayComponent {
    protected swatchElement: HTMLElement
    protected hexInput = document.createElement("input")
    protected initialColor: string
    private pickerImgLoaded = false
    private pickerImgLoadedEvent = new EventRouter<undefined>("pickerImgLoaded")

    constructor(swatchElement: HTMLElement, private onColorPicked: (color: string) => void, private onColorHovered: (color: string) => void) {
        super()

        this.swatchElement = swatchElement
        this.element.dataset.testid = "color-picker-modal"
        this.element.style.visibility = "hidden"
        this.element.style.width = "auto"
        this.element.style.height = "auto"
        this.element.style.opacity = "0"
        styleTransition(this.element, `opacity ${transitionTime}ms`)
        addEventListenerPoly("keydown", this.element, (event: KeyboardEvent) => {
            if (event.keyCode === 27) { // escape
                this.hide()
            }
        })

        this.overlayClick.listen(() => {
            this.hide()
        })

        this.createColorCanvas()
        this.createHexInput()
    }

    protected createColorCanvas(): void {
        const canvas = document.createElement("canvas")
        canvas.style.cursor = "crosshair"
        canvas.style.border = "1px solid #000001"
        this.element.appendChild(canvas)

        const colorPickerImage = document.createElement("img")
        requestCORSIfNotSameOrigin(colorPickerImage, `${STATIC_URL}colorPicker.png?v=1`)
        colorPickerImage.src = `${STATIC_URL}colorPicker.png?v=1`

        colorPickerImage.onload = () => {
            canvas.width = colorPickerImage.width
            canvas.height = colorPickerImage.height
            canvas.onclick = () => {
                this.initialColor = this.hexInput.value
                this.colorPicked(this.hexInput.value)
                this.hide()
            }
            canvas.onmousemove = (event: MouseEvent) => {
                const context = canvas.getContext("2d")
                if (context !== null && event.offsetX > 0 && event.offsetY > 0 && event.offsetX < canvas.clientWidth && event.offsetY < canvas.clientHeight) {
                    const pixelData = context.getImageData(event.offsetX, event.offsetY, 1, 1).data
                    const rgb = { red: pixelData[0], green: pixelData[1], blue: pixelData[2] }
                    this.hexInput.value = `#${rgbToHex(rgb)}`
                } else {
                    this.hexInput.value = this.initialColor
                }
                this.onColorHovered(this.hexInput.value)
            }
            canvas.onmouseleave = () => {
                this.hexInput.value = this.initialColor
                this.onColorHovered(this.hexInput.value)
            }
            const context = canvas.getContext("2d")
            if (context !== null) {
                context.drawImage(colorPickerImage, 0, 0, colorPickerImage.width, colorPickerImage.height)
            }
            this.hexInput.style.width = `${canvas.width - 8}px`
            this.pickerImgLoaded = true
            this.pickerImgLoadedEvent.fire(undefined)
        }
    }

    protected createHexInput(): void {
        this.hexInput.dataset.testid = "color-hex-input"
        this.hexInput.type = "text"
        this.hexInput.style.display = "block"
        this.hexInput.style.border = "1px solid #000000"
        this.hexInput.style.padding = "2px 4px"
        this.hexInput.style.position = "relative"
        this.hexInput.style.top = "-3px"
        this.hexInput.style.fontSize = "12px"
        this.hexInput.oninput = () => {
            this.onColorHovered(this.hexInput.value)
        }
        addEventListenerPoly("keydown", this.hexInput, (event: KeyboardEvent) => {
            if (event.keyCode === 13) { // enter
                this.colorPicked(this.hexInput.value)
                this.hide()
            }
        })
        this.element.appendChild(this.hexInput)
    }

    protected colorPicked(value: string): void {
        this.onColorPicked(value)
    }

    protected repositionChildren(): void {
        this.element.style.left = `${this.swatchElement.offsetLeft}px`
        this.element.style.top = `${this.swatchElement.offsetTop + this.swatchElement.offsetHeight + 2}px`
    }

    public show(initialColor: string): void {
        this.initialColor = initialColor
        if (!this.pickerImgLoaded) {
            this.pickerImgLoadedEvent.once(() => this.styleForShow())
        } else {
            this.styleForShow()
        }
    }

    protected styleForShow(): void {
        this.hexInput.value = this.initialColor
        this.element.style.opacity = "1"
        this.element.style.visibility = ""
        this.showOverlay()
        this.repositionChildrenRecursive()
    }

    public hide(): void {
        this.hideOverlay()
        this.element.style.opacity = "0"
        window.setTimeout(() => {
            this.element.style.visibility = "hidden"
        }, transitionTime)
    }
}
