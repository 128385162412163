import { titleCase } from "@multimediallc/web-utils"
import { isIE } from "@multimediallc/web-utils/modernizr"
import { addColorClass, colorClass } from "../cb/colorClasses"
import { isRoomRoomlistSpaEligiblePage } from "../cb/components/roomlist/spaHelpers";
import { makeResponsive, resizeDebounceEvent } from "../cb/ui/responsiveUtil"
import { buildTooltip } from "../cb/ui/tooltip"
import { normalizeResource } from "./api"
import {
    addPhotoSetClickedPageAction,
    parseBioApiResponse,
 sanitizeUserCreatedContent } from "./bioContent"
import { BaseRoomTab } from "./broadcastlib/broadcastTabs/baseRoomTab"
import { profileLoaded, roomLoaded } from "./context"
import { DivotPosition } from "./divot"
import { underlineOnHover } from "./DOMutils"
import {
    convertAllChildLinksToJoinOverlayAnchors,
    generateJoinOverlayAnchor,
} from "./theatermodelib/joinOverlay"
import { openPhotoVideoTabRequest } from "./theatermodelib/userActionEvents"
import { i18n } from "./translation"
import { dom } from "./tsxrender/dom"
import { safeWindowOpen } from "./windowUtils"
import type {
    IBioParsed,
    IBioPhotoset,
    IBioSocialMedia,
    IInfoSection } from "./bioContent"
import type { ITabInstance } from "./roomTabs"


export class BioContentTab<T extends object> extends BaseRoomTab<IBioParsed | T> implements ITabInstance {
    protected room: string
    private tableRef: HTMLElement
    private labelRef: HTMLTableCellElement
    private header: HTMLHeadingElement
    private bioRootWrapper: HTMLDivElement
    private bioRootElement: HTMLTableElement
    private adjustWidth: (() => void) | undefined
    private adjustMargin: (() => void) | undefined
    protected customSections: HTMLElement[] = []

    constructor() {
        super()
        addColorClass(this.element, "BioContents")
        this.element.dataset.testid = "room-bio-tab-contents"
        this.element.style.width = "100%"
        this.element.style.margin = "0 0 14px"

        this.loadingMsg.style.padding = "14px 14px 0"
        this.errorMsg.style.padding = "14px 14px 0"

        profileLoaded.listen(context => {
            this.loadRoom(context.dossier.room)
        })
        roomLoaded.listen((ev) => {
            this.loadRoom(ev.dossier.room)
        })
        window["refreshSocials"] = () => {
            this.load()
        }
    }

    protected loadRoom(room: string): void {
        this.room = room
        this.setResourceUrl(`api/biocontext/${this.room}/`)
        this.load()
    }

    public show(): void {
        this.element.style.display = ""
        this.element.style.height = ""
        this.element.style.minHeight = "325px"
        if (this.adjustMargin !== undefined) {
            this.adjustMargin()
        }
        if (this.adjustWidth !== undefined) {
            this.adjustWidth()
        }
        if (this.showingError) {
            // Don't reload the contents of this tab on show, unless an error is showing
            this.load()
        }
    }

    public hide(): void {
        this.element.style.height = "0"
        this.element.style.minHeight = "0"
        this.element.style.margin = "0"
    }

    protected parseData(rawData: string): IBioParsed | T {
        return parseBioApiResponse(rawData, this.room)
    }

    protected createContent(data: IBioParsed | T): void {
        this.createBioContents(data as IBioParsed)
    }

    protected clearContent(): void {
        super.clearContent()
        this.clearResizeListeners()
    }

    protected clearResizeListeners(): void {
        if (this.adjustWidth !== undefined) {
            resizeDebounceEvent.removeListener(this.adjustWidth)
        }
        if (this.adjustMargin !== undefined) {
            resizeDebounceEvent.removeListener(this.adjustMargin)
        }
    }

    private createResizeListeners(): void {
        this.clearResizeListeners()
        this.adjustWidth = makeResponsive(this.labelRef, 500, 600, [
            { name: "width", min: 117, max: 150 },
            { name: "min-width", min: 117, max: 150 },
        ])
        this.adjustMargin = makeResponsive(this.tableRef, 500, 530, [
            { name: "margin-right", min: 0, max: 14 },
            { name: "padding-right", min: 0, max: 14 },
        ])
    }

    protected repositionChildren(): void {
        for (const section of this.customSections) {
            sanitizeUserCreatedContent(section, this.bioRootWrapper)
        }
    }

    private createPhotoVideos(data: IBioParsed, canUpload = false): void {
        const videoPS = data.photoSets.filter(pv => pv.isVideo)
        const picturePS = data.photoSets.filter(pv => !pv.isVideo)
        if (videoPS.length > 0 || canUpload) {
            const videos = this.generatePhotoVideoSection(videoPS, data.infoSection.username, "video")
            this.bioRootElement.appendChild(videos)
        }
        if (picturePS.length > 0 || canUpload) {
            const photos = this.generatePhotoVideoSection(picturePS, data.infoSection.username)
            this.bioRootElement.appendChild(photos)
        }
    }

    protected createBioContents(data: IBioParsed, canUpload = false): void {
        this.bioRootWrapper = <div style={{ paddingLeft: "14px", overflow: "hidden" }}></div>

        this.bioRootElement = <table style={{
            tableLayout: "fixed",
            width: "100%",
        }} ref={(el: HTMLElement) => { this.tableRef = el }}>
            <tr style={{ height: "1px" }}>
                <th style={{
                    width: "150px",
                    minWidth: "150px",
                }} ref={(el: HTMLTableCellElement) => {
                    this.labelRef = el
                }}/>
                <th/>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
                <th colSpan={2}>
                    <h1 colorClass={[colorClass.defaultColor, "bioHeader"]} style={{
                        fontSize: `${fontSize}.4px`,
                        fontFamily: "UbuntuMedium, Arial, Helvetica, sans-serif",
                        fontWeight: "normal",
                        margin: "10px 0 20px",
                        lineHeight: "20px",
                        textAlign: "left",
                    }} ref={(el: HTMLHeadingElement) => {
                        this.header = el
                    }} data-testid="bio-header">
                    </h1>
                </th>
            </tr>
        </table>
        this.bioRootWrapper.appendChild(this.bioRootElement)
        this.element.appendChild(this.bioRootWrapper)
        this.createResizeListeners()

        const aboutSection = data.infoSection
        if (this.room !== aboutSection.username) {
            return
        }
        this.header.innerText = i18n.bioAndFreeWebCamText(titleCase(this.room))

        this.createSections(aboutSection)
        // endregion

        // region SocialMedia creation
        if (this.shouldShowSocialMediaSection(data.socialMedias.length)) {
            const socialMedias = this.generateSocialMediaSection(data.socialMedias, data.infoSection.username)
            this.bioRootElement.appendChild(socialMedias)
        }
        // endregion

        // region PhotoSet creation
        if (this.shouldShowPicsSection(data.photoSets.length)) {
            this.createPhotoVideos(data, canUpload)
        }
        // endregion

        // region About Me creation
        if (data.aboutMe.length >= 1) {
            const aboutMeSection = this.generateSectionWithUserHTML(data.aboutMe, i18n.aboutMeText)
            if (!isRoomRoomlistSpaEligiblePage()) {
                convertAllChildLinksToJoinOverlayAnchors(aboutMeSection)
            }
            this.bioRootElement.appendChild(aboutMeSection)
            this.afterCustomSectionCreated(aboutMeSection)
        }
        // endregion

        // region Wish List creation
        if (data.wishList.length >= 1) {
            const wishListSection = this.generateSectionWithUserHTML(data.wishList, i18n.wishListText)
            if (!isRoomRoomlistSpaEligiblePage()) {
                convertAllChildLinksToJoinOverlayAnchors(wishListSection)
            }
            this.bioRootElement.appendChild(wishListSection)
            this.afterCustomSectionCreated(wishListSection)
        }
        // endregion

        this.repositionChildren()
    }

    private createSections(aboutSection: IInfoSection): void {  // eslint-disable-line complexity
        if (aboutSection.realName !== "") {
            this.bioRootElement.appendChild(this.createSection(i18n.realNameText, aboutSection.realName, false))
        }
        this.bioRootElement.appendChild(this.createFollowersSection(aboutSection.followersCount))

        if (aboutSection.displayBirthday !== "") {
            this.bioRootElement.appendChild(this.createSection(i18n.birthdateText, aboutSection.displayBirthday, false))
        }
        if (!isNaN(aboutSection.displayAge)) {
            this.bioRootElement.appendChild(this.createSection(i18n.ageText, aboutSection.displayAge.toString(), false))
        }

        if (aboutSection.sex === "A Couple") {
            this.bioRootElement.appendChild(this.createSection(i18n.sexTextCouple, aboutSection.sex, false))
        } else if (aboutSection.sex === "Trans" && aboutSection.subgender) {
            this.bioRootElement.appendChild(this.createSection(i18n.sexText, `${aboutSection.sex} (${aboutSection.subgender})`, false))
        } else {
            this.bioRootElement.appendChild(this.createSection(i18n.sexText, aboutSection.sex, false))
        }

        if (aboutSection.interestedIn !== "") {
            let interestedInValue = aboutSection.interestedIn
            interestedInValue = interestedInValue.slice(1, (interestedInValue.length - 1))
            if (interestedInValue !== "") {
                while (interestedInValue.includes(`"`)) {
                    interestedInValue = interestedInValue.replace(`"`, "")
                }
                interestedInValue = interestedInValue.split(",").join(", ")
                this.bioRootElement.appendChild(this.createSection(i18n.interstedInText, interestedInValue, false))
            }
        }
        if (aboutSection.location !== "") {
            this.bioRootElement.appendChild(this.createSection(i18n.locationText, aboutSection.location, false))
        }
        if (aboutSection.lastBroadcast !== undefined && aboutSection.lastBroadcast !== "") {
            this.bioRootElement.appendChild(this.createSection(i18n.lastBroadcastText, aboutSection.lastBroadcast, false))
        }
        if (aboutSection.languages !== "") {
            this.bioRootElement.appendChild(this.createSection(i18n.languagesText, aboutSection.languages, false))
        }
        if (aboutSection.bodyType !== "") {
            this.bioRootElement.appendChild(this.createSection(i18n.bodyTypeText, aboutSection.bodyType, false))
        }
        if (aboutSection.smokeDrink !== "") {
            this.bioRootElement.appendChild(this.createSection(i18n.smokeOrDrinkText, aboutSection.smokeDrink, false))
        }
        if (aboutSection.bodyDecorations !== "") {
            this.bioRootElement.appendChild(this.createSection(i18n.bodyDecorationsText, aboutSection.bodyDecorations, false))
        }
    }

    protected createFollowersSection(followerCount: number): HTMLDivElement {
        return this.createSection(
            i18n.followersText,
            !isNaN(followerCount) ? followerCount.toString() : "0",
            false,
        )
    }

    protected shouldShowSocialMediaSection(socialMediaLength: number): boolean {
        return socialMediaLength >= 1
    }

    protected shouldShowPicsSection(picsLength: number): boolean {
        return picsLength >= 1
    }

    protected generateSocialMediaSection(socialMedias: IBioSocialMedia[], username: string): HTMLDivElement {
        const generateSocialMedia = (socialMedia: IBioSocialMedia) => {
            const sm = generateJoinOverlayAnchor()
            sm.dataset.testid = "social-media-item"
            const url = socialMedia.link
            sm.href = normalizeResource(url)
            sm.style.position = "relative"
            sm.style.display = "inline-block"
            sm.style.marginBottom = "17px"
            sm.style.marginRight = "5px"
            sm.style.cursor = "pointer"
            sm.className = "userUpload socialMediaUpload"
            sm.title = socialMedia.titleName
            underlineOnHover(sm)
            if (socialMedia.popup) {
                sm.onclick = () => {
                    safeWindowOpen(url, undefined, `resizable,dependent,scrollbars,height=700,width=700,top=${(screen.height / 2) - 600},left=${(screen.width / 2) - 800}`)
                    return false
                }
            } else {
                sm.target = "_blank"
            }
            const preview: HTMLImageElement = document.createElement("img")
            preview.dataset.testid = "social-media-preview"
            addColorClass(preview, "previewBorder")
            preview.src = socialMedia.imageUrl
            preview.width = previewWidth
            preview.height = previewHeight
            preview.style.borderWidth = "1px"
            preview.style.borderStyle = "solid"
            preview.style.display = "block"
            preview.style.borderRadius = "3%"
            preview.style.display = "block"
            sm.appendChild(preview)
            if (socialMedia.labelText !== "") {
                const tokens = document.createElement("span")
                tokens.dataset.testid = "social-media-tokens-badge"
                addColorClass(tokens, "tokenText")
                tokens.innerText = socialMedia.labelText
                tokens.style.backgroundColor = socialMedia.labelColor
                tokens.style.position = "absolute"
                tokens.style.bottom = "16px"
                tokens.style.right = "0"
                tokens.style.margin = "8px"
                tokens.style.fontSize = "9px"
                tokens.style.borderRadius = "2px"
                tokens.style.padding = "1px 3px"
                tokens.style.lineHeight = "12px"
                sm.appendChild(tokens)
            }
            const title = document.createElement("div")
            title.dataset.testid = "social-media-title"
            addColorClass(title, "link")
            title.innerText = socialMedia.titleName
            title.style.fontSize = `${fontSize}px`
            title.style.left = "0px"
            title.style.maxWidth = "145px"
            title.style.whiteSpace = "nowrap"
            title.style.textOverflow = "ellipsis"
            title.style.overflow = "hidden"
            title.style.lineHeight = "16px"
            title.style.textDecoration = "none"
            sm.appendChild(title)
            return sm
        }
        const content = []
        let numberOfSocialMedias = 0
        const maxNumOfSocialMedias = 8
        for (const socialMedia of socialMedias) {
            if (numberOfSocialMedias === maxNumOfSocialMedias) {
                break
            }
            content.push(generateSocialMedia(socialMedia))
            numberOfSocialMedias += 1
        }
        const needsShowMoreLink = (socialMedias.length > maxNumOfSocialMedias)
        content.push(this.generateSocialMediaLinks(username, needsShowMoreLink))
        const section = this.createSection(i18n.socialMediaText, content)
        addColorClass(section, "smContainer")
        return section
    }

    protected generateSocialMediaLinks(username: string, needsShowMore: boolean): HTMLDivElement {
        // needed to force "display:block" without width + click handler side effects
        const socialMediaLinks = document.createElement("div")
        socialMediaLinks.style.marginBottom = "20px"
        if (needsShowMore) {
            const showMoreLink = generateJoinOverlayAnchor()
            const showMoreHref = `/socials/social_media/list_popup/${username}/`
            addColorClass(showMoreLink, "link")
            showMoreLink.href = normalizeResource(showMoreHref)
            showMoreLink.innerText = i18n.showMoreText
            showMoreLink.style.fontSize = "14px"
            showMoreLink.style.cursor = "pointer"
            underlineOnHover(showMoreLink)
            showMoreLink.onclick = (ev) => {
                safeWindowOpen(showMoreHref, undefined,
                    `resizable,dependent,scrollbars,height=600,width=800,top=${screen.height / 2},left=${screen.width / 2}`)
                ev.preventDefault()
            }
            socialMediaLinks.appendChild(showMoreLink)
        }

        return socialMediaLinks
    }

    private generatePhotoVideoSection(photosets: IBioPhotoset[], username: string, type = "photo"): HTMLDivElement {
        // Function for generating photo & video ui thumbnail element
        const generatePhotoset = (photoset: IBioPhotoset) => {
            const ps = generateJoinOverlayAnchor()
            ps.dataset.testid="photo-video-item"
            ps.href = normalizeResource(`/photo_videos/photoset/detail/${username}/${photoset.id}`)
            ps.target = "_blank"
            ps.style.position = "relative"
            ps.style.display = "inline-block"
            ps.style.marginBottom = "17px"
            ps.style.marginRight = "5px"
            ps.style.cursor = "pointer"
            underlineOnHover(ps)
            ps.className = "userUpload"
            // Disable title on IE11 to prevent link title tooltip
            // from overlapping with no-sound-icon tooltip
            if (!isIE()) {
                ps.title = photoset.name
            }
            ps.onclick = (ev) => {
                addPhotoSetClickedPageAction(photoset, "BioTab")
                this.handlePictureClick(ev, photoset.id, ps.href)
            }

            const preview: HTMLImageElement = document.createElement("img")
            preview.dataset.testid="photo-video-preview"
            addColorClass(preview, "previewBorder")
            preview.src = photoset.coverUrl
            preview.width = previewWidth
            preview.height = previewHeight
            preview.style.borderWidth = "1px"
            preview.style.borderStyle = "solid"
            preview.style.display = "block"
            preview.style.borderRadius = "3%"
            preview.style.display = "block"
            ps.appendChild(preview)

            if (!photoset.userCanAccess) {
                // Add purchase overlay
                const lockedOverlay: HTMLDivElement = document.createElement("div")
                lockedOverlay.style.width = `${previewWidth + 1}px`
                lockedOverlay.style.height = `${previewHeight + 1}px`
                lockedOverlay.style.borderRadius = "3%"
                lockedOverlay.style.position = "absolute"
                lockedOverlay.style.left = "0px"
                lockedOverlay.style.top = "0px"
                lockedOverlay.style.backgroundColor = "rgba(0, 0, 0, .55)"
                ps.appendChild(lockedOverlay)

                const locked: HTMLImageElement = document.createElement("img")
                locked.dataset.testid = "lock-icon"
                const lockImgDim = 18
                locked.src = `${STATIC_URL}lock.svg`
                locked.width = lockImgDim
                locked.height = lockImgDim
                locked.style.marginLeft = `${-lockImgDim / 2}px`
                locked.style.marginTop = `${-lockImgDim / 2}px`
                locked.style.position = "absolute"
                locked.style.left = "50%"
                locked.style.top = "40%"
                locked.style.padding = "4px"
                locked.style.border = "none"
                ps.appendChild(locked)

                if (photoset.labelText !== "") {
                    // Token baddge creation
                    const tokens = document.createElement("span")
                    tokens.dataset.testid = "token-badge"
                    addColorClass(tokens, "tokenText")
                    tokens.innerText = photoset.labelText
                    tokens.style.backgroundColor = photoset.labelColor
                    tokens.style.position = "absolute"
                    tokens.style.bottom = "16px"
                    tokens.style.right = "0"
                    tokens.style.margin = "8px"
                    tokens.style.fontSize = "9px"
                    tokens.style.padding = "1px 3px"
                    tokens.style.lineHeight = "12px"
                    tokens.style.borderRadius = "2px"
                    ps.appendChild(tokens)
                }
            } else if (photoset.userCanAccess && photoset.userHasPurchased) {
                // Add purchased badge
                const purchased = document.createElement("span")
                purchased.dataset.testid = "purchased-badge"
                addColorClass(purchased, "purchasedBadge")
                purchased.style.backgroundColor = "#6d85b5"
                purchased.style.color = "#fff"
                purchased.style.position = "absolute"
                purchased.style.top = "80px"
                purchased.style.right = "8px"
                purchased.style.padding = "1px 3px"
                purchased.style.lineHeight = "12px"
                purchased.style.borderRadius = "2px"
                purchased.style.fontSize = "9px"
                purchased.textContent = i18n.purchasedCAPS
                ps.appendChild(purchased)
            }
            if (photoset.isVideo) {
                const videoIcon = document.createElement("img")
                videoIcon.src = `${STATIC_URL}video.svg`
                videoIcon.height = 18
                videoIcon.style.position = "absolute"
                videoIcon.style.top = "8px" // padding + border
                videoIcon.style.right = "8px" // padding + border
                ps.appendChild(videoIcon)
            }
            if (photoset.isVideo && !photoset.videoHasSound && !photoset.pendingApproval) {
                const noSoundIcon = document.createElement("img")
                noSoundIcon.src = `${STATIC_URL}no-audio.svg`
                noSoundIcon.height = 18
                noSoundIcon.style.position = "absolute"
                noSoundIcon.style.top = "8px" // padding + border
                noSoundIcon.style.right = "32px" // padding + border

                const toolTip: HTMLDivElement = buildTooltip({
                                content: `${i18n.audioRemovedText}`,
                                hasHTML: false,
                                width: 175,
                                divotPosition: DivotPosition.Bottom,
                                divotLeftOrTop: "90px" })
                toolTip.style.textAlign = "center"

                const span = document.createElement("span")
                toolTip.appendChild(span)

                ps.appendChild(toolTip)
                noSoundIcon.onmouseenter = () => {
                    toolTip.style.display = "block"

                    toolTip.style.left = "12px"
                    toolTip.style.top = "-50px"
                }
                noSoundIcon.onmouseleave = () => {
                    toolTip.style.display = "none"
                }
                ps.appendChild(noSoundIcon)
            }
            const title = document.createElement("div")
            title.dataset.testid = "title"
            addColorClass(title, "link")
            title.innerText = photoset.name
            title.style.fontSize = `${fontSize}px`
            title.style.left = "0px"
            title.style.maxWidth = "145px"
            title.style.whiteSpace = "nowrap"
            title.style.textOverflow = "ellipsis"
            title.style.overflow = "hidden"
            title.style.lineHeight = "16px"
            title.style.textDecoration = "none"
            ps.appendChild(title)
            return ps
        }

        // Show at most 8 media items with shore more link if more
        const content = []
        let numberOfPictureVideos = 0
        const maxNumOfPictureVideos = 8
        for (const photo of photosets) {
            if (numberOfPictureVideos === maxNumOfPictureVideos) {
                break
            }
            content.push(generatePhotoset(photo))
            numberOfPictureVideos += 1
        }
        const needsShowMoreLink = (photosets.length > maxNumOfPictureVideos)
        let showId
        if (needsShowMoreLink) {
            showId = photosets[0].id
        }
        const needsShowMoreDiv = this.generatePicsLinks(username, needsShowMoreLink, type, showId)
        needsShowMoreDiv.style.marginBottom = "20px"
        content.push(needsShowMoreDiv)
        const section = this.createSection(
            type === "video" ? i18n.vidsText : i18n.picsText,
            content,
        )
        addColorClass(section, "psContainer")
        return section
    }

    protected handlePictureClick(ev: Event, id: number, href: string): void {
        openPhotoVideoTabRequest.fire(id)
        ev.preventDefault()
    }

    protected generatePicsLinks(username: string, needsShowMore: boolean, type = "photo", id: number | undefined = undefined): HTMLDivElement {
        // needed to force "display:block" without width + click handler side effects
        const picsLinksContainer = document.createElement("div")
        if (needsShowMore) {
            const showMoreLink = generateJoinOverlayAnchor()
            showMoreLink.href = normalizeResource(`/photo_videos/photoset/list_popup/${username}/`)
            addColorClass(showMoreLink, "link")
            showMoreLink.innerText = i18n.showMoreText
            showMoreLink.style.fontSize = "14px"
            showMoreLink.style.cursor = "pointer"
            underlineOnHover(showMoreLink)
            showMoreLink.onclick = (ev) => {
                openPhotoVideoTabRequest.fire(id)
                ev.preventDefault()
            }
            picsLinksContainer.appendChild(showMoreLink)
        }

        return picsLinksContainer
    }

    protected createSection(label: string, content: string | HTMLElement[], html = false): HTMLDivElement {
        const testIdLabel = label.toLowerCase().replace(/[ \/]/g, "-").replace(/[()\/]/g,"").replace(/--/g,"")
        const section = (
            <tr style={{
                fontSize: `${fontSize}px`,
                fontWeight: "normal",
                lineHeight: "15px",
                verticalAlign: "top",
                textAlign: "left",
            }}>
                <td colorClass="label" style={{
                    paddingBottom: "9px",
                    fontFamily: "UbuntuMedium, Arial, Helvetica, sans-serif",
                    height: "16px",
                }}><span data-testid={`bio-tab-${testIdLabel}-label`}>{label}:</span>
                </td>
                <td className="contentText bioContentText" style={{
                    fontSize: `${fontSize}px`,
                    lineHeight: "16px",
                    fontFamily: "UbuntuRegular, Arial, Helvetica, sans-serif",
                }} ref={(el: HTMLElement) => {
                    if (typeof content === "string") {
                        if (html) {
                            const container = <div data-testid={`bio-tab-${testIdLabel}-value`}></div>
                            el.appendChild(container)
                            container.innerHTML = content // eslint-disable-line @multimediallc/no-inner-html
                            if (container.children.length !== 0) {
                                const userContent = container.children[0] as HTMLElement
                                userContent.style.display = "unset"
                            }
                        } else {
                            el.innerText = content
                        }
                    } else {
                        content.forEach(elem => {
                            el.appendChild(elem)
                        })
                    }
                    if(!html) {
                        el.dataset["testid"] = `bio-tab-${testIdLabel}-value`
                    }
                }}>
                </td>
            </tr>
        )

        return section
    }

    private generateSectionWithUserHTML(sectionSpec: string, sectionTitle: string): HTMLElement {
        return this.createSection(titleCase(sectionTitle), sectionSpec, true)
    }

    protected afterCustomSectionCreated(section: HTMLElement): void {
        this.customSections.push(section)
        sanitizeUserCreatedContent(section, this.bioRootWrapper)
    }
}

// region Constants declarations
const fontSize = 14
const previewWidth = 150
const previewHeight = 100
// endregion
