import { ArgJSONMap } from "@multimediallc/web-utils"
import { modalAlert } from "../../common/alerts"
import { getCb, postCb, XhrError } from "../../common/api"
import { isAnonymous } from "../../common/auth"
import { parseFromUser, parsePushPrivateMessage } from "../../common/chatconnection/messageParsers"
import { EventRouter } from "../../common/events"
import { addPageAction } from "../../common/newrelic"
import { ShortcodeParser } from "../../common/specialoutgoingmessages"
import { i18n } from "../../common/translation"
import { buildQueryString } from "../../common/urlUtil"
import { pageContext, roomDossierContext } from "../interfaces/context"
import { RoomListSource } from "../roomList"
import type { IChatMedia, IPrivateMessage, IUserInfo } from "../../common/messageInterfaces"

export const pmHistoryBatchSize = 50

export const createNewSession = new EventRouter<string>("createNewSession")

export function dmsEnabled(): boolean {
    return pageContext.current.dmsEnabled
}

export enum ConversationType {
    PM = "PM",
    DM = "DM",
}

export interface IConversationListItem {
    message: string,
    numUnread: number,
    time?: number,
    fromUsername: string,
    otherUser: IUserInfo,
    hasMedia: boolean,
    room?: string,
    origMessage?: IPrivateMessage,
}

export function parseConversationListItemResponse(p: ArgJSONMap): IConversationListItem {
    return {
        message: p.getString("m"),
        numUnread: p.getNumber("num_unread"),
        time: p.getNumber("created_at") * 1000,
        fromUsername: p.getString("from_user"),
        otherUser: parseFromUser(new ArgJSONMap(p.getObject("other_user"))),
        hasMedia: p.getBoolean("has_media"),
    }
}

export function createBroadcasterUserInfo(broadcaster: string): IUserInfo {
    return {
        username: broadcaster,
        isBroadcaster: true,
        inFanclub: false,
        hasTokens: false,
        isMod: false,
        tippedRecently: false,
        tippedALotRecently: false,
        tippedTonsRecently: false,
        exploringHashTag: "",
        sourceName: RoomListSource.Default,
    }
}

export function createBroadcasterConversationItem(broadcaster: string): IConversationListItem {
    const userInfo = createBroadcasterUserInfo(broadcaster)

    return {
        message: "",
        numUnread: 0,
        fromUsername: broadcaster,
        hasMedia: false,
        otherUser: userInfo,
    }
}

export interface IPrivateMessageList {
    messages: IPrivateMessage[],
    numUnread: number,
}

function parsePmMessageList(responseMap: ArgJSONMap): IPrivateMessageList {
    const messageList = responseMap.getList("messages")
    if (messageList === undefined) {
        return {
            messages: [],
            numUnread: 0,
        } as IPrivateMessageList
    }
    return {
        messages: messageList.map((pm: ArgJSONMap) => {
            return parsePushPrivateMessage(pm)
        }),
        numUnread: responseMap.getNumber("num_unread"),
    } as IPrivateMessageList
}

const roomHistoryPromises = new Map<string, Promise<IPrivateMessageList>>()
export function getRoomHistoryMessages(username: string, room: string | undefined, offset: string | undefined): Promise<IPrivateMessageList> {
    if (offset === undefined || isAnonymous()) {
        return Promise.resolve({ messages: [], numUnread: 0 })
    }
    const cacheKey = `${username}-${room}-${offset}`
    let roomHistoryPromise = roomHistoryPromises.get(cacheKey)
    if (roomHistoryPromise === undefined) {
        const params = buildQueryString({ "offset": offset, "room": room })
        roomHistoryPromise = getCb(`api/ts/chatmessages/pm_list/${username}/?${params}`).then((xhr: XMLHttpRequest) => {
            return parsePmMessageList(new ArgJSONMap(xhr.responseText))
        })
        roomHistoryPromises.set(cacheKey, roomHistoryPromise)
        window.setTimeout(() => {
            roomHistoryPromises.delete(cacheKey)
        }, 100)
    }
    return roomHistoryPromise
}

export function getDmHistoryMessages(username: string, offset?: string): Promise<IPrivateMessageList> {
    let pmListUrl = `api/ts/chatmessages/pm_history/${username}/`
    if (offset !== undefined) {
        pmListUrl += `?${buildQueryString({ "offset": offset })}`
    }
    return getCb(pmListUrl).then((response) => {
        return parsePmMessageList(new ArgJSONMap(response.responseText))
    })
}

export function getUnreadPms(otherUser: string, room: string, offset: string | undefined): Promise<IPrivateMessage[]> {
    return new Promise((resolve, reject) => {
        getRoomHistoryMessages(otherUser, room, offset ?? "0").then((pmList) => {
            if (pmList.numUnread <= 0) {
                return resolve([])
            }

            const unreadMessages = pmList.messages.slice(-pmList.numUnread)
            return resolve(unreadMessages)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const enum PrivateMessageSource {
    RoomViewPM = "roomviewPM",
    MobilePM = "mobilePM",
    Mobile = "mobile",
    DM = "DM",
}

export const PrivateMessageSources = [
    PrivateMessageSource.RoomViewPM,
    PrivateMessageSource.MobilePM,
    PrivateMessageSource.Mobile,
    PrivateMessageSource.DM,
]

export interface ISendPrivateMessage {
    message: string,
    username: string,
    source: PrivateMessageSource,
    roomName?: string,
    media?: IChatMedia[],
}

export interface IPMError {
    username: string,
    errorMessage: string,
    showDmLink: boolean,
}

function serializePmBody(privateMessage: ISendPrivateMessage): string {
    const messageData: {
        m: string,
        media_id?: number[],
    } = {
        "m": privateMessage.message,
    }
    if (privateMessage.media !== undefined) {
        messageData["media_id"] = privateMessage.media.map(image => image.mediaId)
    }
    return JSON.stringify(messageData)
}

export async function sendPrivateMessage(privateMessage: ISendPrivateMessage): Promise<void> {
    if (ShortcodeParser.isShortcodeSyntax(privateMessage.message)) {
        // Shortcode syntax is not allowed in PMs
        const errorPM: IPMError = {
            username: privateMessage.username,
            errorMessage: privateMessage.source === PrivateMessageSource.DM ?
                i18n.shortcodeNotSupportedInDMs :
                i18n.shortcodeNotSupportedInPMs,
            showDmLink: false,
        }
        return Promise.reject(errorPM)
    }
    if (pageContext.current.loggedInUser === undefined) {
        // Should never be hit, mostly to guarantee to the type checker that loggedInUser is defined below
        error("Sending private message as anon")
        return Promise.reject(i18n.loggedInForFeature)
    }
    if (pageContext.current.isNoninteractiveUser) {
        modalAlert(i18n.internalStaffMessage)
        return Promise.reject({
            username: privateMessage.username,
            errorMessage: i18n.internalStaffMessage,
            showDmLink: false,
        })
    }

    const { username, source, roomName = "" } = privateMessage
    const privateShowId = roomDossierContext.getState().privateShowId
    const formData = new FormData()
    const messageData = serializePmBody(privateMessage)
    formData.append("room", roomName)
    formData.append("to_user", username)
    formData.append("from_user", pageContext.current.loggedInUser.username)
    formData.append("message", messageData)
    if (privateShowId !== "") {
        formData.append("private_show_id", privateShowId)
    }
    return postCb("api/ts/chatmessages/pm_publish/", formData).then((res: XMLHttpRequest) => {
            const data = new ArgJSONMap(res.responseText)
            const attributes = {
                "username": username,
                "source": source,
                "is_reply": data.getBoolean("is_reply"),
                "token_color": data.getStringOrUndefined("token_color"),
                "in_fanclub": data.getBoolean("in_fanclub", undefined, false),
                "success": data.getString("status") === "Ok",
            }
            addPageAction("SendPrivateMessage", attributes)

            const status = data.getStringOrUndefined("status", false)
            if (status !== "Ok") {
                const error: IPMError = {
                    username: username,
                    errorMessage: status ?? i18n.errorSendingMessage,
                    showDmLink: data.getBoolean("show_sitewide_pm_link", false, false),
                }
                return Promise.reject(error)
            }
            return Promise.resolve()
        }).catch((err: XhrError | IPMError) => {
            error("Could not send private message: ", err)
            if (err instanceof XhrError) {
                const errorMessage = new ArgJSONMap(err.xhr.responseText).getStringOrUndefined("error")
                if (errorMessage !== undefined) {
                    return Promise.reject({ errorMessage: errorMessage, isOfflineUserError: false })
                } else {
                    return Promise.reject({ errorMessage: i18n.errorSendingMessage, isOfflineUserError: false })
                }
            } else {
                return Promise.reject(err)
            }
        })
}

export interface IUserInfoAndUnread {
    numUnread: number
    canPm: boolean  // Specifies whether the requesting user is able to PM the requested user
    canTip: boolean  // Specifies whether the requesting user is able to tip the requested user
    user: IUserInfo
    sitewideUser: IUserInfo
}

function parseUserInfo(p: ArgJSONMap): IUserInfoAndUnread {
    return {
        numUnread: p.getNumber("num_unread"),
        canPm: p.getBoolean("can_pm"),
        canTip: p.getBoolean("can_tip"),
        user: parseFromUser(new ArgJSONMap(p.getObject("user"))),
        sitewideUser: parseFromUser(new ArgJSONMap(p.getObject("sitewide_user"))),
    }
}

// Prevents excessive identical API calls within a few seconds. In rare
// cases there could be two in quick succession but never more.
const cachedUserInfo = new Map<string, Promise<IUserInfoAndUnread>>()

export function getUserInfo(user: string, room?: string): Promise<IUserInfoAndUnread> {
    let roomQueryString = ""
    if (room !== undefined && room !== "") {
        roomQueryString = `?${buildQueryString({ "room": room })}`
    }

    const query = `api/ts/chatmessages/user_info/${user}/${roomQueryString}`

    // if the promise is cached, return that
    if (cachedUserInfo.has(query)) {
        return cachedUserInfo.get(query) as Promise<IUserInfoAndUnread>
    }

    // otherwise add the promise to the cache
    const promise = getCb(query).then((xhr: XMLHttpRequest) => {
        const json = new ArgJSONMap(xhr.responseText)
        const parsedUserInfo = parseUserInfo(json)

        const sitewide = room === undefined
        const currentRoom = roomDossierContext.getState().room
        if (sitewide && currentRoom !== "" && user === currentRoom) {
            parsedUserInfo.user.isBroadcaster = true
        }

        window.setTimeout(() => { cachedUserInfo.delete(query) }, 2500) // remove it from the cache after 2.5 seconds
        return parsedUserInfo
    }).catch((err) => {
        error(`Error getting user info`, {
            "user": user,
            "room": room,
            "error": err.toString(),
        })
        cachedUserInfo.delete(query)
        throw err
    })
    cachedUserInfo.set(query, promise)
    return promise
}
