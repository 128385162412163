import { addPageAction } from "./newrelic"

declare global {
    interface Window {
        experiments: {
            active: string
            eligible: string
        }
    }

}

const recordedExp: Record<string, boolean> = {}

export class Experiment {
    readonly name: string
    readonly active: boolean = false
    readonly eligible: boolean = false

    constructor(name: string) {
        this.name = name
        const experiments = window["experiments"]
        if (experiments !== undefined) {
            const testRegExp = new RegExp(`\\b${name}\\b`)
            const active = experiments["active"]
            this.active = active !== undefined && testRegExp.test(active)
            const eligible = experiments["eligible"]
            this.eligible = eligible !== undefined && testRegExp.test(eligible)
        }
    }

    record(): void {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!this.eligible || recordedExp[this.name]) {
            return
        }
        const attrs = {
            "experimentName": this.name,
            "experimentGroup": this.active ? "active" : "inactive",
            "experimentTreatment": this.active ? this.name : "control",
        }
        addPageAction("ExperimentEntered", attrs, true)
        recordedExp[this.name] = true
    }
}

const recordedNamespace: Record<string, boolean> = {}

/**
 * Represents a split test with mutually exclusive sides and a control group.
 */
export class MultiSidedExperiment {
    readonly active: boolean = false
    readonly eligible: boolean = false
    readonly activeSide: string | undefined

    constructor(names: string[], readonly namespace: string) {
        const experiments = window["experiments"]
        if (experiments !== undefined) {
            const activeNames = experiments["active"]
            const eligibleNames = experiments["eligible"]
            if (eligibleNames !== undefined) {
                for (const name of names) {
                    const testRegExp = new RegExp(`\\b${name}\\b`)
                    if (testRegExp.test(eligibleNames)) {
                        this.eligible = true
                        break
                    }
                }

                if (this.eligible && activeNames !== undefined) {
                    for (const name of names) {
                        const testRegExp = new RegExp(`\\b${name}\\b`)
                        if (testRegExp.test(activeNames)) {
                            this.active = true
                            this.activeSide = name
                            break
                        }
                    }
                }
            }
        }
    }

    isControlGroup(): boolean {
        return this.eligible && !this.active
    }

    record(): void {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!this.eligible || recordedNamespace[this.namespace]) {
            return
        }
        const attrs = {
            "experimentName": this.namespace,
            "experimentGroup": this.active ? "active" : "inactive",
            "experimentTreatment": this.active ? this.activeSide : "control",
        }
        addPageAction("ExperimentEntered", attrs, true)
        recordedNamespace[this.namespace] = true
    }
}
