import { type IURLState, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { getCb } from "../../../common/api"
import { isAnonymous } from "../../../common/auth"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { isArrowDownKey, isArrowUpKey } from "../../../common/eventsUtil"
import { getCurrentGender } from "../../../common/genders"
import { addPageAction } from "../../../common/newrelic"
import { dom } from "../../../common/tsxrender/dom"
import { addColorClass, removeColorClass } from "../../colorClasses"
import { SuggestionClicked } from "./baseSuggestedItem"
import { HashtagSuggestedItem } from "./hashtagSuggestedItem"
import { historyDeleteClicked, HistorySuggestedItem } from "./historySuggestedItem"
import { OfflineSuggestedItem } from "./offlineSuggestedItem"
import { OnlineSuggestedItem } from "./onlineSuggestedItem"
import { cleanSearchHistory, getUserHistoryKey } from "./searchHistory"
import { SearchInput } from "./searchInput"
import type { ISearchHistory } from "./searchHistory"
import type { Gender } from "../../../common/genders"

interface ISuggestion {
    label: string,
    element: HTMLDivElement,
}

export interface IInputSuggestionsProps {
    input: HTMLInputElement,
    searchInput: SearchInput,
}

export const enum ResultType {
    Online = "online",
    Offline = "offline",
    Hashtag = "hashtags",
    History = "history",
}

const HASHTAG_RESULT_ORDER = [ResultType.Hashtag, ResultType.Online, ResultType.Offline]
const MAX_SUGGESTIONS = 10
const PAUSE_SUGGESTIONS_TIMER = 60000
export const SUGGESTIONS_DELAY_MS = 250
export const tabKey = "Tab"

export class InputSuggestions extends HTMLComponent<HTMLDivElement, IInputSuggestionsProps> {
    protected suggestionsList: ISuggestion[]
    private currentIndex = -1
    private highestIndex = -1
    private keyNavigated = false
    private lastSearch: string | undefined
    private pauseSuggestions = false
    private searchInput: SearchInput
    private firstText: string
    private preventShow: boolean
    protected input: HTMLInputElement
    public timeoutId = 0
    public suggestionPromise: Promise<void>
    private gender: Gender

    protected createElement(props: IInputSuggestionsProps): HTMLDivElement {
        return <div className="suggestionsDiv"/>
    }

    protected initData(props: IInputSuggestionsProps): void {
        this.input = props.input
        this.searchInput = props.searchInput
        this.suggestionsList = []
        this.gender = getCurrentGender()

        SearchInput.onSubmit.listen(() => {
            this.lastSearch = undefined
        })

        SuggestionClicked.listen((labelText: string) => {
            if (labelText !== null) {
                this.input.value = labelText
                this.searchInput.onSubmitSearchInput()
            }
        })

        historyDeleteClicked.listen((label: string) => {
            const index = this.suggestionsList.findIndex((sugg) => {
                return sugg.label === label
            })
            this.suggestionsList[index].element.remove()
            this.suggestionsList.splice(index, 1)
            // Update highest index after removing suggestion.
            this.highestIndex = this.suggestionsList.length - 1
            this.showSuggestions()
            this.input.focus()
            this.input.select()
        })
    }

    private styleActiveSuggestion(row: HTMLDivElement): void {
        addColorClass(row, "active")
    }

    private styleInactiveSuggestion(row: HTMLDivElement): void {
        removeColorClass(row, "active")
    }

    public removeExistingSuggestions(): void {
        this.suggestionsList = []
        this.highestIndex = -1
        while (this.element.lastChild !== null) {
            this.element.removeChild(this.element.lastChild)
        }
        this.hideElement()
    }

    private createUrlForHashtagSuggestion(tag: string): string {
        const currentState = { ...UrlState.current.state }
        const newState: IURLState = {
            genders: currentState.genders,
            regions: currentState.regions,
            ageMin: currentState.ageMin,
            ageMax: currentState.ageMax,
            privatePrices: currentState.privatePrices,
            roomSize: currentState.roomSize,
            tags: [tag],
        }
        return UrlState.current.convertStateToUrl(newState)
    }

    private createSuggestion(labelText: string, input: string, resultType: ResultType, count: number, historyType?: ResultType): void {
        let suggestion: HTMLElement
        let href = `/${labelText}/`
        this.gender = getCurrentGender()
        switch (resultType) {
            case ResultType.Online:
                suggestion = new OnlineSuggestedItem({ labelText, input, count, href }).element
                break
            case ResultType.Offline:
                suggestion = new OfflineSuggestedItem({ labelText, input, count, href }).element
                break
            case ResultType.Hashtag:
                href = this.createUrlForHashtagSuggestion(labelText)
                suggestion = new HashtagSuggestedItem({ labelText, input, count, href }).element
                suggestion.dataset.testid = "search-hashtag-suggestion"
                break
            default:
                if (historyType === ResultType.Hashtag) {
                    href = this.createUrlForHashtagSuggestion(labelText.replace("#", ""))
                } else if (historyType === ResultType.History) {
                    href = ""
                }
                suggestion = new HistorySuggestedItem({ labelText, input, count, href }).element
                break
        }
        this.element.appendChild(suggestion)
        // Hover events
        const row = suggestion.firstElementChild as HTMLDivElement
        row.onmouseenter = () => {
            this.styleInactiveLastSug()
            this.styleActiveSuggestion(row)
            const strIndex = suggestion.getAttribute("index")
            if (strIndex !== null) {
                this.currentIndex = parseInt(strIndex, 10)
            }
            this.keyNavigated = false
        }
        row.onmouseleave = () => {
            this.styleInactiveLastSug()
            this.styleInactiveSuggestion(row)
            this.currentIndex = -1
            this.keyNavigated = false
        }
        this.suggestionsList.push({ label: labelText, element: row })
    }

    public navigateSuggestions(e: KeyboardEvent): void {
        if (this.suggestionsList.length > 0) {
            this.saveFirstText()
            this.keyNavigated = true
            const prevIndex = this.currentIndex
            if (prevIndex > -1) {
                this.styleInactiveSuggestion(this.suggestionsList[prevIndex].element)
            }
            this.tabThroughSuggestions(e)
            if (isArrowDownKey(e)) {
                this.currentIndex = this.currentIndex + 1 <= this.highestIndex ? this.currentIndex + 1 : 0
            } else if (isArrowUpKey(e)) {
                this.currentIndex = this.currentIndex - 1 >= 0 ? this.currentIndex - 1 : this.highestIndex
                // Prevent cursor moving to the beginning.
                e.preventDefault()
            }
            const currentRow = this.suggestionsList[this.currentIndex]
            if (currentRow !== undefined) {
                this.replaceInputValue(currentRow)
                this.styleActiveSuggestion(currentRow.element)
            }
            this.styleAllInactiveSuggestions()
        }
    }

    private replaceInputValue(currentRow: ISuggestion): void {
        // Replaces this.input.element.value with the current row suggestion
        const rowClass = currentRow.element.getAttribute("class") ?? ""
        if (rowClass !== undefined) {
            this.input.value = rowClass.includes("hashtag_suggestion") ? `#${currentRow.label}` : currentRow.label
        }
    }

    private tabThroughSuggestions(e: KeyboardEvent): void {
        if (e.key === tabKey) {
            if (this.currentIndex > -1 && this.currentIndex <= this.highestIndex) {
                if (e.shiftKey) {
                    if (this.currentIndex === 0) {
                        this.resetSuggestionsNavigation()
                        this.input.value = this.firstText
                        return
                    }
                    this.currentIndex = this.currentIndex - 1
                } else {
                    if (this.currentIndex === this.highestIndex) {
                        this.resetSuggestionsNavigation()
                        this.input.value = this.firstText
                        return
                    }
                    this.currentIndex = this.currentIndex + 1
                }
                e.preventDefault()
            } else if (this.currentIndex === -1 && !e.shiftKey) {
                this.currentIndex = 0
                e.preventDefault()
            }
        }
    }

    private saveFirstText(): void {
        if (!this.keyNavigated) {
            this.firstText = this.input.value
        }
    }

    public getSuggestionsAfterTime(show: boolean): void {
        if (!this.pauseSuggestions) {
            if (this.timeoutId !== 0) {
                // reset the limiter
                window.clearTimeout(this.timeoutId)
            }
            const input = this.input.value
            const cleanedInput = input.trim().replace(/^#/, "%23")
            // Prevent autocomplete spam by checking length
            const spacedInput = cleanedInput.split(" ")
            const tooLong = (stringArr: string[]) => {
                return stringArr.some(el => el.length > 30) || stringArr.length > 6 || cleanedInput.length > 60
            }
            const currentGender = getCurrentGender()
            // Set the timeout for the suggestions
            if (((cleanedInput !== this.lastSearch) || (this.gender !== currentGender)) && !tooLong(spacedInput)) {
                this.timeoutId = window.setTimeout(() => {
                    this.suggestionPromise = this.getSuggestions(show)
                }, SUGGESTIONS_DELAY_MS)
            } else {
                if (tooLong(spacedInput)) {
                    this.showHistorySuggestions(cleanedInput)
                }
                show ? this.showSuggestions() : this.hideSuggestions()
            }
        } else if (this.input.value === "") {
            this.removeExistingSuggestions()
        }
    }

    private styleAllInactiveSuggestions(): void {
        for (const suggestion of this.suggestionsList) {
            if (suggestion !== this.suggestionsList[this.currentIndex]) {
                this.styleInactiveSuggestion(suggestion.element)
            }
        }
    }

    private async getSuggestions(show: boolean): Promise<void> {
        const input = this.input.value
        const cleanedInput = input.trim().replace(/^#/, "%23")
        this.timeoutId = 0
        if (input === "") {
            this.lastSearch = undefined
            this.showHistorySuggestions(input)
            this.showSuggestions()
            return
        }
        return await getCb(`ax/search/?keywords=${cleanedInput}`).then((response) => {
            if (this.input.value !== input) {
                return
            }
            this.lastSearch = cleanedInput
            this.showHistorySuggestions(cleanedInput)
            let suggestionCounter = this.suggestionsList.length
            const data = new ArgJSONMap(response.responseText)
            HASHTAG_RESULT_ORDER.forEach((category: ResultType) => {
                const list = data.getStringList(category)
                if (list !== undefined) {
                    list.forEach((item: string) => {
                        if (suggestionCounter >= MAX_SUGGESTIONS) {
                            return
                        }
                        this.createSuggestion(item, input, category, suggestionCounter)
                        this.highestIndex = suggestionCounter
                        suggestionCounter += 1
                    })
                }
            })
            // If suggestions arrive after searching, prevent showing the suggestions.
            if (this.preventShow) {
                this.preventShow = false
            } else {
                show ? this.showSuggestions() : this.hideSuggestions()
            }
        }).catch((err) => {
            this.pauseSuggestions = true
            window.setTimeout(() => {
                this.pauseSuggestions = false
            }, PAUSE_SUGGESTIONS_TIMER)
            addPageAction("TooManySearchAutocompleteError")
            warn("Too many autocomplete queries, wait before resuming", err)
        })
    }

    private showHistorySuggestions(input: string): void {
        this.removeExistingSuggestions()
        this.createHistorySuggestions(input)
        this.highestIndex = this.suggestionsList.length - 1
    }

    private createHistorySuggestions(cleanedInput: string): void {
        // Show up to 5 entries of search history for loggedin users that match the cleanedInput
        if (!isLocalStorageSupported()) {
            return
        }
        cleanSearchHistory()
        let suggestionCounter = 0
        if (!isAnonymous()) {
            const searchHistory = window.localStorage.getItem(getUserHistoryKey())
            if (searchHistory !== null) {
                const searches = JSON.parse(searchHistory)
                searches.forEach((search: ISearchHistory) => {
                    if (search.content !== undefined && search.content.includes(cleanedInput.replace(/%23/g, "#").toLowerCase())) {
                        suggestionCounter += 1
                        this.createSuggestion(search.content, cleanedInput, ResultType.History, suggestionCounter, search.type)
                    }
                })
            }
        }
    }

    public hideSuggestions(): void {
        this.styleInactiveLastSug()
        this.hideElement()
        this.resetSuggestionsNavigation()
        // For some reason, Safari retains focus on elements even after they become display: none. This can cause blur
        // listeners to fire later at unexpected times, which combined with timeouts leads to very confusing behavior.
        // Thus, if the document focus is STILL within the component AFTER being hidden, we artificially blur() it.
        window.setTimeout(() => {
            if (document.activeElement instanceof HTMLElement && this.element.contains(document.activeElement)) {
                document.activeElement.blur()
            }
        })
    }

    public getCurrentNavigatedSuggestion(): ISuggestion {
        return this.suggestionsList[this.currentIndex]
    }

    public isSuggestionsNavigated(): boolean {
        return (this.keyNavigated && this.currentIndex > -1 && this.suggestionsList.length > this.currentIndex)
    }

    public resetSuggestionsNavigation(): void {
        this.currentIndex = -1
        this.keyNavigated = false
    }

    public styleInactiveLastSug(): void {
        if (this.currentIndex > -1 && this.suggestionsList.length > this.currentIndex) {
            this.styleInactiveSuggestion(this.suggestionsList[this.currentIndex].element)
        }
    }

    public preventShowOnGetSuggestions(show: boolean): void {
        this.preventShow = show
    }

    public showSuggestions(): void {
        // Since suggestions are loaded on a timeout, make sure input still has focus before showing them
        if (this.suggestionsList.length && document.activeElement === this.input) {
            this.showElement()
        } else {
            this.hideElement()
        }
    }
}
