"use strict";
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* ====== GENDER CATEGORIES ====== */
// NOTE this file is intentionally free of any external dependencies, please keep it thus!
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenderNameToSymbolMap = exports.GendersSymbolToNameMap = exports.Subgender = exports.Gender = void 0;
exports.parseSimpleGender = parseSimpleGender;
exports.getCurrentGender = getCurrentGender;
var Gender;
(function (Gender) {
    Gender["All"] = "";
    Gender["Male"] = "m";
    Gender["Female"] = "f";
    Gender["Couple"] = "c";
    Gender["Trans"] = "t";
    Gender["OldTrans"] = "s";
})(Gender || (exports.Gender = Gender = {}));
var Subgender;
(function (Subgender) {
    Subgender["Transfemme"] = "tf";
    Subgender["Transmasc"] = "tm";
    Subgender["Nonbinary"] = "tn";
})(Subgender || (exports.Subgender = Subgender = {}));
function parseSimpleGender(gender) {
    switch (gender) {
        case Gender.All:
        case Gender.Male:
        case Gender.Female:
        case Gender.Couple:
        case Gender.Trans:
            return gender;
        case Gender.OldTrans:
        case Subgender.Transfemme:
        case Subgender.Transmasc:
        case Subgender.Nonbinary:
            return Gender.Trans;
        default:
            return Gender.All;
    }
}
exports.GendersSymbolToNameMap = new Map([
    [Gender.All, ""],
    [Gender.Male, "male"],
    [Gender.Female, "female"],
    [Gender.Couple, "couple"],
    [Gender.Trans, "trans"],
    [Gender.OldTrans, "trans"],
]);
exports.GenderNameToSymbolMap = new Map();
exports.GendersSymbolToNameMap.forEach((v, k) => exports.GenderNameToSymbolMap.set(v, k));
exports.GenderNameToSymbolMap.set("trans", Gender.Trans);
// eslint-disable-next-line complexity
function getCurrentGender(url) {
    var _a, _b, _c, _d;
    // First check for gender in the path, since that takes top priority.  We turn `<slug>-cams` into just
    // `<slug>` if possible, then check gender names first (e.g. `female`) followed by symbols (e.g. `f`)
    // This should catch all lexical gender paths, incl. /female-cams/, /asian-cams/female/, /tag/dance/f/
    // Other *-cams category pages or non-gendered slugs will return Gender.All from parseSimpleGender()
    let pathName = (_a = url === null || url === void 0 ? void 0 : url.pathname) !== null && _a !== void 0 ? _a : window.location.pathname;
    if (!pathName.endsWith("/")) {
        pathName += "/";
    }
    const pathSegments = pathName.split("/");
    // Check if we're looking at a tag page. We want to distinguish between /tag/female/ vs /tag/dance/female/
    // In the former situation, we return early since we know a user intends to navigate to the "female" tag,
    // vs filtering by gender
    if (pathSegments.length === 4 && pathSegments[1] === "tag") {
        return Gender.All;
    }
    const lastPathSegment = (_b = pathSegments[pathSegments.length - 2]) !== null && _b !== void 0 ? _b : "";
    const lexicalSlug = lastPathSegment.endsWith("-cams")
        ? lastPathSegment.slice(0, -5)
        : lastPathSegment;
    const lexicalGender = (_c = exports.GenderNameToSymbolMap.get(lexicalSlug)) !== null && _c !== void 0 ? _c : parseSimpleGender(lexicalSlug);
    // If a specific gender was in fact encoded in the last lexical path segment, return it
    if (lexicalGender !== Gender.All) {
        return lexicalGender;
    }
    // If not, look in URL params next, in the same order the backend checks -- `gender` first, since
    // it actually just redirects to a lexical-gender URL on roomlist pages, then `genders`, then `g`
    const searchParams = (_d = url === null || url === void 0 ? void 0 : url.searchParams) !== null && _d !== void 0 ? _d : new URLSearchParams(window.location.search);
    for (const genderParam of ["gender", "genders", "g"]) {
        const paramVal = searchParams.get(genderParam);
        if (paramVal !== null) {
            return parseSimpleGender(paramVal.split(",")[0]);
        }
    }
    // Finally, fall back to a default value
    return Gender.All;
}
