import { followingEvent } from "../follow"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import { FollowButton } from "./followButton"

export class HeaderFollowButton extends FollowButton {
    constructor() {
        super()
    }

    protected initUI(): void {
        this.followButton = <FollowButtonElement onClick={() => {this.handleFollowAndUnfollow()}} />
        this.unfollowButton = <UnfollowButtonElement onClick={() => {this.onUnfollowClick()}} />

        this.element = (
            <div style={{ display: "flex", alignItems: "center" }}>
                {this.followButton}
                {this.unfollowButton}
            </div>
        )
        this.updateFollowButtonDisplay()

        followingEvent.listen((event) => {
            if (event.roomName === this.roomName) {
                this.updateContext(this.roomName, event.following)
            }
        })
    }
}

const FollowButtonElement = (props: { onClick: () => void }) => {
    const buttonStyle: CSSX.Properties = {
        display: "none",
        backgroundColor: "#E0E0E0",
        color: "#ffffff",
        boxSizing: "border-box",
        borderRadius: "4px",
        padding: 0,
        height: "32px",
        justifyContent: "center",
        alignItems: "center",
    }
    const starStyle: CSSX.Properties = {
        position: "static",
        padding: "0 3px 0",
        height: "29px",
        width: "29px",
        backgroundSize: "28px 28px",
        display: "inline-block",
    }
    const followTextStyle: CSSX.Properties = {
        display: "inline-block",
        fontSize: "14px",
        color: "#222222",
        paddingRight: "8px",
    }

    return (
        <div style={buttonStyle} onClick={props.onClick}>
            <span className="icon_not_following no_hover" style={starStyle} />
            <span style={followTextStyle}>{i18n.followText}</span>
        </div>
    )
}

const UnfollowButtonElement = (props: { onClick: () => void }) => {
    const buttonStyle: CSSX.Properties = {
        display: "none",
        backgroundColor: "#17202A",
        color: "#ffffff",
        boxSizing: "border-box",
        borderRadius: "4px",
        justifyContent: "center",
        alignItems: "center",
    }
    const starStyle: CSSX.Properties = {
        position: "relative",
        height: "20px",
        width: "20px",
        backgroundSize: "20px 20px",
    }

    return (
        <div style={buttonStyle} onClick={props.onClick}>
            <span className="icon_following_new no_hover" style={starStyle} />
        </div>
    )
}
