import { Component } from "../../common/defui/component"
import { addColorClass, removeColorClass } from "../colorClasses"

abstract class Checkbox<T> extends Component<HTMLDivElement> {
    protected checkbox = document.createElement("input")
    private onChange = () => {}
    protected disabled = false

    constructor(initialChecked?: boolean, onChange?: () => void, subclassProps?: T) {
        super()

        this.checkbox.type = "checkbox"
        // Overflow is set to hidden in Component, but that hides the focus ring from the child checkbox
        this.element.style.overflow = "visible"
        // Override inline absolute positioning from Component
        this.element.style.position = "relative"
        addColorClass(this.element, "checkboxComponent")

        this.subclassInit(subclassProps)

        if (initialChecked !== undefined) {
            this.setChecked(initialChecked)
        }
        if (onChange !== undefined) {
            this.setOnChange(onChange)
        }

        this.element.appendChild(this.checkbox)
        this.updateStyles()

        this.element.onclick = (event) => {
            if (this.disabled) {
                return
            }
            const target = event.target
            if (target === this.checkbox) {
                // do nothing
            } else {
                this.toggle()
                this.checkbox.focus()
                event.preventDefault()
            }
        }
        this.checkbox.onchange = () => {
            // This will happen when the checkbox value is changed by the keyboard, or clicked directly
            this.updateStyles()
            this.onChange()
        }
    }

    protected abstract subclassInit(subclassProps?: T): void

    protected abstract updateStyles(): void

    private toggle(): void {
        this.checkbox.click()
    }

    public focus(): void {
        this.checkbox.focus()
    }

    public blur(): void {
       this.checkbox.blur()
    }

    public isActiveElement(): boolean {
        return document.activeElement === this.checkbox
    }

    public isChecked(): boolean {
        return this.checkbox.checked
    }

    public setChecked(isChecked: boolean): void {
        this.checkbox.checked = isChecked
        this.updateStyles()
        this.onChange()
    }

    // This function exists so that you can mimic updating a checkbox with javascript, meaning no onChange is fired
    // Only use this if you want no side effects to occur (such as when setting the initial state)
    public setCheckedDirectly(isChecked: boolean): void {
        this.checkbox.checked = isChecked
        this.updateStyles()
    }

    public setOnChange(callback: () => void): void {
        this.onChange = callback
    }

    public disable(): void {
        this.disabled = true
        this.checkbox.tabIndex = -1
        this.updateStyles()
    }

    public enable(): void {
        this.disabled = false
        this.checkbox.removeAttribute("tabindex")
        this.updateStyles()
    }

    public setCheckboxValue(value: string): void {
        this.checkbox.value = value
    }

    public getCheckboxValue(): string {
        return this.checkbox.value
    }

    public setCheckboxId(value: string): void {
        this.checkbox.id = value
    }

    public getCheckboxId(): string {
        return this.checkbox.id
    }
}

export class TransparentCheckbox extends Checkbox<Record<string, never>> {
    constructor(size: number, initialChecked?: boolean, onChange?: () => void) {
        super(initialChecked, onChange)
        this.element.style.height = `${size}px`
        this.element.style.width = `${size}px`
    }

    protected subclassInit(): void {
        addColorClass(this.element, "transparentCheckbox")
    }

    protected updateStyles(): void {
        if (this.checkbox.checked) {
            addColorClass(this.element, "checked")
        } else {
            removeColorClass(this.element, "checked")
        }

        if (this.disabled) {
            addColorClass(this.element, "disabled")
            this.element.style.cursor = "default"
        } else {
            removeColorClass(this.element, "disabled")
            this.element.style.cursor = "pointer"
        }
    }

    public setName(name: string): void {
        this.checkbox.name = name
    }

    public getName(): string {
        return this.checkbox.name
    }

    public setTitle(title: string): void {
       this.checkbox.title = title
    }
}

interface IToggleProps {
    width: number,
    height: number
}

const COLOR = "rgb(246, 115, 0)"
export class Toggle extends Checkbox<IToggleProps> {
    private button: HTMLDivElement
    private toggledOnLeftStyle: string

    constructor(initialChecked?: boolean, onChange?: () => void, props?: IToggleProps) {
        super(initialChecked, onChange, props)
    }

    protected subclassInit(props?: IToggleProps): void {
        const width = props?.width ?? 50
        const height = props?.height ?? 30
        const widthStyle = `${width}px`
        const heightStyle = `${height}px`
        const borderRadius = `${height * 2/3}px`
        this.toggledOnLeftStyle = `${width - height}px`

        this.element.style.width = widthStyle
        this.element.style.height = heightStyle
        this.element.style.borderRadius = borderRadius
        this.element.style.lineHeight = heightStyle
        addColorClass(this.element, "toggle")

        this.button = document.createElement("div")
        this.button.style.height = heightStyle
        this.button.style.width = heightStyle
        addColorClass(this.button, "toggleButton")

        this.element.appendChild(this.button)
    }

    protected updateStyles(): void {
        if (this.checkbox.checked) {
            this.element.style.backgroundColor = `${COLOR}`
            this.element.style.boxShadow = `${COLOR} 0 0 0 16px inset`
            this.element.style.border = `1px solid ${COLOR}`
            this.button.style.left = this.toggledOnLeftStyle
        } else {
            this.element.style.backgroundColor = "rgb(233, 233, 233)"
            this.element.style.boxShadow = "rgb(233, 233, 233) 0 0 0 16px inset"
            this.element.style.border = "1px solid rgb(223, 223, 223)"
            this.button.style.left = "0"
        }
    }
}
