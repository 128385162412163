import { isiOS } from "@multimediallc/web-utils/modernizr"
import { ConversationListData } from "../../cb/components/pm/conversationListData"
import { createDmWindowRequest } from "../../cb/components/pm/dmWindowsManager"
import { SearchBar } from "../../cb/components/pm/searchBar"
import { pageContext } from "../../cb/interfaces/context"
import { showLoginOverlay } from "../../cb/ui/loginOverlay"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { LOGIN_BASE_PATH, SIGN_UP_BASE_PATH } from "../redirectParamUtils"
import { styleTransition } from "../safeStyle"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import { MobileConversationList } from "./mobileConversationList"
import { MobileDmWindowManager } from "./mobileDmWindowManager"
import { siteHeaderMenuOpened, toggleDms } from "./userActionEvents"
import { getViewportHeight } from "./viewportDimension"

interface IMobileDmsMenuProps {
    dmListIconRoot: HTMLDivElement
    userUpdatesBellRoot: HTMLDivElement | null
    hamburgerIcon: HTMLDivElement | null | undefined
}

export class MobileDmsMenu extends Component<HTMLDivElement, IMobileDmsMenuProps> {
    private dmListIconRoot: HTMLDivElement
    private userUpdatesBellRoot: HTMLDivElement | null
    private hamburgerIcon?: HTMLDivElement | null
    private dmList?: MobileConversationList
    private contentMask: HTMLDivElement

    constructor(props: IMobileDmsMenuProps) {
        super("div", props)
    }

    protected initData(props: IMobileDmsMenuProps): void {
        this.dmListIconRoot = props.dmListIconRoot
        this.userUpdatesBellRoot = props.userUpdatesBellRoot
        this.hamburgerIcon = props.hamburgerIcon
    }

    protected initUI(props: IMobileDmsMenuProps): void {
        const containerStyle: CSSX.Properties = {
            width: "320px",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "#FFF",
            color: "#000",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
        }
        const maskStyle: CSSX.Properties = {
            width: "320px",
            display: "none",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "#FFF",
        }
        const myUsername = pageContext.current.loggedInUser?.username

        if (myUsername !== undefined) {
            const searchBar = new SearchBar({ isFullVideoMode: false, room: "" })
            this.dmList = new MobileConversationList({
                onItemRemoved: () => {},
                openConversationEvent: createDmWindowRequest,
                isDropdown: true,
                searchBar,
            })
            this.contentMask = <div style={maskStyle}/>
            const dmWindowManager = new MobileDmWindowManager({
                myUsername,
                onShow: () => this.toggleMaskingContent(true),
                onHide: () => this.toggleMaskingContent(false),
            })

            this.element = (
                <div style={containerStyle}>
                    <BackButton onClick={() => {this.handleBackButtonClick()}} />
                    {searchBar.element}
                    {this.dmList.element}
                    {this.contentMask}
                    {dmWindowManager.element}
                </div>
            )
        } else {
            const titleStlye: CSSX.Properties = {
                height: "45px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
                borderBottom: "1px solid #EFEFEF",
            }
            const contentContainerStyle: CSSX.Properties = {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                flex: 1,
            }
            const linkStyle: CSSX.Properties = {
                color: "#0a5a83",
                textDecoration: "none",
            }

            const onLoginClick = () => {
                const path = `${window.location.pathname}${window.location.search}`
                const isOnLoginPage = path.indexOf(LOGIN_BASE_PATH) !== -1

                if (!isOnLoginPage) {
                    showLoginOverlay({ fromFeature: true })
                }

                const pushMenuOverlay = document.querySelector(".push-overlay")
                this.hideElement()

                if (pushMenuOverlay instanceof HTMLElement) {
                    pushMenuOverlay.click()
                }
            }

            this.element = (
                <div style={containerStyle}>
                    <div style={titleStlye}>{i18n.directMessageLabel}</div>
                    <div style={contentContainerStyle}>
                        <img style={{ display: "inline-block", width: "50px", height: "50px" }} src={`${STATIC_URL}pms/empty-chat-state.svg`} />
                        <span style={{ margin: "25px 0", display: "inline-block" }}>{i18n.sendDirectMessage}</span>
                        <span style={{ ...linkStyle, marginBottom: "50px" }} onClick={onLoginClick}>{i18n.loginVerbText}</span>
                        <span>{i18n.dontHaveAccount} <a href={SIGN_UP_BASE_PATH} style={linkStyle} account-redirect>{i18n.signUpText}</a></span>
                    </div>
                </div>
            )
        }

        styleTransition(this.element, "transform 150ms ease")
        this.bindIconClickEvent()
        this.bindMenuChangeEvents()
        this.bindNumUnreadEvents()
        this.bindResizeEvents()
        this.hideElement()
    }

    private handleBackButtonClick(): void {
        this.hideElement()
    }

    public showElement(): void {
        applyStyles(this.element, { transform: "translateX(0)" })
        this.dmListIconRoot.classList.add("active")
    }

    public hideElement(): void {
        applyStyles(this.element, { transform: "translateX(-100%)" })
        this.dmListIconRoot.classList.remove("active")
    }

    private toggleMaskingContent(mask: boolean): void {
        this.contentMask.style.display = mask ? "block" : "none"
    }

    public isShown(): boolean {
        return this.element.style.transform !== "translateX(-100%)"
    }

    private bindIconClickEvent(): void {
        this.dmListIconRoot.onclick = () => {
            if (!this.isShown()) {
                this.showElement()
            }
        }
    }

    private bindMenuChangeEvents(): void {
        toggleDms.listen((shouldOpen: boolean) => {
            if (shouldOpen && !this.isShown()) {
                this.showElement()
            } else if (!shouldOpen && this.isShown()) {
                this.hideElement()
            }
        })

        siteHeaderMenuOpened.listen((isOpen: boolean) => {
            if (!isOpen) {
                this.hideElement()
            }
        })
    }

    private bindNumUnreadEvents(): void {
        ConversationListData.unreadConversationsCountUpdate.listen(() => {
            if (this.dmList === undefined) {
                return
            }

            const hasUnread = this.dmList?.getNumUnread() > 0

            if (hasUnread) {
                this.dmListIconRoot.classList.add("has_unread")
                this.hamburgerIcon?.classList.add("has_unseen")
            } else {
                this.dmListIconRoot.classList.remove("has_unread")
                const hasUserUpdates = this.userUpdatesBellRoot?.classList.contains("has_unseen") ?? false

                if (!hasUserUpdates) {
                    this.hamburgerIcon?.classList.remove("has_unseen")
                }
            }
        })
    }

    private bindResizeEvents(): void {
        if (!isiOS()) {
            // Prevent Android landscape keyboard from squeezing dm UI
            addEventListenerPoly("resize", window, () => {
                const viewportHeight = getViewportHeight()

                if (viewportHeight < 250) {
                    this.element.style.top = ""
                } else {
                    this.element.style.top = "0"
                }
            })
        }
    }
}

export const BackButton = (props: { onClick: () => void }): HTMLDivElement => {
    const backButtonStyle: CSSX.Properties = {
        height: "45px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "10px",
        color: "#306A91",
        borderBottom: "1px solid #EFEFEF",
    }

    return (
        <span style={backButtonStyle} onClick={props.onClick} data-testid="back-button">{i18n.back}</span>
    )
}
