import { extractKeywords, getPageHashtag, isHomepageFiltersActive, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { EventRouter, ListenerGroup } from "../../../common/events"
import { isFilterInPathActive } from "../../../common/featureFlagUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { SearchInput } from "../../ui/searchBar/searchInput"

interface ISearchResultsMessageState {
    numRooms: number
    filterPanelActive?: boolean
}

export const noResultsMessageUpdateEvent = new EventRouter<string>("updateNoResultsMessage")

export class SearchResultsMessage extends HTMLComponent<HTMLDivElement, object, ISearchResultsMessageState> {
    private listeners: ListenerGroup

    protected createElement(): HTMLDivElement{
        return <div className="searching-keyword" bind={{
            display: () => {
                return (this.state.filterPanelActive !== true && this.state.numRooms === 0) ? "none" : "block"
            },
        }} />
    }

    protected initData(props: object): void {
        this.listeners = new ListenerGroup()
    }

    protected initUI(): void {
        this.updateMessage()
        this.listeners.add(SearchInput.onSubmit.listen((keyword) => {
            this.updateMessage(keyword)
        }))
        this.listeners.add(noResultsMessageUpdateEvent.listen((keywords) => {
            if (keywords.length !== 0) {
                this.updateNoSearchResultsMessage(keywords)
            }
        }))
        if (isFilterInPathActive()) {
            UrlState.current.listen(["keywords"], (state) => {
                this.updateMessage(state.keywords)
            }, this.element)            
        }
    }

    public updateMessage(searchString?: string): void {
        const hashtag = getPageHashtag()
        const [keywords, keywordHashtags] = extractKeywords(searchString)
        if (keywords.length === 0 && keywordHashtags.length === 0) {
            // The only search keywords (if any) match the page hashtag, so don't show a message
            this.removeAllDOMChildren()
        } else {
            const formattedHashtags = keywordHashtags.map((keyword) => `#${keyword}`)
            if (!isHomepageFiltersActive() && Boolean(hashtag)) {
                formattedHashtags.push(`#${hashtag}`)
            }
            const keywordString = [...keywords, ...formattedHashtags].join(" ")
            this.removeAllDOMChildren()
            this.element.appendChild( <p>{i18n.searchResultsFor(keywordString)}</p> )
        }
    }

    private updateNoSearchResultsMessage(searchKeywords: string): void {
        // TODO: Temporary fix to update no results message for Django-template roomlists. Remove event router and
        // associated methods here once TS roomlist fully replaces the template version (see NoRoomsAvailableNotification)
        const noSearchResultsMessage = document.querySelector(".endless_page_template .no-rooms-available")
        if (noSearchResultsMessage !== null) {
            noSearchResultsMessage.textContent = this.constructNoResultsMessage(searchKeywords)
        }
    }

    private constructNoResultsMessage(noResultsKeywords: string): string {
        const hashtag = getPageHashtag()
        const [keywords, keywordHashtags] = extractKeywords(noResultsKeywords)
        const formattedHashtags = keywordHashtags.map((keyword) => `#${keyword}`)
        if (Boolean(hashtag)) {
            formattedHashtags.push(`#${hashtag}`)
        }
        const keywordString = [...keywords, ...formattedHashtags].join(" ")
        return i18n.noSearchResultsMatch(keywordString)
    }

    public dispose(): void {
        this.listeners.removeAll()
    }
}
