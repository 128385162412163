import { EventRouter } from "../events"
import { parseQueryString } from "../urlUtil"
import { JoinOverlay, JoinOverlayAnchor } from "./joinOverlay"

export const DISCOVER_JOIN_OVERLAY_ATTRIBUTE = "discover-join-overlay"

export class DiscoverJoinOverlay extends JoinOverlay {
    constructor() {
        super({ onHide: () => removeDiscoverJoinOverlayAnchors.fire(undefined) })
    }
}

/** Adds an additional attribute when join overlay is in effect
 * to make sure that while join overlay is open, room does not load.
 * the attribute is used as part of the logic in roomCard.tsx:onRoomClick */
export function convertAllDiscoverRoomsToJoinOverlayAnchors(element: HTMLElement): void {
    for (const anchor of element.querySelectorAll("a:not(.nooverlay):not([href='#'])")) {
        if (anchor.getAttribute("data-room-nav") !== null
            && parseQueryString(window.location.search)["join_overlay"] !== undefined) {
            anchor.setAttribute(DISCOVER_JOIN_OVERLAY_ATTRIBUTE, "")  // eslint-disable-line @multimediallc/no-set-attribute
        }
        new JoinOverlayAnchor(<HTMLAnchorElement>anchor)
    }
}

export const removeDiscoverJoinOverlayAnchors = new EventRouter<undefined>("removeDiscoverJoinOverlayAnchors")
