import { isiOS } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { EventRouter } from "../events"
import { isScrollDownNoticeActive } from "../featureFlagUtil"
import { requestFullscreen } from "../fullscreen"
import { addPageAction } from "../newrelic"
import { BasePlayer } from "../player/basePlayer"
import { airPlayChange } from "../player/hlsNativePlayer"
import { HlsPlayer } from "../player/hlsPlayer"
import { JpegPushPlayer } from "../player/jpegPlayer"
import { sdRatio, wsRatio } from "../player/playerSettings"
import { VideoJsPlayer } from "../player/videoJsPlayer"
import { inputDivHeight } from "./chatContents"
import { MobileRoomStatusNotifier } from "./mobileRoomStatusNotifier"
import { MobileVideoControls } from "./mobileVideoControls"
import { MobileVideoJsPlayer } from "./mobileVideoJsPlayer"
import { TouchState } from "./touchUtil"
import { SwipeUniqueState } from "./videoControlsInterfaces"
import { FULLSCREEN_PLAYER_SCALE_RATIO } from "./videoPlayerTouchControls"
import { getViewportHeight, getViewportWidth, viewportOffsetTop } from "./viewportDimension"
import { isPortrait } from "./windowOrientation"

import type { MobileDismissibleMessages } from "./mobileDismissibleMessages"
import type { MobileRoot } from "./mobileRoot"
import type { IPlayerScaled, ISwipeInfo } from "./videoControlsInterfaces"
import type { WebKitPlaybackTargetAvailabilityEvent } from "@multimediallc/web-utils/modernizr"

export class MobilePlayer extends BasePlayer {
    public toggleFullscreen = new EventRouter<boolean>("MobilePlayer:toggleFullscreen")
    public sendMessageInputFieldHasFocus = false
    private isWidescreen: boolean
    private portraitHeight: number | undefined
    private desiredPortraitHeight: number | undefined
    private fullscreenHeight: number | undefined
    private minPortraitLeft = 0
    public videoControls: MobileVideoControls
    public roomStatusNotifier: MobileRoomStatusNotifier
    private playerScaled: IPlayerScaled | undefined
    public isVideoHidden = false
    public isFullscreen = false // tracks if the Fullscreen Icon been toggled on (not the same as the fullscreen API)
    public usingAirPlay = false
    public usingChromecast = false
    private headerMenu: HTMLElement


    constructor(inlineAutoplaySupported: boolean, mobileRoot: MobileRoot, private mobileDismissibleMessages: MobileDismissibleMessages) {
        super(inlineAutoplaySupported, new MobileRoomStatusNotifier(), { noHook: true })

        this.element.style.position = "absolute"
        this.element.style.top = "0"
        this.element.style.width = "auto"
        this.element.style.height = "auto"
        this.element.style.overflow = "hidden"
        this.element.style.setProperty("scrollBehavior", "auto")
        if (isiOS()) {
            this.element.style.touchAction = "none"
        }
        this.playerComponent.element.style.overflowX = "hidden"

        this.headerMenu = document.querySelector("#static-header") as HTMLElement

        this.roomStatusNotifier.header.style.fontSize = "20px"
        this.roomStatusNotifier.body.style.fontSize = "12px"

        this.videoControls = new MobileVideoControls(this, mobileRoot, mobileDismissibleMessages)
        this.bindControlsEvents()
        this.afterPlayerCreated()

        this.addChild(this.videoControls)

        this.element.removeChild(this.watermark)

        this.addChild(this.roomStatusNotifier)

        this.toggleFullscreen.listen(() => {
            this.repositionChildrenRecursive()
        })

        // Disable picture-in-picture controls on modern mobile browsers
        /* eslint-disable */
        // @ts-ignore
        if (navigator.mediaSession !== undefined) {
            // @ts-ignore
            navigator.mediaSession.setActionHandler("play", null)
            // @ts-ignore
            navigator.mediaSession.setActionHandler("pause", null)
            // @ts-ignore
            navigator.mediaSession.setActionHandler("previoustrack", null)
            // @ts-ignore
            navigator.mediaSession.setActionHandler("nexttrack", null)
        }
        /* eslint-enable */

        this.setupAirPlayControls()
        this.setupChromecast()

        debug(`Player type: ${typeof this.playerComponent}`)
    }

    private stylePortraitFullscreen(): void {
        this.roomStatusNotifier.setShowMoreRoomsLink(true)

        let playerWidth: number
        let playerHeight: number

        if (this.playerScaled === undefined) {
            playerHeight = getViewportHeight()
            playerWidth = playerHeight / (this.isWidescreen ? wsRatio : sdRatio)
        } else {
            playerHeight = this.playerScaled.height
            playerWidth = this.playerScaled.width
        }

        this.playerComponent.element.style.height = `${playerHeight}px`
        this.playerComponent.element.style.width = `${playerWidth}px`
        this.applyPlayerHeight(playerHeight)

        if (isScrollDownNoticeActive()) {
            // This fixes the issue where chat doesn't scroll down on certain iPhones after changing orientation
            // Need to scroll down here since the width changes which in turn changes the scroll position
            const wasPureChatScrolledUp = this.videoControls.mobilePureChat?.isScrolledUp() ?? false
            this.element.style.width = `${getViewportWidth()}px`
            if (wasPureChatScrolledUp) {
                this.videoControls.mobilePureChat?.scrollToBottom()
            }
        } else {
            this.element.style.width = `${getViewportWidth()}px`
        }

        this.toggleMenuBar(true)
    }

    private styleLandscape(): void {
        this.roomStatusNotifier.setShowMoreRoomsLink(false)
        this.applyPlayerHeight(getViewportHeight())
        this.element.style.height = "100%"

        if (isScrollDownNoticeActive()) {
            // This fixes the issue where chat doesn't scroll down on certain iPhones after changing orientation
            // Need to scroll down here since the width changes which in turn changes the scroll position
            const wasPureChatScrolledUp = this.videoControls.mobilePureChat?.isScrolledUp() ?? false
            this.element.style.width = "100%"
            if (wasPureChatScrolledUp) {
                this.videoControls.mobilePureChat?.scrollToBottom()
            }
        } else {
            this.element.style.width = "100%"
        }
        this.playerComponent.element.style.width = "100%"

        if (isiOS()) {
            this.playerComponent.element.style.height = "100%"
        } else {
            // Do not update Android's player height while the keyboard is open to prevent it from shrinking
            const activeTag = (document.activeElement as HTMLElement).tagName.toLowerCase()
            if (!(activeTag === "input" || activeTag === "textarea")) {
                this.playerComponent.element.style.height = `${this.element.getBoundingClientRect().height}px`
            }
        }
        this.toggleMenuBar(true)
    }

    private stylePortraitNonFullscreen(): void {
        this.roomStatusNotifier.setShowMoreRoomsLink(true)
        const [playerWidth, playerHeight] = this.getNoneFullscreenSize()
        this.playerComponent.element.style.height = "100%"
        this.playerComponent.element.style.position = "static"
        this.applyPlayerHeight(playerHeight)
        if (playerWidth > getViewportWidth()) {
            this.element.style.width = "100%"
            this.element.style.left = "0"
        } else {
            if (this.usingAirPlay || this.usingChromecast) {
                this.element.style.width = "100%"
                this.element.style.left = "0"
            } else {
                this.element.style.width = `${playerWidth}px`
                this.element.style.left = `${getViewportWidth() * 0.5 - playerWidth * 0.5}px`
            }
        }

        if (this.usingAirPlay || this.usingChromecast) {
            this.element.style.height = "100px"
            this.playerComponent.element.style.height = "100px"
            this.playerComponent.element.style.width = "100%"
        } else {
            this.playerComponent.element.style.width = `${playerWidth}px`
        }

        this.toggleMenuBar(false)
    }

    protected repositionChildren(): void {
        if (isPortrait() && this.isFullscreen) {
            this.stylePortraitFullscreen()
        } else if (isPortrait() && !this.isFullscreen) {
            if (this.desiredPortraitHeight !== undefined) {
                this.setPortraitHeight(this.desiredPortraitHeight)
            }
            this.stylePortraitNonFullscreen()
        } else {
            this.styleLandscape()
        }

        if (!isPortrait() || this.isFullscreen) {
            if (this.isVideoHidden) {
                this.centerPlayer()
            }
            this.element.style.left = "0"
        }
    }

    private applyPlayerHeight(height: number): void {
        this.element.style.height = `${height}px`
    }

    public centerPlayer(): void {
        let playerHeight: number
        if (this.isFullscreen || this.portraitHeight === undefined) {
            if (this.fullscreenHeight === undefined) {
                playerHeight = getViewportHeight()
            } else {
                playerHeight = this.fullscreenHeight
            }
        } else {
            playerHeight = this.portraitHeight
        }
        const playerWidth = playerHeight / (this.isWidescreen ? wsRatio : sdRatio)
        this.element.scrollLeft = (playerWidth / 2) - (getViewportWidth() / 2)
    }

    private getNoneFullscreenSize(): number[] {
        const windowWidth = getViewportWidth()
        const windowHeight = getViewportHeight()
        let playerWidth: number
        let playerHeight: number

        if (this.sendMessageInputFieldHasFocus && viewportOffsetTop() > 0) {
            [playerWidth, playerHeight] = this.getDimensionsWithinBounds(windowWidth, windowHeight - inputDivHeight())
        } else if (isPortrait()) {
            if (this.portraitHeight !== undefined) {
                playerHeight = Math.min(windowHeight - this.minPortraitLeft, this.portraitHeight)
                playerWidth = playerHeight / (this.isWidescreen ? wsRatio : sdRatio)
            } else {
                // In portrait and desktop modes, the UI can not be smaller than the minimumUIHeight.
                const minimumUIHeight = 120
                playerWidth = Math.min(windowWidth, (windowHeight - minimumUIHeight) / (this.isWidescreen ? wsRatio : sdRatio))
                playerHeight = playerWidth * (this.isWidescreen ? wsRatio : sdRatio)
            }
        } else {
            [playerWidth, playerHeight] = this.getDimensionsWithinBounds(windowWidth, windowHeight)
        }
        return [Math.round(playerWidth), Math.round(playerHeight)]
    }

    // getDimensionsWithinBounds returns a width and height that will make it so that the player will be either full
    // boundsWidth or full boundsHeight, whichever fits without cropping.
    private getDimensionsWithinBounds(boundsWidth: number, boundsHeight: number): number[] {
        let playerWidth = boundsHeight / (this.isWidescreen ? wsRatio : sdRatio)
        let playerHeight = boundsHeight

        if (playerWidth > boundsWidth) {
            playerWidth = boundsWidth
            playerHeight = playerWidth * (this.isWidescreen ? wsRatio : sdRatio)
        }

        return [playerWidth, playerHeight]
    }

    private setupAirPlayControls(): void {
        if (window["WebKitPlaybackTargetAvailabilityEvent"] !== undefined) {
            this.videoControls.showAirPlay()
            addEventListenerPoly("click", this.videoControls.airPlayImg, () => {
                const videoElement = this.playerComponent.getVideoElement()
                if (videoElement === undefined) {
                    // The current player does not have a video element suitable for airplay and is not jpegToHls which has its own handler
                    if (!this.videoControls.getPlayerIsHlsPlaceholder()) {
                        this.videoControls.forceHLS()
                    }
                } else {
                    videoElement["webkitShowPlaybackTargetPicker"]()
                }
            })

            addEventListenerPoly("webkitplaybacktargetavailabilitychanged", this.playerComponent.element, (event: WebKitPlaybackTargetAvailabilityEvent) => {
                this.videoControls.forceHLS()
                if (event["availability"] !== undefined) {
                    switch (event["availability"]) {
                        case "available":
                            this.videoControls.showAirPlay()
                            break
                        case "not-available":
                            this.videoControls.hideAirPlay()
                            break
                    }
                }
            })
        } else {
            this.videoControls.hideAirPlay()
        }
    }

    private setupChromecastControls(): void {
        if (this.playerComponent instanceof VideoJsPlayer) {
            const videoJs = this.playerComponent.getVideoJs()
            if (videoJs !== undefined && videoJs.chromecastSessionManager !== undefined) {
                const castContext = videoJs.chromecastSessionManager.getCastContext()

                addEventListenerPoly("click", this.videoControls.chromecastImg, () => {
                    const videoElement = this.playerComponent.getVideoElement()
                    if (videoElement === undefined) {
                        this.videoControls.forceHLS()
                    } else {
                        videoJs.chromecastSessionManager.openCastMenu()
                    }
                })

                castContext.addEventListener("caststatechanged", (event: cast.framework.CastStateEventData) => {
                    if (event.castState !== undefined) {
                        switch (event.castState) {
                            case "NOT_CONNECTED":
                                this.videoControls.showChromecast()
                                break
                            case "NO_DEVICES_AVAILABLE":
                                this.videoControls.hideChromecast()
                                break
                        }
                    }
                })

                // Probably refreshed page while already connected. Have them restart the session, trying to reconnect
                // after refresh could be messier
                if (castContext.getCastState() === "CONNECTED" || castContext.getCastState() === "CONNECTING") {
                    this.videoControls.showChromecast()
                    castContext.getCurrentSession().endSession(true)
                } else if (castContext.getCastState() === "NOT_CONNECTED") {
                    this.videoControls.showChromecast()
                } else if (castContext.getCastState() === "NO_DEVICES_AVAILABLE") {
                    this.videoControls.hideChromecast()
                }
            } else {
                this.videoControls.hideChromecast()
            }
        }
    }

    private setupChromecast(): void {
        let tries = 0
        const MAX_TRIES = 10
        const intervalFunc = window.setInterval(() => {
            if (tries >= MAX_TRIES) {
                clearInterval(intervalFunc)
            }

            if (this.playerComponent instanceof VideoJsPlayer) {
                const videoJs = this.playerComponent.getVideoJs()
                if (videoJs !== undefined && videoJs.chromecastSessionManager !== undefined) {
                    this.setupChromecastControls()

                    const videoElement = this.playerComponent.getVideoElement()
                    if (videoElement !== undefined) {
                        this.bindChromecastEventsToVideo(videoElement)
                    }

                    clearInterval(intervalFunc)
                }
            }

            tries += 1
        }, 1000)
    }

    protected createVideoJsPlayer(): MobileVideoJsPlayer {
        return new MobileVideoJsPlayer(this.roomStatusNotifier)
    }

    protected afterPlayerCreated(): void {
        super.afterPlayerCreated()
        const videoElement = this.playerComponent.getVideoElement()
        if (videoElement !== undefined) {
            this.bindAirPlayEventsToVideo(videoElement)
        }
        this.videoControls.notifyPlayerIsJPEG(this.playerComponent instanceof JpegPushPlayer)
    }

    private setUsingAirPlay(usingAirplay: boolean): void {
        this.usingAirPlay = usingAirplay
        this.videoControls.notifyUsingAirPlayChange(usingAirplay)
    }

    private bindAirPlayEventsToVideo(videoElement: HTMLVideoElement): void {
        if (window["WebKitPlaybackTargetAvailabilityEvent"] !== undefined) {
            addEventListenerPoly("webkitcurrentplaybacktargetiswirelesschanged", videoElement, () => {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (videoElement["webkitCurrentPlaybackTargetIsWireless"]) {
                    addPageAction("Video_CastStart", { "CastType": "airplay" })
                    this.setUsingAirPlay(true)
                } else {
                    addPageAction("Video_CastStop", { "CastType": "airplay" })
                    this.setUsingAirPlay(false)
                }
                airPlayChange.fire(this.usingAirPlay)
                this.videoControls.updateCastingStyling(this.usingAirPlay ? "AirPlay" : "")
                this.repositionChildrenRecursive()
                this.centerPlayer()
            })
        }
    }

    private setUsingChromecast(usingChromecast: boolean): void {
        this.usingChromecast = usingChromecast
        this.videoControls.notifyUsingChromecastChange(usingChromecast)
    }

    private bindChromecastEventsToVideo(videoElement: HTMLVideoElement): void {
        if (this.playerComponent instanceof VideoJsPlayer) {
            const videoJs = this.playerComponent.getVideoJs()
            if (videoJs !== undefined && videoJs.chromecastSessionManager !== undefined) {
                const castContext = videoJs.chromecastSessionManager.getCastContext()
                castContext.addEventListener("sessionstatechanged", (event: cast.framework.SessionStateEventData) => {
                    if (event.sessionState !== undefined) {
                        switch (event.sessionState) {
                            case "SESSION_STARTED":
                            case "SESSION_RESUMED":
                                addPageAction("Video_CastStart", { "CastType": "chromecast" })
                                this.setUsingChromecast(true)
                                break
                            case "SESSION_ENDED":
                                addPageAction("Video_CastStop", { "CastType": "chromecast" })
                                this.setUsingChromecast(false)
                                break
                        }
                    }
                    this.videoControls.updateCastingStyling(this.usingChromecast ? "Chromecast" : "")
                    this.repositionChildrenRecursive()
                    this.centerPlayer()
                })
            }
        }
    }

    private bindControlsEvents(): void {
        this.videoControls.setPlayerVisibilityEvent.listen((visibility) => {
            this.setHidden(visibility)
        })
        this.videoControls.centerPlayerEvent.listen(() => {
            this.centerPlayer()
        })
        this.videoControls.requestFullscreenEvent.listen(() => {
            requestFullscreen(this.element)
        })
        this.videoControls.setPlayerComponentVolumeMutedEvents.listen((state) => {
            this.playerComponent.setVolumeMuted(
                state.volume,
                state.isMuted,
            )
        })
        this.videoControls.forceHlsPlayerEvent.listen(({ roomContext, unmute }) => {
            this.forceHlsPlayer(roomContext, unmute ?? true)
        })
        this.videoControls.touchControls.scalePlayerEvent.listen(({ factor, pointX }) => {
            if (this.canUpdateMobilePlayer() && isPortrait()) {
                if (this.isFullscreen) {
                    this.scalePlayer(factor, pointX)
                }
            }
        })
        this.videoControls.touchControls.changeVideoHeightEvent.listen((videoHeightInfo) => {
            if (this.canUpdateMobilePlayer() && isPortrait()) {
                if (this.isFullscreen) {
                    const zoomPercentage = videoHeightInfo.distance.y / getViewportHeight() * FULLSCREEN_PLAYER_SCALE_RATIO
                    const scaleFactor = 1 + zoomPercentage
                    const pointX = getViewportWidth() / 2
                    this.scalePlayer(scaleFactor, pointX)
                } else {
                    this.setDesiredPortraitHeight(videoHeightInfo.distance.y)
                }
            }
        })
        this.videoControls.touchControls.scrollPlayerEvent.listen((x) => {
            if (this.canUpdateMobilePlayer() && !this.isVideoHidden) {
                if (this.element.scrollBy !== undefined) {
                    this.element.scrollBy(x, 0)
                } else {
                    this.element.scrollLeft += x
                }
            }
        })

        this.videoControls.touchControls.swipePlayerEvent.listen((swipeInfo) => {
            const isLeftSwipe = (swipeInfo.totalDistance > 0)
            switch (swipeInfo.state) {
                case (SwipeUniqueState.Cancelled):
                    debug("Swipe Reset")
                    break
                case TouchState.End:
                    const direction = (isLeftSwipe) ? "Left" : "Right"
                    debug("Handle Completed Swipe", { direction: direction, totalDistance: swipeInfo.totalDistance })
                    // addPageAction("MobileNextCamSwiped", {"direction": direction})
                    break
                default:
                    if (isLeftSwipe) {
                        this.handleLeftSwipe(swipeInfo)
                    } else {
                        this.handleRightSwipe(swipeInfo)
                    }
                    break
            }
        })
        this.videoControls.playerComponentReadjustForceHlsOverlayOrderEvent.listen((forceHlsOverlay) => {
            this.playerComponent.element.insertBefore(
                forceHlsOverlay,
                this.playerComponent.element.firstChild,
            )
            this.playerComponent.element.insertBefore(forceHlsOverlay, this.playerComponent.element.firstChild)
        })
        this.videoControls.showJpegPlayerComponentImage.listen(() => {
            if (this.playerComponent instanceof JpegPushPlayer) {
                this.playerComponent.showPlayerImage()
            }
        })
        this.videoControls.toggleFullscreen.listen((isFullscreen) => {
            this.isFullscreen = isFullscreen
            this.toggleFullscreen.fire(isFullscreen)
        })
        this.videoControls.requestHlsPlayerPlayIfPaused.listen(() => {
            // TODO Figure out if the instanceof is necessary. Can it be replaced with getVideoElement?
            if (this.playerComponent instanceof HlsPlayer && this.playerComponent.videoElement.paused) {
                this.playerComponent.play()
            }
        })
    }

    protected bindVideoEvents(): void {
        super.bindVideoEvents()
        this.playerComponent.requestControlVisibility.listen((visible) => {
            if (visible) {
                this.videoControls.show()
            } else {
                this.videoControls.hide()
            }
        })
        this.playerComponent.setControlVolume?.listen(({ volume, save }) => {
            if (save) {
                this.videoControls.updateAndSaveVolume(volume)
            } else {
                this.videoControls.updateVolume(volume)
            }
        })
        this.playerComponent.setControlIsMuted?.listen(({ isMuted, save }) => {
            if (save) {
                this.videoControls.updateAndSaveIsMuted(isMuted)
            } else {
                this.videoControls.updateIsMuted(isMuted)
            }
        })
    }

    public toggleMenuBar(isFullscreen: boolean): void {
        if (isFullscreen) {
            this.headerMenu.style.display = "none"
        } else {
            this.headerMenu.style.display = ""
        }
    }

    public setIsWidescreen(isWidescreen: boolean): void {
        this.isWidescreen = isWidescreen
    }

    public setPortraitHeight(height: number): void {
        let oldHeight = this.portraitHeight
        let newHeight = Math.min(getViewportHeight() - this.getOtherComponentsCombinedHeights(), Math.max(100, height))
        newHeight = Math.round(newHeight)
        this.portraitHeight = newHeight

        oldHeight = oldHeight !== undefined ? oldHeight : this.portraitHeight
        if (oldHeight === newHeight) {
            return
        }

        this.repositionChildrenRecursive()
        this.element.scrollLeft += (newHeight - oldHeight)
    }

    private getOtherComponentsCombinedHeights(): number {
        const siteHeaderHeight = document.getElementById("static-header")?.offsetHeight ?? 40
        const tabRoomHeaderHeight = document.getElementById("tab-room-header")?.offsetHeight ?? 42
        const dismissibleMessagesHeight = this.mobileDismissibleMessages.element.offsetHeight

        return siteHeaderHeight + tabRoomHeaderHeight + inputDivHeight() + dismissibleMessagesHeight
    }

    public setDesiredPortraitHeight(additionalHeight: number): void {
        const height = this.element.offsetHeight + additionalHeight
        if (this.desiredPortraitHeight === height) {
            return
        }
        this.desiredPortraitHeight = height
        this.repositionChildren()
    }

    public scalePlayer(factor: number, pointX: number): void {
        // Apply scale factor
        const oldWidth = this.playerComponent.element.offsetWidth
        let newWidth = factor * oldWidth
        const MAX_WIDTH = getViewportHeight() / (this.isWidescreen ? wsRatio : sdRatio)
        const MIN_WIDTH = getViewportWidth()
        newWidth = Math.max(newWidth, MIN_WIDTH)
        newWidth = Math.min(newWidth, MAX_WIDTH)
        newWidth = Math.round(newWidth)
        const oldScroll = this.element.scrollLeft
        const newHeight = Math.round(newWidth * ((this.isWidescreen) ? wsRatio : sdRatio))
        this.element.style.width = `${newWidth}px`
        this.fullscreenHeight = newHeight
        this.applyPlayerHeight(newHeight)
        this.playerComponent.element.style.width = `${newWidth}px`

        this.playerScaled = {
            width: newWidth,
            height: newHeight,
        }

        this.repositionChildrenRecursive()

        // Center the video (in the X direction) on the point that was zoomed in/out on
        const actualScaleFactor = (newWidth / oldWidth) - 1
        const offsetX = (pointX + this.element.scrollLeft) * actualScaleFactor
        this.element.scrollLeft = oldScroll + offsetX
    }

    // limit the video player height, in portrait mode, by leaving a defined amount of pixels
    public limitPortraitHeight(leftHeight: number): void {
        this.minPortraitLeft = leftHeight
        this.repositionChildrenRecursive()
    }

    public setMessageInputFieldHasFocus(hasFocus: boolean): void {
        this.sendMessageInputFieldHasFocus = hasFocus
        if (hasFocus) {
            // this can run during superclass constructor
            this.videoControls.hideControls()
        }
    }

    public setHidden(isHidden: boolean): void {
        this.isVideoHidden = isHidden
        // this can run during superclass constructor
        this.repositionChildren()
    }

    private canUpdateMobilePlayer(): boolean {
        return !this.usingAirPlay && !this.usingChromecast && !this.videoControls.isAnyInputFocused()
    }

    public handleLeftSwipe(swipeInfo: ISwipeInfo): void {
        // TODO: Add Next/Scan Cam Functionality
        debug("Handle Left Swipe In Progress")
    }

    public handleRightSwipe(swipeInfo: ISwipeInfo): void {
        // TODO: Add Prev/Scan Cam Functionality
        debug("Handle Right Swipe In Progress")
    }
}
