import { ArgJSONMap } from "@multimediallc/web-utils"
import {
    parseAppLog, parseFromUser, parsePushRoomMessage, parseRoomNotice, parseShortcodes, parseUserInfo,
} from "../../../common/chatconnection/messageParsers"
import {
    EnterLeaveAction,
} from "../../../common/messageInterfaces"
import { statusMapLookup } from "../../../common/roomStatus"
import { GameSelection } from "../../components/games/gameSelection"
import { Topic } from "./base"
import type { ITopicMessage } from "./base"
import type {
    HiddenShowStatuses,
    IGameUpdate,
    IHiddenShowStatus,
    IPrivateShowStatus,
    IPushAppLog,
    IPushEnterLeave,
    IPushNotice,
    IPushPurchase,
    IPushRoomAction,
    IPushRoomMessage,
    IPushSettingsUpdateNotification,
    IPushTipAlert,
    IRoomStatus,
    IRoomTitleChange,
    IRoomUpdate,
    IShortcodeMessage,
    IViewerPromotion,
    PrivateRequestStatus,
    RoomUpdateType } from "../../../common/messageInterfaces"

type Room = {
    "broadcaster_uid": string,
}

// represents a room-id resolved topic, ie push:room:message:<roomUid>
export abstract class RoomTopic<T extends ITopicMessage> extends Topic<T> {
    protected roomUid: string
    public constructor(roomUid: string) {
        // Props are sent through the network, and must be kept in string notation
        super({
            "broadcaster_uid": roomUid,
        })
        this.uidCheck(this.roomUid)
    }

    protected initData(props: Room): void {
        this.roomUid = props["broadcaster_uid"]
    }

    public getKey(): string {
        return `${this.getId()}:${this.roomUid}`
    }
}

type RoomUser = {
    "broadcaster_uid": string,
    "user_uid": string,
}

// represents a room-id, user-specific resolved topic, ie push:room:message:<roomUid>:<userUid>
export abstract class RoomUserTopic<T extends ITopicMessage> extends Topic<T> {
    protected roomUid: string
    protected userUid: string
    public constructor(roomUid: string, userUid: string) {
        // Props are sent through the network, and must be kept in string notation
        super({
            "broadcaster_uid": roomUid,
            "user_uid": userUid,
        })
        this.uidCheck(this.roomUid)
        this.uidCheck(this.userUid)
    }

    public getKey(): string {
        return `${this.getId()}:${this.roomUid}:${this.userUid}`
    }

    protected initData(props: RoomUser): void {
        this.roomUid = props["broadcaster_uid"]
        this.userUid = props["user_uid"]
    }
}

export class PrivilegedSessionTopic extends RoomTopic<ITopicMessage> {
    public readonly maxListeners = 1
    public getId(): string {
        return "PrivilegedSessionTopic"
    }
}

export class RoomUserPresenceTopic extends RoomUserTopic<ITopicMessage> {
    public readonly maxListeners = 1
    public getId(): string {
        return "RoomUserPresenceTopic"
    }
}

export class RoomAnonPresenceTopic extends RoomTopic<ITopicMessage> {
    public readonly subscribeOnlyOnPrimaryBackend: boolean = true
    public getId(): string {
        return "RoomAnonPresenceTopic"
    }
}

export class RoomPasswordProtectedTopic extends RoomTopic<ITopicMessage> {
    public readonly maxListeners = 1
    public getId(): string {
        return "RoomPasswordProtectedTopic"
    }
}

export class RoomTitleChangeTopic extends RoomTopic<IRoomTitleChange> {
    public getId(): string {
        return "RoomTitleChangeTopic"
    }

    public parseData(data: ArgJSONMap): IRoomTitleChange {
        return {
            ...super.parseData(data),
            title: data.getString("title"),
        }
    }
}

export class RoomTipAlertTopic extends RoomTopic<IPushTipAlert> {
    public getId(): string {
        return "RoomTipAlertTopic"
    }

    public parseData(data: ArgJSONMap): IPushTipAlert {
        return {
            ...super.parseData(data),
            fromUser: parseFromUser(data),
            message: data.getString("message"),
            amount: data.getNumber("amount"),
            isAnonymousTip: data.getBoolean("is_anonymous_tip"),
            toUsername: data.getString("to_username"),
            ts: Math.floor(data.getNumber("ts") * 1000),
            roomType: "public",
        }
    }
}

export class RoomStatusTopic extends RoomTopic<IRoomStatus> {
    public getId(): string {
        return "RoomStatusTopic"
    }

    public parseData(data: ArgJSONMap): IRoomStatus {
        return {
            ...super.parseData(data),
            status: statusMapLookup(data.getString("status")),
            message: data.getString("message"),
            password: data.getString("hash"),
            substatus: data.getStringOrUndefined("substatus"),
        }
    }
}


export class RoomUserHiddenCamStatusTopic extends RoomUserTopic<IHiddenShowStatus> {
    public getId(): string {
        return "RoomUserHiddenCamStatusTopic"
    }

    parseData(data: ArgJSONMap): IHiddenShowStatus {
        return {
            ...super.parseData(data),
            status: data.getString("status") as HiddenShowStatuses,
        }
    }
}

export class RoomMessageTopic extends RoomTopic<IPushRoomMessage> {
    public getId(): string {
        return "RoomMessageTopic"
    }

    public parseData(data: ArgJSONMap): IPushRoomMessage {
        return {
            ...super.parseData(data),
            ...parsePushRoomMessage(data),
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }
}

export class RoomSilenceTopic extends RoomTopic<IPushRoomAction> {
    public getId(): string {
        return "RoomSilenceTopic"
    }

    public parseData(data: ArgJSONMap): IPushRoomAction {
        return {
            ...super.parseData(data),
            username: data.getString("username"),
            fromUser: data.getString("from_username"),
        }
    }
}

export class RoomKickTopic extends RoomTopic<IPushRoomAction> {
    public getId(): string {
        return "RoomKickTopic"
    }

    public parseData(data: ArgJSONMap): IPushRoomAction {
        return {
            ...super.parseData(data),
            username: data.getString("username"),
            fromUser: "Unknown", // data.getString("from_username") Push is not sending fromUser currently
        }
    }
}

export class RoomModeratorPromotedTopic extends RoomTopic<IPushRoomAction> {
    public getId(): string {
        return "RoomModeratorPromotedTopic"
    }

    public parseData(data: ArgJSONMap): IPushRoomAction {
        return {
            ...super.parseData(data),
            username: data.getString("username"),
            fromUser: data.getString("from_username"),
        }
    }
}

export class RoomModeratorRevokedTopic extends RoomTopic<IPushRoomAction> {
    public getId(): string {
        return "RoomModeratorRevokedTopic"
    }

    public parseData(data: ArgJSONMap): IPushRoomAction {
        return {
            ...super.parseData(data),
            username: data.getString("username"),
            fromUser: data.getString("from_username"),
        }
    }
}

export class RoomNoticeTopic extends RoomTopic<IPushNotice> {
    public getId(): string {
        return "RoomNoticeTopic"
    }

    public parseData(data: ArgJSONMap): IPushNotice {
        return {
            ...super.parseData(data),
            ...parseRoomNotice(data),
            ts: Math.floor(data.getNumber("ts") * 1000),
            toGroup: undefined,
            toUser: undefined,
        }
    }
}

export class RoomUserNoticeTopic extends RoomUserTopic<IPushNotice> {
    public getId(): string {
        return "RoomUserNoticeTopic"
    }

    public parseData(data: ArgJSONMap): IPushNotice {
        return {
            ...super.parseData(data),
            ...parseRoomNotice(data),
            ts: Math.floor(data.getNumber("ts") * 1000),
            toGroup: undefined,
            toUser: this.userUid,
        }
    }
}

export class RoomAppLogTopic extends RoomTopic<IPushAppLog> {
    public getId(): string {
        return "RoomAppLogTopic"
    }

    public parseData(data: ArgJSONMap): IPushAppLog {
        return {
            ...super.parseData(data),
            ...parseAppLog(data),
        }
    }
}

export class RoomPurchaseTopic extends RoomTopic<IPushPurchase> {
    public getId(): string {
        return "RoomPurchaseTopic"
    }

    public parseData(data: ArgJSONMap): IPushPurchase {
        return {
            ...super.parseData(data),
            fromUser: parseUserInfo(data.getParsedSubMap("user")),
            message: data.getString("message"),
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }
}

export class RoomFanClubJoinedTopic extends RoomTopic<IPushPurchase> {
    public getId(): string {
        return "RoomFanClubJoinedTopic"
    }

    parseData(data: ArgJSONMap): IPushPurchase {
        return {
            ...super.parseData(data),
            fromUser: parseUserInfo(data.getParsedSubMap("user")),
            message: data.getString("message"),
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }
}

export class RoomSettingsTopic extends RoomTopic<IPushSettingsUpdateNotification> {
    public getId(): string {
        return "RoomSettingsTopic"
    }

    public parseData(data: ArgJSONMap): IPushSettingsUpdateNotification {
        return {
            ...super.parseData(data),
            allowPrivateShow: data.getBoolean("allow_private_shows"),
            privatePrice: data.getNumber("private_show_tokens_per_minute"),
            spyPrice: data.getNumber("spy_on_private_show_tokens_per_minute"),
            privateMinMinutes: data.getNumber("private_min_minutes"),
            allowShowRecordings: data.getBoolean("allow_show_recordings"),
            hasFanClub: data.getBoolean("has_fan_club"),
            activePassword: data.getBoolean("active_password"),
            fanClubSpyPrice: data.getNumberOrUndefined("fan_club_spy_on_private_show_tokens_per_minute"),
            premiumPrivatePrice: data.getNumberOrUndefined("premium_private_show_tokens_per_minute") ?? 0,
            premiumPrivateMinMinutes: data.getNumberOrUndefined("premium_private_show_minimum_minutes") ?? 0,
        }
    }
}

export class RoomEnterLeaveTopic extends RoomTopic<IPushEnterLeave> {
    public getId(): string {
        return "RoomEnterLeaveTopic"
    }

    public parseData(data: ArgJSONMap): IPushEnterLeave {
        return {
            ...super.parseData(data),
            user: parseUserInfo(data.getParsedSubMap("user")),
            action: data.getString("action") as EnterLeaveAction,
            viewers: data.getNumber("count"),
            connections: data.getNumber("num_connections"),
        }
    }
}

export class RoomPrivilegedEnterTopic extends RoomTopic<IPushEnterLeave> {
    public getId(): string {
        return "RoomPrivilegedEnterTopic"
    }

    public parseData(data: ArgJSONMap): IPushEnterLeave {
        const userObj = {
            ...data.getObject("user"),
            "source_name": data.getStringOrUndefined("source"),
            "exploringHashTag": data.getStringOrUndefined("exploring_hash") ?? "",

        }
        return {
            ...super.parseData(data),
            action: EnterLeaveAction.Enter,
            user: parseUserInfo(new ArgJSONMap(userObj)),
            connections: data.getNumber("num_connections"),
            viewers: data.getNumber("count"),
        }
    }
}

export class RoomPrivilegedLeaveTopic extends RoomTopic<IPushEnterLeave> {
    public getId(): string {
        return "RoomPrivilegedLeaveTopic"
    }

    public parseData(data: ArgJSONMap): IPushEnterLeave {
        return {
            ...super.parseData(data),
            action: EnterLeaveAction.Leave,
            user: parseUserInfo(data.getParsedSubMap("user")),
            viewers: data.getNumber("count"),
            connections: data.getNumber("num_connections"),
        }
    }
}

export class RoomUpdateTopic extends RoomTopic<IRoomUpdate> {
    public getId(): string {
        return "RoomUpdateTopic"
    }

    public parseData(data: ArgJSONMap): IRoomUpdate {
        const msg = {
            ...super.parseData(data),
            target: data.getString("target") as RoomUpdateType,
            appId: data.getStringOrUndefined("app_id", false),
            appSystem: data.getStringOrUndefined("app_system", false),
        } as IRoomUpdate

        const username = data.getString("target_user")
        if (username !== "") {
            msg.targetUser = username
        }

        return msg
    }
}

export class RoomUserPrivateStatusTopic extends RoomUserTopic<IPrivateShowStatus> {
    public getId(): string {
        return "RoomUserPrivateStatusTopic"
    }

    public parseData(data: ArgJSONMap): IPrivateShowStatus {
        return {
            ...super.parseData(data),
            status: data.getString("status") as PrivateRequestStatus,
            privateShowId: data.getStringWithNumbers("show_id"),
            isPremium: data.getBooleanOrUndefined("premium") ?? false,
        }
    }
}

export interface IQualityUpdate extends ITopicMessage {
    quality: string,
    rate: number,
    stopped: boolean,
}

export class QualityUpdateTopic extends RoomTopic<IQualityUpdate> {
    public getId(): string {
        return "QualityUpdateTopic"
    }

    parseData(data: ArgJSONMap): IQualityUpdate {
        return {
            ...super.parseData(data),
            quality: data.getString("quality"),
            rate: data.getNumber("rate"),
            stopped: data.getBoolean("stopped"),
        }
    }
}

export interface ILatencyUpdate extends ITopicMessage {
    localTimeTranscoderInput: number | undefined,
    streamTimeTranscoderInput: number | undefined,
    localTimeSegmentStart: number | undefined,
    streamTimeSegmentStart: number | undefined,
}

export class LatencyUpdateTopic extends RoomTopic<ILatencyUpdate> {
    public getId(): string {
        return "LatencyUpdateTopic"
    }

    parseData(data: ArgJSONMap): ILatencyUpdate {
        return {
            ...super.parseData(data),
            localTimeTranscoderInput: data.getNumberOrUndefined("local_time_transcoder_input"),
            streamTimeTranscoderInput: data.getNumberOrUndefined("stream_time_transcoder_input"),
            localTimeSegmentStart: data.getNumberOrUndefined("local_time_segment_start"),
            streamTimeSegmentStart: data.getNumberOrUndefined("stream_time_segment_start"),
        }
    }
}

export class RoomShortcodeTopic extends RoomTopic<IShortcodeMessage> {
    public getId(): string {
        return "RoomShortcodeTopic"
    }

    parseData(data: ArgJSONMap): IShortcodeMessage {
        const shortcodes = data.getList("shortcodes") ?? []
        return {
            ...super.parseData(data),
            ...parsePushRoomMessage(data),
            ts: Math.floor(data.getNumber("ts") * 1000),
            shortcodes: parseShortcodes(shortcodes),
        }
    }
}

export class GameUpdateTopic extends RoomTopic<IGameUpdate> {
    public getId(): string {
        return "GameUpdateTopic"
    }

    parseData(data: ArgJSONMap): IGameUpdate {
        return {
            ...super.parseData(data),
            game: GameSelection.parseSelection(data.getObjectStringOrUndefined("game")),
        }
    }
}

export class ViewerPromotionTopic extends RoomTopic<IViewerPromotion> {
    public getId(): string {
        return "ViewerPromotionTopic"
    }

    parseData(data: ArgJSONMap): IViewerPromotion {
        return {
            ...super.parseData(data),
            purchaser: parseFromUser(data.getParsedSubMap("purchaser")),
            durationMins: data.getNumber("duration_mins"),
        }
    }
}
