import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCookieOrEmptyString } from "@multimediallc/web-utils/storage"
import { modalAlert } from "../../../common/alerts"
import { addPageAction } from "../../../common/newrelic"
import { OverlayModal } from "../../../common/overlayModal"
import { printCatch } from "../../../common/promiseUtils"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { tokenBalanceUpdate } from "../../api/tipping"
import { PromotionApi } from "./promotionApi"

interface IPromoteConfirmationProps {
    slug: string
    showDisabledFunc: () => void
}

interface IPromoteConfirmationState {
    price: number
}

const pendingErrorCode = "pending"

export class PromoteRoomConfirmation extends OverlayModal {
    protected state: IPromoteConfirmationState
    private priceText: HTMLDivElement
    private props: IPromoteConfirmationProps
    private notNotButton: HTMLDivElement
    private acceptButton: HTMLDivElement

    constructor(props: IPromoteConfirmationProps) {
        super(props)
        this.props = props
        this.defineTabbableElements([this.notNotButton, this.acceptButton])
    }

    protected createElement(props: IPromoteConfirmationProps): HTMLDivElement {
        return <div className="promoteRoomConfirmation">
            <div className="title">{i18n.promotionConfirmTitle}</div>
            <div className="description">{i18n.promotionConfirmDescription}</div>
            <div className="price" ref={(el: HTMLDivElement) => { this.priceText = el }}>{`${i18n.userPromotionCurrentPriceLabel}:`}</div>
            <div className="buttonDiv">
                <div className="notNowButton"
                     data-testid="promote-confirm-notnow"
                     onClick={() => {this.hide()}}
                     ref={(el: HTMLDivElement) => {this.notNotButton = el}}
                >
                    {i18n.notNowText}
                </div>
                <div className="promoteButton"
                     data-testid="promote-confirm-accept"
                     onClick={() => {
                         this.promoteRoom()
                         this.hide()
                     }}
                     ref={(el: HTMLDivElement) => {this.acceptButton = el}}
                >
                    {i18n.promotionConfirmPromoteText}
                </div>
            </div>
        </div>
    }

    public setPrice(price: number): void {
        this.setState({ price: price })
        this.priceText.textContent = i18n.promotionConfirmPriceText(this.state.price)
    }

    private promoteRoom(): void {
        const formData = new FormData()
        formData.append("csrfmiddlewaretoken", getCookieOrEmptyString("csrftoken"))
        formData.append("token_price", `${this.state.price}`)
        formData.append("slug", this.props.slug)
        const queryParams = new URLSearchParams(window.location.search)
        queryParams.forEach((key: string, value: string) => {
            formData.append(key, value)
        })

        PromotionApi.submitPromotion(formData).then((xhr: XMLHttpRequest) => {
            const responseMap = new ArgJSONMap(xhr.responseText)
            tokenBalanceUpdate.fire({ tokens: responseMap.getNumber("token_balance") })
            addPageAction("ViewerPromoteConfirmSuccess")
        }).catch((err) => {
            const responseMap = new ArgJSONMap(err.xhr.responseText)
            const errorMsg = responseMap.getStringOrUndefined("detail")
            const code = responseMap.getStringOrUndefined("code")
            if (errorMsg !== undefined) {
                // User submitted promo request before the push service was able to disable the feature, so disable here
                if (code === pendingErrorCode) {
                    this.props.showDisabledFunc()
                } else {
                    modalAlert(errorMsg)
                }
            }
            printCatch(err)
            addPageAction("ViewerPromoteConfirmFail", {
                "status": code ?? `${err.xhr.statusText} (${err.xhr.status})`,
                "detail": errorMsg ?? `${err.xhr.responseText} (${err.toString()})`,
            })
        })
    }
}
