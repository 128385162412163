import { hasWellSupportedEmojis, isIE } from "@multimediallc/web-utils/modernizr"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { createDmWindowRequest } from "../../cb/components/pm/dmWindowsManager"
import { MediasetThumbnails } from "../../cb/components/pm/mediasetThumbnails"
import { pageContext , roomDossierContext } from "../../cb/interfaces/context"
import { normalizeResource } from "../api"
import { DarkModeHandler, swapColors } from "../chatcolors/darkModeColors"
import { roomLoaded } from "../context"
import { applyStyles } from "../DOMutils"
import { styleUsernameMention } from "../fullvideolib/messageToDOM"
import { setPureChatColorData } from "../fullvideolib/pureChatUtil"
import { PartType } from "../messageInterfaces"
import { brighten, createMessageContentDiv, setTimestamp } from "../messageToDOM"
import { toggleDms } from "../mobilelib/userActionEvents"
import { addPageAction } from "../newrelic"
import { approvePrivateShow, enterSpyShowAlertChain } from "../privateShow"
import { renderMessage, renderShortcodeMessage, renderShortcodePart } from "../renderMessage"
import { RoomStatus } from "../roomStatus"
import { ShortcodeParser } from "../specialoutgoingmessages"
import { i18n } from "../translation"
import { UsernameLabel } from "../usernameLabel"
import { openDefaultTipCalloutRequest } from "./userActionEvents"
import type { IShortcode } from "../../cb/interfaces/shortcode"
import type { IChatConnection } from "../context"
import type {
    IPrivateMessage,
    IRoomMessage,
    IRoomNoticePart,
    IShortcodeMessage,
    IUserInfo,
} from "../messageInterfaces"

export const splitModeMessagePaddingPx = 4

let chatConnection: IChatConnection

// eslint-disable-next-line @multimediallc/no-global-listener
roomLoaded.listen((context) => {
    chatConnection = context.chatConnection
})


interface IEmoticonBounds {
    height: number
    width: number
}

export interface IRatioScale {
    emoticonBounds: IEmoticonBounds
    maxHeight: number
    maxWidth: number
}

export function newPossibleImageBounds(ratioScale: IRatioScale): IEmoticonBounds {
    const ratio = ratioScale.emoticonBounds.width / ratioScale.emoticonBounds.height
    let height = ratioScale.emoticonBounds.height
    let width = ratioScale.emoticonBounds.width

    if (width > ratioScale.maxWidth) {
        width = ratioScale.maxWidth
        height = Math.round(width / ratio)
    }
    if (height > ratioScale.maxHeight) {
        height = ratioScale.maxHeight
        width = Math.round(height * ratio)
    }

    return { height, width }
}

function createUsernameLabel(fromUser: IUserInfo, message?: IRoomMessage): UsernameLabel {
    return new UsernameLabel(fromUser, message)
}

export function createRoomMessage(m: IRoomMessage): HTMLDivElement {
    const div = createBaseMessageDiv(m.fromUser.username)

    const content = createMessageContentDiv()
    content.style.padding = `0px ${splitModeMessagePaddingPx}px`
    content.style.left = `-${splitModeMessagePaddingPx}px`
    content.style.width = `calc(100% + ${splitModeMessagePaddingPx}px)`
    if (m.backgroundColor !== undefined) {
        content.style.background = m.backgroundColor
    }
    addDarkModeOptions(div, content, DarkModeHandler.parseMessage(m))
    div.appendChild(content)

    const username = createUsernameLabel(m.fromUser, m)
    username.element.dataset["paction"] = "Chat"
    username.element.dataset["pactionName"] = "Username"
    username.element.style.paddingRight = "4px"
    username.element.dataset.testid = "chat-message-username"
    content.appendChild(username.element)

    const span = renderMessage(m.message)
    addColorClass(span, "msg-text")
    span.classList.add("split-mode")
    span.dataset.testid = "chat-message-text"
    if (m.textColor !== undefined) {
        span.style.color = m.textColor
    }
    if (m.font !== undefined) {
        username.element.style.fontFamily = m.font
        span.style.fontFamily = m.font
    }
    content.appendChild(span)

    if (isIE()) {
        // Without this IE11 de-parses the emojis, don't know why that happens
        window.setTimeout(() => { twemoji.parse(div, { className: "emojiChat" }) }, 0)
    } else {
        if (!hasWellSupportedEmojis()) {
            twemoji.parse(div, { className: "emojiChat" })
        }
    }
    styleUsernameMention(div)
    setTimestamp(m, div)
    return div
}

export function createRoomPhotoMessage(m: IPrivateMessage): HTMLDivElement | undefined {
    if (m.mediaList.length === 0) {
        return undefined
    }

    const div = createBaseMessageDiv(m.fromUser.username)

    const content = createMessageContentDiv()
    div.appendChild(content)

    const thumbnails = new MediasetThumbnails({
        messageId: m.messageID,
        outgoing: m.fromUser.username !== m.otherUsername,
        mediaList: m.mediaList,
    })
    thumbnails.element.style.marginTop = "6px"
    content.appendChild(thumbnails.element)

    // eslint-disable-next-line @multimediallc/no-set-attribute
    div.setAttribute("type", "photo")
    div.dataset.testid = "chat-image"
    setTimestamp(m, div)
    return div
}

export function addDarkModeOptions(baseDiv: HTMLDivElement, messageDiv: HTMLDivElement, msg: DarkModeHandler ): boolean {
    const bgDarkDefined = msg.bgDark !== undefined
    const fgDarkDefined = msg.fgDark !== undefined
    if (bgDarkDefined || fgDarkDefined) {
        baseDiv.classList.add("dm-adjust")
        /* eslint-disable @multimediallc/no-set-attribute */
        if (bgDarkDefined) {
            messageDiv.setAttribute("dm-adjust-bg", msg.bgDark as string)
            messageDiv.setAttribute("dm-init-bg", msg.background ?? "")
        }
        if (fgDarkDefined) {
            messageDiv.setAttribute("dm-adjust-fg", msg.fgDark as string)
            messageDiv.setAttribute("dm-init-fg", msg.foreground ?? "")
        }
        /* eslint-enable @multimediallc/no-set-attribute */
        return true
    }
    return false
}

export function combineNoticeParts(container: HTMLDivElement, messages: IRoomNoticePart[][], shortcodes?: IShortcode[]): HTMLDivElement {
    for (const message of messages) {
        const msgDiv = document.createElement("div")
        for (const part of message) {
            switch (part.partType) {
                case PartType.message:
                    msgDiv.appendChild(createMessagePart(part, shortcodes))
                    break
                case PartType.user:
                    msgDiv.appendChild(createUsernameLabel(part.user as IUserInfo).element)
                    break
                case PartType.hashtag:
                    msgDiv.appendChild(createHashtagAnchor(part.message as string))
                    break
                case PartType.spyPrivateShow:
                    msgDiv.appendChild(createLink("spy private show", () => {
                        if (chatConnection.status !== RoomStatus.PrivateSpying) {
                            enterSpyShowAlertChain(chatConnection)
                        }
                    }))
                    break
                case PartType.acceptPrivateShow:
                    msgDiv.appendChild(createLink("start private show", () => {
                        if (chatConnection.status === RoomStatus.PrivateRequesting) {
                            approvePrivateShow()
                        }
                    }))
                    break
                default:
                    error(`Unknown roomNotice type for: ${JSON.stringify(part)}`)
            }
        }
        container.appendChild(msgDiv)
    }
    return container
}

export function createShortcodeMessage(message: IShortcodeMessage): HTMLDivElement {
    // Form base message div to to hold our our shortcode msg content
    const baseMsgDiv = createBaseMessageDiv()

    const content = createMessageContentDiv()
    content.classList.add("msg-text")
    content.style.padding = `0px ${splitModeMessagePaddingPx}px`
    content.style.left = `-${splitModeMessagePaddingPx}px`
    content.style.width = `calc(100% + ${splitModeMessagePaddingPx}px)`
    if (message.backgroundColor !== undefined) {
        content.style.background = message.backgroundColor
    }
    addDarkModeOptions(baseMsgDiv, content, DarkModeHandler.parseMessage(message))

    const username = createUsernameLabel(message.fromUser, message)
    username.element.dataset["paction"] = "Chat"
    username.element.dataset["pactionName"] = "Username"
    username.element.dataset.testid = "chat-message-username"
    username.element.style.paddingRight = "4px"
    content.appendChild(username.element)

    const span = renderShortcodeMessage(message, createShortcodeLink, openDefaultTipCalloutRequest)
    span.dataset.testid = "chat-message-text"
    if (message.font !== undefined) {
        username.element.style.fontFamily = message.font
        span.style.fontFamily = message.font
    }
    content.appendChild(span)

    baseMsgDiv.appendChild(content)

    swapColors(baseMsgDiv, document.body.classList.contains("darkmode"))
    styleUsernameMention(baseMsgDiv)
    setTimestamp(message, baseMsgDiv)
    return baseMsgDiv
}

export function createShortcodeLink(linkText: string, linkUrl: string|undefined, callback: () => void): HTMLAnchorElement {
    const link = createLink(linkText, callback)
    link.dataset.testid = "shortcode-link"
    if (linkUrl !== undefined && linkUrl !== "") {
        link.href = normalizeResource(linkUrl)
        link.target = "_blank"
    }
    return link
}

function createLink(text: string, onClick: () => void): HTMLAnchorElement {
    const anchor = document.createElement("a")
    addColorClass(anchor, "msg-link")
    anchor.style.textDecoration = "underline"
    anchor.innerText = text
    anchor.onclick = onClick
    anchor.style.cursor = "pointer"
    return anchor
}

function createHashtagAnchor(hashtag: string): HTMLAnchorElement {
    const anchor = document.createElement("a")
    anchor.href = normalizeResource(`/tag/${hashtag}/${roomDossierContext.getState().roomGender ?? ""}`)
    addColorClass(anchor, colorClass.camSubjectTagColor)
    anchor.style.textDecoration = "none"
    anchor.innerText = `#${hashtag}`
    return anchor
}

export function createLogMessage(msg: string): HTMLDivElement {
    const d = createBaseMessageDiv()
    d.innerText = msg
    return d
}

export function createSupporterSignupMessage(broadcaster: string, isAgeVerified: boolean): HTMLDivElement {
    function createTextSpan(text: string): HTMLSpanElement {
        const span = document.createElement("span")
        span.innerText = text
        return span
    }

    const div = createBaseMessageDiv()

    const pmSupportNotice = isAgeVerified ? i18n.pmSupporterNoticeAgeVerified : i18n.pmSupporterNotice
    div.appendChild(createTextSpan(pmSupportNotice))
    div.appendChild(document.createElement("br"))
    div.appendChild(createTextSpan(`${i18n.pmSupporterSignUp} `))  // Add space at the end to split from the next text.
    const link = createLink(i18n.pmSupporterMembership, () => {})
    link.href = normalizeResource(`/supporter/upgrade/?source=${pageContext.current.PurchaseEventSources["SUPPORTER_SOURCE_PM_UPSELL"]}`)
    link.target = "_blank"
    link.dataset.testid = "supporter-upsell-link"
    addColorClass(link, "msg-link")
    link.onclick = () => {
        addPageAction("SupporterPageOpened", { "source": "chat" })
    }
    div.appendChild(link)
    div.appendChild(createTextSpan(` ${i18n.pmSupporterSendPm(broadcaster)}.`))  // Add space at the beginning.
    return div
}

export function createBaseMessageDiv(dataNick = ""): HTMLDivElement {
    const div = document.createElement("div")
    div.dataset.testid = "chat-message"
    div.classList.add("msg-text")
    div.style.fontFamily = "Tahoma,Arial,Helvetica,sans-serif"
    div.style.boxSizing = "border-box"
    div.style.paddingTop = "2px"
    div.style.paddingBottom = "3px"
    div.style.paddingLeft = "5px"
    div.style.paddingRight = "5px"
    div.style.overflowWrap = "break-word"
    div.style.wordWrap = "break-word"
    div.style.wordBreak = "break-word"
    if (dataNick.length > 0) {
        div.setAttribute("data-nick", dataNick) // eslint-disable-line @multimediallc/no-set-attribute
    }
    return div
}

export function createNewMessageNoticeDiv(): HTMLDivElement {
    const div = createBaseMessageDiv()
    applyStyles(div, {
        display: "flex",
        whiteSpace: "nowrap",
        alignItems: "center",
    })

    const line = document.createElement("div")
    applyStyles(line, {
        display: "inline-block",
        borderBottom: "1px solid",
        width: "100%",
    })
    addColorClass(line, colorClass.brandColor)
    div.appendChild(line)
    
    const newLabel = document.createElement("div")
    newLabel.textContent = i18n.newCapitalized
    applyStyles(newLabel, {
        display: "inline-block",
        marginLeft: "12px",
    })
    addColorClass(newLabel, colorClass.brandColor)
    div.appendChild(newLabel)

    return div
}

function createMessagePart(part: IRoomNoticePart, shortcodes?: IShortcode[]): HTMLSpanElement {
    if (
        part.message !== undefined &&
        shortcodes &&
        ShortcodeParser.isShortcodeSyntax(part.message)
    ) {
        return renderShortcodePart(
            part,
            shortcodes,
            createShortcodeLink,
            openDefaultTipCalloutRequest,
        )
    }
    return createRoomNoticeMessagePart(part)
}

function createRoomNoticeMessagePart(part: IRoomNoticePart): HTMLSpanElement {
    let span = document.createElement("span")
    if (part.parseEmoticon !== false) {
        span = renderMessage(part.message as string)
    } else {
        span.innerText = part.message as string
    }
    span.style.color = part.foreground !== undefined ? part.foreground : ""
    span.style.backgroundColor = part.background !== undefined ? part.background : ""
    span.style.fontWeight = part.weight !== undefined ? part.weight : ""
    return span
}

export function createPMChatLinkMessage(msg: string, pmChatLink: HTMLElement): HTMLDivElement {
    const d = createBaseMessageDiv()
    d.innerText = msg
    d.appendChild(pmChatLink)
    setPureChatColorData(d, brighten("#aaaaaa"))
    d.classList.add("roomNotice")
    return d
}

export function createDMChatLinkMessage(msg: string, username: string): HTMLDivElement {
    const messageDiv = createBaseMessageDiv(username)
    messageDiv.textContent = `${msg} `
    const dmLink = document.createElement("span") // Should be a button, but a button won't word wrap correctly
    addColorClass(dmLink, colorClass.hrefColor)
    dmLink.textContent = i18n.sendDirectMessageNew
    dmLink.style.cursor = "pointer"
    dmLink.onclick = () => {{
        toggleDms.fire(true) // For mobile
        createDmWindowRequest.fire(username)
    }}
    dmLink.dataset.testid = "send-dm-link"
    messageDiv.appendChild(dmLink)
    return messageDiv
}
