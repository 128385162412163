import { addEventListenerPoly, removeEventListenerPoly } from "../../../../common/addEventListenerPolyfill"
import { isAnonymous } from "../../../../common/auth"
import { dossierLoaded } from "../../../../common/chatRoot"
import { EventRouter, ListenerGroup } from "../../../../common/events"
import { MobileVideoControls } from "../../../../common/mobilelib/mobileVideoControls"
import { OverlayComponent } from "../../../../common/overlayComponent"
import { isNonChatInputFocused } from "../../../../common/theatermodelib/eventListeners"
import { userChatSettingsUpdate } from "../../../../common/theatermodelib/userActionEvents"
import { i18n } from "../../../../common/translation"
import { addColorClass } from "../../../colorClasses"
import { pageContext } from "../../../interfaces/context"
import type { IChatContents } from "../../../../common/chatRoot"
import type { CustomInput } from "../../../../common/customInput"

import type { MobileRoot } from "../../../../common/mobilelib/mobileRoot"
import type { IRoomDossier, IUserChatSettings } from "../../../../common/roomDossier"

// todo: Remove this since we now close modals on clicking outside
const rulesModalList: Set<RulesModal> = new Set<RulesModal>()

function clearRulesModals(excludeRoom?: string): void {
    rulesModalList.forEach(rm => {
        if (excludeRoom !== rm.roomName) {
            rm.dispose()
        }
    })
}

function hashFn(s: string): string {
    return (
        Array.from(
            s, ch => ch.charCodeAt(0),
        ).reduce(
            (a, b) => ((a << 5) - a + b) | 0, 0,
        ) >>> 0
    ).toString(16)
}

dossierLoaded.listen(dossier => {
    // remove modals that AREN'T for the current room
    clearRulesModals(dossier.room)
})

function areRulesAccepted(roomName: string, chatRules: string): boolean {
    const username = pageContext.current.loggedInUser?.username

    if (username === roomName) {
        return true
    }

    const rulesHash = hashFn(chatRules.trim())
    const key = `rules_accepted_${username}_${roomName}`
    return localStorage.getItem(key) === rulesHash
}

export function rulesModalVisible(): boolean {
    // used in eventListener to identify if any modal is open to keep focus in modal for 'Tab' keypdown events
    return Array.from(rulesModalList).some(rulesModal => rulesModal.isShown())
}

export function setupRulesModal(parent: IChatContents | MobileVideoControls, dossier: IRoomDossier): RulesModal | undefined {
    // just in case we missed removing a modal from a previous room
    parent.rulesModal?.dispose()
    if (!isAnonymous() && dossier.chatRules.trim().length > 0 && !areRulesAccepted(dossier.room, dossier.chatRules)) {
        if (parent instanceof MobileVideoControls) {
            const rulesModal = new RulesModal({
                inputContainer: parent.centerControlsDiv,
                customInputField: parent.chatInput,
                roomName: dossier.room,
                chatRules: dossier.chatRules,
                container: parent.getMobileRoot(),
                chatSettings: dossier.userChatSettings,
            }, true)
            rulesModal.element.style.position = "fixed"
            rulesModal.element.style.zIndex = "999999"
            return rulesModal
        } else {
            const rulesModal = new RulesModal({
                inputContainer: parent.inputDiv,
                customInputField: parent.customInputField,
                roomName: dossier.room,
                chatRules: dossier.chatRules,
                container: parent,
                chatSettings: dossier.userChatSettings,
            })
            rulesModal.element.style.zIndex = "1001"
            return rulesModal
        }
    }
    return
}

interface IRulesModalSetup {
    inputContainer: HTMLElement
    customInputField: CustomInput
    roomName: string
    chatRules: string
    container: IChatContents | MobileRoot
    chatSettings: IUserChatSettings
}

export class RulesModal extends OverlayComponent {
    public visibilityChanged = new EventRouter<boolean>("visibilityChanged", { reportIfNoListeners: false })

    private inputContainer: HTMLElement
    private customInput: CustomInput
    private textPar: HTMLDivElement

    private acceptBtn: HTMLButtonElement
    private closeBtn: HTMLButtonElement
    private chatRules: string
    private isMobile: boolean

    public roomName: string
    private scrollIndicator: HTMLDivElement
    private container: IChatContents | MobileRoot

    private showFunction: EventListener = (evt: Event) => { this.show() }
    private listenerAdded = false
    private listenerGroup = new ListenerGroup()
    private isVisible = false
    public parent?: IChatContents | MobileVideoControls

    constructor(props: IRulesModalSetup, mobileFullscreen = false) {
        super(props)
        this.isMobile = pageContext.current.isMobile
        this.overlayClick.listen(() => {
            this.hide()
        })

        rulesModalList.add(this)

        if (mobileFullscreen) {
            this.element.style.width = "70%"
            this.element.style.left = "15%"
        } else {
            this.element.style.width = "95%"
            this.element.style.left = "2.5%"
        }

        this.bindListeners()

        this.updateScrollIndicator()
    }

    // needed for mobile to handle font changes because the
    // modal isn't a descendant of the chat contents
    private applyChatSettingsFontStyles(settings: IUserChatSettings): void {
        this.element.style.fontFamily = settings.fontFamily
        this.element.style.fontSize = settings.fontSize
    }

    protected initData(props: IRulesModalSetup): void {
        this.chatRules = props.chatRules.trim()
    }

    protected initUI(props: IRulesModalSetup): void {
        super.initUI()
        this.element.style.bottom = "10px"
        this.element.style.borderWidth = "1px"
        this.element.style.borderStyle = "solid"
        this.element.style.borderRadius = "4px"
        this.element.style.margin = "0 auto"
        this.element.style.padding = "10px"
        this.element.style.boxSizing = "border-box"
        this.element.style.maxHeight = "95%"
        this.applyChatSettingsFontStyles(props.chatSettings)
        addColorClass(this.element, "rulesModal")

        this.inputContainer = props.inputContainer
        this.customInput = props.customInputField
        this.roomName = props.roomName
        this.container = props.container

        this.element.style.position = "absolute"
        this.element.style.textAlign = "center"
        this.element.style.height = "auto"

        this.element.style.display = "none"

        const rulesStart = document.createElement("div")
        rulesStart.style.fontWeight = "bold"
        rulesStart.style.textAlign = "left"
        rulesStart.style.paddingBottom = "5px"
        rulesStart.style.paddingRight = "10px"
        rulesStart.style.whiteSpace = "nowrap"
        rulesStart.style.textOverflow = "ellipsis"
        rulesStart.style.overflow = "hidden"
        const capitalizedRoomName = `${this.roomName[0].toUpperCase()}${this.roomName.substring(1)}`
        rulesStart.innerText = `${capitalizedRoomName}'s Rules:`
        this.element.appendChild(rulesStart)

        this.textPar = this.createTextContent(this.chatRules)
        this.scrollIndicator = this.createScrollIndicator()
        const textContainer = document.createElement("div")
        textContainer.style.position = "relative"
        textContainer.appendChild(this.textPar)
        textContainer.appendChild(this.scrollIndicator)
        textContainer.dataset["testid"] = "chat-rules"
        this.element.appendChild(textContainer)

        this.acceptBtn = this.createAcceptButton()
        this.closeBtn = this.createCloseButton()

        this.element.appendChild(this.acceptBtn)
        this.element.appendChild(this.closeBtn)

        this.repositionChildren()
    }

    private createTextContent(text: string): HTMLDivElement {
        const div = document.createElement("div")
        div.style.whiteSpace = "pre-line"
        div.style.textAlign = "left"
        div.style.lineHeight = "1.4"
        div.style.height = "auto"
        div.style.paddingBottom = "15px"
        div.style.boxSizing = "border-box"
        div.style.width = "100%"
        div.style.overflowY = "scroll"
        div.style.overflowWrap = "break-word"
        div.style.wordBreak = "break-word"
        div.style.wordWrap = "break-word"
        div.style.cssText += "; -ms-overflow-style: -ms-autohiding-scrollbar"
        div.style.marginBottom = "3px"
        div.style.paddingRight = "15px"
        div.onscroll = () => { this.updateScrollIndicator() }

        div.appendChild(document.createTextNode(text))

        return div
    }

    private isTextScrolledToBottom(): boolean {
        return this.textPar.scrollHeight - this.textPar.scrollTop - this.textPar.clientHeight <= 1
    }

    private updateScrollIndicator(): void {
        if (this.isTextScrolledToBottom()) {
            this.scrollIndicator.style.display = "none"
        } else {
            this.scrollIndicator.style.display = "block"
        }
    }

    private createScrollIndicator(): HTMLDivElement {
        const div = document.createElement("div")
        addColorClass(div, "scrollIndicator")
        div.style.height = "50px"
        div.style.position = "absolute"
        div.style.bottom = "0"
        div.style.fontWeight = "bold"
        div.style.textAlign = "center"
        div.style.boxSizing = "border-box"
        div.style.width = "100%"
        return div
    }

    protected repositionChildren(): void {
        const h = this.container.element.getBoundingClientRect().height
        this.textPar.style.maxHeight = `calc(${h}px - 120px)`
        this.updateScrollIndicator()
    }

    private captureKeys = (event: KeyboardEvent) => {
        if (isNonChatInputFocused()) {
            return
        }

        if (event.key === "Tab") {
            if (document.activeElement === this.acceptBtn) {
                this.closeBtn.focus()
            } else {
                this.acceptBtn.focus()
            }
            event.stopPropagation()
            event.preventDefault()
        } else if (event.key === "Escape") {
            this.hide()
            event.stopPropagation()
            event.preventDefault()
        } else if (event.key !== "Enter") {
            event.stopPropagation()
            event.preventDefault()
        }
    }

    public show(suppressEvent = false): void {
        if (this.isVisible) {
            return
        }

        this.isVisible = true
        this.element.style.display = "block"
        this.customInput.disable()
        this.inputContainer.style.pointerEvents = "none"
        this.showOverlay()
        this.repositionChildren()
        // ideally we really need to avoid messing with tabs and let browser handle it
        // disable for a mobile at least for now
        if (!this.isMobile) {
            addEventListenerPoly("keydown", document, this.captureKeys, true)
        }
        if (!suppressEvent) {
            this.visibilityChanged.fire(this.isVisible)
        }
    }

    public hide(suppressEvent = false): void {
        if (!this.isVisible) {
            return
        }

        this.isVisible = false
        this.element.style.display = "none"
        this.customInput.enable()
        this.inputContainer.style.pointerEvents = ""
        this.hideOverlay()
        this.container.repositionChildrenRecursive()

        if (!this.isMobile) {
            removeEventListenerPoly("keydown", document, this.captureKeys, true)
        }
        if (!suppressEvent) {
            this.visibilityChanged.fire(this.isVisible)
        }
    }

    private accept(): void {
        const key = `rules_accepted_${pageContext.current.loggedInUser?.username}_${this.roomName}`
        const rulesHash = hashFn(this.chatRules)
        localStorage.setItem(key, rulesHash)
        clearRulesModals()
        this.customInput.focus()
    }

    private bindListeners(): void {
        userChatSettingsUpdate.listen((settings) => {
            this.applyChatSettingsFontStyles(settings)
        }).addTo(this.listenerGroup)
        if (!this.listenerAdded) { // this check shouldn't be necessary but is there as a safeguard
            addEventListenerPoly("focus", this.customInput.element, this.showFunction)
            this.listenerAdded = true
        }
    }

    private removeInputListener(): void {
        if (this.listenerAdded) {
            removeEventListenerPoly("focus", this.customInput.element, this.showFunction)
            this.listenerAdded = false
        }
    }

    public dispose(): void {
        this.hide()
        this.removeInputListener()
        this.listenerGroup.removeAll()
        if (this.parent !== undefined) {
            delete this.parent.rulesModal
            this.parent.removeChild(this)
        }
        rulesModalList.delete(this)
    }

    private createAcceptButton(): HTMLButtonElement {
        const button = document.createElement("button")
        button.innerText = i18n.acceptRules
        button.style.fontFamily = "'UbuntuRegular',sans-serif"
        button.style.display = "inline-block"
        button.style.boxSizing = "content-box"
        button.style.width = "calc(100% - 16px)"
        button.style.padding = "8px"
        button.style.borderRadius = "4px"
        button.style.cursor = "pointer"
        button.style.fontWeight = "500"
        button.style.lineHeight = "16px"
        button.style.whiteSpace = "nowrap"
        button.style.overflow = "hidden"
        button.style.textOverflow = "ellipsis"
        button.style.fontSize = "120%"
        button.style.zIndex = "2"

        addEventListenerPoly("click", button, () => { this.accept() })
        addColorClass(button, "acceptRulesButton")
        return button
    }

    private createCloseButton(): HTMLButtonElement {
        const button = document.createElement("button")
        const img = document.createElement("img")
        img.src = `${STATIC_URL}close_icon.svg`
            img.style.backgroundColor = "inherit"
        button.appendChild(img)
        button.style.boxSizing = "border-box"
        button.style.padding = "5px 5px 4px 5px"
        button.style.position = "absolute"
        button.style.top = "2px"
        button.style.right = "2px"
        button.style.cursor = "pointer"
        addEventListenerPoly("click", button, () => { this.hide() })
        addColorClass(button, "closeRulesButton")
        return button
    }
}
