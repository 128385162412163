import { ArgJSONMap } from "@multimediallc/web-utils"
import { SubSystemType } from "../../../common/debug"
import { addPageAction } from "../../../common/newrelic"
import { createAuthRequest } from "../auth"
import { ReportedActions } from "../baseClient"
import { ErrorCode } from "../states"
import { ABLY_CLIENT_NAME, AblyContext, normalizeRejection } from "./index"
import type { IAuthProvider } from "../auth"

export const enum AuthCapabilityType {
    subscribe = "subscribe",
    presence = "presence",
}

export type AuthCapability = Record<string, AuthCapabilityType[]>


class AblyAuthToken {
    public readonly tokenDetails: ArgJSONMap
    public readonly capability: AuthCapability

    constructor(tokenDetails: ArgJSONMap) {
        this.tokenDetails = tokenDetails
        this.capability = this.parseCapability(tokenDetails.getString("x-ably-capability"))
    }

    public getChannelNames(): string[] {
        return Object.keys(this.capability)
    }

    private parseCapability(data: string): AuthCapability {
        const capability: AuthCapability = {}
        const parsedCapability = JSON.parse(data)
        const topicKeys = Object.keys(parsedCapability)
        for (const topicKey of topicKeys) {
            const operations = parsedCapability[topicKey]
            if (operations instanceof Array) {
                capability[topicKey] = operations as AuthCapabilityType[]
            } else {
                error(`Invalid capability type for topic: ${topicKey}`, { "capability": data }, SubSystemType.PushService)
            }
        }
        return capability
    }
}

export class AblyAuthProvider implements IAuthProvider {
    private authToken: AblyAuthToken | undefined
    private readonly realtime: Ably.RealtimePromise
    private ablyContext: AblyContext | undefined
    private consecutiveAuthFails = 0

    constructor(realtime: Ably.RealtimePromise, context?: AblyContext) {
        this.realtime = realtime
        this.ablyContext = context
    }

    public serialize(): string {
        if (this.ablyContext?.isValid() !== true) {
            return ""
        }
        return this.ablyContext.serialize()
    }

    public getCapabilities(): AuthCapability {
        const token = this.authToken
        return token === undefined ? {} : token.capability
    }

    public fetchTokenRequest(): Promise<AblyContext> {
        return new Promise<AblyContext>((resolve, reject) => {
            createAuthRequest(ABLY_CLIENT_NAME).then((authCtx) => {
                this.consecutiveAuthFails = 0
                this.reportFailedTopics(authCtx)
                this.ablyContext = new AblyContext(authCtx)
                resolve(this.ablyContext)
            }).catch((error) => {
                this.consecutiveAuthFails += 1
                reject(error)
            })
        })
    }

    public reportFailedTopics(authContext: ArgJSONMap): void {
        const failures = authContext.getObjectOrUndefined("failures")
        if (typeof failures === "object" && Object.keys(failures).length !== 0) {
            addPageAction("PushServiceClient", {
                "action": ReportedActions.token_request_failed_topics,
                "topics": JSON.stringify(failures),
                "client": ABLY_CLIENT_NAME,
            })
        }
    }

    public canAccessTopic(topicKey: string): boolean {
        const channelName = this.ablyContext?.getChannelName(topicKey)
        if (channelName === undefined) {
            return false
        }
        return this.authToken !== undefined && this.authToken.getChannelNames().includes(channelName)
    }

    // this needs to be in auth because its really "get topic keys that I have auth for"
    public getTopicKeys(): string[] {
        if (this.authToken === undefined) {
            return []
        }
        const channelNames = this.authToken.getChannelNames()
        const allTopicKeys: string[] = []
        channelNames.forEach(channelName => {
            const topicKeys = this.ablyContext?.getTopicKeys(channelName)
            if (topicKeys !== undefined) {
                allTopicKeys.push(...topicKeys)
            }
        })
        return allTopicKeys
    }

    public updateAuthToken(): Promise<void> {
        return new Promise((resolve, reject) => {
            // This `authorize` function calls `fetchTokenRequest`
            // See "authCallback" option in the Ably.Realtime.Promise constructor
            this.realtime.auth.authorize().then((ablyToken) => {
                const token = JSON.parse(atob(ablyToken["token"].split(".")[1]))
                this.authToken = new AblyAuthToken(new ArgJSONMap(token))
                resolve()
            }).catch((reason: Ably.ErrorInfo | string | undefined) => {
                const rejection = normalizeRejection(ErrorCode.auth, reason)
                reject(rejection)
            })
        })
    }
}
