import { timeDifferenceSeconds } from "@multimediallc/web-utils"
import { Component } from "../../../common/defui/component"
import { renderMessage } from "../../../common/renderMessage"
import { createRoomPhotoMessage } from "../../../common/theatermodelib/messageToDOM"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import type { IPrivateMessage } from "../../../common/messageInterfaces"

export function createDmMessageBubble(content: HTMLElement | string, isMine: boolean, containerColorClass: string, messageColorClass?: string, timestampTooltip?: string): HTMLDivElement {
    const bubbleStyle: CSSX.Properties = {
        padding: "5px 8px",
        display: "inline-block",
        textAlign: "left",
        maxWidth: "77%",
        borderRadius: "10px",
        overflowWrap: "break-word",
    }
    const myMessageStyle: CSSX.Properties = {
        ...bubbleStyle,
        marginRight: "8px",
    }
    const otherMessageStyle: CSSX.Properties = {
        ...bubbleStyle,
        borderWidth: "1px",
        borderStyle: "solid",
        position: "relative",
    }

    const messageContainerStyle: CSSX.Properties = { textAlign: isMine ? "right" : "left", paddingTop: "2px" }
    const messageStyle = isMine ? myMessageStyle : otherMessageStyle
    const testid = isMine ? "sent-message" : "received-message"
    return <div colorClass={containerColorClass} style={messageContainerStyle}>
        <div colorClass={messageColorClass} style={messageStyle} title={timestampTooltip ?? ""} data-testid={testid}>
            {content}
        </div>
    </div>
}

type DmMessageProps = {
    dm: IPrivateMessage,
    isMine: boolean,
    prevTimestamp?: Date,
}

export class DmMessageItem extends Component<HTMLDivElement, DmMessageProps> {
    private dm: IPrivateMessage
    private timestamp?: Date
    private timestampDiv: HTMLDivElement
    private readonly timestampThresholdSeconds = 60 * 60

    constructor(props: DmMessageProps) {
        super("div", props)
        this.updateForPrevTimestamp(props.prevTimestamp)
    }

    protected initData(props: DmMessageProps): void {
        super.initData(props)
        this.dm = props.dm
        this.timestamp = props.dm.createdAt
    }

    protected initUI(props: DmMessageProps): void {
        super.initUI(props)

        const messageContainerStyle: CSSX.Properties = {
            marginTop: "1px",
        }

        const messageColorClass = props.isMine ? "me" : "other"
        const tooltipTimestampString = this.dm.createdAt !== undefined ? this.tooltipTimestampString(this.dm.createdAt) : ""
        const mediaDiv = createRoomPhotoMessage(this.dm)

        const messageSpan = renderMessage(this.dm.message)
        messageSpan.style.display = "block"

        this.element = <div style={messageContainerStyle}>
            { this.createTimestamp() }
            { this.dm.message !== "" && createDmMessageBubble(messageSpan, props.isMine, "dmWindowMessage", messageColorClass, tooltipTimestampString) }
            {mediaDiv && createDmMessageBubble(mediaDiv, props.isMine, "dmWindowMessage", messageColorClass)}
        </div>
    }

    public showTimestamp(): void {
        this.timestampDiv.style.display = "block"
    }

    public updateForPrevTimestamp(prevTimestamp?: Date): void {
        this.timestampDiv.style.display = this.shouldShowTimestamp(prevTimestamp) ? "block" : "none"
    }

    private createTimestamp(): HTMLDivElement {
        const timestampStyle: CSSX.Properties = {
            textAlign: "center",
            display: "none",
            padding: "8px 0 2px",
        }

        let timestampString = ""
        if (this.dm.createdAt !== undefined) {
            timestampString = this.timestampString(this.dm.createdAt)
        }

        this.timestampDiv = <div style={timestampStyle} colorClass="timestamp">{timestampString}</div>
        return this.timestampDiv
    }

    private timestampString(timestamp: Date): string {
        if (timestamp.toLocaleDateString() === new Date().toLocaleDateString()) {
            return i18n.todayAtTime(timestamp.toLocaleTimeString([], { hour: "numeric", minute: "numeric" }))
        } else if (timestamp.toLocaleDateString() === new Date(Date.now() - 24*60*60*1000).toLocaleDateString()) {
            return i18n.yesterdayAtTime(timestamp.toLocaleTimeString([], { hour: "numeric", minute: "numeric" }))
        } else {
            if (timestamp.getFullYear() === new Date().getFullYear()) {
                return timestamp.toLocaleString([], { month:"short", day:"numeric", weekday: "short", hour: "numeric", minute: "numeric" })
            } else {
                return timestamp.toLocaleString([], { year:"numeric", month:"short", day:"numeric", hour: "numeric", minute: "numeric" })
            }
        }
    }

    private tooltipTimestampString(timestamp: Date): string {
        return timestamp.toLocaleString([], { year:"numeric", month:"short", day:"numeric", hour: "numeric", minute: "numeric", second: "numeric" })
    }

    private shouldShowTimestamp(prevTimestamp?: Date): boolean {
        return this.timestamp !== undefined && prevTimestamp !== undefined
            && timeDifferenceSeconds(this.timestamp, prevTimestamp) > this.timestampThresholdSeconds
    }
}
