import {
    isAudioContextFullySupported,
    isChrome,
    isFirefox,
    isiOS,
    isiPad,
    isMobileDevice,
    isSafari,
    isSamsungBrowser,
    isSamsungTablet,
    unloadEventName,
} from "@multimediallc/web-utils/modernizr"
import { getLocalStorageWithExpiration, setLocalStorageWithExpiration } from "@multimediallc/web-utils/storage"
import { updateSubgenderFromForm } from "../cb/api/subgenderUpdate"
import { SubgenderSelectionPrompt } from "../cb/ui/subgenderSelectionPrompt"
import { addEventListenerPoly, removeEventListenerPoly } from "../common/addEventListenerPolyfill"
import { modalAlert , modalSubmitForm } from "../common/alerts"
import {
    checkDevicePermsGranted,
    enumerateDevices,
} from "../common/broadcastlib/mediaDevices"
import { withSuspensionCheck } from "../common/broadcastlib/suspension"
import { VolumeMeter } from "../common/broadcastlib/volumeLib"
import {
    streamStart,
    streamStatusUpdate,
    streamStop,
    WebRTCHandler,
    WebRTCSource,
} from "../common/broadcastlib/webRTCHandler"
import { Component } from "../common/defui/component"
import { applyStyles, getTextWidthFont } from "../common/DOMutils"
import { exitFullscreen } from "../common/fullscreen"
import { Gender } from "../common/genders"
import { isOrientationChangeSupported, isPortrait, isProperLandscape } from "../common/mobilelib/windowOrientation"
import { addPageAction } from "../common/newrelic"
import { returnFromAway } from "../common/privateShow"
import { ignoreCatch } from "../common/promiseUtils"
import { i18n } from "../common/translation"
import { VideoMode, videoModeHandler } from "../common/videoModeHandler"
import { webRTCBroadcastStartStop } from "./broadcastStatus"
import { addColorClass, colorClass, removeColorClass } from "./colorClasses"
import { getCurrentSMCRoom, onNoSMCRoom } from "./components/showMyCam/smcUtil"
import { TransparentCheckbox } from "./components/toggle"
import { pageContext } from "./interfaces/context"
import type { ILoggedInUser } from "../cb/interfaces/user"
import type { IBroadcastDossier } from "../common/broadcastlib/dossier"
import type {
    IDevice,
    IDevices,
    IResolution } from "../common/broadcastlib/mediaDevices"
import type {
    IStatusUpdate } from "../common/broadcastlib/webRTCHandler"

const desktopFrameRateGood = 20.0
const desktopFrameRateAvg = 15.0
const mobileFrameRateGood = 12.0
const mobileFrameRateAvg = 9.0
const silentMicId = "we-only-need-a-silent-mic"

const componentHeight = "382px"
const broadcastStatuses = ["public", "private", "hidden", "away", "connecting"]

export const BROADCAST_MUTE_KEY = "broadcast_muted"
const SELECTED_MIC_KEY = "selected_mic"
const SELECTED_CAM_KEY = "selected_cam"
const SELECTED_RES_KEY = "selected_res"
export const BROADCAST_SETTINGS_EXPIRATION = { days: 60 }

function createBoldLabel(name: string): HTMLDivElement {
    const div: HTMLDivElement = document.createElement("div")
    div.style.paddingLeft = "10px"
    div.style.paddingTop = "3px"
    div.style.fontSize = "20px"
    div.textContent = name
    return div
}

function createLightLabel(name: string): HTMLDivElement {
    const div = createBoldLabel(name)
    div.style.paddingTop = "10px"
    div.style.fontFamily = "UbuntuRegular"
    div.style.fontSize = "15px"
    return div
}

function createSelect(): HTMLSelectElement {
    const select: HTMLSelectElement = document.createElement("select")
    addColorClass(select, "select")
    select.style.width = "177px"
    select.style.height = "20px"
    select.style.marginLeft = "10px"
    select.style.marginTop = "10px"
    select.style.fontFamily = "UbuntuRegular"
    select.style.fontSize = "12px"
    select.style.borderWidth = "1px"
    select.style.borderStyle = "solid"
    select.style.borderRadius = "4px"
    select.style.overflow = "hidden"
    return select
}

function createVideo(): HTMLVideoElement {
    const video: HTMLVideoElement = document.createElement("video")
    video.autoplay = true
    video.muted = true
    video.setAttribute("playsinline", "") // eslint-disable-line @multimediallc/no-set-attribute
    video.oncontextmenu = (e) => {
        // Don't show menu options for video, to avoid the "Show Controls" option
        e.preventDefault()
    }
    return video
}

export interface IStreamerConstraints {
    micId: string,
    camId: string,
    width: number,
    height: number,
    muted: boolean,
}

class Streamer {
    private handler: WebRTCHandler | undefined
    stream: MediaStream | undefined
    broadcasting = false
    uuid: string

    private stopPromise: Promise<void> | undefined = undefined

    setup(video: HTMLVideoElement, dossier: IBroadcastDossier, constraints: IStreamerConstraints): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const c: any = {
            video: {
                deviceId: { exact: constraints.camId },
                width: { ideal: constraints.width },
            },
            frameRate: { min: 24, max: 30 },
        }
        if (isMobileDevice()) {
            c["aspectRatio"] = 1.777777778
        } else {
            c.video["height"] = { ideal: constraints.height }
        }
        if (constraints.micId !== silentMicId) {
            c["audio"] = {
                deviceId: {
                    exact: constraints.micId,
                },
            }
            c["audio"]["advanced"] = [
                { autoGainControl: true },
                { noiseSuppression: true },
            ]
            if (isChrome()) {
                c["audio"]["advanced"].push({ googEchoCancellation: true })
                c["audio"]["advanced"].push({ googExperimentalEchoCancellation: true })
                c["audio"]["advanced"].push({ googHighpassFilter: true })
            }
        }
        this.handler = new WebRTCHandler(dossier, video,  WebRTCSource.Desktop)
        this.uuid = this.handler.uuid
        return new Promise<void>((resolve, reject) => {
            if (this.handler !== undefined) {
                this.handler.setup(c).then((stream: MediaStream) => {
                    this.stream = stream
                    if (this.handler !== undefined) {
                        this.handler.setMute(constraints.muted)
                    }
                    resolve()
                }).catch((e) => {
                    reject(e)
                })
            }
        })
    }

    stop(): Promise<void> {
        if (this.stopPromise === undefined) {
            this.stopPromise = new Promise<void>((resolve) => {
                const finish = () => {
                    this.handler = undefined
                    this.stream = undefined
                    this.broadcasting = false
                    resolve()
                }

                if (this.handler !== undefined) {
                    this.handler.stop().then((uuid) => {
                        if (this.uuid === uuid) {
                            finish()
                        }
                    }).catch(ignoreCatch)
                } else {
                    finish()
                }
            })
        }
        return this.stopPromise
    }

    start(): Promise<void> {
        this.broadcasting = false
        if (this.handler !== undefined) {
            if (isOrientationChangeSupported() &&
                !isProperLandscape(this.handler.streamSettings.facingMode)) {
                let msg = i18n.turnDevice180
                if (isPortrait()) {
                    msg = i18n.turnDeviceLandscape
                }
                return Promise.reject(new Error(msg))
            }
            return new Promise<void>((resolve, reject) => {
                if (this.handler !== undefined) {
                    let started = false
                    const finish = (uuid?: string) => {
                        if (uuid !== undefined && uuid !== this.uuid) {
                            return
                        }
                        if (started === false) {
                            this.broadcasting = true
                            started = true
                            streamStart.removeListener(onStreamStart)
                            resolve()
                        }
                    }
                    const onStreamStart = (uuid: string) => {
                        finish(uuid)
                    }
                    // if the stream fails immediately, listen for a restart
                    streamStart.listen(onStreamStart)
                    this.handler.start().then(() => {
                        finish()
                    }).catch(e => {
                        if (e.fatal === true) {
                            streamStart.removeListener(onStreamStart)
                            reject(e)
                        }
                    })
                }
            })
        }
        return Promise.resolve()
    }

    streaming(): boolean {
        return this.handler !== undefined
    }

    setMute(muted: boolean): boolean {
        if (this.handler !== undefined && this.stream !== undefined) {
            this.handler.setMute(muted)
            return true
        }
        return false
    }
}

interface IPreviewPanelConfig {
    onOBSClick: () => void,
    onStartBroadcast: () => void,
}

class PreviewPanel extends Component {
    protected readonly dossier: IBroadcastDossier
    protected user: ILoggedInUser | undefined
    protected readonly config: IPreviewPanelConfig
    protected readonly constraints: IStreamerConstraints
    protected streamer: Streamer | undefined
    protected devices: IDevices
    protected video: HTMLVideoElement
    protected helpOverlay: HTMLDivElement
    private permissionsErrorSpan: HTMLSpanElement
    private static permissionsErrorString: string | undefined
    private requestDevicesButton: HTMLButtonElement
    private hdLabel: HTMLDivElement | undefined
    private volumeMeter: VolumeMeter | undefined
    private volumeMeterInterval: number | undefined
    protected volumeRange: HTMLDivElement | undefined
    protected startButton: HTMLButtonElement
    protected videoWidth = 268
    protected videoRatio = 16 / 9
    protected camSelect: HTMLSelectElement
    protected resSelect: HTMLSelectElement
    protected micSelect: HTMLSelectElement | undefined  // Is undefined in PreviewPanelForModal child class

    constructor(dossier: IBroadcastDossier, constraints: IStreamerConstraints, config: IPreviewPanelConfig,  loggedInUser?: ILoggedInUser) {
        super()
        this.dossier = dossier
        this.user = loggedInUser
        this.config = config
        this.constraints = constraints
        this.helpOverlay = this.createHelpOverlay()
        this.createPreviewPanel()
    }

    protected start(): void {
        const onSuccess = () => {
            if (this.streamer !== undefined) {
                onNoSMCRoom(() => this.setStartButtonDisabled(false))
                const height = Math.floor(this.videoWidth / this.videoRatio)
                this.video.style.height = `${height}px`
                this.startMeter()
            }
        }
        this.stop().then(() => {
            this.streamer = new Streamer()
            this.streamer.setup(this.video, this.dossier, this.constraints).then(onSuccess).catch((e) => {
                if (isChrome() && isSamsungTablet() && e.message === "Could not start video source") {
                    // Chrome on Samsung tablets will sometimes throw this error when the page is reloaded but the user
                    // can still broadcast fine
                    onSuccess()
                    return
                }
                modalAlert(`${i18n.couldNotSetupPreview}:\n${e.message}`)
            })
        }).catch(ignoreCatch)
    }

    protected stop(): Promise<void> {
        this.setStartButtonDisabled(true)
        return this.stopMeter().then(() => {
            if (this.streamer !== undefined) {
                return this.streamer.stop()
            }
            return Promise.resolve()
        })
    }

    protected stopMeter(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this.volumeRange !== undefined) {
                this.volumeRange.style.width = "0%"
            }
            if (this.volumeMeter !== undefined) {
                this.volumeMeter.stop().then(() => {
                    clearInterval(this.volumeMeterInterval)
                    this.volumeMeter = undefined
                    resolve()
                }).catch(ignoreCatch)
            } else {
                resolve()
            }
        })
    }

    protected startMeter(): void {
        this.stopMeter().then(() => {
            if (
                isAudioContextFullySupported() &&
                this.streamer !== undefined &&
                this.streamer.stream !== undefined &&
                this.streamer.stream.getAudioTracks().length > 0
            ) {
                this.volumeMeter = new VolumeMeter()
                this.volumeMeter.connect(this.streamer.stream)
                this.volumeMeterInterval = window.setInterval(() => {
                    if (this.volumeMeter !== undefined && this.volumeRange !== undefined) {
                        const volume = this.volumeMeter.level * 100
                        this.volumeRange.style.width = `${volume}%`
                    }
                })
            }
        }).catch(ignoreCatch)
    }

    protected updateResolution(option: HTMLOptionElement): void {
        const cam = this.devices.cams.get(this.constraints.camId)
        if (cam !== undefined) {
            const res = cam.resolutions.get(option.value)
            if (res !== undefined) {
                this.constraints.width = res.width
                this.constraints.height = res.height
                this.videoRatio = res.ratio
                if (this.hdLabel !== undefined) {
                    if (res.isHD) {
                        this.hdLabel.style.visibility = "visible"
                    } else {
                        this.hdLabel.style.visibility = "hidden"
                    }
                }
                this.start()
            }
        }
    }

    protected updateResolutions(): void {
        const cam = this.devices.cams.get(this.constraints.camId)
        if (cam !== undefined) {
            for (let i = this.resSelect.options.length - 1 ; i >= 0 ; i -= 1) {
                this.resSelect.removeChild(this.resSelect.options[i])
            }
            const stored_res = getLocalStorageWithExpiration(SELECTED_RES_KEY)
            let i = 0, index = -1
            const samsungInternet = isSamsungBrowser()
            cam.resolutions.forEach((res: IResolution) => {
                // The 960x540 camera resolution from Samsung Browser tries to start at 480x352, an invalid ratio, this may change/fix itself in the future
                if (!samsungInternet || res.width !== 960){
                    const option: HTMLOptionElement = document.createElement("option")
                    option.label = res.label
                    // text needed for Firefox
                    option.text = res.label
                    option.value = res.id
                    this.resSelect.add(option)
                    if (option.value === stored_res) {
                        index = i
                    }
                    i += 1
                }
            })
            if (index === -1) {
                // Default to the middle resolution
                index = Math.floor(this.resSelect.options.length / 2)
            }
            const option = this.resSelect.options[index]
            option.selected = true
            this.updateResolution(option)
        }
    }

    protected onCamChange(): void {
        const index = this.camSelect.selectedIndex
        if (index >= 0) {
            const option = this.camSelect.options[index]
            this.constraints.camId = option.value
            setLocalStorageWithExpiration(SELECTED_CAM_KEY, option.value, BROADCAST_SETTINGS_EXPIRATION)
            window.localStorage.removeItem(SELECTED_RES_KEY)
            this.updateResolutions()
        }
    }

    protected onResChange(): void {
        const index = this.resSelect.selectedIndex
        if (index >= 0) {
            const option = this.resSelect.options[index]
            setLocalStorageWithExpiration(SELECTED_RES_KEY, option.value, BROADCAST_SETTINGS_EXPIRATION)
            this.updateResolution(option)
        }
    }

    protected createPreviewPanel(): void {
        addColorClass(this.element, "previewPanel")
        this.element.style.height = componentHeight
        this.element.style.width = "488px"
        this.element.style.paddingLeft = "5px"

        this.element.appendChild(this.createTitle())
        this.element.appendChild(this.createMediaSettingsPanel())
        this.element.appendChild(this.createVideoPanel())
    }

    protected createTitle(): HTMLDivElement {
        const div: HTMLDivElement = document.createElement("div")
        addColorClass(div, "welcomeTitle")
        div.dataset.testid = "preview-title"
        div.style.width = "100%"
        div.style.height = "32px"

        const title: HTMLSpanElement = document.createElement("span")
        title.style.paddingLeft = "10px"
        title.style.verticalAlign = "sub"
        title.style.lineHeight = "32px"
        title.textContent = `${i18n.welcomeBack}, ${this.dossier.room}`
        div.appendChild(title)

        return div
    }

    protected createMediaSettingsPanel(): HTMLDivElement {
        const panel: HTMLDivElement = document.createElement("div")
        panel.style.width = "198px"
        panel.style.cssFloat = "left"
        panel.style.height = "318px"
        panel.style.padding = "10px 0 10px 0"

        panel.appendChild(this.createCamSettingsPanel())
        panel.appendChild(this.createMicSettingsPanel())
        checkDevicePermsGranted().then(granted => {
            if (granted) {
                this.getUserMedia()
            } else {
                this.setStartButtonDisabled(true)
                this.showHelpOverlay()
            }
        }).catch(() => {
            // This call will probably run into the same error as what caused us to hit this catch, but worth trying
            // and we'll want the getUserMedia error handling anyway
            this.getUserMedia()
        })

        return panel
    }

    protected getUserMedia(): void {
        this.hideHelpOverlay()
        enumerateDevices(false).then((devices: IDevices) => {
            this.setStartButtonDisabled(false)
            this.devices = devices
            if (devices.mics.size > 0 && this.micSelect !== undefined) {
                const option: HTMLOptionElement = document.createElement("option")
                option.label = i18n.noMicrophone
                // text needed for Firefox
                option.text = option.label
                option.value = silentMicId
                this.micSelect.add(option)
                const stored_mic = getLocalStorageWithExpiration(SELECTED_MIC_KEY)
                let i = 1, index = 1
                if (option.value === stored_mic) {
                    index = 0
                }
                devices.mics.forEach((device: IDevice) => {
                    const option: HTMLOptionElement = document.createElement("option")
                    option.label = device.label
                    // text needed for Firefox
                    option.text = device.label
                    option.value = device.id
                    if (this.micSelect !== undefined) {
                        this.micSelect.add(option)
                    }
                    if (option.value === stored_mic) {
                        index = i
                    }
                    i += 1
                })
                const mic = this.micSelect.options[index]
                mic.selected = true
                this.constraints.micId = mic.value
            }
            if (devices.cams.size > 0) {
                const stored_cam = getLocalStorageWithExpiration(SELECTED_CAM_KEY)
                let i = 0, index = 0
                devices.cams.forEach((device: IDevice) => {
                    const option: HTMLOptionElement = document.createElement("option")
                    option.label = device.label
                    // text needed for Firefox
                    option.text = device.label
                    option.value = device.id
                    this.camSelect.add(option)
                    if (option.value === stored_cam) {
                        index = i
                    }
                    i += 1
                })
                const cam = this.camSelect.options[index]
                cam.selected = true
                this.constraints.camId = cam.value
                this.updateResolutions()
            }
        }).catch((err) => { // eslint-disable-line complexity
            this.setStartButtonDisabled(true)
            let errMsg = ""
            let errSep = ""
            if (err.message !== null && err.message !== undefined) {
                if (err.name === "OverconstrainedError" || err.name === "NotFoundError" || err.name === "DevicesNotFoundError") {
                    errMsg = i18n.noCameraFound
                } else if (err.name === "PermissionDeniedError" || err.name === "NotAllowedError") {
                    errMsg = i18n.camAndMicPermissionDenied
                } else if (err.name === "NotReadableError") {
                    errMsg = `${err.message}. ${i18n.noOtherTabsOpen}`
                } else {
                    errMsg = err.message
                }
            } else if (err.name !== null && err.name !== undefined) {
                errMsg = err.name
            }
            if (errMsg !== "") {
                errSep = ". "
            }

            this.showHelpOverlay(`${i18n.couldNotGetDevices}${errSep}${errMsg}`)
        })
    }

    protected hideHelpOverlay(): void {
        for (const select of [this.camSelect, this.resSelect, this.micSelect]){
            if (select !== undefined) {
                select.disabled = false
            }
        }
        this.helpOverlay.style.display = "none"
    }

    protected showHelpOverlay(errorText?: string): void {
        if (errorText !== undefined) {
            // Remember permissions error for any new C2C preview panel instances
            PreviewPanel.permissionsErrorString = errorText
        }
        if (PreviewPanel.permissionsErrorString !== undefined) {
            this.permissionsErrorSpan.textContent = PreviewPanel.permissionsErrorString
            this.permissionsErrorSpan.style.display = ""
            this.requestDevicesButton.style.display = "none"
        } else {
            this.permissionsErrorSpan.style.display = "none"
            this.requestDevicesButton.style.display = ""
        }
        for (const select of [this.camSelect, this.resSelect, this.micSelect]){
            if (select !== undefined) {
                select.disabled = true
            }
        }
        this.helpOverlay.style.display = ""
    }

    protected createHelpOverlay(): HTMLDivElement {
        const helpOverlay: HTMLDivElement = document.createElement("div")
        addColorClass(helpOverlay, "helpOverlay")
        helpOverlay.style.fontSize = "14px"
        helpOverlay.style.fontFamily = "UbuntuRegular"
        helpOverlay.style.display = "none"
        helpOverlay.style.width = "304px"
        helpOverlay.style.fontWeight = "bold"
        helpOverlay.style.borderRadius = "6px"
        helpOverlay.style.textAlign = "center"
        helpOverlay.style.padding = "8px"
        helpOverlay.style.position = "absolute"
        helpOverlay.style.top = "25%"
        helpOverlay.style.left = "50%"
        helpOverlay.style.transform = "translateX(-50%)"
        helpOverlay.style.zIndex = "1" // Without this the broadcast start button floats above the help overlay
        helpOverlay.style.borderWidth = "2px"
        helpOverlay.style.borderStyle = "solid"

        const permissionPrompt = document.createElement("div")
        permissionPrompt.style.marginBottom = "16px"
        if (isFirefox()) {
            permissionPrompt.textContent = i18n.camAndMicPermissionPromptFF
            indexedDB.open("test").onerror = () => {
                this.showHelpOverlay(i18n.privateBrowsingMessage)
            }
        } else {
            permissionPrompt.textContent = i18n.camAndMicPermissionPrompt
        }
        helpOverlay.appendChild(permissionPrompt)

        this.permissionsErrorSpan = document.createElement("div")
        addColorClass(this.permissionsErrorSpan, "error")
        this.permissionsErrorSpan.style.display = "none"
        helpOverlay.appendChild(this.permissionsErrorSpan)

        this.requestDevicesButton = document.createElement("button")
        this.requestDevicesButton.style.width = "268px"
        this.requestDevicesButton.style.padding = "7px 6px"
        this.requestDevicesButton.style.margin = "auto"
        this.requestDevicesButton.style.fontWeight = "normal"
        this.requestDevicesButton.style.fontSize = "14px"
        this.requestDevicesButton.style.fontFamily = "UbuntuRegular"
        this.requestDevicesButton.style.cursor = "pointer"
        this.requestDevicesButton.innerText = i18n.requestDevicePermissions
        this.requestDevicesButton.onclick = () => {
            // Issue in IFS on Samsung Browser where device permissions aren't
            // shown until the user exits fullscreen
            if (
                videoModeHandler.getVideoMode() === VideoMode.IFS &&
                isSamsungBrowser() &&
                !pageContext.current.isMobile
            ) {
                exitFullscreen()
            }
            this.getUserMedia()
        }
        helpOverlay.appendChild(this.requestDevicesButton)

        const supportText = document.createElement("div")
        supportText.style.marginTop = "16px"
        supportText.innerHTML = i18n.camAndMicPermissionSupportInfo() // eslint-disable-line @multimediallc/no-inner-html
        const supportLink = supportText.querySelector<HTMLAnchorElement>("#camAndMicInfoLink")
        if (supportLink !== null) {
            supportLink.onclick = () => addPageAction("DevicePermsInfoLinkClicked")
        }
        helpOverlay.appendChild(supportText)

        this.element.appendChild(helpOverlay)
        return helpOverlay
    }

    protected createCamSettingsPanel(): HTMLDivElement {
        // create camera panel
        const camPanel: HTMLDivElement = document.createElement("div")
        addColorClass(camPanel, "settingsPanel")
        camPanel.style.width = "100%"
        camPanel.style.height = "158px"
        camPanel.style.marginBottom = "5px"
        camPanel.style.borderWidth = "1px"
        camPanel.style.borderStyle = "solid"
        camPanel.style.boxSizing = "border-box"

        const cam = createBoldLabel(i18n.cameraLabel)
        cam.style.lineHeight = "28px"
        cam.style.paddingTop = "8px"
        cam.dataset.testid = "camera-label"
        camPanel.appendChild(cam)

        const camSelect = createSelect()
        camSelect.style.marginTop = "8px"
        camSelect.style.height = "32px"
        camSelect.dataset.testid = "camera-selection"
        camPanel.appendChild(camSelect)

        const res = createLightLabel(i18n.resolutionLabel)
        res.style.marginTop = "16px"
        res.style.fontSize = "12px"
        res.style.paddingTop = "0"
        res.dataset.testid = "resolution-label"
        camPanel.appendChild(res)

        const resSelect = createSelect()
        resSelect.style.marginTop = "5px"
        resSelect.style.height = "32px"
        resSelect.dataset.testid = "resolution-selection"
        camPanel.appendChild(resSelect)
        camSelect.onchange = () => {
            this.onCamChange()
        }
        resSelect.onchange = () => {
            this.onResChange()
        }

        this.camSelect = camSelect
        this.resSelect = resSelect
        return camPanel
    }

    protected createMicSettingsPanel(): HTMLDivElement {
        // create microphone panel
        const micPanel: HTMLDivElement = document.createElement("div")
        addColorClass(micPanel, "settingsPanel")
        micPanel.style.width = "100%"
        micPanel.style.height = "162px"
        micPanel.style.paddingTop = "8px"
        micPanel.style.borderWidth = "1px"
        micPanel.style.borderStyle = "solid"
        micPanel.style.boxSizing = "border-box"

        const mic = createBoldLabel(i18n.microphoneLabel)
        mic.style.lineHeight = "28px"
        micPanel.appendChild(mic)

        const micSelect = createSelect()
        micPanel.appendChild(micSelect)
        micSelect.style.marginTop = "8px"
        micSelect.style.height = "32px"
        micSelect.dataset.testid = "microphone-selection"

        micSelect.onchange = () => {
            const index = micSelect.selectedIndex
            if (index >= 0) {
                const option = micSelect.options[index]
                this.constraints.micId = option.value
                setLocalStorageWithExpiration(SELECTED_MIC_KEY, option.value, BROADCAST_SETTINGS_EXPIRATION)
                this.start()
            }
        }

        const audioSupported = isAudioContextFullySupported()
        if (audioSupported) {
            // create level div
            const div: HTMLDivElement = document.createElement("div")

            const label = createLightLabel(`${i18n.inputLevelLabel}:`)
            label.style.paddingTop = "16px"
            label.style.paddingLeft = "8px"
            label.style.display = "inline-block"

            div.appendChild(label)

            const level: HTMLDivElement = document.createElement("div")
            addColorClass(level, "inputLevelBar")
            level.style.width = "82px"
            level.style.margin = "23px 8px 0 10px"
            level.style.borderWidth = "1px"
            level.style.borderStyle = "solid"
            level.style.display = "inline-block"
            level.style.height = "7px"
            level.dataset.testid = "audio-level-bar"

            const range: HTMLDivElement = document.createElement("div")
            range.style.width = "78px"
            range.style.height = "7px"
            range.style.width = "0%"
            range.style.backgroundColor = "lightgreen"
            this.volumeRange = range
            level.appendChild(range)

            div.appendChild(level)
            micPanel.appendChild(div)

            this.tuneLabelFontSizeWithinSettingsPanel(label)
        }

        // create mute div
        const div: HTMLDivElement = document.createElement("div")
        const muteBox = new TransparentCheckbox(16, false, () => {
            const muted = !this.constraints.muted
            if (setMute(muted)) {
                this.constraints.muted = muted
            }
        })
        muteBox.setCheckboxValue("Mute")
        muteBox.setCheckboxId("mutebox-input")
        muteBox.element.id = "mutebox"
        muteBox.element.style.marginTop = "8px"
        muteBox.element.style.marginLeft = "8px"
        muteBox.element.dataset.testid = "mute-box"

        muteBox.disable()
        const interval = window.setInterval(() => {
            if (setMute(this.constraints.muted)) {
                muteBox.enable()
                clearInterval(interval)
            }
        }, 200)
        div.appendChild(muteBox.element)

        const setMute = (muted: boolean) => {
            if (this.streamer !== undefined) {
                if (!this.streamer.setMute(muted)) {
                    return false
                }
            } else {
                return false
            }
            if (muted) {
                muteBox.setCheckedDirectly(true)
            } else {
                muteBox.setCheckedDirectly(false)
            }
            setLocalStorageWithExpiration(BROADCAST_MUTE_KEY, String(muted), BROADCAST_SETTINGS_EXPIRATION)
            return true
        }

        const label = document.createElement("label")
        label.textContent = i18n.muteLabel
        label.htmlFor = "mutebox-input"
        label.style.paddingLeft = "5px"
        label.style.transform = "translateY(-4px)"
        label.style.fontFamily = "UbuntuRegular"
        label.style.fontSize = "14px"
        label.style.display = "inline-block"
        label.style.lineHeight = "22px"
        div.appendChild(label)

        if (!audioSupported) {
            muteBox.element.style.marginTop = "100px"
            label.style.marginTop = "70px"
        }
        micPanel.appendChild(div)

        this.micSelect = micSelect
        return micPanel
    }

    private tuneLabelFontSizeWithinSettingsPanel(label: HTMLDivElement): void {
        // prevent overflow for languages with longer "Input Level" text
        let size = 16
        const widthFromTextFontSizeChangeFactor = isSafari() ? 8 : 0
        const defaultLabelTextWidth = 85 - widthFromTextFontSizeChangeFactor
        while (
            getTextWidthFont(
                `${i18n.inputLevelLabel}:`,
                `${size}px UbuntuRegular`,
            ) > defaultLabelTextWidth &&
            size > 1
        ) {
            size -= 1
        }
        label.style.fontSize = `${size}px`
    }

    protected createVideoPanel(): HTMLDivElement {
        const panel: HTMLDivElement = document.createElement("div")
        panel.style.width = "289px"
        panel.style.cssFloat = "left"
        panel.style.height = "318px"
        panel.style.padding = "10px 0 10px 0"
        panel.dataset.testid = "preview-video-panel"

        // create video
        const video = createVideo()
        video.style.width = `${this.videoWidth}px`
        video.style.height = "150px"
        video.style.marginLeft = "10px"
        video.style.marginRight = "10px"
        panel.appendChild(video)
        this.video = video

        // create HD label
        const label: HTMLDivElement = document.createElement("div")
        addColorClass(label, "hdLabel")
        label.style.fontSize = "24px"
        label.style.fontFamily = "UbuntuBold"
        label.style.marginLeft = "10px"
        label.style.marginTop = "10px"
        label.textContent = "HD"
        label.style.visibility = "hidden"
        this.hdLabel = label
        panel.appendChild(label)

        // create start button
        panel.appendChild(this.createStartButton())

        // create OBS message
        const msg = createLightLabel(`${i18n.highQualityStream}:`)
        msg.style.fontSize = "11px"
        msg.style.paddingLeft = "0"
        msg.style.paddingTop = "5px"
        msg.style.textAlign = "center"
        panel.appendChild(msg)

        // create OBS Link
        const linkDiv:  HTMLDivElement = document.createElement("div")
        linkDiv.style.textAlign = "center"
        const link: HTMLAnchorElement = document.createElement("a")
        addColorClass(link, colorClass.hrefColor)
        link.style.fontSize = "12px"
        link.text = i18n.useOBS
        link.style.cursor = "pointer"
        link.dataset.testid = "obs-link"
        linkDiv.appendChild(link)
        panel.appendChild(linkDiv)
        link.onclick = () => {
            this.config.onOBSClick()
        }

        return panel
    }

    protected createStartButton(): HTMLDivElement {
        const buttonDiv: HTMLDivElement = document.createElement("div")
        buttonDiv.style.textAlign = "center"
        const button: HTMLButtonElement = document.createElement("button")
        addColorClass(button, "startButton")
        button.style.marginTop = "75px"
        button.style.width = "268px"
        button.style.height = "32px"
        button.style.fontWeight = "normal"
        button.style.fontSize = "14px"
        button.textContent = i18n.startBroadcasting
        button.style.fontFamily = "UbuntuBold"
        button.style.cursor = "pointer"
        buttonDiv.appendChild(button)
        button.onclick = () => {
            // Check if gender is transgender and if subgender is unspecified.
            if (this.user) {
                if (this.user.gender === Gender.OldTrans && this.user.subgender === undefined) {
                    // Create ModalSubmitForm with specify subgender prompt
                    const subgender_prompt = new SubgenderSelectionPrompt()
                    const config = {
                        acceptText: i18n.subgenderSelectionConfirm,
                        separator: false,
                        allowDecline: false,
                        orange: true,
                    }
                    modalSubmitForm(
                        subgender_prompt.element,
                        () => {
                            updateSubgenderFromForm(new FormData(subgender_prompt.element)).then((subgenderChoice) => {
                                if (this.user) {
                                    this.user.subgender = subgenderChoice
                                }
                                this.startBroadcast()
                            }).catch(ignoreCatch)
                        },
                        undefined,
                        undefined,
                        config,
                        true,
                    )
                } else {
                    this.startBroadcast()
                }
            }
        }
        this.startButton = button
        this.setStartButtonDisabled(true)

        // Firefox may perform worse for users, warning for users to avoid firefox webrtc
        if (isFirefox()){
            const ffWarning = createLightLabel(`${i18n.firefoxWarning}`)
            applyStyles(ffWarning, {
                fontSize: "11px",
                paddingLeft: "5px",
                textAlign: "center",
            })
            button.before(ffWarning)
            button.style.marginTop = "15px"
        }

        return buttonDiv
    }

    protected setStartButtonDisabled(disabled: boolean): void {
        this.startButton.disabled = disabled
        this.startButton.style.opacity = disabled ? ".5" : "1"
        this.startButton.style.cursor = disabled ? "default" : "pointer"
    }

    protected startBroadcast(): void {
        withSuspensionCheck(() => {
            getCurrentSMCRoom().then((room) => {
                if (room !== "") {
                    modalAlert(i18n.showMyCamCurrentlySharing(room))
                    this.setStartButtonDisabled(true)
                    onNoSMCRoom(() => {
                        this.setStartButtonDisabled(false)
                    })
                } else {
                    this.stop().then(() => {
                        this.config.onStartBroadcast()
                    }).catch(ignoreCatch)
                }
            }).catch(ignoreCatch)
        })
    }
}

interface IPreviewPanelModalConfig extends IPreviewPanelConfig {
    title: string,
    infoText: string[],
    startButtonText: string,
    onClose: () => void,
}

export class PreviewPanelForModal extends PreviewPanel {
    protected readonly config: IPreviewPanelModalConfig
    constructor(dossier: IBroadcastDossier, constraints: IStreamerConstraints, config: IPreviewPanelModalConfig) {
        super(dossier, constraints, config)
    }

    stop (): Promise<void> {
        return super.stop()
    }

    start(): void {
        super.start()
    }

    protected createPreviewPanel(): void {
        this.element.style.width = "402px"
        this.element.style.backgroundColor = "transparent"
        this.element.style.position = "relative"

        this.element.appendChild(this.createTitle())
        this.element.appendChild(this.createClose())
        this.element.appendChild(this.createVideoPanel())
        this.element.appendChild(this.createInfoPanel())
        this.element.appendChild(this.createMediaSettingsPanel())
        this.element.appendChild(this.createStartButton())
    }

    protected createTitle(): HTMLDivElement {
        const div: HTMLDivElement = document.createElement("div")
        div.style.width = "200px"
        div.style.height = "26px"
        div.style.display = "inline-block"
        const title: HTMLSpanElement = document.createElement("div")
        addColorClass(title, "title")
        title.style.fontFamily = "UbuntuBold"
        title.style.fontSize = "18px"
        title.textContent = this.config.title
        title.dataset.testid = "preview-title"
        div.appendChild(title)

        return div
    }

    protected createClose(): HTMLDivElement {
        const div = document.createElement("div")
        div.style.display = "inline-block"
        div.style.position = "relative"
        div.style.right = "-1px"
        div.style.width = div.style.height = "17px"
        div.style.cssFloat = "right"
        div.style.cursor = "pointer"
        div.dataset.testid = "cam-to-cam-preview-close-button"
        div.onclick = this.config.onClose

        const icon = document.createElement("img")
        icon.src = `${STATIC_URL_ROOT}tsdefaultassets/close-gray.svg`
        icon.style.width = icon.style.height = "100%"
        div.appendChild(icon)

        return div
    }

    protected createVideoPanel(): HTMLDivElement {
        this.videoWidth = 400
        const panel: HTMLDivElement = document.createElement("div")
        panel.style.textAlign = "center"
        panel.dataset.testid = "preview-video-panel"

        const video = createVideo()
        video.style.width = `${this.videoWidth}px`
        video.style.height = `${this.videoWidth / this.videoRatio}px`
        panel.appendChild(video)
        this.video = video

        return panel
    }

    protected createInfoPanel(): HTMLDivElement {
        const panel = document.createElement("div")
        addColorClass(panel, "infoPanel")
        panel.style.marginTop = "6px"
        panel.style.padding = "6px 5px 9px 4px"
        panel.style.borderWidth = "1px"
        panel.style.borderStyle = "solid"
        panel.style.boxSizing = "border-box"
        panel.dataset.testid = "cam-to-cam-preview-info-panel"

        const createLine = () => {
            const line = document.createElement("div")
            line.style.fontFamily = "UbuntuRegular"
            line.style.fontStyle = "normal"
            line.style.fontWeight = "bold"
            line.style.fontSize = "12px"
            line.style.lineHeight = "14px"
            line.style.textAlign = "center"
            return line
        }

        const infoText = this.config.infoText
        let line
        for (line of infoText) {
            const lineDiv = createLine()
            lineDiv.textContent = line
            panel.appendChild(lineDiv)
        }

        return panel
    }

    protected createMediaSettingsPanel(): HTMLDivElement {
        const panel = super.createMediaSettingsPanel()
        panel.style.width = "100%"
        panel.style.height = "auto"
        panel.style.padding = "9px 0px 0px 0px"
        panel.style.cssFloat = ""
        return panel
    }

    protected createHelpOverlay(): HTMLDivElement {
        const helpOverlay = super.createHelpOverlay()
        helpOverlay.style.top = "11%"
        return helpOverlay
    }

    protected createCamSettingsPanel(): HTMLDivElement {
        const camPanel: HTMLDivElement = document.createElement("div")
        addColorClass(camPanel, "settingsPanel")
        camPanel.style.width = "100%"
        camPanel.style.height = "68px"
        camPanel.style.paddingTop = "5px"
        camPanel.style.borderWidth = "2px"
        camPanel.style.borderStyle = "solid"
        camPanel.style.boxSizing = "border-box"

        const camDiv = document.createElement("div")
        camDiv.style.display = "inline-block"
        const camLabel = this.createMediaSettingLabel(i18n.cameraLabel)
        camLabel.dataset.testid = "camera-label"
        const camSelect = createSelect()
        camSelect.style.marginLeft = "8px"
        camSelect.style.marginTop = "8px"
        camDiv.appendChild(camLabel)
        camDiv.appendChild(camSelect)

        const resDiv = document.createElement("div")
        resDiv.style.display = "inline-block"
        resDiv.style.paddingLeft = "16px"
        const resLabel = this.createMediaSettingLabel(i18n.resolutionLabel)
        resLabel.style.paddingLeft = "10px"
        resLabel.dataset.testid = "resolution-label"
        const resSelect = createSelect()
        resSelect.style.marginTop = "8px"
        resDiv.appendChild(resLabel)
        resDiv.appendChild(resSelect)

        camPanel.appendChild(camDiv)
        camPanel.appendChild(resDiv)

        camSelect.onchange = () => {
            this.onCamChange()
        }
        resSelect.onchange = () => {
            this.onResChange()
        }

        this.camSelect = camSelect
        camSelect.dataset.testid = "camera-selection"
        this.resSelect = resSelect
        resSelect.dataset.testid = "resolution-selection"
        return camPanel
    }

    protected createMicSettingsPanel(): HTMLDivElement {
        // Note, if you need to make a mic settings panel make sure it's optional and doesn't mess up Show My Cam
        this.constraints.micId = silentMicId
        const dummyDiv = document.createElement("div")
        dummyDiv.style.display = "none"
        return dummyDiv
    }

    protected createStartButton(): HTMLDivElement {
        const buttonDiv: HTMLDivElement = document.createElement("div")
        buttonDiv.style.textAlign = "center"
        buttonDiv.style.padding = "10px 0px 2px"

        const button: HTMLButtonElement = document.createElement("button")
        addColorClass(button, "startButton")
        button.style.width = "305px"
        button.style.height = "27px"
        button.style.borderWidth = "1px"
        button.style.borderStyle = "solid"
        button.style.borderRadius = "4px"
        button.textContent =  this.config.startButtonText
        button.style.fontFamily = "UbuntuBold"
        button.style.fontSize = "16px"
        button.style.cursor = "pointer"
        button.style.whiteSpace = "nowrap"
        button.style.overflow = "hidden"
        button.style.textOverflow = "ellipsis"
        buttonDiv.appendChild(button)
        button.onclick = () => {
            button.disabled = true
            this.startBroadcast()
        }
        button.dataset.testid = "cam-to-cam-preview-start-button"
        this.startButton = button
        this.setStartButtonDisabled(true)

        return buttonDiv
    }

    private createMediaSettingLabel(text: string): HTMLDivElement {
        const label = document.createElement("div")
        label.style.paddingLeft = "8px"
        label.style.paddingTop = "3px"
        label.style.fontSize = "16px"
        label.style.fontFamily = "UbuntuBold"
        label.textContent = text
        return label
    }
}

export interface IBroadcastPanelConfig {
    onStopBroadcast: () => void,
}

export class BroadcastPanel extends Component {
    protected readonly dossier: IBroadcastDossier
    protected readonly config: IBroadcastPanelConfig
    protected readonly constraints: IStreamerConstraints
    private streamer: Streamer | undefined
    private video: HTMLVideoElement
    private status: HTMLDivElement | undefined
    private fps: HTMLDivElement | undefined
    private warning: HTMLImageElement | undefined
    private onBeforeUnload: ((e: Event) => string) | undefined
    private onUnload: (() => void) | undefined
    private stopping = false
    private timeStarted = 0
    readonly onStreamStatusUpdate: (status: IStatusUpdate) => void
    readonly onStreamStop: (uuid: string) => void
    protected main: HTMLDivElement
    protected away: HTMLDivElement | undefined

    constructor(dossier: IBroadcastDossier, constraints: IStreamerConstraints, config: IBroadcastPanelConfig) {
        super()

        this.dossier = dossier
        this.config = config
        this.constraints = constraints
        this.createBroadcastPanel()

        const fpsDelay = 5000
        let fpsDropped = false
        let isWarning = false
        let isAway = false
        let isConnecting = true
        const setStatus = (msg: string, warn?: boolean) => {
            if (this.status !== undefined && this.warning !== undefined) {
                if (warn === true && !isWarning) {
                    isWarning = true
                    this.status.style.color = "#D80000"
                    this.status.style.fontFamily = "UbuntuBold"
                    this.status.style.width = "28%"
                    this.status.style.paddingLeft = "18%"
                    this.status.style.cssFloat = "left"
                    this.status.title = this.warning.title = i18n.poorBroadcast
                    this.warning.style.display = ""
                } else if (warn !== true && isWarning) {
                    this.status.style.color = "black"
                    this.status.style.fontFamily = "UbuntuRegular"
                    this.status.style.width = ""
                    this.status.style.paddingLeft = "0px"
                    this.status.style.cssFloat = "none"
                    this.status.title = ""
                    this.warning.style.display = "none"
                    isWarning = false
                }
                this.status.textContent = msg
                if (msg.length > 27) {
                    this.status.style.paddingTop = "2px"
                } else {
                    this.status.style.paddingTop = "10px"
                }
            }
        }
        const showConnectingStatus = (): void => {
            const retriesMax = 10
            let retryTime = performance.now()
            const connectingInterval = window.setInterval(() => {
                if (!isConnecting) {
                    clearInterval(connectingInterval)
                    return
                }
                const now = performance.now()
                const retriesSecs = Math.floor((now - retryTime) / 1000.0)
                if (retriesSecs < 1) {
                    return
                }
                if (retriesSecs >= retriesMax) {
                    retryTime = now
                }
                if (this.fps !== undefined) {
                    this.fps.textContent = ""
                }
                if (this.status !== undefined) {
                    this.status.textContent = `${i18n.pleaseWaitConnecting} ${retriesMax - retriesSecs + 1}...`
                    if (this.status.textContent.length > 33) {
                        this.status.style.paddingTop = "2px"
                    } else if (pageContext.current.languageCode === "ja") {
                        // japanese is less than 27 characters but takes up more space per character
                        this.status.style.fontSize = "14px"
                    } else {
                        this.status.style.paddingTop = "10px"
                    }
                }
                // The interval is not guaranteed to run at the exact interval of time. Attempt to run it more often
                // than 1 second so that the status message doesn't show a lag in the countdown
            }, 200)
        }
        showConnectingStatus()
        this.onStreamStatusUpdate = (status: IStatusUpdate) => { // eslint-disable-line complexity
            if (this.streamer === undefined || this.streamer.uuid !== status.uuid) {
                return
            }
            const data = status.data
            if (data["fps"] !== undefined) {
                if (this.setFPS(data["fps"])) {
                    fpsDropped = false
                } else {
                    fpsDropped = true
                }
            }
            if (data["status"] !== undefined) {
                const status = data["status"]
                if (status !== "connecting") {
                    isConnecting = false
                }
                if (this.isStatusOffline(status)) {
                    setStatus(`(Offline)`)
                    return
                }
                if (status === "away" && !isAway) {
                    // Don't stop the broadcast, which effectively stops the handler. We still need to listen to
                    // status messages from the handler
                    this.main.style.display = "none"
                    if (this.away !== undefined) {
                        this.away.style.display = ""
                    }
                    isAway = true
                } else if (status !== "away" && isAway) {
                    if (this.away !== undefined ) {
                        this.away.style.display = "none"
                    }
                    this.main.style.display = ""
                    isAway = false
                } else {
                    const statusMsg = status.charAt(0).toUpperCase() + status.slice(1)
                    if (status === "connecting") {
                        if (!isConnecting) {
                            isConnecting = true
                            showConnectingStatus()
                        }
                    } else if (fpsDropped && this.timeStarted > 0 && (performance.now() - this.timeStarted) > fpsDelay) {
                        setStatus(i18n.lowFPS, true)
                    } else {
                        setStatus(`(${statusMsg} Broadcasting)`)
                    }
                }
            }
        }

        this.onStreamStop = (uuid: string) => {
            // streamStop can fire many times, but, we can't listen only once (with the "once" method)
            // since we are not guaranteed the id we need. Here we guard against trying to stop the stream
            // too many times
            if (this.streamer !== undefined && this.streamer.uuid === uuid && !this.stopping) {
                this.stop(true)  // eslint-disable-line @typescript-eslint/no-floating-promises
            }
        }

        streamStatusUpdate.listen(this.onStreamStatusUpdate)
        streamStop.listen(this.onStreamStop)

        this.start()
    }

    protected createBroadcastPanel(): void {
        const main = this.createMain()
        main.appendChild(this.createVideo())
        main.appendChild(this.createControls())
        const orientationWarning = document.createElement("div")
        orientationWarning.innerText = i18n.incorrectOrientation
        applyStyles(orientationWarning, {
            color: "#ffffff",
            position: "absolute",
            fontSize: "1.0em",
            fontWeight: "bold",
            top: "8px",
            left: "8px",
            zIndex: 100,
            display: "none",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            lineHeight: "150%",
            padding: "1px",
        })
        orientationWarning.setAttribute("draggable", "false") // eslint-disable-line @multimediallc/no-set-attribute
        addEventListenerPoly("orientationchange", window, () => {
            if (isOrientationChangeSupported() && !isProperLandscape()) {
                orientationWarning.style.display = ""
                if (isPortrait()) {
                    orientationWarning.innerText = i18n.incorrectPortrait
                } else {
                    orientationWarning.innerText = i18n.incorrectLandscape
                }
            } else {
                orientationWarning.style.display = "none"
            }
        })
        main.appendChild(orientationWarning)
        const away = this.createAway()
        away.appendChild(this.createTitle())
        away.appendChild(this.createMessage())
        away.appendChild(this.createLink())

        addColorClass(this.element, "broadcastPanel")
        this.element.appendChild(main)
        this.element.appendChild(away)
        this.main = main
        this.away = away
    }

    public isBroadcasting(): boolean {
        if (this.streamer !== undefined) {
            return this.streamer.broadcasting
        }
        return false
    }

    private start(): void {
        this.stop().then(() => {
            const setupMaxRetries = 5
            let setupRetries = 0
            const setupRetry = (resolve: () => void, reject: (msg: string) => void) => {
                this.streamer = new Streamer()
                this.streamer.setup(this.video, this.dossier, this.constraints).then(() => {
                    if (this.streamer !== undefined) {
                        this.streamer.start().then(() => {
                            this.onBeforeUnload = (e: Event) => {
                                e.preventDefault()
                                e.returnValue = false
                                return i18n.wantToLeaveConfirmation
                            }
                            addEventListenerPoly("beforeunload", window, this.onBeforeUnload)
                            this.onUnload = () => {
                                this.stop(true)  // eslint-disable-line @typescript-eslint/no-floating-promises
                            }
                            addEventListenerPoly(unloadEventName(), window, this.onUnload)
                            webRTCBroadcastStartStop.fire(true)
                            resolve()
                        }).catch((e: Error) => {
                            reject(`${i18n.couldNotStartBroadcast}: ${e.message}`)
                        })
                    }
                }).catch((e) => {
                    if (isiPad() && e.message === "Invalid constraint") {
                        // iPad fails a few times with this message before the setup works
                        setupRetries += 1
                        if (setupRetries <= setupMaxRetries) {
                            this.stop().then(() => {
                                window.setTimeout(() => {
                                    setupRetry(resolve, reject)
                                }, 500)
                            }).catch(ignoreCatch)
                            return
                        }
                        reject(i18n.ipadFailedToSetup)
                    }
                    reject(`${i18n.couldNotSetupBroadcast}: ${e.message}`)
                })
            }

            new Promise<void>(setupRetry).catch((msg: string) => {
                modalAlert(msg)
                this.stop(true)  // eslint-disable-line @typescript-eslint/no-floating-promises
            })
        }).catch(ignoreCatch)
    }

    protected stop(exit?: boolean): Promise<void> {
        this.stopping = true
        if (this.onBeforeUnload !== undefined) {
            removeEventListenerPoly("beforeunload", window, this.onBeforeUnload)
            this.onBeforeUnload = undefined
        }
        if (this.onUnload !== undefined) {
            removeEventListenerPoly(unloadEventName(), window, this.onUnload)
            this.onUnload = undefined
        }
        let promise = Promise.resolve()
        if (this.streamer !== undefined) {
            promise = this.streamer.stop()
        }
        return new Promise<void>((resolve, reject) => {
            promise.then(() => {
                this.stopping = false
                if (exit === true) {
                    streamStatusUpdate.removeListener(this.onStreamStatusUpdate)
                    streamStop.removeListener(this.onStreamStop)
                    this.config.onStopBroadcast()
                    webRTCBroadcastStartStop.fire(false)
                }
                resolve()
            }).catch(reject)
        })
    }

    private setFPS(fps: number): boolean {
        let frameRateGood = desktopFrameRateGood
        let frameRateAvg =  desktopFrameRateAvg
        if (isMobileDevice()) {
            frameRateGood = mobileFrameRateGood
            frameRateAvg =  mobileFrameRateAvg
        }
        let normal = true
        let colorClass
        fps = Math.round(fps)
        if (fps >= frameRateGood) {
            colorClass = "frameRateGood"
        } else if (fps >= frameRateAvg) {
            colorClass = "frameRateAvg"
        } else {
            colorClass = "frameRateBad"
            normal = false
        }
        if (this.fps !== undefined) {
            this.fps.className = ""
            addColorClass(this.fps, colorClass)
            this.fps.textContent = `${fps} FPS`
        }

        return normal
    }

    protected createMain(): HTMLDivElement {
        const main = document.createElement("div")
        main.style.height = componentHeight
        main.style.width = "100%"
        main.style.overflow = "unset"

        return main
    }

    private createAway(): HTMLDivElement {
        const away = document.createElement("div")
        away.style.height = componentHeight
        away.style.width = "100%"
        away.style.backgroundColor = "black"
        away.style.display = "none"

        return away
    }

    protected createVideo(): HTMLVideoElement {
        const video = createVideo()
        video.style.width = "100%"
        video.style.height = "370px"
        video.style.backgroundColor = "black"
        this.video = video

        return video
    }

    protected createControls(): HTMLDivElement {
        const controls: HTMLDivElement = document.createElement("div")
        addColorClass(controls, "controls")
        controls.style.width = "100%"
        controls.style.height = "35px"

        // create mute button
        const muteButton: HTMLButtonElement = document.createElement("button")
        muteButton.style.border = "0px"
        const muteImg = document.createElement("div")
        addColorClass(muteImg, "muteImg")
        muteImg.textContent = ""
        muteImg.style.height = "20px"
        muteImg.style.width = "20px"
        muteImg.style.border = "0px"
        muteImg.style.paddingTop = "9px"
        if (isSafari() && !isMobileDevice()) {
            muteImg.style.paddingTop = "3px"
        }
        muteImg.style.outline = "none"
        muteButton.appendChild(muteImg)
        const muteDiv: HTMLDivElement = document.createElement("div")
        muteDiv.style.cssFloat = "left"
        muteDiv.style.textAlign = "center"
        muteDiv.style.height = "34px"
        muteDiv.style.borderRight = "1px solid black"
        muteDiv.style.width = "10%"
        if (isiOS() && isSafari()) {
            muteDiv.style.width = "12%"
        }
        muteDiv.appendChild(muteButton)
        controls.appendChild(muteDiv)

        const setMute = (muted: boolean) => {
            if (this.streamer !== undefined) {
                if (!this.streamer.setMute(muted)) {
                    return false
                }
            } else {
                return false
            }
            if (muted) {
                addColorClass(muteImg, "muted")
            } else {
                removeColorClass(muteImg, "muted")
            }
            setLocalStorageWithExpiration(BROADCAST_MUTE_KEY, String(muted), BROADCAST_SETTINGS_EXPIRATION)
            return true
        }
        muteButton.onclick = () => {
            const muted = !this.constraints.muted
            if (setMute(muted)) {
                this.constraints.muted = muted
            }
        }
        muteButton.disabled = true
        const interval = window.setInterval(() => {
            if (setMute(this.constraints.muted)) {
                muteButton.disabled = false
                clearInterval(interval)
            }
        }, 200)

        // create status
        const statusDiv: HTMLDivElement = document.createElement("div")
        addColorClass(statusDiv, "inBrowserStatus")
        statusDiv.style.cssFloat = "left"
        statusDiv.style.textAlign = "center"
        statusDiv.style.height = "34px"
        statusDiv.style.width = "51%"
        statusDiv.dataset["testid"] = "stream-status"
        if (isiOS()) {
            statusDiv.style.width = "44%"
        }
        statusDiv.style.borderRight = "1px solid black"
        statusDiv.style.padding = "0px"
        statusDiv.style.fontFamily = "UbuntuRegular"
        const fps = createBoldLabel("")
        fps.style.cssFloat = "left"
        fps.style.paddingLeft = "5px"
        fps.style.paddingTop = "10px"
        const status = createLightLabel("")
        status.style.paddingLeft = "0px"
        const warning: HTMLImageElement = document.createElement("img")
        warning.src = `${STATIC_URL_ROOT}broadcastassets/warning.png`
        warning.style.height = "16px"
        warning.style.paddingTop = "9px"
        warning.style.paddingLeft = "5px"
        warning.style.display = "none"
        warning.style.cssFloat = "left"
        statusDiv.appendChild(fps)
        statusDiv.appendChild(status)
        statusDiv.appendChild(warning)
        this.fps = fps
        this.status = status
        this.warning = warning
        controls.appendChild(statusDiv)

        // create stop button
        const stopButton: HTMLButtonElement = document.createElement("button")
        const stopImg: HTMLInputElement = document.createElement("input")
        stopImg.type = "image"
        stopImg.src = `${STATIC_URL_ROOT}broadcastassets/stop.svg`
        stopImg.style.verticalAlign = "middle"
        stopImg.style.height = "16px"
        stopImg.style.paddingTop = "1px"
        stopButton.appendChild(stopImg)
        const stopLabel: HTMLSpanElement = document.createElement("span")
        stopLabel.style.fontFamily = "UbuntuRegular"
        stopLabel.textContent = stopLabel.innerText = i18n.stopBroadcasting
        // to accomodate translations of various lengths:
        const stopLabelLength = stopLabel.innerText.length
        if (stopLabelLength > 24) {
            stopLabel.style.fontSize = "11px"
            stopLabel.style.paddingLeft = "1px"
        } else if (stopLabelLength < 24 && stopLabelLength > 18) {
            stopLabel.style.fontSize = "13px"
            stopLabel.style.paddingLeft = "2px"
        } else {
            stopLabel.style.fontSize = "18px"
            stopLabel.style.paddingLeft = "7px"
        }
        stopLabel.style.verticalAlign = "middle"
        stopButton.appendChild(stopLabel)
        stopButton.style.paddingTop = "5px"
        stopButton.style.cssFloat = "right"
        stopButton.style.border = "0"
        stopButton.dataset["testid"] = "stop-broadcasting-web-rtc-button"
        const stopDiv: HTMLDivElement = document.createElement("div")
        stopDiv.style.cssFloat = "left"
        stopDiv.style.textAlign = "center"
        stopDiv.style.height = "34px"
        stopDiv.appendChild(stopButton)
        controls.appendChild(stopDiv)
        stopButton.onclick = () => {
            this.stop(true)  // eslint-disable-line @typescript-eslint/no-floating-promises
        }

        return controls
    }

    private createTitle(): HTMLDivElement {
        const div: HTMLDivElement = document.createElement("div")
        div.style.textAlign = "center"
        div.style.paddingTop = "90px"
        const title: HTMLSpanElement = document.createElement("span")
        addColorClass(title, "awayTitle")
        title.textContent = i18n.youAreAway
        title.style.fontFamily = "UbuntuRegular"
        title.style.fontSize = "40px"
        div.appendChild(title)

        return div
    }

    private createMessage(): HTMLDivElement {
        const div: HTMLDivElement = document.createElement("div")
        div.style.textAlign = "center"
        div.style.paddingLeft = "16%"
        div.style.paddingRight = "16%"
        div.style.paddingTop = "50px"
        const message: HTMLSpanElement = document.createElement("span")
        addColorClass(message, "inBrowserAwayMessage")
        message.style.fontFamily = "UbuntuRegular"
        message.style.fontSize = "14px"
        message.textContent = i18n.wentToAway
        div.appendChild(message)

        return div
    }

    private createLink(): HTMLDivElement {
        const div: HTMLDivElement = document.createElement("div")
        div.style.textAlign = "center"
        div.style.paddingTop = "35px"
        const link: HTMLAnchorElement = document.createElement("a")
        addColorClass(link, "awayLink")
        link.text = i18n.exitAwayMode
        link.style.fontFamily = "UbuntuRegular"
        link.style.fontSize = "20px"
        link.style.cursor = "pointer"
        link.style.textDecoration = "underline"
        div.appendChild(link)
        link.onclick = () => {
            returnFromAway()
        }

        return div
    }

    protected isStatusOffline(status: string): boolean {
        return !broadcastStatuses.some(x => x === status)
    }
}

export interface IBroadcastConfig {
    onOBSClick: () => void,
}

export class Broadcast extends Component {
    private readonly dossier: IBroadcastDossier
    private readonly user: ILoggedInUser | undefined
    private readonly config: IBroadcastConfig
    private readonly constraints: IStreamerConstraints
    private broadcastPanel: BroadcastPanel | undefined
    private previewPanel: PreviewPanel | undefined
    private startCounter = 1

    constructor(dossier: IBroadcastDossier, config: IBroadcastConfig, loggedInUser?: ILoggedInUser) {
        super()

        this.dossier = dossier
        this.user = loggedInUser
        this.config = config
        const muted = getLocalStorageWithExpiration(BROADCAST_MUTE_KEY) === "true"
        this.constraints = {
            micId: "",
            camId: "",
            width: 0,
            height: 0,
            muted: muted !== undefined ? muted : false,
        }

        addColorClass(this.element, "Broadcast")
        this.element.style.width = "498px"
        this.element.style.height = "407px"
        this.element.style.fontFamily = "UbuntuBold"
        this.element.style.fontSize = "18px"
        this.element.style.position = "relative"

        this.previewPanel = this.createPreview()
        this.addChild(this.previewPanel)
    }

    public isBroadcasting(): boolean {
        if (this.broadcastPanel !== undefined) {
            return this.broadcastPanel.isBroadcasting()
        }
        return false
    }

    private createPreview(): PreviewPanel {
        return new PreviewPanel(this.dossier, this.constraints, {
            onOBSClick: this.config.onOBSClick,
            onStartBroadcast: () => {
                if (this.previewPanel !== undefined) {
                    this.removeChild(this.previewPanel)
                    this.previewPanel = undefined
                }

                addPageAction("WebRTCBroadcastStart", {
                    "startCount": this.startCounter,
                })
                this.startCounter += 1

                this.broadcastPanel = this.createBroadcast()
                this.addChild(this.broadcastPanel)
            },
        }, this.user)
    }

    private createBroadcast(): BroadcastPanel {
        return new BroadcastPanel(this.dossier, this.constraints, {
            onStopBroadcast: () => {
                if (this.broadcastPanel !== undefined) {
                    this.removeChild(this.broadcastPanel)
                    this.broadcastPanel = undefined
                }

                this.previewPanel = this.createPreview()
                this.addChild(this.previewPanel)
            },
        })
    }
}
