import { ArgJSONMap } from "@multimediallc/web-utils"
import { isLocalStorageSupported, isWebRTCSupported } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { modalAlert, modalConfirm } from "../../../common/alerts"
import { deleteCb, getCb, postCb } from "../../../common/api"
import { isNotLoggedIn } from "../../../common/auth"
import { getBroadcastDossier } from "../../../common/broadcastlib/dossier"
import { streamStatusUpdate, WebRTCHandler } from "../../../common/broadcastlib/webRTCHandler"
import { BroadcastTermsModal } from "../../../common/broadcastTermsModal"
import { stringPart, userPart } from "../../../common/chatconnection/roomnoticeparts"
import { roomLoaded } from "../../../common/context"
import { createDivotBottom, DivotPosition } from "../../../common/divot"
import { applyStyles, hoverEvent } from "../../../common/DOMutils"
import { addDragListener } from "../../../common/dragListener"
import { EventRouter, ListenerGroup } from "../../../common/events"
import { fullscreenChange, fullscreenElement, isFullscreen } from "../../../common/fullscreen"
import { ModalComponent } from "../../../common/modalComponent"
import { addPageAction } from "../../../common/newrelic"
import { ignoreCatch } from "../../../common/promiseUtils"
import { RoomStatus } from "../../../common/roomStatus"
import { VIDEO_CONTROLS_ARE_COMPACT } from "../../../common/theatermodelib/theaterVideoControls"
import { i18n } from "../../../common/translation"
import { safeWindowOpen } from "../../../common/windowUtils"
import { createBroadcasterUserInfo } from "../../api/pm"
import { BroadcastPanel, PreviewPanelForModal } from "../../broadcast"
import { addColorClass, removeColorClass } from "../../colorClasses"
import { pageContext } from "../../interfaces/context"
import { showMyCamViewStarted, showMyCamViewStopped, UserSMCWatchingTopic } from "../../pushservicelib/topics/user"
import { resizeDebounceEvent } from "../../ui/responsiveUtil"
import { buildTooltip } from "../../ui/tooltip"
import { dmsHeightChanged } from "../pm/dmWindowsManager"
import { getDmWindowHeight } from "../pm/dmWindowUtils"
import { baseZIndex, checkOnline, createCornerViewerButton, createCornerViewerControlsContainer } from "./smcUtil"
import type { IBroadcastDossier } from "../../../common/broadcastlib/dossier";
import type { IStatusUpdate } from "../../../common/broadcastlib/webRTCHandler";
import type { IChatConnection, IRoomContext } from "../../../common/context";
import type { BoundListener } from "../../../common/events";
import type { IUserInfo } from "../../../common/messageInterfaces"
import type { TheaterControlsIconTextButton } from "../../../common/theatermodelib/theaterControlsButtonsUtil"
import type { IStreamerConstraints } from "../../broadcast";

/* smcViewer as in the broadcast viewer who will share their own cam */
const SHOW_MY_CAM_COOLDOWN_TIME = 5 * 1000
const showMyCamBroadcastSettingsKey = "smcBroadcastSettings"

const enum SmcViewerState {
    Ready = "ready",
    Starting = "starting",
    Started = "started",
    Cooldown = "cooldown",
}

let smcSharedWith: string | undefined
let showMyCam: ShowMyCam | undefined
let chatStatus: RoomStatus

addEventListenerPoly("pageshow", window, (event: PageTransitionEvent) => {
    // Back-forward cache can leave up a broken cam. Shut that cam down
    if (event.persisted && showMyCam !== undefined) {
        showMyCam.stopCam()  // eslint-disable-line @typescript-eslint/no-floating-promises
    }
})

export function getSmcSharedWith(): string | undefined {
    return smcSharedWith
}

const showMyCamDidNotStart = new EventRouter<undefined>("showMyCamDidNotStart")
const requestChangeClickListener = new EventRouter<() => void>("requestChangeClickListener")
const showMyCamStateChanged = new EventRouter<SmcViewerState>("showMyCamStateChanged")

export function setupShowMyCamUI(videoOfflineChange: EventRouter<boolean>): boolean {
    if (!isWebRTCSupported() || !WebRTCHandler.canUseWebRTC()) {
        return false
    }

    let didNotStartListener: BoundListener<undefined> | undefined
    roomLoaded.listen((context: IRoomContext) => {
        if (showMyCam !== undefined && context.chatConnection.room() !== showMyCam.roomChatConn.room()) {
            showMyCam.cleanup()
            showMyCam = undefined
        }

        if (context.chatConnection.viewerIsAnonymous()) {
            requestChangeClickListener.fire(() => {
                isNotLoggedIn()
            })
        } else {
            if (showMyCam === undefined) {
                showMyCam = new ShowMyCam(context.chatConnection, videoOfflineChange)
            }

            const onShowMyCamLinkClick = () => {
                showMyCamStateChanged.fire(SmcViewerState.Starting)
                requestChangeClickListener.fire(() => {})
                if (didNotStartListener !== undefined) {
                    didNotStartListener.removeListener()
                }
                didNotStartListener = showMyCamDidNotStart.listen(() => {
                    showMyCamStateChanged.fire(SmcViewerState.Ready)
                    requestChangeClickListener.fire(onShowMyCamLinkClick)
                }, false)

                getCb(`api/ts/chat/can-share-cam/${context.dossier.room}/`).then((xhr) => {
                    const response = new ArgJSONMap(xhr.responseText)
                    if (response.getBoolean("can_share") && response.getStringList("shared_cam").length === 0) {
                        if (showMyCam !== undefined) {
                            showMyCam.startCam()
                        }
                    } else {
                        if (response.getBoolean("show_upsell")) {
                            modalConfirm(i18n.showMyCamMayNotShareUpsell, () => {
                                const purchaseTokensUrl = `/tipping/purchase_tokens/?source=${pageContext.current.PurchaseEventSources["TOKEN_SOURCE_SHOW_MY_CAM"]}`
                                addPageAction("SharedCamPurchaseTokensClicked")
                                safeWindowOpen(purchaseTokensUrl, "_blank", "height=615, width=850, scrollbars=1")
                            })
                        } else if (response.getBoolean("is_private")) {
                            modalAlert(i18n.showMyCamMayNotShareIsPrivate)
                        } else if (response.getStringList("shared_cam").length > 0) {
                            // Sharing with multiple rooms not supported yet
                            modalAlert(i18n.showMyCamAlreadyBroadcasting)
                            addPageAction("SharedCamAlreadySharing")
                        } else {
                            modalAlert(response.getString("message"))
                        }
                        showMyCamDidNotStart.fire(undefined)
                    }
                }).catch(ignoreCatch)
            }

            showMyCam.setOnStart(() => {
                showMyCamStateChanged.fire(SmcViewerState.Started)
                requestChangeClickListener.fire(() => {
                    addPageAction("SharedCamBroadcastStopClicked")
                    if (showMyCam !== undefined) {
                        showMyCam.stopCam(true)  // eslint-disable-line @typescript-eslint/no-floating-promises
                    }
                })
            })

            showMyCam.setOnStop(() => {
                showMyCamStateChanged.fire(SmcViewerState.Cooldown)
                requestChangeClickListener.fire(() => {})
                window.setTimeout(() => {
                    showMyCamStateChanged.fire(SmcViewerState.Ready)
                    requestChangeClickListener.fire(() => {
                        onShowMyCamLinkClick()
                    })
                }, SHOW_MY_CAM_COOLDOWN_TIME)
            })

            requestChangeClickListener.fire(() => {
                onShowMyCamLinkClick()
            })
        }
    })
    return true
}

function generateShowMyCamLinkTooltip(content: string): HTMLDivElement {
    const tooltip = buildTooltip({
        content,
        hasHTML: false,
        divotPosition: DivotPosition.Bottom,
    })
    applyStyles(tooltip, {
        fontSize: "11px",
        padding: "4px",
        top: "8px",
        pointerEvents: "none",
    })
    return tooltip
}

export function setupShowMyCamLink(showMyCamLink: HTMLElement, videoOfflineChange: EventRouter<boolean>): boolean {
    requestChangeClickListener.listen((listener) => {
        if (!pageContext.current.isNoninteractiveUser){
            showMyCamLink.onclick = () => {
                if (!showMyCamLink.classList.contains("disabled")) {
                    listener()
                }
            }
        } else {
            showMyCamLink.onclick = () => {
                modalAlert(i18n.internalStaffC2C)
            }
        }
    })

    let tooltip = generateShowMyCamLinkTooltip(i18n.waitingToConnect)

    showMyCamLink.textContent = i18n.showMyCamShow
    addColorClass(showMyCamLink, "disabled")
    showMyCamLink.ariaDisabled = "true"
    showMyCamLink.appendChild(tooltip)

    hoverEvent(showMyCamLink).listen(hovered => {
        if (tooltip.parentElement !== null) {
            tooltip.style.display = hovered ? "block" : "none"
        }
    })

    showMyCamStateChanged.listen((state) => {
        tooltip.remove()
        removeColorClass(showMyCamLink, "disabled")
        switch (state) {
            case SmcViewerState.Ready:
                showMyCamLink.textContent = i18n.showMyCamShow
                break
            case SmcViewerState.Starting:
                showMyCamLink.textContent = i18n.showMyCamShow
                addColorClass(showMyCamLink, "disabled")
                showMyCamLink.ariaDisabled = "true"
                break
            case SmcViewerState.Started:
                showMyCamLink.textContent = i18n.showMyCamStop
                break
            case SmcViewerState.Cooldown:
                showMyCamLink.textContent = i18n.showMyCamShow
                addColorClass(showMyCamLink, "disabled")
                showMyCamLink.ariaDisabled = "true"
                tooltip = generateShowMyCamLinkTooltip(i18n.showMyCamCooldownAlert(SHOW_MY_CAM_COOLDOWN_TIME / 1000))
                showMyCamLink.appendChild(tooltip)
                break
        }
    })

    return setupShowMyCamUI(videoOfflineChange)
}

export function setupShowMyCamVideoControlsButton(showMyCamButton: TheaterControlsIconTextButton, videoOfflineChange: EventRouter<boolean>): boolean {
    requestChangeClickListener.listen((listener) => {
        if (!pageContext.current.isNoninteractiveUser){
            showMyCamButton.element.onclick = () => {
                if (!showMyCamButton.isDisabled()) {
                    listener()
                }
            }
        } else {
            showMyCamButton.element.onclick = () => {
                if (!showMyCamButton.isDisabled()) {
                    modalAlert(i18n.internalStaffC2C)
                }
            }
        }
    })

    showMyCamButton.setTooltipTextOverride(i18n.waitingToConnect)
    showMyCamButton.disable()

    showMyCamStateChanged.listen((state) => {
        switch (state) {
            case SmcViewerState.Ready:
                resetSmcButtonLabelAndTooltip(showMyCamButton, i18n.showMyCamShow)
                if (chatStatus !== RoomStatus.Offline) {
                    showMyCamButton.enable()
                }
                break
            case SmcViewerState.Starting:
                resetSmcButtonLabelAndTooltip(showMyCamButton, i18n.showMyCamShow)
                showMyCamButton.disable()
                break
            case SmcViewerState.Started:
                resetSmcButtonLabelAndTooltip(showMyCamButton, i18n.showMyCamStop)
                showMyCamButton.enable()
                break
            case SmcViewerState.Cooldown:
                resetSmcButtonLabelAndTooltip(showMyCamButton, i18n.showMyCamShow)
                // when room is offline, theaterVideoControls disables the button
                if (chatStatus !== RoomStatus.Offline) {
                    showMyCamButton.setTooltipTextOverride(i18n.showMyCamCooldownAlert(SHOW_MY_CAM_COOLDOWN_TIME / 1000))
                    showMyCamButton.disable()
                }
                break
        }
    })

    return setupShowMyCamUI(videoOfflineChange)
}

function resetSmcButtonLabelAndTooltip(smcButton: TheaterControlsIconTextButton, label: string): void {
    // clears any tooltip overrides and sets the button label or tooltip text
    smcButton.updateTextLabel(label)
    smcButton.clearTooltipTextOverride()
    if (VIDEO_CONTROLS_ARE_COMPACT) {
        smcButton.setTooltipTextFromLabel()
    } else {
        smcButton.clearTooltipText()
    }
}

class ShowMyCam {
    private previewModal: ShowMyCamPreviewModal | undefined
    private broadcastModal: ShowMyCamBroadcast | undefined
    private camDossier: IBroadcastDossier
    private readonly constraints: IStreamerConstraints
    private onStartBroadcast: (() => void)[] = []
    private onStopBroadcast: (() => void)[] = []
    public readonly roomChatConn: IChatConnection
    private roomOffline: boolean
    private chatOffline: boolean
    private listenerGroup = new ListenerGroup()
    private fullscreenChangeListener: BoundListener<void> | undefined
    private connectingTimeout: number | undefined

    constructor(roomChatConn: IChatConnection, videoOfflineChange: EventRouter<boolean>) {
        this.roomChatConn = roomChatConn
        this.constraints = {
            micId: "",
            camId: "",
            width: 0,
            height: 0,
            muted: true,
        }

        this.roomOffline = true
        videoOfflineChange.listen((offline) => {
            this.roomOffline = offline
            if (offline) {
                this.stopCam()  // eslint-disable-line @typescript-eslint/no-floating-promises
            } else if (!this.chatOffline && showMyCamStateChanged.historyLength() === 0) {
                showMyCamStateChanged.fire(SmcViewerState.Ready)
            }
        }, false).addTo(this.listenerGroup)

        this.chatOffline = true
        roomChatConn.event.statusChange.listen(roomStatusChangeNotification => {
            this.chatOffline = roomStatusChangeNotification.currentStatus === RoomStatus.NotConnected
            if (!this.chatOffline && !this.roomOffline && showMyCamStateChanged.historyLength() === 0) {
                showMyCamStateChanged.fire(SmcViewerState.Ready)
            }

            chatStatus = roomStatusChangeNotification.currentStatus
            // End cams if room is private with another user, password added to room, or kicked/disconnected from chat
            switch (roomStatusChangeNotification.currentStatus) {
                case RoomStatus.PrivateNotWatching:
                case RoomStatus.PrivateSpying:
                case RoomStatus.PasswordProtected:
                case RoomStatus.NotConnected:
                case RoomStatus.Offline:
                    this.stopCam()  // eslint-disable-line @typescript-eslint/no-floating-promises
            }
        }).addTo(this.listenerGroup)

        const userUid = pageContext.current.loggedInUser?.userUid
        if (userUid !== undefined) {
            new UserSMCWatchingTopic(userUid).onMessage.listen((msg) => {
                if (msg.started) {
                    showMyCamViewStarted.fire(msg)
                } else {
                    showMyCamViewStopped.fire(msg)
                }
            }).addTo(this.listenerGroup)
        }
    }

    // User entrypoint
    public startCam(): void {
        addPageAction("SharedCamStartClicked")
        if (this.roomOffline || this.chatOffline) {
            modalAlert(i18n.showMyCamMustBeConnected)
            showMyCamDidNotStart.fire(undefined)
            return
        }
        checkOnline(this.roomChatConn.username()).then((online) => {
            if (!online) {
                if (BroadcastTermsModal.areTermsAccepted()) {
                    this.showBroadcastPreview()
                } else {
                    const termsAccepted = () => {
                        this.showBroadcastPreview()
                    }
                    const termsRejected = () => {
                        showMyCamDidNotStart.fire(undefined)
                    }
                    const termsModal = new BroadcastTermsModal(false, termsAccepted, termsRejected)
                    termsModal.show()
                }
            } else {
                modalAlert(i18n.showMyCamAlreadyBroadcasting)
                showMyCamDidNotStart.fire(undefined)
            }
        }).catch(ignoreCatch)
    }

    public stopCam(fromClick = false): Promise<void> {
        if (this.broadcastModal !== undefined) {
            return this.broadcastModal.stopBroadcast(fromClick)
        }
        else {
            return Promise.resolve()
        }
    }

    public cleanup(): void {
        this.stopCam()  // eslint-disable-line @typescript-eslint/no-floating-promises
        this.listenerGroup.removeAll()
    }

    private showBroadcastPreview(): void {
        if (this.roomOffline || this.chatOffline) {
            modalAlert(i18n.showMyCamMustBeConnected)
            showMyCamDidNotStart.fire(undefined)
            return
        }

        getBroadcastDossier().then((dossier) => {
            this.camDossier = dossier
            if (this.previewModal !== undefined) {
                this.previewModal.hide()
            }
            this.previewModal = new ShowMyCamPreviewModal(this.camDossier, this.constraints, this.startBroadcast, this.roomChatConn.room())
            this.previewModal.show()
        }).catch(ignoreCatch)
    }

    // Starts a broadcast
    private startBroadcast = () => {
        if (this.previewModal !== undefined) {
            this.previewModal.hide()
        }

        addPageAction("SharedCamBroadcastStartClicked")
        if (this.roomOffline || this.chatOffline) {
            modalAlert(i18n.showMyCamMustBeConnected)
            showMyCamDidNotStart.fire(undefined)
            return
        }

        // stopCam shouldn't be necessary here because there should never be an existing cam at this point, but call it
        // anyway in case of strange behavior from bcast errors. Don't run onStopBroadcast callbacks for the old cam.
        const onStopBackup = this.onStopBroadcast
        this.onStopBroadcast = []
        this.stopCam().then(() => {
            this.onStopBroadcast = onStopBackup

            let timedOut = false
            const connectingTimeout = window.setTimeout(() => {
                if (this.broadcastModal !== undefined) {
                    this.broadcastModal.stopBroadcast()  // eslint-disable-line @typescript-eslint/no-floating-promises
                }
                addPageAction("SharedCamBroadcastStartTimedOut")
                modalAlert(i18n.showMyCamBroadcastTimeoutError)
                error("smcViewer - timeout starting cam")
                timedOut = true
            }, 20 * 1000) // Timeout must be <30s to guarantee the bcast starts within 30s of the POST to share-my-cam
            this.connectingTimeout = connectingTimeout

            postCb("api/ts/chat/share-my-cam/", { username: this.roomChatConn.room() }).then(() => {
                if (timedOut) {
                    return
                }
                this.broadcastModal = new ShowMyCamBroadcast(this.camDossier, this.constraints, this.broadcastStop, this.roomChatConn, connectingTimeout)
                this.broadcastModal.showBroadcast()
                this.fullscreenChangeListener = fullscreenChange.listen(this.broadcastModal.showBroadcast)
                for (const f of this.onStartBroadcast) {
                    f()
                }
            }).catch(() => {
                if (timedOut) {
                    return
                }
                modalAlert(i18n.showMyCamBroadcastError)
                showMyCamDidNotStart.fire(undefined)
            })
        }).catch(ignoreCatch)
    }

    // Called when broadcast is stopped
    private broadcastStop = (): void => {
        if (this.broadcastModal !== undefined) {
            this.fullscreenChangeListener?.removeListener()
            this.fullscreenChangeListener = undefined
            if (this.broadcastModal.currentlyViewed) {
                this.roomChatConn.event.roomNotice.fire({
                    messages: [[
                        userPart(createBroadcasterUserInfo(this.roomChatConn.room())),
                        stringPart(i18n.showMyCamStoppedViewing),
                    ]],
                    showInPrivateMessage: true,
                })
            }
        }
        window.clearTimeout(this.connectingTimeout)
        this.connectingTimeout = undefined
        this.broadcastModal = undefined
        for (const f of this.onStopBroadcast) {
            f()
        }
    }

    public setOnStart(onStartBroadcast: () => void): void {
        this.onStartBroadcast.push(onStartBroadcast)
    }

    public setOnStop(onStopBroadcast: () => void): void {
        this.onStopBroadcast.push(onStopBroadcast)
    }

    public isBroadcasting(): boolean {
        return this.broadcastModal !== undefined
    }
}

class ShowMyCamPreviewModal extends ModalComponent {
    private readonly previewPanel: PreviewPanelForModal
    private paused = false
    protected preventChatFocus = true

    constructor(dossier: IBroadcastDossier, constraints: IStreamerConstraints, onStartBroadcast: () => void, room: string) {
        super()

        this.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.4)"
        this.element.id = "smc-preview-modal"
        this.element.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.3)"
        this.element.style.position = "fixed"
        this.element.style.overflow = "hidden"
        this.element.style.height = "auto"
        this.element.style.width = "422px"
        this.element.style.top = "50%"
        this.element.style.left = "50%"
        this.element.style.transform = "translate(-50%, -56%)"
        this.element.style.borderRadius = "6px"

        this.previewPanel = new PreviewPanelForModal(dossier, constraints, {
            onOBSClick: () => {},
            onStartBroadcast: onStartBroadcast,
            title: i18n.showMyCamPreviewTitle,
            infoText: [i18n.showMyCamPreviewInfo1(room), i18n.showMyCamPreviewInfo2],
            startButtonText: i18n.showMyCamStart(room),
            onClose: () => {
                showMyCamDidNotStart.fire(undefined)
                this.hide()
            },
        })

        this.element.style.padding = "8px 10px"
        this.element.style.boxSizing = "border-box"

        this.overlayClick.listen(() => {
            showMyCamDidNotStart.fire(undefined)
            this.hide()
        })

        this.addChild(this.previewPanel)
    }

    public show(): void {
        if (this.paused) {
            this.previewPanel.start()
        }
        super.show()
        addPageAction("SharedCamPreviewDisplayed")
    }

    public hide(): void {
        super.hide()
        this.previewPanel.stop()  // eslint-disable-line @typescript-eslint/no-floating-promises
        this.paused = true
    }
}

class ShowMyCamBroadcast extends BroadcastPanel {
    public currentlyViewed = false
    private controls: HTMLDivElement
    private statusIcon: HTMLImageElement
    private statusTooltip: HTMLDivElement
    private statusTooltipText: HTMLSpanElement
    private listenerGroup = new ListenerGroup()
    private readonly roomUsername: string
    private bottom = 16
    private cachedBottom = this.bottom
    private right = 16
    private fullscreenBottom = 32
    private fullscreenRight = 0
    private currentWatchers = new Map<string, IUserInfo>()

    constructor(dossier: IBroadcastDossier, constraints: IStreamerConstraints, onStopBroadcast: () => void, private roomChatConn: IChatConnection, connectingTimeout: number) {
        super(dossier, constraints, {
            onStopBroadcast: () => {
                deleteCb(`api/ts/chat/share-my-cam/?`, { username: this.roomUsername })  // eslint-disable-line @typescript-eslint/no-floating-promises
                this.removeFromDom()
                this.listenerGroup.removeAll()
                smcSharedWith = undefined
                onStopBroadcast()
            },
        })
        this.roomUsername = roomChatConn.room()
        this.getSettingsLocalstorage()
        this.createBroadcastDisplay()

        resizeDebounceEvent.listen(() => { this.recalcPosition() })

        let isConnecting = true
        streamStatusUpdate.listen((status: IStatusUpdate) => {
            const connectionStatus = status.data["status"]
            if (connectionStatus === undefined) {
                warn("smcViewer - connectionStatus is undefined")
                return
            }
            if (connectionStatus !== "connecting" && isConnecting && connectionStatus === "hidden") {
                clearTimeout(connectingTimeout)
                isConnecting = false
                this.onConnected()
            } else if (!isConnecting && this.isStatusOffline(connectionStatus)) {
                this.stopBroadcast()  // eslint-disable-line @typescript-eslint/no-floating-promises
                modalAlert(i18n.showMyCamBroadcastError)
                error("smcViewer - cam went offline")
            }
        }, false).addTo(this.listenerGroup)

        dmsHeightChanged.listen(() => {
            this.recalcPosition()
        }).addTo(this.listenerGroup)
    }

    private onConnected(): void {
        addPageAction("SharedCamBroadcastStarted")
        smcSharedWith = this.roomUsername

        showMyCamViewStarted.listen((userInfo) => {
            this.updateCamStatus(true, userInfo)
        }, false).addTo(this.listenerGroup)
        showMyCamViewStopped.listen((userInfo) => {
            this.updateCamStatus(false, userInfo)
        }, false).addTo(this.listenerGroup)
    }

    public showBroadcast = () => {
        const fullscreenEl = fullscreenElement()
        if (isFullscreen() && fullscreenEl !== undefined) {
            fullscreenEl.appendChild(this.element)
            this.main.style.bottom = `${this.fullscreenBottom}px`
            this.main.style.right = `${this.fullscreenRight}px`
        } else {
            document.body.appendChild(this.element)
            this.main.style.bottom = `${this.bottom}px`
            this.main.style.right = `${this.right}px`
        }
    }

    public stopBroadcast(fromClick = false): Promise<void> {
        addEventListenerPoly("mouseenter", this.element, (event) => {
            event.stopPropagation()
        }, true)
        addEventListenerPoly("mouseleave", this.element, (event) => {
            event.stopPropagation()
        }, true)

        if (fromClick) {
            addPageAction("SharedCamBroadcastStopClicked", { is_broadcaster_viewing: this.currentlyViewed })
        }
        return this.stop(true)
    }

    private removeFromDom(): void {
        if (this.element.parentElement !== null) {
            this.element.parentElement.removeChild(this.element)
        }
    }

    private updateCamStatus(currentlyViewed: boolean, userInfo: IUserInfo): void {
        this.currentlyViewed = currentlyViewed
        let messageString: string | undefined

        if (currentlyViewed) {
            if (!this.currentWatchers.has(userInfo.username)) {
                this.statusIcon.src = `${STATIC_URL_ROOT}broadcastassets/active-cam-overlaying-video.svg`
                this.statusTooltipText.textContent = `${this.roomUsername} ${i18n.showMyCamIsViewing}`
                this.currentWatchers.set(userInfo.username, userInfo)
                messageString = i18n.showMyCamStartedViewing
            }
        } else {
            if (this.currentWatchers.has(userInfo.username)) {
                this.statusIcon.src = `${STATIC_URL_ROOT}broadcastassets/inactive-cam-overlaying-video.svg`
                this.statusTooltipText.textContent = `${this.roomUsername} ${i18n.showMyCamNotViewing}`
                this.currentWatchers.delete(userInfo.username)
                messageString = i18n.showMyCamStoppedViewing
            }
        }

        if (messageString !== undefined) {
            this.roomChatConn.event.roomNotice.fire({
                messages: [[
                    userPart(createBroadcasterUserInfo(userInfo.username)),
                    stringPart(messageString),
                ]],
                showInPrivateMessage: true,
            })
        }
    }

    protected createBroadcastPanel(): void {
        // pass, override super
    }

    private createBroadcastDisplay(): void {
        this.element.style.height = "auto"
        this.element.style.width = "auto"
        this.main = this.createMain()
        this.main.appendChild(this.createCamStatus())
        this.main.appendChild(this.createVideo())
        this.main.appendChild(this.createControls())
        this.element.appendChild(this.main)
        this.setupUIEventListeners()
    }

    protected createMain(): HTMLDivElement {
        const main = document.createElement("div")
        main.style.height = "117px"
        main.style.width = `${117 * this.constraints.width / this.constraints.height}px`
        main.style.backgroundColor = "#000"
        main.style.position = "fixed"
        main.style.zIndex = `${baseZIndex}`
        main.style.border = "1px solid #FFF"
        main.dataset.testid = "cam-to-cam-broadcast-panel"
        return main
    }

    protected createCamStatus(): HTMLDivElement {
        const camStatus = document.createElement("div")
        camStatus.style.position = "absolute"
        camStatus.style.right = "5px"
        camStatus.style.top = "5px"
        camStatus.style.width = camStatus.style.height = "15px"
        camStatus.style.zIndex = `${baseZIndex + 2}`
        const icon = document.createElement("img")
        icon.src = `${STATIC_URL_ROOT}broadcastassets/inactive-cam-overlaying-video.svg`

        const tooltip = document.createElement("div")
        addColorClass(tooltip, "smc-status-tooltip")
        tooltip.style.position = "absolute"
        tooltip.style.width = "175px"
        tooltip.style.padding = "9px"
        tooltip.style.borderRadius = "4px"
        tooltip.style.fontSize = "14px"
        tooltip.style.display = "none"
        tooltip.style.zIndex = "5"
        tooltip.style.bottom = "25px"
        tooltip.style.left = "-168px"
        tooltip.dataset.testid = "cam-to-cam-status-tooltip"

        const tooltipText = document.createElement("span")
        tooltipText.textContent = `${this.roomUsername} ${i18n.showMyCamNotViewing}`
        tooltip.appendChild(tooltipText)

        const divot = createDivotBottom("", "", "168px", 2)
        addColorClass(divot, "divotBottom")
        tooltip.appendChild(divot)

        camStatus.appendChild(icon)
        camStatus.appendChild(tooltip)

        this.statusIcon = icon
        this.statusTooltip = tooltip
        this.statusTooltipText = tooltipText
        return camStatus
    }

    protected createVideo(): HTMLVideoElement {
        const video = super.createVideo()
        video.style.height = "100%"
        video.dataset.testid = "cam-to-cam-video"
        return video
    }

    protected createControls(): HTMLDivElement {
        const controls = createCornerViewerControlsContainer()

        const header = document.createElement("div")
        header.style.width = "100%"
        header.style.height = "26px"
        header.style.backgroundColor = "rgba(0, 0, 0, 0.4)"
        header.style.cursor = "move"
        this.addDrag(header)

        const headerText = document.createElement("div")
        headerText.style.paddingLeft = "10px"
        headerText.style.position = "relative"
        headerText.style.top = "50%"
        headerText.style.transform = "translateY(-50%)"
        headerText.style.color = "#FFF"
        headerText.style.cursor = "default"
        headerText.textContent = i18n.showMyCamMyCam
        headerText.style.cursor = "move"
        this.addDrag(headerText)

        header.appendChild(headerText)
        controls.appendChild(header)

        const mutedDiv = createCornerViewerButton({ iconAsset: "broadcastassets/volume-mute-grey.svg",
                                                          tooltipText: i18n.showMyCamMuted,
                                                          iconSize: 31,
                                                          centerTop: "60%" })
        mutedDiv.style.left = "50%"
        mutedDiv.style.transform = "translateX(calc(-50% - 30px))"
        controls.appendChild(mutedDiv)

        const stopDiv = createCornerViewerButton({ iconAsset: "broadcastassets/close.svg",
                                                         tooltipText: i18n.showMyCamCloseCam,
                                                         iconSize: 23,
                                                         centerTop: "60%",
                                                         onClick: () => {
                                                            this.stopBroadcast(true)  // eslint-disable-line @typescript-eslint/no-floating-promises
                                                         } })
        stopDiv.style.left = "50%"
        stopDiv.style.transform = "translateX(calc(-50% + 30px))"
        controls.appendChild(stopDiv)

        this.controls = controls
        return controls
    }

    private setupUIEventListeners(): void {
        const showControls = () => {
            this.controls.style.display = "block"
            if (this.currentlyViewed) {
                this.statusIcon.src = `${STATIC_URL_ROOT}broadcastassets/active-cam.svg`
            } else {
                this.statusIcon.src = `${STATIC_URL_ROOT}broadcastassets/inactive-cam.svg`
            }
        }

        const hideControls = () => {
            this.controls.style.display = "none"
            if (this.currentlyViewed) {
                this.statusIcon.src = `${STATIC_URL_ROOT}broadcastassets/active-cam-overlaying-video.svg`
            } else {
                this.statusIcon.src = `${STATIC_URL_ROOT}broadcastassets/inactive-cam-overlaying-video.svg`
            }
        }

        addEventListenerPoly("mouseenter", this.main, showControls)
        addEventListenerPoly("mouseleave", this.main, hideControls)

        let touchCount = 0
        addEventListenerPoly("touchstart", this.main, (event) => {
            if (this.controls.style.display === "none") {
                showControls()
                touchCount = 1
            } else {
                touchCount += 1
            }
        })
        addEventListenerPoly("touchend", this.main, (event) => {
            if (touchCount > 1) {
                hideControls()
            }
        })
        addEventListenerPoly("touchstart", document, (event) => {
            if (!this.element.contains((event.target as HTMLElement))) {
                hideControls()
            }
        })

        addEventListenerPoly("mouseenter", this.statusIcon, (event) => {
            this.statusTooltip.style.display = "block"
        })
        addEventListenerPoly("mouseleave", this.statusIcon, (event) => {
            this.statusTooltip.style.display = "none"
        })
        addEventListenerPoly("touchstart", this.statusIcon, (event) => {
            this.statusTooltip.style.display = "block"
        })
        addEventListenerPoly("touchstart", document, (event) => {
            this.statusTooltip.style.display = "none"
        }, true)
    }

    private addDrag(dragElement: HTMLDivElement): void {
        addDragListener(dragElement, (ev: Event, x: number, y: number) => {
            const clientStartRight = this.getCorrectedRight(isFullscreen() ? this.fullscreenRight : this.right)
            const clientStartBottom = this.getCorrectedBottom(isFullscreen() ? this.fullscreenBottom : this.bottom)
            let currentRight = clientStartRight
            let currentBottom = clientStartBottom

            const clientXStart = x
            const clientYStart = y

            const moveBroadcastEl = (clientXCurrent: number, clientYCurrent: number) => {
                const rightMovement = clientXCurrent - clientXStart
                const downMovement = clientYCurrent - clientYStart
                currentBottom = this.getCorrectedBottom(clientStartBottom - downMovement)
                currentRight = this.getCorrectedRight(clientStartRight - rightMovement)
                this.main.style.bottom = `${currentBottom}px`
                this.main.style.right = `${currentRight}px`
            }

            return {
                enabled: true,
                move: moveBroadcastEl,
                end: () => {
                    if (isFullscreen()) {
                        this.fullscreenRight = currentRight
                        this.fullscreenBottom = currentBottom
                    } else {
                        this.right = currentRight
                        this.bottom = currentBottom
                        this.cachedBottom = currentBottom
                    }
                    this.saveSettingsLocalstorage()
                },
            }
        })
    }

    private getCorrectedBottom(currentBottom: number): number {
        return Math.max(0, Math.min(window.innerHeight - this.main.offsetHeight, currentBottom))
    }

    private getCorrectedRight(currentRight: number): number {
        return Math.max(0, Math.min(window.innerWidth - this.main.offsetWidth, currentRight))
    }

    private recalcPosition(): void {
        if (isFullscreen()) {
            this.main.style.bottom = `${this.getCorrectedBottom(this.fullscreenBottom)}px`
            this.main.style.right = `${this.getCorrectedRight(this.fullscreenRight)}px`
        } else {
            const dmWindowHeight = getDmWindowHeight()
            const margin = 4

            if (dmWindowHeight > 0) {
                this.bottom = Math.max(this.cachedBottom, dmWindowHeight + margin)
            } else {
                this.bottom = this.cachedBottom
            }

            this.main.style.bottom = `${this.getCorrectedBottom(this.bottom)}px`
            this.main.style.right = `${this.getCorrectedRight(this.right)}px`
        }
    }

    private getSettingsLocalstorage(): void {
        if (!isLocalStorageSupported()) {
            return
        }
        const settingsString = window.localStorage.getItem(showMyCamBroadcastSettingsKey)
        if (settingsString !== null) {
            const settings = JSON.parse(settingsString)
            this.bottom = settings["bottom"]
            this.cachedBottom = settings["bottom"]
            this.right = settings["right"]
            this.fullscreenBottom = settings["fullscreenBottom"]
            this.fullscreenRight = settings["fullscreenRight"]
        }
    }

    private saveSettingsLocalstorage(): void {
        if (!isLocalStorageSupported()) {
            return
        }
        const data = {
            "bottom": this.bottom,
            "right": this.right,
            "fullscreenBottom": this.fullscreenBottom,
            "fullscreenRight": this.fullscreenRight,
        }
        window.localStorage.setItem(showMyCamBroadcastSettingsKey, JSON.stringify(data))
    }
}
