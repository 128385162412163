import { isAnonymous } from "../../../common/auth"
import { roomLoaded } from "../../../common/context"
import { Component } from "../../../common/defui/component"
import { ListenerGroup } from "../../../common/events"
import { addPageAction } from "../../../common/newrelic"
import { ignoreCatch } from "../../../common/promiseUtils"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { userInitiatedPm } from "../../../common/userActionEvents"
import { createBroadcasterConversationItem, createNewSession, getUserInfo } from "../../api/pm"
import { addColorClass, removeColorClass } from "../../colorClasses"
import { pageContext } from "../../interfaces/context"
import { currentSiteSettings } from "../../siteSettings"
import { SpinnerIcon } from "../../ui/spinnerIcon"
import { adjustedUserList, ConversationListData } from "./conversationListData"
import { ConversationListItem } from "./conversationListItem"
import { removeDmWindowRequest } from "./dmWindowsManager"
import { SearchBar } from "./searchBar"
import { closePmSession } from "./userActionEvents"
import Key = JQuery.Key
import type { INavigateSuggestionInfo, IUpdateSearchBarInfo } from "./userActionEvents"
import type { IRoomContext } from "../../../common/context"
import type { EventRouter } from "../../../common/events"
import type { IConversationListItem } from "../../api/pm"

const conversationLists = new Set<ConversationList>()

export function hideConversationsFromUser(username: string, useDms: boolean): void {
    if (!useDms) {
        closePmSession.fire(username)
    }
    adjustedUserList.hide(username)
    conversationLists.forEach(wr => {
        if (wr.isDms === useDms) {
            wr.hideConversation(username)
        }
    })
    if (useDms) {
        removeDmWindowRequest.fire({ username })
    }
}

type ConversationListProps = {
    isDms?: boolean,
    clearSearchOnSelect: boolean
    isFullVideoMode: boolean,
    openConversationEvent: EventRouter<string>,
    inBroadcast?: boolean,
}

export class ConversationList extends Component<HTMLDivElement> {
    public isDms: boolean
    private currentIndex = -1
    private isFullVideoMode: boolean
    private inBroadcast: boolean
    private listenerGroup: ListenerGroup
    private openConversationEvent: EventRouter<string>
    private conversationListData: ConversationListData | undefined // may be undefined when the user is anonymous
    private conversationBodyRoot: HTMLDivElement
    private room: string
    private renderedConversations: ConversationListItem[] // DOM elements that are actually shown
    private searchBar: SearchBar
    private emptyListMessage: HTMLDivElement
    private currentConversation?: string
    private readonly currentConversationColorClass = "currentConvo"
    private get searchPrefix(): string {
        return this.searchBar.value.trim()
    }

    constructor(props: ConversationListProps) {
        super("div", props)
        conversationLists.add(this)

        roomLoaded.listen((context: IRoomContext) => {
            this.room = context.dossier.room
            adjustedUserList.clear()
            this.addBroadcasterToTopOfDOM()  // eslint-disable-line @typescript-eslint/no-floating-promises
        }).addTo(this.listenerGroup)
        ConversationListData.conversationDataChanged.listen((context: IRoomContext | undefined) => {
            if (context !== undefined) {
                this.room = context.dossier.room
            }

            this.updateList()  // eslint-disable-line @typescript-eslint/no-floating-promises
        }).addTo(this.listenerGroup)
        ConversationListData.conversationRead.listen(({ username, isDm }) => {
            if (isDm === this.isDms) {
                const conversation = this.renderedConversations.find(conversation => conversation.getOtherUsername() === username)
                if (conversation !== undefined) {
                    conversation.setNumUnread(0)
                }
            }
        })
        ConversationListData.conversationItemAdded.listen((newConversation) => {
            if ((this.isDms && newConversation.room !== "") || (!this.isDms && newConversation.room === "")) {
                return
            }

            adjustedUserList.show(newConversation.fromUsername)

            const hasOnlyBroadcasterPm = !this.isDms && this.renderedConversations.length === 1
            if (this.renderedConversations.length === 0 || hasOnlyBroadcasterPm) {
                 this.updateList() // eslint-disable-line @typescript-eslint/no-floating-promises
            } else {
                this.removeOldConversation(newConversation.otherUser.username)
                this.addConversationIfPrefixMatch(newConversation, true)
            }
        })
        userInitiatedPm.listen((notitication) => {
            adjustedUserList.show(notitication.username)
        })
        props.openConversationEvent.listen(() => {
            if (props.clearSearchOnSelect) {
                this.currentIndex = -1
                this.filterConversations("").catch(ignoreCatch)
                this.searchBar.clear()
            }
        }).addTo(this.listenerGroup)

        this.startTimeContainerUpdates()
    }

    private startTimeContainerUpdates(): void {
        const intervalMS = 10000
        const updateLoop = () => {
            for (const conversation of this.renderedConversations) {
                conversation.updateTimeContainer()
            }
            window.setTimeout(updateLoop, intervalMS)
        }
        window.setTimeout(updateLoop, intervalMS)
    }

    private conversationIsRendered(username: string): boolean {
        return this.renderedConversations.findIndex(conversation => conversation.getRawData().otherUser.username === username) > -1
    }

    protected initData(props: ConversationListProps): void {
        this.isDms = props.isDms === true
        this.isFullVideoMode = props.isFullVideoMode
        this.inBroadcast = props.inBroadcast === true
        this.listenerGroup = new ListenerGroup()
        this.renderedConversations = []

        if (!isAnonymous()) {
            this.conversationListData = ConversationListData.getInstance()
        }

        this.openConversationEvent = props.openConversationEvent
        this.room = ""
        this.emptyListMessage = <EmptyListMessage/>
    }

    protected initUI(props: ConversationListProps): void {
        const conversationListStyle: CSSX.Properties = {
            fontSize: "12px",
            fontFamily: "UbuntuRegular, Tahoma, Arial, Helvetica, sans-serif",
            height: "100%",
            lineHeight: "16px",
            overflowX: "hidden",
            overflowY: "hidden",
            position: "relative",
            textAlign: "left",
            width: "100%",
            zIndex: 2,
            ...this.isDms && {
                flex: 1,
                display: "flex",
                flexDirection: "column",
            },
        }
        const conversationBodyRootStyle: CSSX.Properties = {
            maxHeight: "calc(100% - 32px)", // subtract height of search bar
            overflowY: "auto",
            width: "100%",
            ...this.isDms && {
                flex: 1,
            },
        }

        this.conversationBodyRoot = (
            <div style={conversationBodyRootStyle} colorClass="conversationBodyRoot" data-testid="conversation-body-root">
                <div className="loadingDiv" style={{ position: "absolute", top: "60px", left: "50%", transform: "translateX(-50%)" }}>
                    <SpinnerIcon/>
                </div>
            </div>
        )

        this.element = (
            <div style={conversationListStyle} colorClass="conversationList">
                {this.conversationBodyRoot}
            </div>
        )

        this.renderSearchBar()
    }

    render(): HTMLDivElement {
        return this.element
    }

    repositionChildrenRecursive(): void {
        super.repositionChildrenRecursive()
        for (const conversation of this.renderedConversations) {
            conversation.repositionChildrenRecursive()
        }
    }

    afterDOMConstructedIncludingChildren(): void {
        super.afterDOMConstructedIncludingChildren()
        for (const conversation of this.renderedConversations) {
            conversation.afterDOMConstructedIncludingChildren()
        }
    }

    private renderSearchBar(): void {
        this.searchBar = new SearchBar({
            isFullVideoMode: this.isFullVideoMode,
            room: this.room,
        })

        this.searchBar.events.navigateSuggestions.listen((info: INavigateSuggestionInfo) => {
            this.navigateSuggestions(info.event)
        }).addTo(this.listenerGroup)

        this.searchBar.events.openCurrentSuggestion.listen(() => {
            this.openCurrentSuggestion()
        }).addTo(this.listenerGroup)

        this.searchBar.events.inputChange.listen((info: IUpdateSearchBarInfo) => {
            if (info.isFullVideo === this.isFullVideoMode) {
                this.currentIndex = -1

                if (info.isValid) {
                    this.filterConversations(info.prefix)  // eslint-disable-line @typescript-eslint/no-floating-promises
                } else {
                    this.clearConversationListDOM()
                }
            }
        }).addTo(this.listenerGroup)

        const searchBarRoot = <div>{this.searchBar.element}</div>
        this.element.insertBefore(searchBarRoot, this.element.firstChild)
    }

    private async updateList(): Promise<void> {
        if (isAnonymous()) {
            return
        }

        this.clearConversationListDOM()

        const didAddBroadcasterToTop = await this.addBroadcasterToTopOfDOM()
        if (this.conversationListData !== undefined) {
            const conversationListItems = this.isDms ? this.conversationListData.getDms() : this.conversationListData.getPms()
            const conversationsToPopulate = conversationListItems.filter(conversation => this.conversationMatchesPrefix(conversation))
            this.populateConversationsToDOM(conversationsToPopulate, didAddBroadcasterToTop)
        }
    }

    public dispose(): void {
        this.listenerGroup.removeAll()
        this.searchBar.dispose()
    }

    private clearConversationListDOM(): void {
        while (this.conversationBodyRoot.firstChild !== null) {
            this.conversationBodyRoot.removeChild(this.conversationBodyRoot.firstChild)
        }
        this.renderedConversations.forEach(conversation => conversation.dispose())
        this.renderedConversations = []
    }

    public getRoomUserCount(): number {
        return this.conversationBodyRoot.childNodes.length
    }

    public getLastConversation(): IConversationListItem | undefined {
        return this.conversationListData?.getDms()[0]
    }

    /**
     * Determine whether the broadcaster should be automatically added to the top of the room.
     * This happens when the user is in a room, but is not the broadcaster.
     * @param prefix When used, only returns true if the current broadcaster starts with prefix
     */
    private shouldAddBroadcaster(prefix?: string): boolean {
        const isBroadcaster = pageContext.current.loggedInUser?.username === this.room
        if (this.room === "" || isBroadcaster) {
            return false
        }
        const broadcasterStartsWithPrefix = (prefix === undefined) ? true : this.room.startsWith(prefix)
        return !this.isDms
            && broadcasterStartsWithPrefix
            && !this.inBroadcast
            && (isAnonymous() || !isBroadcaster)
    }

    /**
     * Add the current broadcaster to the top of the conversation list
     * @return Whether the broadcaster was added
     */
    private async addBroadcasterToTopOfDOM(): Promise<boolean> {
        const shouldAddBroadcasterToTop = this.shouldAddBroadcaster()
        if (shouldAddBroadcasterToTop) {
            if (isAnonymous()) { // anonymous users have no conversationListData
                this.clearConversationListDOM()
                // hard-code the broadcaster for anon users
                const broadcasterItem = createBroadcasterConversationItem(this.room)
                this.addConversationIfPrefixMatch(broadcasterItem)
            } else if (this.conversationListData !== undefined) {
                const oldBroadcasterItem = this.conversationListData.getConversation(this.room)
                if (oldBroadcasterItem === undefined) {
                    const otherUser = await getUserInfo(this.room, this.room)
                    if (this.conversationListData.getConversation(this.room) === undefined) { // prevent race condition
                        this.clearConversationListDOM()
                        this.addConversationIfPrefixMatch({
                            message: "",
                            numUnread: otherUser.numUnread,
                            fromUsername: otherUser.user.username,
                            otherUser: otherUser.user,
                            hasMedia: false,
                        })
                    }
                    return shouldAddBroadcasterToTop
                }
                this.clearConversationListDOM()
                this.addConversationIfPrefixMatch(oldBroadcasterItem)
            }
        }
        return shouldAddBroadcasterToTop
    }

    private shouldSkipPopulating(conversation: IConversationListItem, skipBroadcaster: boolean): boolean {
        if (adjustedUserList.have(conversation.otherUser.username)) {
            return !adjustedUserList.isShowing(conversation.otherUser.username)
        }
        // Skip the broadcaster, since it was already added to the top
        if (skipBroadcaster && this.room === conversation.otherUser.username) {
            return true
        }
        return false
    }

    private maybeAddEmptyListMessage(): void {
        if (this.isDms && this.searchPrefix === "" && this.renderedConversations.length === 0) {
            this.conversationBodyRoot.appendChild(this.emptyListMessage)
        }
    }

    private populateConversationsToDOM(conversations: IConversationListItem[], skipBroadcaster: boolean): void {
        conversations.forEach((conversation: IConversationListItem) => {
            if (this.shouldSkipPopulating(conversation, skipBroadcaster)) {
                return
            }
            this.addConversationListItemToDOM(conversation)
            if (!this.isDms) {
                createNewSession.fire(conversation.otherUser.username)
            }
        })

        this.maybeAddEmptyListMessage()
    }

    /**
     * Adds a conversation to the list
     * @param conversation conversation info to be added
     * @param addToTop true: add to the top of list; false: bottom of list
     */
    private addConversationListItemToDOM(conversation: IConversationListItem, addToTop = false): void {
        // I don't know why we need this check but it has duplicates without it ~ Cliff
        if (this.conversationIsRendered(conversation.otherUser.username)) {
            return
        }

        const conversationListItem = <ConversationListItem
            rawData={conversation}
            openConversationEvent={this.openConversationEvent}
            isDropdown={this.isDms}
            classRef={(c: ConversationListItem) => {
                const isBroadcasterItem = conversation.otherUser.username === this.room

                if (!this.isDms && !isBroadcasterItem) {
                    c.addCloseBtn()
                }
                if (addToTop) {
                    if (!this.isDms && !isBroadcasterItem) {
                        this.renderedConversations.splice(1, 0, c)
                    } else {
                        this.renderedConversations.unshift(c)
                    }
                } else {
                    this.renderedConversations.push(c)
                }
            }}
        />
        if (addToTop) {
            const firstConversation = this.conversationBodyRoot.firstChild
            const isBroadcaster = pageContext.current.loggedInUser?.username === this.room
            const isBroadcasterItem = conversation.otherUser.username === this.room

            if (!this.isDms && !isBroadcasterItem && !isBroadcaster && firstConversation !== null) {
                this.conversationBodyRoot.insertBefore(conversationListItem, firstConversation.nextSibling)
            } else {
                this.conversationBodyRoot.insertBefore(conversationListItem, firstConversation)
            }
        } else {
            this.conversationBodyRoot.appendChild(conversationListItem)
        }

        if (conversation.otherUser.username === this.currentConversation) {
            addColorClass(conversationListItem, this.currentConversationColorClass)
        }
        this.repositionChildrenRecursive()
        addPageAction("PmListItemAdded", {
            "rendered_pm_count": this.renderedConversations.length,
            "other_user": conversation.otherUser.username,
        })
    }


    public hideConversation(username: string): void {
        const conversation = this.renderedConversations.find(conversation => conversation.getOtherUsername() === username)
        const isBroadcasterRoomPm = !this.isDms && conversation?.getOtherUsername() === this.room

        if (conversation !== undefined && !isBroadcasterRoomPm) {
            this.renderedConversations.splice(this.renderedConversations.indexOf(conversation), 1)
            conversation.hide()
            addPageAction("PmListItemRemoved", {
                "rendered_pm_count": this.renderedConversations.length,
                "other_user": username,
            })
        }

        this.maybeAddEmptyListMessage()
    }

    /**
     * Go through conversationListData and only include those that start with the given prefix.
     */
    private async filterConversations(prefix: string): Promise<void> {
        let addedBroadcaster = false
        if (this.conversationListData !== undefined) {
            this.clearConversationListDOM()
            const conversationList = this.isDms
                ? this.conversationListData.getDms()
                : this.conversationListData.getPms()

            if (this.shouldAddBroadcaster(prefix)) {
                // Append the broadcaster to the top of search results
                let conversationToAdd: IConversationListItem
                const oldConversation = this.conversationListData.getConversation(this.room)
                if (oldConversation !== undefined) {
                    conversationToAdd = oldConversation
                } else {
                    // hardcode the broadcaster
                    conversationToAdd = createBroadcasterConversationItem(this.room)
                }
                this.addConversationListItemToDOM(conversationToAdd)
                addedBroadcaster = true
            }

            const filteredConversations = conversationList.filter(conversation => this.conversationMatchesPrefix(conversation, prefix))
            this.populateConversationsToDOM(filteredConversations, addedBroadcaster)
        }
    }

    private conversationMatchesPrefix(conversation: IConversationListItem, prefix = this.searchPrefix): boolean {
        return prefix.length === 0 || conversation.otherUser.username.startsWith(prefix)
    }

    private addConversationIfPrefixMatch(conversation: IConversationListItem, addToTop = false, prefix?: string): void {
        if (this.conversationMatchesPrefix(conversation, prefix)) {
            this.addConversationListItemToDOM(conversation, addToTop)
        }
    }

    /**
     * Remove old item if it exists, based on username
     * @param otherUsername username of the otherUser to remove
     * @return the removed conversation if it exists, undefined otherwise
     */
    private removeOldConversation(otherUsername: string): ConversationListItem | void {
        let oldConversation: ConversationListItem | undefined
        for (let i = this.renderedConversations.length - 1; i >= 0; i -= 1) {
            const conversationItem = this.renderedConversations[i]
            if (conversationItem.getOtherUsername() === otherUsername) {
                oldConversation = conversationItem
                this.renderedConversations.splice(i, 1)
                conversationItem.element.remove()
            }
        }
        return oldConversation
    }

    /**
     * Keyboard navigation handler
     * @param e keyboard event
     */
    private navigateSuggestions(e: KeyboardEvent): void {
        this.unHighlightSelectedIndex(this.currentIndex)
        if (e.key === "ArrowDown" || e.keyCode === Key.ArrowDown) {
            this.currentIndex = (this.currentIndex + 1 >= this.renderedConversations.length) ? -1 : this.currentIndex + 1
        } else if (e.key === "ArrowUp" || e.keyCode === Key.ArrowUp) {
            this.currentIndex = (this.currentIndex - 1 < -1) ? this.renderedConversations.length - 1 : this.currentIndex - 1
        }

        if (this.currentIndex > -1) {
            const currentConversation = this.renderedConversations[this.currentIndex]
            const currentSuggestion = currentConversation.getOtherUsername()
            this.highlightSelectedIndex(this.currentIndex, true)
            this.searchBar.events.suggestionActiveEvent.fire({ slug: currentSuggestion })
        } else {
            this.searchBar.events.suggestionActiveEvent.fire({ slug: "" })
        }
    }

    /**
     * Open currently selected conversation
     */
    private openCurrentSuggestion(): void {
        if (this.currentIndex > -1) {
            const currentConversation = this.renderedConversations[this.currentIndex]
            const location = this.room === "" ? "PMWindow" : "PMTab"
            const userColorClass = currentConversation.getUserColorClass()
            addPageAction("PMSearchResultClicked", { "location": location, "color": userColorClass })
            this.searchBar.events.suggestionActiveEvent.fire({ slug: "" })
            this.openConversationEvent.fire(currentConversation.getOtherUsername())
        }
    }

    /**
     * Highlight a given conversation
     * @param rowIndex index of conversation to highlight
     * @param shouldScroll true: scroll list to keep selected conversation visible
     */
    private highlightSelectedIndex(rowIndex: number, shouldScroll: boolean): void {
        if (rowIndex > -1) {
            const selectedConversation = this.renderedConversations[rowIndex]
            if (shouldScroll) {
                const listHeight = this.conversationBodyRoot.clientHeight
                const itemHeight = selectedConversation.element.clientHeight
                this.conversationBodyRoot.scrollTop = this.currentIndex * itemHeight - (listHeight / 2)
            }
            removeColorClass(selectedConversation.element, "unreadBg")
            addColorClass(selectedConversation.element, "selected")
        }
    }

    /**
     * Un-highlight a given conversation
     * @param rowIndex index of conversation to un-highlight
     */
    private unHighlightSelectedIndex(rowIndex: number): void {
        if (rowIndex > -1) {
            const row = this.renderedConversations[rowIndex]
            removeColorClass(row.element, "selected")

            if (row.getNumUnread() > 0) {
                addColorClass(row.element, "unreadBg")
            } else {
                removeColorClass(row.element, "unreadBg")
            }
        }
    }

    public setCurrentConversation(username: string): void {
        this.currentConversation = username
        this.renderedConversations.forEach(conversationListItem => {
            if (conversationListItem.getOtherUsername() === username) {
                addColorClass(conversationListItem.element, this.currentConversationColorClass)
            } else {
                removeColorClass(conversationListItem.element, this.currentConversationColorClass)
            }
        })
    }

    public focusSearchBar(): void {
        this.searchBar.focus()
    }
}

const EmptyListMessage = (): HTMLDivElement => {
    const containerStyle: CSSX.Properties = {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        lineHeight: "20px",
        padding: "50px",
        boxSizing: "border-box",
    }
    const imageStyle: CSSX.Properties = {
        margin: "13px",
        width: "30px",
    }
    const sendMessageStyle: CSSX.Properties = {
        fontFamily: "UbuntuBold, Arial, Helvetica, sans-serif",
        fontSize: "14px",
    }

    return (
        <div style={containerStyle}>
            <img src={`${STATIC_URL}pms/empty-chat-state.svg`} style={imageStyle} alt="empty-chat-state" />
            <span style={sendMessageStyle} colorClass="sendDmPrompt" >{i18n.sendDirectMessage}</span>
            <span colorClass="cautionMessage">{i18n.conversationCautionMessage(currentSiteSettings.siteName)}</span>
        </div>
    )
}
