import { Component } from "../defui/component"
import { numberFromStyle } from "../DOMutils"
import { sdRatio } from "../player/playerSettings"
import { dom } from "../tsxrender/dom"
import { Header } from "./header"
import { RoomTabs } from "./roomTabs"
import { TabList, TabName } from "./tabList"
import { sendMessageInputFocus, userPanelRequest, userSwitchedTab } from "./userActionEvents"
import { UserPanel } from "./userPanel"
import { getPortraitDimensions, getViewportWidth } from "./viewportDimension"
import { isPortrait } from "./windowOrientation"
import type { ChatContents } from "./chatContents"

import type { MobileDismissibleMessages } from "./mobileDismissibleMessages"
import type { MobilePlayer } from "./mobilePlayer"
import type { IUserPanelRequest } from "./userPanel"

interface IPortraitContentsProps {
    player: MobilePlayer
    mobileDismissibleMessages: MobileDismissibleMessages
}

export class PortraitContents extends Component<HTMLDivElement, IPortraitContentsProps> {
    private player: MobilePlayer
    private mobileDismissibleMessages: MobileDismissibleMessages
    private tabList: TabList
    private header: Header
    private roomTabs: RoomTabs
    private userPanel: UserPanel

    constructor(props: IPortraitContentsProps) {
        super("div", props)
    }

    protected initData(props: IPortraitContentsProps): void {
        this.player = props.player
        this.mobileDismissibleMessages = props.mobileDismissibleMessages
        this.tabList = new TabList(this.player)
        this.userPanel = new UserPanel()
    }

    protected initUI(): void {
        const containerStyle: CSSX.Properties = {
            width: "100%",
            height: "100%",
            top: `${getViewportWidth() * sdRatio}px`,
            position: "absolute",
            boxSizing: "border-box",
        }

        this.element = <div style={containerStyle} />

        this.header = new Header({ tabList: this.tabList })
        this.addChild(this.header)

        this.roomTabs = new RoomTabs({ tabList: this.tabList })
        this.addChild(this.roomTabs)

        this.player.didRepositionEvent.listen(() => {
            this.repositionChildrenRecursive()
        })

        this.initListenersForUserPanel()
    }

    private initListenersForUserPanel(): void {
        userPanelRequest.listen((panelRequest: IUserPanelRequest) => {
            this.closeUserPanel()
            this.openUserPanel(panelRequest)
        })

        sendMessageInputFocus.listen(() => {
            this.closeUserPanel()
        })

        userSwitchedTab.listen(() => {
            this.closeUserPanel()
        })
    }

    // Close UCM if user clicks outside the UCM (replaces UCM's overlay element, and allows chat to scroll freely)
    private closeUcmClickHandler = (e: MouseEvent): void => {
        if (e.target instanceof Node && this.userPanel.element !== undefined && !this.userPanel.element.contains(e.target)) {
            this.closeUserPanel()
        }
    }

    private openUserPanel(panelRequest: IUserPanelRequest): void {
        this.userPanel.updateContents(panelRequest.username, panelRequest.message)
        const clickedMessageLine = panelRequest.attachTo

        if (clickedMessageLine.parentElement !== null) {
            clickedMessageLine.parentElement.insertBefore(this.userPanel.element, clickedMessageLine.nextSibling)
        } else {
            error(`Error inserting userPanel.div: panelRequest.attachTo.parentElement is null`)
        }

        this.scrollMessageList(panelRequest)
        this.scrollUserList(panelRequest)

        document.addEventListener("click", this.closeUcmClickHandler)
    }

    private closeUserPanel(): void {
        this.userPanel.hideOverlay()

        if (this.userPanel.element.parentElement !== null) {
            this.userPanel.element.parentElement.removeChild(this.userPanel.element)
        }

        document.removeEventListener("click", this.closeUcmClickHandler)
    }

    private scrollMessageList(panelRequest: IUserPanelRequest): void {
        if (![TabName.Chat, TabName.Private].includes(RoomTabs.currentTab)) {
            return
        }

        const chatMessageListWrapper = this.tabList.getChatTab().getChatWindow().chatContents.messageListWrapper
        const privateTabMessageListWrapper = this.tabList.getPrivateTab().getChatContents().messageListWrapper
        const messageListWrapper = RoomTabs.currentTab === TabName.Chat ? chatMessageListWrapper : privateTabMessageListWrapper

        const clickedMessageDiv = panelRequest.attachTo.parentElement

        if (clickedMessageDiv !== null && messageListWrapper !== undefined) {
            const privateContainerHeight = RoomTabs.currentTab === TabName.Private
                ? this.tabList.getPrivateTab().getPrivateContainerHeight()
                : this.tabList.getChatTab().getChatWindow().getPrivateContainerHeight()
            const distanceFromBottom = clickedMessageDiv.offsetTop
                + clickedMessageDiv.offsetHeight
                - (messageListWrapper.offsetHeight + messageListWrapper.scrollTop)
                - (privateContainerHeight + numberFromStyle(this.userPanel.element.style.marginBottom))

            if (distanceFromBottom > 0) {
                messageListWrapper.scrollTop += distanceFromBottom
            }
        }
    }

    private scrollUserList(panelRequest: IUserPanelRequest): void {
        // scroll room menu user list when opening the last user's
        // userPanel to ensure that it's fully visible
        const scrollableWrapper = this.userPanel.element.offsetParent
        const usernameLabel = panelRequest.attachTo

        if (this.userPanel === null || !(scrollableWrapper instanceof HTMLElement) || RoomTabs.currentTab !== TabName.RoomMenu) {
            return
        }

        const labelHeight = usernameLabel.offsetHeight + numberFromStyle(usernameLabel.style.marginBottom)
        const userPanelHeight = this.userPanel.element.offsetHeight + numberFromStyle(this.userPanel.element.style.marginBottom)
        const newScrollTop =
            usernameLabel.offsetTop
            - (scrollableWrapper.offsetHeight + scrollableWrapper.scrollTop)
            + labelHeight
            + userPanelHeight

        if (newScrollTop > 0) {
            scrollableWrapper.scrollTop += newScrollTop
        }
    }

    public getChatContents(): ChatContents {
        return this.tabList.getChatTab().getChatContents()
    }

    public getPrivateTabChatContents(): ChatContents {
        return this.tabList.getPrivateTab().getChatContents()
    }

    public getPrivateShowChatContents(): ChatContents | undefined {
        return this.tabList.getPrivateTab().getPrivateShowChatContents()
    }

    protected repositionChildren(): void {
        if (!isPortrait() || this.player.isFullscreen) {
            this.hideElement()
        } else {
            if (!this.player.isFullscreen) {
                this.showElement()
                this.player.videoControls.mobilePureChat?.setVisible(false)
            }

            this.repositionContainer()
            this.repositionRoomTabs()
        }
    }

    protected repositionContainer(): void {
        const roomHeaderHeight = document.getElementById("static-header")?.offsetHeight ?? 40
        const playerHeight = this.player.element.offsetHeight
        const dismissibleMessagesHeight = this.mobileDismissibleMessages.element.offsetHeight
        const contentHeight =
            getPortraitDimensions().height
            - roomHeaderHeight
            - playerHeight
            - dismissibleMessagesHeight

        this.element.style.height = `${contentHeight}px`
        this.element.style.top = `${playerHeight}px`
    }

    protected repositionRoomTabs(): void {
        const portraitHeaderHeight = this.header.element.offsetHeight
        this.roomTabs.element.style.height = `calc(100% - ${portraitHeaderHeight}px)`
    }
}
