import { RoomListSource } from "../../cb/roomList"
import {
    C2CNotificationLimit, FollowNotificationFrequency, UserListSortOptions,
} from "../roomDossier"
import { RoomStatus } from "../roomStatus"
import type { IRoomDossier } from "../roomDossier";

export const mockRoomDossier: IRoomDossier = {
    room: "",
    chatUserName: "",
    viewerPassword: "",
    roomPassword: "",
    chatRules: "",
    roomUid: "",
    viewerUid: "",
    host: "",
    roomTitle: "",
    appsRunning: [{ app_name: "", app_location: "" }],
    roomStatus: RoomStatus.Public,
    userName: "",
    edgeAuth: "",
    viewerGender: "m",
    hlsSource: "",
    allowAnonymousTipping: false,
    privateMinMinutes: 0,
    allowShowRecordings: false,
    numViewers: 0,
    isWidescreen: false,
    roomGender: "f",
    allowPrivateShow: false,
    privatePrice: 6,
    spyPrice: 0,
    premiumPrivatePrice: 0,
    premiumPrivateMinMinutes: 0,
    premiumShowActive: false,
    privateShowId: "",
    hasLowSatisfactionScore: false,
    isAgeVerified: true,
    hiddenMessage: "",
    following: true,
    followNotificationFrequency: FollowNotificationFrequency.SMART,
    isModerator: false,
    broadcasterIsOnNewChat: false,
    tokenBalance: 0,
    isSupporter: false,
    needsSupporterToPm: true,
    serverName: "",
    numFollowed: 0,
    numFollowedOnline: 0,
    hasStudio: false,
    isMobile: false,
    ignoredEmoticons: [],
    hideSatisfactionScore: false,
    satisfactionScore: { upVotes: 0, downVotes: 0, percent: 100 },
    tipsInPast24Hours: 0,
    lastVoteInPast24Hours: 0,
    lastVoteInPast90DaysDown: false,
    tfaEnabled: false,
    dismissibleMessages: [],
    showMobileSiteBannerLink: false,
    exploringHashTag: "",
    sourceName: RoomListSource.Unknown,
    isInFanClub: false,
    hasFanClub: false,
    paidTokensFanClub: false,
    userChatSettings: {
        fontSize: "",
        fontColor: "",
        fontFamily: "",
        showEmoticons: true,
        emoticonAutocompleteDelay: 5,
        sortUsersKey: UserListSortOptions.Alphabetical,
        modExpiration: 0,
        roomEntryFor: 1,
        roomLeaveFor: 1,
        c2cNotificationLimit: C2CNotificationLimit.None,
        silenceBroadcasters: "false",
        ignoredUsers: "",
        allowedChat: "",
        tipVolume: 0,
        highestTokenColor: "",
        collapseNotices: true,
    },
    browserId: "",
    aspAuthUrl: "",
    quality: {
        quality: "good",
        rate: 0,
        stopped: true,
    },
    edgeRegion: "",
    userlistColor: "g",
    activePassword: false,
    summaryCardImage: "",
}
