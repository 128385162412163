import { pageContext } from "../cb/interfaces/context"
import { showLoginOverlay } from "../cb/ui/loginOverlay"
import { modalConfirm } from "./alerts"

export function isAnonymous(): boolean {
    return pageContext.current?.loggedInUser === undefined
}

let anonymousCallback: (() => void) | undefined

export function isNotLoggedIn(msg = "Login required", useAnonCallback = true, cancelCallback?: () => void, fromFeature?: boolean): boolean {
    if (isAnonymous()) {
        if (anonymousCallback !== undefined && useAnonCallback) {
            anonymousCallback()
        } else {
            modalConfirm(msg, () => {
                showLoginOverlay({ fromFeature })
            }, cancelCallback)
        }
        return true
    }
    return false
}

export function setAnonymousCallback(callback: () => void): void {
    anonymousCallback = callback
}
