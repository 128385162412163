import { i18n } from "../../../common/translation"
import { BaseSuggestedItem } from "./baseSuggestedItem"

export class OnlineSuggestedItem extends BaseSuggestedItem {
    protected getIconClassName(): string {
        return "icon-online"
    }

    protected getStatusText(): string {
        return i18n.broadcastingNow
    }

    protected getRowClassName(): string {
        return "broadcaster_suggestion"
    }
}