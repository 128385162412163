import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "../../../common/api"
import { parseIRoomInfo } from "../roomlist/roomCard"
import { RoomElement } from "./followedDropdown"


const FOLLOW_TAB_RECOMMENDATIONS_LIMIT = 6

export async function getFollowTabRecommendations(): Promise<RoomElement[]> {
    const api_url = "/api/ts/roomlist/rec-follow-room-list/"
    const params = new URLSearchParams(window.location.search)
    params.set("offset", "0")
    return getCb(`${api_url}?${params.toString()}`).then((xhr) => {
        const result = new ArgJSONMap(xhr.responseText)
        const rooms = result.getList("rooms") ?? []
        return rooms.slice(0, FOLLOW_TAB_RECOMMENDATIONS_LIMIT).map( (room) => {
            const room_info = parseIRoomInfo(room)
            const rec = { room: room_info.room, image: room_info.img }
            return new RoomElement(rec, false, rooms.length + 1)
        })
    }).catch((err) => {
        warn("Failed to fetch recommended follow rooms", err)
        return []
    })
}
