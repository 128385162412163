import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { FollowedData } from "./followedData"
import type { RoomElement } from "./followedDropdown"

export class FollowRecommendedHeader extends HTMLComponent<HTMLDivElement> {
    protected createElement(props: IFollowedRoomTableProps): HTMLDivElement {
        const recommendedRooms = FollowedData.getInstance().getCurrentRecommendations()
        return <div className="followRecommendedHeader"
                display={recommendedRooms.length > 0 ? "block" : "none"}>
            <div className="recommendationsBorder"/>
            <span className="recommendedRoomsTitle">{i18n.recommendedForYou}</span>
        </div>
    }
}


interface IFollowedRoomTableProps {
    rooms: RoomElement[],
}
export class FollowedRoomTable extends HTMLComponent<HTMLDivElement, IFollowedRoomTableProps> {
    protected createElement(props: IFollowedRoomTableProps): HTMLDivElement {
        // inserts every two room elements into a row.
        return <div className="followRoomTable"
                    display={props.rooms.length > 0 ? "inline-block" : "none"}
        >
            {props.rooms.map((room, idx,rms) => {
                if (idx % 2 === 0) {
                    return <div key={idx} display="table-row">
                        <div display="table-cell">{room.card}</div>
                        {idx + 1 < rms.length && <div display="table-cell">{rms[idx + 1].card}</div>}
                    </div>
                }
            })}
        </div>
    }
}
