import { i18n } from "../../../../common/translation"

export const enum MoreRoomRecommendationSources {
    recommended = "Recommended",
    recommendedPopular = "Recommended_popular",
    recommendedConfig = "rec_config",
    unknown = "unknown",
}

function isRecommendedLabel(label: string): boolean {
    return resolveRecommendedLabel(label) !== MoreRoomRecommendationSources.unknown
}

export function isRecommendedConfigLabel(label: string): boolean {
    return resolveRecommendedLabel(label) === MoreRoomRecommendationSources.recommendedConfig
}

export function useRecommendedLabel(label: string | undefined): boolean {
    return label !== undefined && isRecommendedLabel(label)
}

export function determineRoomLabel(sourceInfo: string | undefined, roomLabel: string = MoreRoomRecommendationSources.unknown): string {
    if (sourceInfo !== undefined) {
        const potentialLabel = resolveRecommendedLabel(sourceInfo)
        if (isRecommendedLabel(potentialLabel)) {
            if (potentialLabel === MoreRoomRecommendationSources.recommendedConfig) {
                return sourceInfo.toLowerCase()
            }
            return potentialLabel.toLowerCase()
        }
    }
    return roomLabel
}

function resolveRecommendedLabel(label: string): MoreRoomRecommendationSources {
    if (label.startsWith(MoreRoomRecommendationSources.recommendedConfig)) {
        return MoreRoomRecommendationSources.recommendedConfig
    }
    switch (label) {
        case MoreRoomRecommendationSources.recommended:
            return MoreRoomRecommendationSources.recommended
        case MoreRoomRecommendationSources.recommendedPopular:
            return MoreRoomRecommendationSources.recommendedPopular
        default:
            return MoreRoomRecommendationSources.unknown
    }
}

export function getRecommendedDisplayLabel(label: string): string {
    switch (label) {
        case "recommended":
            return i18n.recommendedCAPS
        case "recommended_popular":
            return i18n.recommendedPopularCAPS
        default:
            return label
    }
}
