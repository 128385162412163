import { roomCleanup, roomLoaded } from "../../../common/context"
import { ListenerGroup } from "../../../common/events"
import { IRoomNotice, IRoomStatusChangeNotification, IUserInfo, PartType } from "../../../common/messageInterfaces"
import { RoomStatus } from "../../../common/roomStatus"
import { RoomUsers } from "../../roomUsers"
import { IUserSearch, updateUserMention } from "./userMentionAutocompleteModal"

export class MentionUserList {
    private static instance: MentionUserList | undefined
    private userList: IUserSearch[]
    private invalidMentions: Set<string> = new Set()
    private sorted = false
    private chatListeners = new ListenerGroup()
    private isAnonymous: boolean
    private userListPromise: Promise<IUserSearch[]> | undefined

    private constructor() {
        this.userList = []
        roomLoaded.listen(context => {
            this.clearUserListPromise()
            this.isAnonymous = context.chatConnection.viewerIsAnonymous()
            context.chatConnection.event.statusChange.listen((status: IRoomStatusChangeNotification) => {
                this.statusChangeShouldRefresh(status.currentStatus, status.previousStatus)
            }).addTo(this.chatListeners)

            // only update list when user is logged in
            if (!this.isAnonymous) {
                context.chatConnection.event.roomNotice.listen((m: IRoomNotice) => {
                    for (const msg of m.messages) {
                        for (const part of msg) {
                            if (part.partType === PartType.user && part.user !== undefined) {
                                this.addRecentUser(part.user)
                            }
                        }
                    }
                }).addTo(this.chatListeners)
            }
        })

        roomCleanup.listen(() => {
            this.chatListeners.removeAll()
        })
    }

    public static getInstance(): MentionUserList {
        if (MentionUserList.instance === undefined) {
            MentionUserList.instance = new MentionUserList()
        }
        return MentionUserList.instance
    }

    // mentionUserList is used when needed in autocomplete modal or valid checks
    // otherwise to just get IUserInfo in message handling use users()
    public mentionUserList(keepRecents = true): Promise<IUserSearch[]> {
        if (this.userListPromise !== undefined) {
            return this.userListPromise
        } else {
            this.userListPromise = this.refreshUserList(keepRecents)
            this.scheduleUserListPromiseReset() // makes userListPromise undefined after timeout
            return this.userListPromise
        }
    }

    public clearList(): void {
        this.userList = []
    }

    private clearUserListPromise(): void {
        this.userListPromise = undefined
    }

    private scheduleUserListPromiseReset(): void {
        window.setTimeout(() => { this.clearUserListPromise() }, 60000)
    }

    public getSortedList(): IUserSearch[] {
        if (!this.sorted) {
            this.userList.sort((a, b) => {
                if (a.user.username.toLowerCase() < b.user.username.toLowerCase()) {
                    return -1
                } else if (a.user.username.toLowerCase() > b.user.username.toLowerCase()) {
                    return 1
                } else {
                    return 0
                }
            })
            this.sorted = true
        }
        return this.userList
    }

    public users(): IUserInfo[] {
        return this.userList.map(u => u.user)
    }

    public get count(): number {
        return this.userList.length
    }

    public refreshUserList(keepRecents = true): Promise<IUserSearch[]> {
        return RoomUsers.getInstance().fetchRoomUsers().then((roomUsersInfo) => {
            const emptyDiv = document.createElement("div")
            if (keepRecents) {
                this.removeNonRecentUsers()
            } else {
                this.userList = []
            }
            for (const u of roomUsersInfo.roomUsers) {
                if (!this.userInList(u.username)) {
                    this.userList.push({
                        user: u,
                        slug: u.username,
                        element: emptyDiv,
                        recent: false,
                    })
                }
                if (this.invalidMentions.has(u.username)) {
                    updateUserMention.fire(undefined)
                    this.invalidMentions.delete(u.username)
                }
            }
            return this.userList
        }).catch(err => {
            error("Error loading username autocomplete", err)
            return this.userList
        })
    }

    public addRecentUser(user: IUserInfo): void {
        const emptyDiv = document.createElement("div")
        this.sorted = false
        // add user to recent users list if they are not in main list or already in recent list
        const userSearch = this.getUser(user.username)
        if (userSearch === undefined) {
            this.userList.push({
                user: user,
                slug: user.username,
                element: emptyDiv,
                recent: true,
            })
        } else {
            // update user in case they have changed to tip/fan club/etc
            userSearch.user = user
            userSearch.recent = true
        }
        if (this.invalidMentions.has(user.username)) {
            updateUserMention.fire(undefined)
            this.invalidMentions.delete(user.username)
        }
    }

    public addInvalidUsers(names: string[]): void {
        for (const n of names) {
            this.invalidMentions.add(n)
        }
    }

    private shouldNotRefresh(status: RoomStatus): boolean {
        return [RoomStatus.Offline, RoomStatus.NotConnected, RoomStatus.Hidden, RoomStatus.PasswordProtected].indexOf(status) > -1
    }

    private statusChangeShouldRefresh(currentStatus: RoomStatus, prevStatus: RoomStatus): void {
        if (this.isAnonymous || this.shouldNotRefresh(currentStatus)) {
            return
        }

        const wasInPublic = prevStatus !== RoomStatus.PrivateSpying && prevStatus !== RoomStatus.PrivateWatching
        const isInPublic = currentStatus !== RoomStatus.PrivateSpying && currentStatus !== RoomStatus.PrivateWatching

        if (!wasInPublic && isInPublic) {
            this.clearUserListPromise()
            this.mentionUserList()  // eslint-disable-line @typescript-eslint/no-floating-promises
        } else if (wasInPublic && !isInPublic) {
            this.clearUserListPromise()
            this.mentionUserList(false)  // eslint-disable-line @typescript-eslint/no-floating-promises
        } else {
            this.mentionUserList()  // eslint-disable-line @typescript-eslint/no-floating-promises
        }
    }

    private removeNonRecentUsers(): void {
        this.userList = this.userList.filter(user => user.recent)
    }

    getUser(username: string): IUserSearch | undefined {
        return this.userList.find(u => u.user.username === username)
    }

    userInList(username: string): boolean {
        return this.getUser(username) !== undefined
    }
}
