import { trackCustomEvent } from "@convivainc/conviva-js-appanalytics"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { isiOS, isiPad, isNativeHlsSupported, isPerformanceNowSupported } from "@multimediallc/web-utils/modernizr"
import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { pageContext, roomDossierContext } from "../../cb/interfaces/context"
import { addEventListenerPoly, removeEventListenerPoly } from "../addEventListenerPolyfill"
import { postCb } from "../api"
import { roomCleanup, roomLoaded } from "../context"
import { ListenerGroup } from "../events"
import { featureFlagIsActive } from "../featureFlag"
import { addNamespaceAndDefault, addPageAction, addPlayerAndTipVolume, setCurrentRoom } from "../newrelic"
import { parseRoomDossierMap } from "../roomDossier"
import { parseQueryString } from "../urlUtil"
import { QualityNotifier } from "./qualityNotifier"
import { convivaEnabled } from "./utils"
import type videojs from "../../../@types/videojs"
import type { ReactComponent } from "../../cb/components/ReactRegistry"
import type { IQualityUpdate } from "../../cb/pushservicelib/topics/room"
import type { IRoomContext } from "../context"
import type { RoomStatus } from "../roomStatus"
import type { NewrelicAttributes } from "@multimediallc/web-utils"
import type Hls from "hls.js"
import type {
    ErrorData, FragChangedData, Fragment,
    FragParsedData, HlsListeners, Level,
    LevelSwitchedData, LevelSwitchingData,
} from "hls.js"

const HlsObj = window["Hls"] satisfies Hls

// TODO we need to update our Closure Compiler externs file for hls.js so it can find the Hls properties using
//  dot-notation and compile this class properly. The only file pointed at by Google
//  (https://github.com/google/closure-compiler/wiki/Externs-For-Common-Libraries) is 2 years old and out of sync with
//  the latest hls.js release.

export function sendVideoMetric(attributes = {}): void {
    sendMetric(attributes)
}

function sendMetric(attributes: NewrelicAttributes = {}): void {
    const sample = SessionID.getInstance().browserId
    if (sample !== undefined) {
        // sample to 1/16th =~ 6.25% of sessions
        if (sample.charAt(sample.length - 1) !== "a" && pageContext.current.sample_metrics_off === false) {
            return
        }
        if(attributes["eventName"] === "playerError" && attributes["stopped"] === true){
            return
        }
        if (attributes["badFrames"] === true){
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const formattedAttrs: Record<string, any> = {}
            for (const key in attributes) {
                formattedAttrs[`attributes.${key}`] = attributes[key]
            }
            // Returns success, even on failure
            postCb("api/ts/chat/send-quality/", formattedAttrs) // eslint-disable-line @typescript-eslint/no-floating-promises
        } else {
            addPageAction("VideoMetric", attributes, true)
        }
    }
}

function millisEpoch(): number {
    return (new Date()).getTime()
}

function millisNow(): number {
    if (isPerformanceNowSupported()) {
        return window.performance.now()
    }
        return millisEpoch()
}

function millisSince(since: number): number {
        return millisNow() - since
}

/**
 * Unique identifier for the video player session
 *
 * If the browser fingerprint somehow changes mid-stream, the previous value will continue to be used.
 *
 * Format: "<browserId>:<epoch ms>"
 */
class SessionID {
    private static instance?: SessionID
    public browserId?: string
    private __value?: string

    private constructor() {
        // this needs to happen before the room "officially" loads for autoplay metrics, etc.
        const browserId = window["browserId"] // fullvideo
        if (browserId !== undefined) {
            this.browserId = browserId
            return
        }

        let roomDossier = roomDossierContext.getState()
        if (roomDossier.room === "" && window["initialRoomDossier"]) { // eslint-disable-line @typescript-eslint/strict-boolean-expressions
            roomDossier = parseRoomDossierMap(new ArgJSONMap(window["initialRoomDossier"]))
        }
        this.browserId = roomDossier.browserId
        setCurrentRoom(roomDossier.room)
    }

    /**
     * @return {string} Current session ID
     */
    public get value(): string {
        if (this.__value === undefined) {
            this.__value = this.generate()
        }
        return this.__value
    }

    /**
     * Clear the current session ID value
     */
    public clear(): void {
        this.__value = undefined
    }

    /**
     * @return {string} New generated session ID
     */
    private generate(): string {
        return `${this.browserId}:${millisEpoch()}`
    }

    public static getInstance(): SessionID {
        if (SessionID.instance === undefined) {
            SessionID.instance = new SessionID()
        }
        return SessionID.instance
    }
}

interface StoppedVideoEvents {
    abort: number
    pause: number
    ended: number
    waiting: number
    seeking: number
    suspend: number
    stalled: number
}

export class AutoPlayMetric {
    protected start: number | undefined

    constructor() {
        if (isPerformanceNowSupported()) {
            this.start = millisNow()
        }
    }

    send(success: boolean, hiddenDelay: number, error?: Error): void {
        if (isPerformanceNowSupported() && this.start !== undefined) {
            const noDelay = millisNow() - this.start - hiddenDelay
            const diff = this.start = millisNow()
            const attrs: NewrelicAttributes = {
                "eventName": "autoPlayDuration",
                "autoPlayDuration": diff / 1000.0,
                "autoPlayDurationMS": diff,
                "autoPlayDurationNoDelay": noDelay / 1000.0,
                "autoPlayDurationNoDelayMS": noDelay,
                "autoPlayable": success,
            }
            if (hiddenDelay !== 0) {
                attrs["autoPlayHiddenDelay"] = hiddenDelay / 1000.0
                attrs["autoPlayHiddenDelayMS"] = hiddenDelay
            }
            if (error !== undefined && error !== null) {
                attrs["autoPlayError"] = JSON.stringify(error)
            }
            sendMetric(attrs)
        }
    }
}

export class VideoMetrics {
    protected readonly playerName: string

    private windowUnloadSent = false
    private roomListening = false

    protected roomLoadedTime: number | undefined
    protected roomUnloadedTime: number | undefined
    protected createdTime: number
    protected roomName: string | undefined
    protected roomDuration = 0
    protected roomsVisited = 0
    protected roomStatus: string | undefined
    protected listeners = new ListenerGroup()
    protected playingStoppedTime: number | undefined
    protected nonPlayingTime = 0
    protected timeBetweenUpdates = 0
    protected tabHidden: number | undefined
    protected hiddenTime = 0
    protected quality: IQualityUpdate | undefined
    protected qualityChange: number | undefined
    protected broadcastQuality: number | undefined
    protected qualityTracker = 0
    protected viewerRegion = ""
    protected playbackStoppedEvents: StoppedVideoEvents = {
        abort: 0,
        pause: 0,
        ended: 0,
        waiting: 0,
        seeking: 0,
        suspend: 0,
        stalled: 0,
    }

    protected qualityNotifier: QualityNotifier | undefined

    constructor(playerName: string) {
        if (featureFlagIsActive("VDPEnblBEQualNR") || parseQueryString(window.location.search)["quality"] !== undefined){
            this.qualityNotifier = new QualityNotifier()
        }
        this.playerName = playerName
        this.sendPlayerCreatedMetric()
    }

    bindAll(): void {
        this.removeListeners()

        roomLoaded.listen(this.onRoomLoaded).addTo(this.listeners)
        roomCleanup.listen(this.onRoomUnloaded).addTo(this.listeners)
        roomDossierContext.onUpdate.listen(this.parseQualityUpdate).addTo(this.listeners)
        addEventListenerPoly("pagehide", window, this.onWindowUnloaded)
        addEventListenerPoly("beforeunload", window, this.onWindowUnloaded)
        addEventListenerPoly("unload", window, this.onWindowUnloaded)

        this.roomListening = true
    }

    static jpegFallback(): void {
        sendMetric({
            "eventName": "jpegFallback",
            "hlsNativeSupported": isNativeHlsSupported(),
            "hlsJsSupported": HlsObj.isSupported(),
        })
    }

    protected sendMetric = (attributes: NewrelicAttributes = {}): void => {
        attributes = {
            "playerType": this.playerName,
            "roomDuration": this.roomDuration,
            "roomsVisited": this.roomsVisited,
            "roomStatus": this.roomStatus,
            "videoSessionID": SessionID.getInstance().value,
            "region": this.viewerRegion,
            "isiPad": isiPad(),
            "hidden": document.hidden,
            ...attributes,
        }
        if (this.quality !== undefined) {
            attributes = {
                "fpsquality": this.quality.quality,
                "rate": this.quality.rate,
                "stopped": this.quality.stopped,
                ...attributes,
            }
        }
        delete attributes["tid"]
        if (attributes["unload"] === true) {
            this.sendBackendMetric(attributes)
        }
        sendMetric(attributes)
    }

    protected sendBackendMetric(attributes = {}): void {
        if (this.qualityNotifier !== undefined) {
            addPlayerAndTipVolume(attributes)
            const namespacedAttributes = addNamespaceAndDefault(attributes, true)
            this.qualityNotifier.sendImmediate(namespacedAttributes)
        }
    }

    protected sendUnloadMetrics(): number | undefined {
        if (this.roomLoadedTime === undefined || !isPerformanceNowSupported()) {
            return undefined
        }
        this.roomUnloadedTime = millisNow()
        const duration = this.roomUnloadedTime - this.roomLoadedTime
        return duration
    }

    protected removeListeners(): void {
        if (this.roomListening) {
            removeEventListenerPoly("pagehide", window, this.onWindowUnloaded)
            removeEventListenerPoly("beforeunload", window, this.onWindowUnloaded)
            removeEventListenerPoly("unload", window, this.onWindowUnloaded)
            this.listeners.removeAll()
            this.roomListening = false
        }
    }

    private sendPlayerCreatedMetric = (): void => {
        const attributes: NewrelicAttributes = { "eventName": "playerCreated" }
        const playerOverride = parseQueryString(window.location.search)["player"]
        if (playerOverride !== undefined) {
            attributes["playerOverride"] = playerOverride
        }
        this.sendMetric(attributes)
        this.createdTime = millisNow()
    }

    private onRoomLoaded = (context: IRoomContext): void => {
        this.roomLoadedTime = millisNow()
        SessionID.getInstance().browserId = context.dossier.browserId
        this.roomName = context.dossier.room
    }

    private onRoomUnloaded = (): void => {
        // This won't be called if we are refreshing the browser, i.e., for the beforeunload/unload window events,
        // but, check this.windowUnloadSent just in case context.roomCleanup starts listening to the beforeunload/unload
        // window events in the future
        if (this.windowUnloadSent) {
            return
        }
        this.sendUnloadMetrics()
        SessionID.getInstance().clear()
    }

    private onWindowUnloaded = (): void => {
        // this can be called more than once since we have to bind beforeunload and unload to satisfy all browsers
        if (this.windowUnloadSent) {
            return
        }
        this.sendUnloadMetrics()
        if (this.qualityNotifier !== undefined) {
            this.qualityNotifier.stop();
        }
        this.windowUnloadSent = true
        this.removeListeners()
    }

    public playerForceRemoved(): void {
        this.onWindowUnloaded()
    }

    protected playbackStopped = (ev?: Event): void => {
        if (ev !== undefined && ev.type in this.playbackStoppedEvents) {
            this.playbackStoppedEvents[ev.type as keyof StoppedVideoEvents] += 1
        }
        if (this.playingStoppedTime === undefined && !document.hidden) {
            this.playingStoppedTime = millisNow()
        }
    }

    protected playbackStarted = (): void => {
        if (this.playingStoppedTime !== undefined) {
            this.nonPlayingTime += millisNow() - this.playingStoppedTime
            this.playingStoppedTime = undefined
        }
    }

    protected parseQualityUpdate = (): void => {
        const current = roomDossierContext.getState()
        this.viewerRegion = current.edgeRegion
        if (current.quality !== undefined) {
            if (this.quality !== undefined && current.quality.quality !== this.quality.quality) {
                const currentTime = millisNow()
                if (this.qualityChange !== undefined && this.broadcastQuality !== undefined) {
                    this.qualityTracker += this.broadcastQuality * (currentTime - this.qualityChange)
                }
                switch (current.quality.quality) {
                    case "bad":
                        this.broadcastQuality = -1
                        break
                    case "okay":
                        this.broadcastQuality = 0
                        break
                    default:
                        this.broadcastQuality = 1
                }
                this.qualityChange = currentTime
            }
            this.quality = current.quality
        }
    }

    public getSessionID(): string {
        return SessionID.getInstance().value
    }
}

export class HLSVideoMetrics extends VideoMetrics {
    private playerElement: HTMLMediaElement | videojs.Player
    private hls: Hls | undefined
    private videoOverlay: ReactComponent | undefined

    private htmlMediaListening = false
    private htmlMediaPlayingSent = false
    private htmlMediaPlayingIsAjax = false
    private htmlMediaLoadStartTime: number | undefined

    private hlsJsListening = false
    private hlsJsLevels: Map<number, LevelSwitchingData>
    private hlsCurrentBitrate: number | undefined
    private hlsJsMaxLevel: LevelSwitchingData | undefined
    private hlsJsSwitchingTimes: { level: number, ts: number }[]
    private hlsJsSwitchedTimes: number[]
    private hlsLastFrag: Fragment | undefined
    private hlsFragIndexToFirstTimecode: Map<number, number>
    private audioSegmentsPlaytime: Record<string, number>[];
    private videoSegmentsPlaytime: Record<string, number>[];
    private hlsMediaAttachingTime: number | undefined
    private hlsCurrentQualityLevel: string | undefined
    private lastError = {}
    private lastTime: number | undefined
    private averagePDTLatency: number | undefined
    private averagePDTLatencyTotal = 0
    private averagePDTLatencySamplesCount = 0
    private maxPDTLatency: number | undefined
    private averageE2ELatency: number | undefined
    private averageE2ELatencyTotal = 0
    private averageE2ELatencySamplesCount = 0
    private maxE2ELatency: number | undefined
    private avShift: number | undefined
    private averageAvShift: number | undefined
    private maxAbsAvShift: number | undefined
    private avShiftSamplesCount = 0
    private avShiftTotal = 0
    private overlayValues = {
        fps: "unknown",
        bitrate: 0,
        latency: "unknown ",
        framesLost: "0.00",
        resolution: "unknown",
        streamType: "unknown",
        avShift: "unknown",
        startTime: 0,
    }
    private extXPartInChunklist: boolean | undefined
    private chunkDownloaded: boolean | undefined
    private selectedStreamType: string | undefined
    private initialTypeSent = false
    private lastTimeUpdate: number | undefined
    private currentHeight: number | undefined
    private resolutionSwitched: number | undefined
    private resolutionTracker = 0
    private host = ""
    private edge = ""
    private playlistResource = ""
    protected sampleLatency: number
    private startTime = 0

    constructor(playerName: string) {
        super(playerName)
        this.reset()
        this.listenForChunklist()
        this.lastTimeUpdate = millisNow()
    }

    private startLatencySampling(): void {
        this.stopLatencySampling()
        this.sampleLatency = window.setInterval(() => {
            this.updateLatencyMetrics()
        }, 2000)
    }

    private stopLatencySampling(): void {
        clearInterval(this.sampleLatency)
    }

    private visibilityChange = (): void => {
        if (document.hidden) {
            this.tabHidden = millisNow()
            this.parseTimeUpdate()
        } else if (this.tabHidden !== undefined) {
            this.hiddenTime += millisNow() - this.tabHidden
            this.tabHidden = undefined
        }
    }

    public bindAllHTMLMedia(playerElement: HTMLMediaElement | videojs.Player, parentElement: HTMLElement): void {
        this.removeHTMLMediaListeners()
        this.playerElement = playerElement
        this.lastTime = Date.now()
        let bindingObject: HTMLMediaElement

        if (this.playerElement instanceof HTMLMediaElement) {
            // <video> tag events
            bindingObject = this.playerElement
        } else {
            // videojs library events
            bindingObject = this.playerElement.tech().el() as HTMLMediaElement
        }
        if (isPerformanceNowSupported()) {
            addEventListenerPoly("playing", bindingObject, this.onHTMLMediaEvent)
            addEventListenerPoly("loadstart", bindingObject, this.onHTMLMediaEvent)
        }
        addEventListenerPoly("error", bindingObject, this.onHTMLMediaEvent)
        addEventListenerPoly("stalled", bindingObject, this.onHTMLMediaEvent)

        addEventListenerPoly("abort", bindingObject, this.playbackStopped)
        addEventListenerPoly("pause", bindingObject, this.playbackStopped)
        addEventListenerPoly("ended", bindingObject, this.playbackStopped)
        addEventListenerPoly("waiting", bindingObject, this.playbackStopped)
        addEventListenerPoly("seeking", bindingObject, this.playbackStopped)
        addEventListenerPoly("suspend", bindingObject, this.playbackStopped)

        addEventListenerPoly("seeked", bindingObject, this.playbackStarted)
        addEventListenerPoly("timeupdate", bindingObject, this.parseTimeUpdate)
        addEventListenerPoly("visibilitychange", document, this.visibilityChange)

        this.htmlMediaListening = true

        const detailsOverlay = parseQueryString(window.location.search)["details"]
        if (detailsOverlay !== undefined) {
            const element = document.createElement("div")
            const VideoOverlay = ReactComponentRegistry.get("VideoOverlay")
            this.videoOverlay = new VideoOverlay(this.overlayValues, element)
            parentElement.appendChild(element)
            this.setQuality()
            roomDossierContext.onUpdate.listen(() => {
                this.setQuality()
            }).addTo(this.listeners)
            window.setInterval(() => {
                if (this.videoOverlay !== undefined) {
                    let latency = this.getEndToEndLatency()
                    if (latency === undefined) {
                        latency = 0
                    }
                    let avShift = this.avShift;
                    if (avShift === undefined) {
                        avShift = 0
                    }
                    this.overlayValues.startTime = this.startTime
                    this.overlayValues.streamType = this.getStreamingMode() ?? "unknown"
                    this.overlayValues.latency = (latency / 1000).toFixed(2)
                    this.overlayValues.avShift = avShift.toFixed(2)
                    this.videoOverlay.update(this.overlayValues)
                }
            }, 2000)
        }
    }

    private sendHTMLMediaPlayingMetric = (): void => {
        if (this.htmlMediaPlayingSent) {
            // The "playing" event can trigger more than once when the viewer is in the room. We only want the first
            // event
            return
        }
        const attrs: NewrelicAttributes = {
            "eventName": "videoPlaying",
            "isAjax": false,
            "bitrate": this.hlsCurrentBitrate,
        }

        const pdt = this.currentProgramDateTime()
        if (pdt !== undefined) {
            this.sendHLSMetric({
                "eventName": "broadcastDelay",
                "broadcastDelayMS": pdt,
                "broadcastDelay": pdt / 1000.0,
            })
        }

        const now = millisNow()
        if (this.htmlMediaLoadStartTime === undefined) {
            warn("Playing metric has triggered but htmlMediaLoadStartTime is undefined...skipping")
        } else {
            const diff = now - this.htmlMediaLoadStartTime
            attrs["timeSinceMediaLoadStart"] = diff / 1000.0
            attrs["timeSinceMediaLoadStartMS"] = diff
        }
        if (this.hlsMediaAttachingTime !== undefined) {
            const diff = now - this.hlsMediaAttachingTime
            attrs["timeSinceMediaAttaching"] = diff / 1000.0
            attrs["timeSinceMediaAttachingMS"] = diff
        }
        if (this.htmlMediaPlayingIsAjax) {
            if (this.roomLoadedTime === undefined) {
                warn("Playing metric is for AJAX reload but roomLoadedTime is undefined...skipping")
                return
            }
            let diff = now - this.roomLoadedTime
            attrs["timeSinceLoadAjax"] = diff / 1000.0
            attrs["timeSinceLoadAjaxMS"] = diff
            attrs["isAjax"] = true

            if (this.roomUnloadedTime === undefined) {
                // This is a metric for debugging, it's OK to leave out, just warn
                warn("Playing metric is for AJAX reload but roomUnloadedTime is undefined")
            } else {
                diff = now - this.roomUnloadedTime
                attrs["timeSinceUnloadAjax"] = diff / 1000.0
                attrs["timeSinceUnloadAjaxMS"] = diff
            }
        } else {
            const diff = now - this.createdTime
            attrs["timeSincePlayerCreated"] = diff / 1000.0
            attrs["timeSincePlayerCreatedMS"] = diff
        }
        this.sendHLSMetric(attrs)

        // this.htmlMediaPlayingSent gets reset after unload metrics are sent, this.htmlMediaPlayingIsAjax  does not
        this.htmlMediaPlayingSent = true
        this.htmlMediaPlayingIsAjax = true
    }

    private sendHTMLMediaErrorMetric = (): void => {
        let error
        let element: HTMLMediaElement
        if (this.playerElement instanceof HTMLMediaElement) {
            error = this.playerElement.error
            element = this.playerElement
        } else {
            // @ts-ignore might be wrong typing in videojs, this is valid
            error = this.playerElement["error_"]
            element = this.playerElement.tech().el() as HTMLMediaElement
        }
        if (error === undefined || error === null) {
            // Sometimes this event gets triggered without an error, for example, when the performer is away
            return
        }
        this.sendHLSMetric({
            "eventName": "playerError",
            "errorCode": error["code"],
            "errorMessage": error["message"],
            "errorSource": "HTMLMediaElement",
            "errorReadyState": element.readyState,
            "errorNetworkState": element.networkState,
            "bitrate": this.hlsCurrentBitrate,
        })
    }

    private onHTMLMediaEvent = (event: Event): void => {
        if (!this.htmlMediaListening) {
            return
        }
        if (event.type === "playing") {
            this.playbackStarted()
            this.sendHTMLMediaPlayingMetric()
            this.setResolution(undefined)
            return
        }
        if (event.type === "loadstart") {
            this.htmlMediaLoadStartTime = millisNow()
            return
        }
        if (event.type === "error" ) {
            this.sendHTMLMediaErrorMetric()
            return
        }
        if (event.type === "stalled") {
            this.playbackStopped(event)
            const target = event["target"] as HTMLMediaElement
            if (target !== undefined && target !== null) {
                const attributes = {
                    "eventName": "playerStalled",
                    "stalledReadyState": target["readyState"],
                    "stalledNetworkState": target["networkState"],
                    "bitrate": this.hlsCurrentBitrate,
                }
                this.sendHLSMetric(attributes)
            }
            return
        }
        warn("Received unknown HTMLMediaElement event: ", event)
    }

    bindAllHlsJs = (hls: Hls): void  => {
        this.removeHLSListeners()
        this.hls = hls

        this.hls.on(HlsObj.Events.ERROR, this.onHlsJsEvent)
        this.hls.on(HlsObj.Events.FRAG_CHANGED, this.onHlsJsEvent)
        this.hls.on(HlsObj.Events.FRAG_PARSED, this.onHlsJsEvent)
        if (isPerformanceNowSupported()) {
            this.hls.on(HlsObj.Events.LEVEL_SWITCHING, this.onHlsJsEvent)
            this.hls.on(HlsObj.Events.LEVEL_SWITCHED, this.onHlsJsEvent)
            this.hls.on(HlsObj.Events.MEDIA_ATTACHING, this.onHlsJsEvent)
        }
        this.hlsJsListening = true
    }

    public sendStreamType(streamType: string, currentSource: string): void {
        this.selectedStreamType = streamType
        if (!this.initialTypeSent) {
            const attributes = {
                "eventName": "initialStreamType",
                "streamType": streamType,
            }
            this.sendHLSMetric(attributes)
            this.initialTypeSent = true
            if (convivaEnabled()){
                trackCustomEvent({
                    name: "InitialStreamType",
                    data: {
                        "streamType": streamType,
                        "roomName": this.roomName,
                        "broadcasterID": roomDossierContext.getState().roomUid,
                        "currentSorce": currentSource,
                    },
                })
            }
        }
    }

    public setQualityLevel(level_label: string): void {
        this.hlsCurrentQualityLevel = level_label
    }

    private addHlsJsLevel = (data: LevelSwitchingData): void => {
        const level = data["level"]
        if (level === undefined) {
            warn("Received HlsObj.Events.LEVEL_SWITCHING without a level: ", data)
            return
        }
        for (const a of ["bitrate", "height", "width"] as (keyof Level)[]) {
            // @ts-ignore checking for string or undefined here
            if (isNaN(data[a])) {
                warn(`Level ${a} is not a number: ${data[a]?.toString()}...skipping`)
                return
            }
        }
        this.hlsJsLevels.set(level, data)
        // levels can repeat, so, we must keep all levels in order to compare last switching level x
        // to last switched level x
        this.hlsJsSwitchingTimes.unshift({ "level": level, "ts": millisNow() })
    }

    private setMaxHlsLevel = (data: LevelSwitchedData): void => {
        if (data.level === undefined) {
            warn("Received Hls.Events.LEVEL_SWITCHED without a level: ", data)
            return
        }
        const currentLevel = this.hlsJsLevels.get(data.level)
        if (currentLevel === undefined) {
            warn("Received unknown Hls.Events.LEVEL_SWITCHED level: ", data)
            return
        }
        // LEVEL_SWITCHING and LEVEL_SWITCHED events are not always consecutive for the same level
        for (const s of this.hlsJsSwitchingTimes) {
            if (s.level === data.level) {
                const diff = millisSince(s.ts)
                this.hlsJsSwitchedTimes.push(diff)
                break
            }
        }
        this.adjustAverageHeight(currentLevel.height)
        if (this.hlsJsMaxLevel === undefined || currentLevel.bitrate > this.hlsJsMaxLevel.bitrate) {
            this.hlsJsMaxLevel = currentLevel
        }
        this.hlsCurrentBitrate = currentLevel.bitrate
        this.overlayValues = {
            ...this.overlayValues,
            bitrate: (currentLevel.bitrate / 1000),
            resolution: `${currentLevel.height}x${currentLevel.width}`,
        }
    }

    private adjustAverageHeight = (height: number): void => {
        const currentTime = millisNow()
        if (this.currentHeight !== undefined && this.resolutionSwitched !== undefined) {
            const timeDiff = currentTime - this.resolutionSwitched
            this.resolutionTracker += (timeDiff * this.currentHeight)
        }
        this.resolutionSwitched = currentTime
        this.currentHeight = height
    }

    private getCurrentPlayerTime = (): number | undefined => {
        if (this.playerElement instanceof HTMLMediaElement) {
            return this.playerElement["currentTime"]
        } else {
            return this.playerElement.currentTime()
        }
    }

    private getPlayerOffsetFromSegmentStart = (): number | undefined => {
        if (this.hlsLastFrag === undefined) {
            return undefined
        }
        const currentTime = this.getCurrentPlayerTime()
        if (currentTime === undefined) {
            return undefined
        }
        const start = this.hlsLastFrag.start
        const offset = currentTime - start
        if (offset < 0) {
            return undefined
        }
        return offset * 1000
    }

    private getLastFragmentTimecode = (): number | undefined => {
        if (this.hlsLastFrag === undefined) {
            return undefined
        }
        const lastFragIndex = this.hlsLastFrag.sn as number
        if (!this.hlsFragIndexToFirstTimecode.has(lastFragIndex)) {
            return undefined
        }
        return this.hlsFragIndexToFirstTimecode.get(lastFragIndex)
    }

    public getStreamingMode = (): string | undefined => {
        if (this.extXPartInChunklist === undefined) {
            return undefined
        }
        if (this.extXPartInChunklist) {
            if (this.chunkDownloaded === undefined ) {
                return undefined
            }
            if (this.chunkDownloaded) {
                return "ll-hls"
            } else {
                return "fallback hls"
            }
        } else {
            return "hls"
        }
    }

    public getEndToEndLatency = (): number | undefined => {
        const offset = this.getPlayerOffsetFromSegmentStart()
        if (offset === undefined) {
            return undefined
        }
        const lastFragIndexTimecode = this.getLastFragmentTimecode()
        if (lastFragIndexTimecode === undefined) {
            return undefined
        }
        let diff
        if (roomDossierContext.getState().latency?.localTimeTranscoderInput !== undefined &&
            roomDossierContext.getState().latency?.streamTimeTranscoderInput !== undefined) {
            const transcoderInputShift = (roomDossierContext.getState().latency?.localTimeTranscoderInput ?? 0) -
                (roomDossierContext.getState().latency?.streamTimeTranscoderInput ?? 0)
            diff = (millisEpoch() - transcoderInputShift - offset - lastFragIndexTimecode)
            if (diff < 0 || diff > 30000) {
                return undefined
            }
        }
        return diff
    }

    private currentProgramDateTime = (): number | undefined => {
        if (this.hlsLastFrag === undefined) {
            return undefined
        }
        let currentTime
        if (this.playerElement instanceof HTMLMediaElement) {
            currentTime = this.playerElement["currentTime"]
        } else {
            currentTime = this.playerElement.currentTime()
        }
        if (currentTime === undefined) {
            return undefined
        }
        const start = this.hlsLastFrag.start
        let offset = currentTime - start
        if (offset < 0) {
            return undefined
        }
        offset *= 1000
        // we specifically want millisEpoch() and not millisNow() since millisNow() could be performance.now() which
        // is not relative to the epoch
        if (this.hlsLastFrag.programDateTime === null) {
            return undefined
        }
        const diff = (millisEpoch() - this.hlsLastFrag.programDateTime) - offset
        // Throwaway skewed values, i.e., values less than zero or greater than 5 minutes. These could come from
        // inaccurate `Date` math, i.e., inaccurate clocks, non UTC timestamps, etc.
        if (diff < 0 || diff > 300000) {
            return undefined
        }
        return diff
    }

    private updateLatencyMetrics = (): void => {
        const currentPDTLatency = this.currentProgramDateTime()
        if (currentPDTLatency !== undefined) {
            if (this.maxPDTLatency === undefined || currentPDTLatency > this.maxPDTLatency) {
                this.maxPDTLatency = currentPDTLatency
            }
            this.averagePDTLatencyTotal += currentPDTLatency
            this.averagePDTLatencySamplesCount += 1
            this.averagePDTLatency = this.averagePDTLatencyTotal / this.averagePDTLatencySamplesCount
        }
        const currentE2ELatency = this.getEndToEndLatency()
        if (currentE2ELatency !== undefined) {
            if (this.maxE2ELatency === undefined || currentE2ELatency > this.maxE2ELatency) {
                this.maxE2ELatency = currentE2ELatency
            }
            this.averageE2ELatencyTotal += currentE2ELatency
            this.averageE2ELatencySamplesCount += 1
            this.averageE2ELatency = this.averageE2ELatencyTotal / this.averageE2ELatencySamplesCount
        }
    }

    private calculateAvShift = (segmentIndex: number, playTime: number, isAudio: boolean, pts: number): void => {
        let avShift
        if (isAudio) {
            const matchingVideo = this.videoSegmentsPlaytime.find(item => item.segmentIndex === segmentIndex);
            if (matchingVideo !== undefined) {
                avShift = playTime - matchingVideo.playTime - (pts - matchingVideo.pts);
                this.videoSegmentsPlaytime = this.videoSegmentsPlaytime.filter(item => item.segmentIndex <= segmentIndex)
                this.audioSegmentsPlaytime = this.audioSegmentsPlaytime.filter(item => item.segmentIndex <= segmentIndex)
            } else {
                this.audioSegmentsPlaytime.push({ segmentIndex: segmentIndex, playTime: playTime, pts: pts })
            }
        } else {
            const matchingAudio = this.audioSegmentsPlaytime.find(item => item.segmentIndex === segmentIndex);
            if (matchingAudio !== undefined) {
                avShift = matchingAudio.playTime - playTime - (matchingAudio.pts - pts);
                this.videoSegmentsPlaytime = this.videoSegmentsPlaytime.filter(item => item.SegmentIndex <= segmentIndex)
                this.audioSegmentsPlaytime = this.audioSegmentsPlaytime.filter(item => item.SegmentIndex <= segmentIndex)
            } else {
                this.videoSegmentsPlaytime.push({ segmentIndex: segmentIndex, playTime: playTime, pts: pts })
            }
        }
        if (avShift !== undefined) {
            this.avShift = avShift
            if (this.maxAbsAvShift === undefined || Math.abs(avShift) > this.maxAbsAvShift) {
                this.maxAbsAvShift = Math.abs(avShift)
            }
            this.avShiftTotal += Math.abs(avShift)
            this.avShiftSamplesCount += 1
            this.averageAvShift = this.avShiftTotal / this.avShiftSamplesCount
        }
    }

    private handleParsedFragment = (data: FragParsedData): void => {
        if (data.part !== undefined && data.part !== null) {  // It is undefined or null for regular HLS
            const relurl = String(data.part.relurl)
            if (relurl.endsWith(".0_m3u8.cmfa")) {
                this.calculateAvShift(data.frag.sn as number, data.frag.start, true, data.frag.elementaryStreams["audio"]?.startPTS as number)
            } else if (relurl.endsWith(".0_m3u8.cmfv")) {
                this.calculateAvShift(data.frag.sn as number, data.frag.start, false, data.frag.elementaryStreams["video"]?.startPTS as number)
            }
        }
    }

    private handleErrorEvent = (data: ErrorData): void => {
        const el = this.playerElement instanceof HTMLMediaElement ?
            this.playerElement : this.playerElement.tech().el() as HTMLMediaElement
        const attrs = {
            "eventName": "playerError",
            "errorCode": data.type,
            "errorMessage": data.details,
            "errorSource": "hls.js",
            "errorReadyState": el["readyState"],
            "errorNetworkState": el["networkState"],
            "bitrate": this.hlsCurrentBitrate,
            "quality": this.hlsCurrentQualityLevel,
        }
        if (this.lastError !== attrs || (this.lastTime !== undefined && (Date.now() - this.lastTime) > 1000)) {
            this.sendHLSMetric(attrs)
            this.lastError = attrs
            this.lastTime = Date.now()
        }
    }

    private onHlsJsEvent = <E extends keyof HlsListeners>(event: E, data: Parameters<HlsListeners[E]>[1]): void => {
        if (!this.hlsJsListening) {
            return
        }
        if (event === HlsObj.Events.LEVEL_SWITCHING) {
            this.addHlsJsLevel(data as LevelSwitchingData)
            return
        }
        if (event === HlsObj.Events.LEVEL_SWITCHED) {
            this.setMaxHlsLevel(data as LevelSwitchedData)
            return
        }
        if (event === HlsObj.Events.FRAG_CHANGED) {
            this.hlsLastFrag = (data as FragChangedData).frag
            return
        }
        if (event === HlsObj.Events.FRAG_PARSED) {
            this.handleParsedFragment(data as FragParsedData)
            return
        }
        if (event === HlsObj.Events.MEDIA_ATTACHING) {
            this.hlsMediaAttachingTime = millisNow()
            return
        }
        if (event === HlsObj.Events.ERROR) {
            this.handleErrorEvent(data as ErrorData)
            return
        }
        warn("Received unknown Hls.Event: ", event)
    }

    protected parseTimeUpdate = (): void => {
        const timeUpdate = millisNow()
        if (this.lastTimeUpdate !== undefined && !document.hidden) {
            const diff = timeUpdate - this.lastTimeUpdate
            if (diff > 500) {
                this.timeBetweenUpdates += diff
            }
        }
        if (document.hidden) {
            this.lastTimeUpdate = undefined
        } else {
            this.lastTimeUpdate = timeUpdate
        }
    }

    protected sendUnloadMetrics(): number | undefined {
        const duration = super.sendUnloadMetrics()
        // Give enough time for all resolutions to load since, if the user switches rooms quickly, it might lead to
        // false positives because there won't be enough time for the highest resolutions to load
        if (duration !== undefined && duration > 5000) {
            if (this.hlsJsMaxLevel !== undefined) {
                this.sendHLSMetric({
                    "room_user": this.roomName,
                    "eventName": "levelMax",
                    "levelMaxName": this.hlsJsMaxLevel.name,
                    "levelMaxBitrate": this.hlsJsMaxLevel.bitrate,
                    "levelMaxAudioCodec": this.hlsJsMaxLevel.audioCodec,
                    "levelMaxVideoCodec": this.hlsJsMaxLevel.videoCodec,
                    "levelMaxHeight": this.hlsJsMaxLevel.height,
                    "levelMaxWidth": this.hlsJsMaxLevel.width,
                    "bitrate": this.hlsCurrentBitrate,
                })
            }

            const switchedAvg = this.hlsJsSwitchedTimes.reduce((a, b) => a + b, 0) / this.hlsJsSwitchedTimes.length
            if (!isNaN(switchedAvg)) {
                this.sendHLSMetric({
                    "room_user": this.roomName,
                    "eventName": "levelSwitchAvg",
                    "levelSwitchAvg": switchedAvg / 1000.0,
                    "levelSwitchAvgMS": switchedAvg,
                    "levelSwitchCount": this.hlsJsSwitchedTimes.length,
                })
            }
        }

        return duration
    }

    protected removeListeners(): void {
        super.removeListeners()
        this.listeners.removeAll()
        this.removeHLSListeners()
        this.removeHTMLMediaListeners()
    }

    private removeHLSListeners = (): void => {
        if (!this.hlsJsListening || this.hls === undefined) {
            return
        }
        this.hls.off(HlsObj.Events.ERROR, this.onHlsJsEvent)
        this.hls.off(HlsObj.Events.FRAG_CHANGED, this.onHlsJsEvent)
        if (isPerformanceNowSupported()) {
            this.hls.off(HlsObj.Events.LEVEL_SWITCHING, this.onHlsJsEvent)
            this.hls.off(HlsObj.Events.LEVEL_SWITCHED, this.onHlsJsEvent)
        }
        this.hlsJsListening = false
    }

    private removeHTMLMediaListeners = (): void => {
        if (!this.htmlMediaListening) {
            return
        }
        let bindingObject: HTMLMediaElement

        if (this.playerElement instanceof HTMLMediaElement) {
            // <video> tag events
            bindingObject = this.playerElement
        } else {
            // videojs library events
            bindingObject = this.playerElement.tech().el() as HTMLMediaElement
        }
        if (isPerformanceNowSupported()) {
            removeEventListenerPoly("playing", bindingObject, this.onHTMLMediaEvent)
            removeEventListenerPoly("loadstart", bindingObject, this.onHTMLMediaEvent)
        }
        removeEventListenerPoly("error", bindingObject, this.onHTMLMediaEvent)
        removeEventListenerPoly("stalled", bindingObject, this.onHTMLMediaEvent)

        removeEventListenerPoly("abort", bindingObject, this.playbackStopped)
        removeEventListenerPoly("pause", bindingObject, this.playbackStopped)
        removeEventListenerPoly("ended", bindingObject, this.playbackStopped)
        removeEventListenerPoly("waiting", bindingObject, this.playbackStopped)
        removeEventListenerPoly("seeking", bindingObject, this.playbackStopped)
        removeEventListenerPoly("suspend", bindingObject, this.playbackStopped)

        removeEventListenerPoly("seeked", bindingObject, this.playbackStarted)
        removeEventListenerPoly("timeupdate", bindingObject, this.parseTimeUpdate)
        removeEventListenerPoly("visibilitychange", document, this.visibilityChange)

        this.htmlMediaListening = false
    }

    private reset = (): void => {
        this.htmlMediaPlayingSent = false
        this.hlsJsLevels = new Map<number, LevelSwitchingData>()
        this.hlsFragIndexToFirstTimecode = new Map<number, number>()
        this.audioSegmentsPlaytime = new Array<Record<string, number>>;
        this.videoSegmentsPlaytime = new Array<Record<string, number>>;
        this.hlsCurrentBitrate = undefined
        this.hlsJsSwitchingTimes = []
        this.hlsJsSwitchedTimes = []
        this.hlsJsMaxLevel = undefined
        this.hlsLastFrag = undefined
        this.htmlMediaLoadStartTime = undefined
        this.hlsMediaAttachingTime = undefined
        this.roomDuration = 0
        this.roomsVisited = 0
        this.extXPartInChunklist = undefined
        this.chunkDownloaded = undefined
        this.avShiftSamplesCount = 0
        this.avShiftTotal = 0
        this.lastTimeUpdate = undefined
        this.nonPlayingTime = 0
        this.timeBetweenUpdates = 0
        this.playingStoppedTime = undefined
        this.hiddenTime = 0
        this.resolutionSwitched = undefined
        this.resolutionTracker = 0
        if (document.hidden) {
            this.tabHidden = millisNow()
        } else {
            this.tabHidden = undefined
        }
        this.createdTime = millisNow()
        this.qualityTracker = 0
        this.qualityChange = undefined
        this.quality = undefined
        this.broadcastQuality = 0
        this.viewerRegion = ""
        this.averagePDTLatencyTotal = 0
        this.averagePDTLatencySamplesCount = 0
        this.startLatencySampling()
        this.startTime = 0
        this.playbackStoppedEvents = {
            abort: 0,
            pause: 0,
            ended: 0,
            waiting: 0,
            seeking: 0,
            suspend: 0,
            stalled: 0,
        }
    }

    public sendStartTimes(startTime: number, pageTime: number, dataTime: number, metaTime: number, loadTime: number, roomStatus: string, roomsVisited: number, height: number): void {
        this.roomStatus = roomStatus
        this.roomsVisited = roomsVisited
        this.roomDuration = 0
        this.startTime = startTime
        if (this.currentHeight === undefined) {
            this.adjustAverageHeight(height)
        }
        const attributes = {
            "eventName": "playerTimes",
            "room_user": this.roomName,
            "startTime": startTime / 1000,
            "pageStartTime": pageTime / 1000,
            "dataLoadTime": dataTime / 1000,
            "metaLoadTime": metaTime / 1000,
            "startLoadTime": loadTime / 1000,
        }
        this.sendHLSMetric(attributes)
    }

    public sendQuality(droppedFrames: number, segmentDropped: number, totalFrames: number, segmentFrames: number, currentPixels: number, videoHeight: number, minutes: number, roomStatus: RoomStatus): void {
        this.roomDuration = minutes !== -1 ? minutes : this.roomDuration
        const currentTime = millisNow()
        let totalStoppedTime = this.nonPlayingTime
        if (this.playingStoppedTime !== undefined) {
            totalStoppedTime += currentTime - this.playingStoppedTime
            this.playingStoppedTime = currentTime
        }
        if (this.tabHidden !== undefined) {
            this.hiddenTime += currentTime - this.tabHidden
            this.tabHidden = currentTime
        }
        const totalTime = currentTime - this.createdTime
        const unhiddenTime = totalTime - this.hiddenTime
        this.adjustAverageHeight(videoHeight)
        this.parseTimeUpdate()
        this.parseQualityUpdate()
        let attributes = {}
        attributes = {
            "eventName": "playerQuality",
            "room_user": this.roomName,
            "resolution": currentPixels,
            "videoHeight": videoHeight,
            "playingRatio": 1 - (totalStoppedTime / unhiddenTime),
            "timeUpdateRatio": 1 - (this.timeBetweenUpdates / unhiddenTime),
            "averageHeight": Math.round(this.resolutionTracker / totalTime),
            "averageQuality": (this.qualityTracker / totalTime),
            "totalTime": totalTime / 1000,
            "unhiddenTime": unhiddenTime / 1000,
            "hiddenTime": this.hiddenTime / 1000,
            "nonPlayingTime": this.nonPlayingTime / 1000,
        }
        if (!isiOS()){
            attributes = {
                ...attributes,
                "droppedFrames": droppedFrames,
                "totalFrames": totalFrames,
                "segmentDropped": segmentDropped,
                "segmentFrames": segmentFrames,
                "badFrames": segmentDropped > 30,
            }
        }
        if (minutes === -1) {
            attributes = {
                ...attributes,
                "unload": true,
                ...this.playbackStoppedEvents,
            }
        }
        this.overlayValues.framesLost = droppedFrames > 0 ? (droppedFrames * 100).toFixed(2) : "0.00"
        this.sendHLSMetric(attributes)
    }

    public setStatus(roomStatus: string): void {
        this.roomStatus = roomStatus
    }

    protected sendHLSMetric = (attributes = {}): void => {
        let hlsLatency: number | undefined
        if (this.hls !== undefined) {
            hlsLatency = this.hls["latency"]
        }
        this.sendMetric({
            "averagePDTLatency": this.averagePDTLatency,
            "maxPDTLatency": this.maxPDTLatency,
            "hlsLatency": hlsLatency,
            "averageEndToEndLatency": this.averageE2ELatency,
            "maxEndToEndLatency": this.maxE2ELatency,
            "streamingMode": this.getStreamingMode(),
            "selectedMode": this.selectedStreamType,
            "averageAvShift": this.averageAvShift,
            "maxAbsAvShift": this.maxAbsAvShift,
            "host": this.host,
            "edge": this.edge,
            "playlistResource": this.playlistResource,
            ...attributes,
        })
    }

    private listenForChunklist(): void {
        let parsedTimebase = -1
        const SEGMENT_TIMECODES_MAP_HISTORY = 15
        const MPEG2_TS_PACKET_SIZE = 188
        const DEFAULT_TIMEBASE = 90000

        const parseCmafHeader = (xhr: XMLHttpRequest): void => {
            const byteArray = new Uint8Array(xhr.response).slice(0, 2000);
            for (let i = 0; parsedTimebase === -1 && i < byteArray.length - 19; ++i) {
                if (byteArray[i] === 0x6d && byteArray[i + 1] === 0x64 && byteArray[i + 2] === 0x68 && byteArray[i + 3] === 0x64) {  // mdhd box
                    parsedTimebase = 0
                    for (let j = 0; j < 4; ++j) {
                        parsedTimebase = parsedTimebase * 256 + byteArray[i + 16 + j]
                    }
                }
            }
        }

        const addSegmentTimecode = (segmentIndex: number, timecode: number): void => {
            this.hlsFragIndexToFirstTimecode.set(segmentIndex, timecode)

            // Delete old elements in map
            this.hlsFragIndexToFirstTimecode.forEach((value, key) => {
                if (key < segmentIndex - SEGMENT_TIMECODES_MAP_HISTORY) {
                    this.hlsFragIndexToFirstTimecode.delete(key)
                }
            })
        }

        const getPTSfromPESPacket = (byteArray: Uint8Array, offset: number): number | undefined => {
            // Parse PES header
            if (byteArray[offset] !== 0x00 || byteArray[offset + 1] !== 0x00 || byteArray[offset + 2] !== 0x01) {
                // Missing PES start code, so it must be some special packet like PMT.
                return undefined
            }
            const streamId = byteArray[offset + 3]
            const hasPts = !!(byteArray[offset + 7] & 0x80)
            if (streamId < 0xE0 || streamId > 0xEF || !hasPts) {
                // Final step of filtering. Removing audio packets and packets without PTS.
                return undefined
            }

            // Parse PTS
            const pts_32_30 = (byteArray[offset + 9] >>> 1) & 0x07
            const pts_29_15 = (byteArray[offset + 10] << 7) + (byteArray[offset + 11] >>> 1)
            const pts_14_0 = (byteArray[offset + 12] << 7) + (byteArray[offset + 13] >>> 1)
            return ((pts_32_30 << 30) + (pts_29_15 << 15) + pts_14_0) / (DEFAULT_TIMEBASE / 1000)
        }

        // eslint-disable-next-line complexity
        const parseMpeg2TsSegment = (xhr: XMLHttpRequest): void => {
            const lastUnderscore = xhr.responseURL.lastIndexOf("_")
            const lastDot = xhr.responseURL.lastIndexOf(".")
            const currentSegmentIndex = Number(xhr.responseURL.substring(lastUnderscore + 1, lastDot))
            let byteArray: Uint8Array | undefined
            try {
                byteArray = new Uint8Array(xhr.response).slice(0, 2000)
            } catch (e) {
                // do nothing if already detached
            }
            if (byteArray === undefined) {
                return
            }
            let timecode
            for (let tsPacketIdx = 0;
                 timecode === undefined && (tsPacketIdx + 1) * MPEG2_TS_PACKET_SIZE < byteArray.length;
                 ++tsPacketIdx) {
                // Parse TS packet header
                let offset = tsPacketIdx * MPEG2_TS_PACKET_SIZE
                const unitStart = !!(byteArray[offset + 1] & 0x40)
                const pid = byteArray[offset + 2] + ((byteArray[offset + 1] & 0x1F) << 8)
                const hasAdaptationField = !!(byteArray[offset + 3] & 0x20)
                const hasPayload = !!(byteArray[offset + 3] & 0x10)
                if (!unitStart || pid < 0x20 || pid > 0x1FFA || !hasPayload) {
                    // We need first packet of video unit, so filtering out packets that for sure are not.
                    // Still, it may be some special PID (e.g. PMT) or audio packet.
                    continue
                }
                offset += 4  // start of adaptation field (if present) or payload (PES packet)
                if (hasAdaptationField) {
                    // move offset to the start of payload (PES packet)
                    const adaptationFieldSize = byteArray[offset]
                    offset += adaptationFieldSize + 1
                }

                timecode = getPTSfromPESPacket(byteArray, offset)
                if (timecode !== undefined) {
                    addSegmentTimecode(currentSegmentIndex, timecode)
                }
            }
        }

        const extractTimecodeFromCmafFragment = (byteArray: Uint8Array): number | undefined => {
            for (let i = 0; i < byteArray.length - 15; ++i) {
                if (byteArray[i] === 0x74 && byteArray[i + 1] === 0x66 && byteArray[i + 2] === 0x64 && byteArray[i + 3] === 0x74) {  // tfdt box
                    let timecode = 0;
                    for (let j = 0; j < 8; ++j) {
                        timecode = timecode * 256 + byteArray[i + 8 + j]
                    }
                    return timecode
                }
            }
            return undefined
        }

        const parseCmafChunkOrSegment = (xhr: XMLHttpRequest): void => {
            const timebase = (parsedTimebase === -1) ? DEFAULT_TIMEBASE : parsedTimebase
            const splitUrl = xhr.responseURL.split("_")
            this.chunkDownloaded = splitUrl[splitUrl.length - 2].includes(".")
            const segmentAndChunkIndex = splitUrl[splitUrl.length - 2].split(".")
            if (segmentAndChunkIndex.length === 1 ||  // HLS segment
                (segmentAndChunkIndex.length === 2 && segmentAndChunkIndex[1] === "0")) {  // first LL-HLS chunk
                let byteArray: Uint8Array | undefined
                try {
                    byteArray = new Uint8Array(xhr.response).slice(0, 2000)
                } catch (e) {
                    // do nothing if already detached
                    return
                }
                let timecode = extractTimecodeFromCmafFragment(byteArray)
                if (timecode !== undefined) {
                    timecode /= (timebase / 1000)

                    const currentSegmentIndex = Number(segmentAndChunkIndex[0])
                    addSegmentTimecode(currentSegmentIndex, timecode)
                }
            }
        }

        const handleChunkListLoad = (responseText: string): void => {
            this.extXPartInChunklist = responseText.includes("#EXT-X-PART")
        }

        const handleMediaFileLoad = (xhr: XMLHttpRequest): void => {
            if (xhr.responseURL.includes("media") && xhr.responseURL.endsWith("_m3u8.cmfv")) {  // CMAF chunk/segment
                parseCmafChunkOrSegment(xhr)
            } else if (xhr.responseURL.includes("header") && xhr.responseURL.endsWith("_m3u8.cmfv")) {  // CMAF header
                parseCmafHeader(xhr)
            } else if (xhr.responseURL.includes("media") && xhr.responseURL.endsWith(".ts")) { // MPEG2TS segment
                parseMpeg2TsSegment(xhr)
            }
        }

        const handleLoadXhr = (event: Event): void => {
            const xhr = event.target as XMLHttpRequest
            if (xhr.responseURL.includes("chunklist") && !xhr.responseURL.includes("_ao_")) {
                // Get the XHR response text
                const responseText = xhr.responseText
                handleChunkListLoad(responseText)
            } else if (xhr.response !== null && !(Boolean(xhr.response.detached))) {
                handleMediaFileLoad(xhr)
            }
        }

        const handleProgressXhr = (event: Event): void => {
            const xhr = event.target as XMLHttpRequest
            if (xhr.response !== null && !(Boolean(xhr.response.detached))) {
                handleMediaFileLoad(xhr)
            }
        }

        const originalXHR = XMLHttpRequest
        // @ts-ignore - we are modifying the original object
        window.XMLHttpRequest = function() {
            const xhr = new originalXHR()
            xhr.addEventListener("load", handleLoadXhr)
            xhr.addEventListener("progress", handleProgressXhr)
            return xhr
        }
    }

    public setQuality(): void {
        const quality = roomDossierContext.getState().quality
        if (quality !== undefined){
            this.overlayValues.fps = quality.quality
        }
    }

    public setResolution(resolution: string | undefined): void {
        if (resolution !== undefined) {
            this.overlayValues.resolution = resolution
        } else if (this.playerElement instanceof HTMLVideoElement) {
            this.overlayValues.resolution = `${this.playerElement.videoHeight}x${this.playerElement.videoWidth}`
        }
    }

    public endSession(): void {
        this.sendUnloadMetrics()
        this.reset()
    }

    public updateResourceInfo(host: string, edge: string, playlistResource: string): void {
        this.host = host
        this.edge = edge
        this.playlistResource = playlistResource
    }
}
