import { isChrome, isiOS } from "@multimediallc/web-utils/modernizr"
import { addColorClass } from "../../cb/colorClasses"
import { mobileMediaChanged, showMobileMediaDock } from "../../cb/components/pm/mediaDock"
import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { MobileUserMentionAutocompleteModal, updateUserMention } from "../../cb/components/userMentions/userMentionAutocompleteModal"
import { setupRulesModal } from "../../cb/components/userMenus/ui/rulesModal"
import { roomDossierContext } from "../../cb/interfaces/context"
import { ScrollDownButton } from "../../cb/ui/scrollDownButton"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert } from "../alerts"
import { isNotLoggedIn } from "../auth"
import { dossierLoaded } from "../chatRoot"
import { roomCleanup, roomLoaded } from "../context"
import { CustomInput } from "../customInput"
import { Debouncer, DebounceTypes } from "../debouncer"
import { Component } from "../defui/component"
import { applyStyles, isScrolledIntoView, stopScrollMomentum } from "../DOMutils"
import { MobileEmoticonAutocompleteModal } from "../emoticonAutocompleteModal"
import { EventRouter, ListenerGroup } from "../events"
import { isScrollDownNoticeActive } from "../featureFlagUtil"
import { fullscreenChange } from "../fullscreen"
import { insertByTimestamp } from "../messageToDOM"
import { addPageAction } from "../newrelic"
import { styleUserSelect } from "../safeStyle"
import { MobileShortcodeAutocompleteModal } from "../shortcodeAutocompleteModal"
import {
    OutgoingMessageType,
    parseOutgoingMessage,
    ShortcodeParser,
} from "../specialoutgoingmessages"
import { maxInputChat, maxInputPm, SendButtonVariant } from "../theatermodelib/chatTabContents"
import { createNewMessageNoticeDiv } from "../theatermodelib/messageToDOM"
import { userChatSettingsUpdate } from "../theatermodelib/userActionEvents"
import { i18n } from "../translation"
import { videoModeHandler } from "../videoModeHandler"
import { SendTipButton } from "./actionButtons"
import { createLogMessage, styleMobileMention } from "./messageToDOM"
import { repositionChatContentsOnInputFocus } from "./mobileControlsEvents"
import { needsSafariInputFix } from "./mobileRoot"
import { scrollFix } from "./scrollFix"
import { sendMessageInputBlur, sendMessageInputFocus, siteHeaderMenuOpened } from "./userActionEvents"
import { getViewportWidth } from "./viewportDimension"
import { isPortrait, screenOrientationChanged } from "./windowOrientation"
import type { IMirrorableChatComponent } from "./mobilePureChat"
import type { SelectedMobileMediaDock } from "../../cb/components/pm/mediaDock"
import type { ReactComponent } from "../../cb/components/ReactRegistry"
import type { RulesModal } from "../../cb/components/userMenus/ui/rulesModal"
import type { AutocompleteModal } from "../autocompleteModal"
import type { IChatContents } from "../chatRoot"
import type { IRoomContext } from "../context"
import type { IItem } from "../filteringCaches"
import type { IPrivateMessage, IRoomNotice } from "../messageInterfaces"
import type { IUserChatSettings } from "../roomDossier"
import type { RoomNotice } from "../roomNotice"
import type {
    IOutgoingMessageHandlers,
    IShortcodeMessage,
    ITipRequestMessage } from "../specialoutgoingmessages"
import type { IHtmlCreateEvent } from "../ui/interfaces"

/**
 * @styles: scss/theme/mobile/room/chatContents.scss
 */

// region DOM Creation
export const inputDivHeight = (): number => 64
export const mediaDockHeight = (): number => 64

export const inputDivBorder = 1
const maxMessageHistory = 1000

function createMessageListWrapper(): HTMLDivElement {
    // the wrapper handles the scrolling and padding so that other parts of the project can
    // call `getBoundingClientRect` on the window
    const messageListWrapper = document.createElement("div")
    messageListWrapper.dataset.testid = "message-list-wrapper"
    messageListWrapper.style.width = "100%"
    messageListWrapper.className = "mobile-msg-list-wrapper"
    messageListWrapper.style.boxSizing = "border-box"
    if (isScrollDownNoticeActive()) {
        messageListWrapper.style.overflowY = "auto"
    }
    messageListWrapper.style.overflowX = "hidden"
    scrollFix(messageListWrapper)
    return messageListWrapper
}

function createMessageList(): HTMLDivElement {
    const m = document.createElement("div")
    addColorClass(m, "msgList")
    styleUserSelect(m, "text")
    m.dataset.testid = "message-list"
    m.style.width = "100%"
    m.style.cursor = "text"
    m.style.paddingBottom = "4px"
    return m
}

function createNoticeList(): HTMLDivElement {
    const m = document.createElement("div")
    addColorClass(m, "noticeList")
    styleUserSelect(m, "text")
    m.style.width = "100%"
    m.style.cursor = "text"
    m.style.paddingBottom = "4px"
    return m
}

function createInputForm(): HTMLFormElement {
    const form = document.createElement("form")
    applyStyles(form, {
        height: "100%",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        flex: 1,
        overflow: "hidden",
        position: "relative",
    })

    return form
}

function createPlaceholder(): HTMLSpanElement {
    const span = document.createElement("span")
    addColorClass(span, "placeholder")
    span.innerText = i18n.sendAMessage
    applyStyles(span, {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        pointerEvents: "none",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    })

    return span
}

function createInputDiv(): HTMLDivElement {
    const inputDiv = document.createElement("div")
    addColorClass(inputDiv, "inputDiv")
    applyStyles(inputDiv, {
        height: `${inputDivHeight()}px`,
        boxSizing: "border-box",
        position: "absolute",
        bottom: "0",
        cursor: "text",
        borderTopWidth: `${inputDivBorder}px`,
        borderTopStyle: "solid",
        zIndex: 1, // So MobilePlayer doesn't cover emoticon autocomplete
        display: "flex",
        alignItems: "center",
        padding: "8px",
    })

    return inputDiv
}

function createInputField(submitInput: () => boolean, maxLength: number): CustomInput {
    const customInput = new CustomInput(submitInput, maxLength)
    customInput.element.dataset.testid= "chat-input"
    const lineHeight = 24

    applyStyles(customInput, {
        boxSizing: "border-box",
        lineHeight: `${lineHeight}px`,
        minHeight: `${lineHeight}px`, // fix android bug where input may resize to 0px when using gboard
        fontSize: "16px",
        fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
        WebkitUserSelect: "text",
        height: "",
    })

    return customInput
}

function createInputControlsWrapper(): HTMLDivElement {
    const wrapper = document.createElement("div")
    wrapper.dataset.testid = "buy-box"
    applyStyles(wrapper, {
        display: "flex",
        alignItems: "center",
        marginLeft: "6px",
    })

    return wrapper
}
// endregion

export class ChatContents extends Component implements IChatContents, IMirrorableChatComponent {
    public addMessageHTMLEvent = new EventRouter<IHtmlCreateEvent>("addMessageHtml", { reportIfNoListeners: false })
    public addNoticeEvent = new EventRouter<IRoomNotice>("addNoticeEvent", { reportIfNoListeners: false })
    public addPhotoMessageEvent = new EventRouter<IPrivateMessage>("addPhotoMessageEvent", { reportIfNoListeners: false })
    public removeMessagesForUserEvent = new EventRouter<string>("removeMessageHtml", { reportIfNoListeners: false })
    public scrolledToBottom = new EventRouter<void>("scrolledToBottom")
    public messageList = createMessageList()
    private noticeList = createNoticeList()
    public messageListWrapper = createMessageListWrapper()
    public customInputField: CustomInput
    private inputForm: HTMLFormElement
    public inputDiv: HTMLDivElement
    private inputPlaceholder: HTMLSpanElement
    private inputControlsWrapper?: HTMLDivElement
    private actionButtonsContainer?: HTMLDivElement
    private sendButtonRoot: HTMLSpanElement
    private sendButton: ReactComponent
    private privateOverlay?: HTMLDivElement
    private isWatchingPrivate = false
    public rulesModal: RulesModal | undefined
    private inputFieldHasFocus = false
    private messageCounter = 0
    private emoticonAutocompleteModal: MobileEmoticonAutocompleteModal
    public userMentionAutocompleteModal: MobileUserMentionAutocompleteModal | undefined
    public mediaDockButton?: HTMLElement
    public mobileMediaDock: SelectedMobileMediaDock
    private listenerGroup = new ListenerGroup()
    private earliestMessageId: string | undefined
    protected currentRoomContext: IRoomContext
    protected autocompleteModalCollection: AutocompleteModal<IItem>[] = []
    private shortcodeAutocompleteModal?: MobileShortcodeAutocompleteModal
    private newMessageNotice: HTMLDivElement
    private scrollDownButton?: ScrollDownButton
    private scrollJumpNeeded = false

    constructor(protected outgoingHandlers: IOutgoingMessageHandlers, protected isPmChatContents = false) {
        super()

        addColorClass(this.element, "ChatContents")
        applyStyles(this.element, {
            position: "unset",
            wordWrap: "break-word",
        })

        this.messageListWrapper.appendChild(this.noticeList)
        this.messageListWrapper.appendChild(this.messageList)
        this.element.appendChild(this.messageListWrapper)

        if (isScrollDownNoticeActive()) {
            this.newMessageNotice = createNewMessageNoticeDiv()
            // Clear new notice line and show latest chat to avoid having different scroll & new line positions
            // when switching between theatre/fullscreen purechat and spit mode chat.
            videoModeHandler.changeVideoMode.listen(() => {
                this.newMessageNotice.remove()
                this.scrollToBottom()
            }).addTo(this.listenerGroup)

            // Also clear it when orientation is changed to avoid showing the scroll down button.
            screenOrientationChanged.listen(() => {
                this.newMessageNotice.remove()
                // Scroll to bottom is done inside MobileRoot's repositionDebouncer
            }).addTo(this.listenerGroup)

            this.scrollDownButton = new ScrollDownButton({
                scrollToBottom: () => this.scrollToBottom(),
                bottomStyle: () => `${this.totalInputHeight() + 4}px`,
            })
            this.addChild(this.scrollDownButton)
        }

        this.initInputUI()
        this.initPrivateOverlay()
        this.initEmoticonModal()
        this.initUserMentionModal()
        this.initShortcodeModal()
        this.initCssClasses()

        addEventListenerPoly("submit", this.inputForm, (ev) => {
            ev.preventDefault()
            this.customInputField.submit()
        })
        addEventListenerPoly("focus", this.customInputField.element, (ev) => {
            this.inputFieldHasFocus = true
            sendMessageInputFocus.fire(undefined)

            if (needsSafariInputFix()) {
                this.inputDiv.style.bottom = "50px"
                if (this.userMentionAutocompleteModal !== undefined) {
                    this.userMentionAutocompleteModal.element.style.bottom = `${inputDivHeight() + 50 - 3}px`
                }
            }
        })
        addEventListenerPoly("blur", this.customInputField.element, (ev) => {
            if (isChrome() && isiOS()) {
                // closing the keyboard manually with the "Done" button on ios chrome will blur for most
                // intents and purposes, but fail to bring up the keyboard the next time you tap the input
                this.customInputField.element.blur()
            }
            const isModalVisible = this.autocompleteModalCollection.some((modal) => modal.isVisible())

            if (isModalVisible && ev.cancelable) {
                ev.preventDefault()
                return
            }
            this.inputFieldHasFocus = false
            sendMessageInputBlur.fire(undefined)

            if (needsSafariInputFix()) {
                this.inputDiv.style.bottom = "0"
                if (this.userMentionAutocompleteModal !== undefined) {
                    this.userMentionAutocompleteModal.element.style.bottom = `${inputDivHeight() - 3}px`
                }
            }
        })

        let scrolling = false
        let wasScrolledUp = false
        const scrollStopDebouncer = new Debouncer(() => {scrolling = false}, { bounceLimitMS: 50, debounceType: DebounceTypes.debounce })

        addEventListenerPoly("scroll", this.messageListWrapper, (ev) => {
            if (!scrolling) {
                scrolling = true
                wasScrolledUp = this.isScrolledUp()
            }
            scrollStopDebouncer.callFunc()
            if (this.isScrolledUp()) {
                this.scrollDownButton?.showElement()
            } else {
                this.scrollDownButton?.hideElement()
                this.scrollDownButton?.clearUnread()
            }

            if (!this.isScrolledUp() && wasScrolledUp) {
                this.scrolledToBottom.fire()
                wasScrolledUp = false
            }
        })
        addEventListenerPoly("keyup", this.element, (ev: KeyboardEvent) => {
            if (ev.key === "Enter") {
                this.customInputField.blur()
            }
        })

        dossierLoaded.listen((dossier) => {
            this.rulesModal = setupRulesModal(this, dossier)
            if (this.rulesModal !== undefined) {
                this.addChild(this.rulesModal)
            }
        }).addTo(this.listenerGroup)

        roomLoaded.once(context => {
            this.currentRoomContext = context
            // this only needs to happen on roomLoaded once since it will be handled afterward in settingsListener
            this.messageListWrapper.style.fontSize = context.dossier.userChatSettings.fontSize
            this.setLineHeight()

            this.listenerGroup.add(updateUserMention.listen(() => {
                styleMobileMention(this.messageList)
            }))
        })

        const settingsListener = (userChatSettings: IUserChatSettings) => {
            this.messageListWrapper.style.fontSize = userChatSettings.fontSize
            this.setLineHeight()
        }
        userChatSettingsUpdate.listen(settingsListener).addTo(this.listenerGroup)
        roomCleanup.listen(() => {
            userChatSettingsUpdate.removeListener(settingsListener)
            this.autocompleteModalCollection.forEach(modal => modal.dispose())
            this.customInputField.clearText()
            this.scrollDownButton?.hideElement()
        }).addTo(this.listenerGroup)

        // fix weird iOS bug where input goes out of screen
        // when site menu is opened then closed
        siteHeaderMenuOpened.listen((isOpen) => {
            if (isOpen) {
                this.inputDiv.style.position = "absolute"
            } else {
                this.inputDiv.style.position = "fixed"
            }

            this.inputDiv.style.bottom = "0"
        }).addTo(this.listenerGroup)

        mobileMediaChanged.listen(() => {
            this.updateInputButtonHighlight()
        })

        showMobileMediaDock.listen((dockVisible) => {
            this.sendButton.update({ "highlight" : this.hasNonEmptyMessageDraft() })
        }).addTo(this.listenerGroup)

        repositionChatContentsOnInputFocus.listen(({ isInputFocused, playerTop }) => {
            this.repositionChatOnInputFocus(isInputFocused, playerTop)
        }).addTo(this.listenerGroup)
    }

    private hasNonEmptyMessageDraft(): boolean {
        return this.customInputField.getText().trim().length > 0 || this.hasMediaFiles()
    }

    private initInputUI(): void {
        // initialize references to input UI elements that are used in the
        // different construct methods
        this.inputForm = createInputForm()
        this.inputDiv = createInputDiv()
        const maxLength = this.isPmChatContents ? maxInputPm : maxInputChat
        this.customInputField = createInputField(() => {
            return this.sendMessageFromInput()
        }, maxLength)
        this.inputPlaceholder = createPlaceholder()
        this.sendButtonRoot = document.createElement("span")
        const SendButton = ReactComponentRegistry.get("SendButton")
        this.sendButton = new SendButton({
            "onClick": () => {
                if (this.isUploadInProgress()) {
                    return
                }
                addPageAction("MobileSendButtonClicked", {
                    "input": this.customInputField.getText(),
                })

                this.customInputField.submit()
                this.customInputField.blur()
            },
            "isPm": this.isPmChatContents,
            "variant": SendButtonVariant.MobileSplitMode,
            "hidden": true,
            "highlight": false,
        }, this.sendButtonRoot)

        fullscreenChange.listen(() => {
            // Fix google pixel bug where if you go fullscreen then exit fullscreen inputForm vanishes
            this.inputForm.style.display = "none"
            window.setTimeout(() => this.showInputForm(), 0)
        }).addTo(this.listenerGroup)

        this.constructInputUI()
    }

    private createMediaDockButton(): HTMLSpanElement {
        const span = document.createElement("span")
        span.style.cursor = "pointer"
        span.style.display = "inline-block"
        span.style.height = "26px"
        span.style.padding = "0 10px 0 5px"
        span.dataset["paction"] = "MobileChat"
        span.dataset["pactionName"] = "UploadPhoto"
        span.dataset["testid"] = "send-image-button"

        const img = document.createElement("img")
        img.src = `${STATIC_URL_ROOT}tsdefaultassets/mediaDock/uploadBackground-lighter.svg`
        img.style.width = "100%"
        img.style.height = "100%"
        span.appendChild(img)

        // Hide keyboard first and then open file selector on touchend to avoid the selector opening in the middle of the screen for iOS.
        if (isiOS()) {
            span.onpointerdown = (e) => {
                e.preventDefault()
                e.stopPropagation()
                if (document?.activeElement instanceof HTMLElement) {
                    document.activeElement.blur()
                }
            }
            span.onpointerup = () => {
                this.onMediaDockButtonClick()
            }
        } else {
            span.onclick = () => {
                this.onMediaDockButtonClick()
            }
        }
        return span
    }

    private constructInputUI(): void {
        addColorClass(this.inputForm, "inputForm")
        applyStyles(this.inputForm, {
            display: "flex",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
            paddingLeft: "8px",
        })

        this.inputControlsWrapper = createInputControlsWrapper()
        applyStyles(this.inputControlsWrapper, {
            margin: 0,
            height: "100%",
        })

        this.actionButtonsContainer = document.createElement("div")
        this.actionButtonsContainer.dataset.testid = "action-buttons-container"
        applyStyles(this.actionButtonsContainer, {
            height: "100%",
        })
        this.inputControlsWrapper.appendChild(this.actionButtonsContainer)

        const sendTipButton = new SendTipButton({
            style: {
                padding: "0px 16px",
                fontSize: "14px",
                minHeight: "",
                height: "100%",
                marginRight: 0,
                marginLeft: "8px",
            },
            tipButtonText: i18n.sendTipButtonText,
        })
        this.actionButtonsContainer.appendChild(sendTipButton.element)

        this.inputForm.appendChild(this.inputPlaceholder)
        this.inputForm.appendChild(this.customInputField.element)
        this.inputForm.appendChild(this.sendButtonRoot)

        if (this.isPmChatContents) {
            this.mediaDockButton = this.createMediaDockButton()
            this.inputDiv.appendChild(this.mediaDockButton)
        }
        this.inputDiv.appendChild(this.inputForm)
        this.inputDiv.appendChild(this.inputControlsWrapper)
        this.element.appendChild(this.inputDiv)

        this.bindInputObserver()
        this.sendButton.update({ "hidden": false })
        this.initToggleActionButtons()
    }

    private totalInputHeight(): number {
        return inputDivHeight() + (this.mobileMediaDock?.element.offsetHeight ?? 0)
    }

    private onMediaDockButtonClick(): void {
        addPageAction("PMPhotoButtonClicked")

        if (!isNotLoggedIn()) {
            if (this.rulesModal !== undefined) {
                this.rulesModal.show()
                return
            }
            this.mobileMediaDock?.showSelectDialog()
            this.scrollToBottom()
        }
    }

    public initMediaDock(mobileMediaDock: SelectedMobileMediaDock): void {
        this.mobileMediaDock = mobileMediaDock
        this.addMediaDockToDOM()
    }

    private addMediaDockToDOM(): void {
        if (this.mobileMediaDock !== undefined) {
            this.element.insertBefore(this.mobileMediaDock.element, this.inputDiv)
        }
    }

    private initShortcodeModal(): void {
        if (this.isPmChatContents) {
            return
        }

        this.shortcodeAutocompleteModal = new MobileShortcodeAutocompleteModal(this.customInputField, this.isPmChatContents)
        this.inputDiv.appendChild(this.shortcodeAutocompleteModal.element)
        this.shortcodeAutocompleteModal.afterDOMConstructedIncludingChildren()

        this.autocompleteModalCollection.push(this.shortcodeAutocompleteModal)
    }

    private initEmoticonModal(): void {
        this.emoticonAutocompleteModal = new MobileEmoticonAutocompleteModal(this.customInputField)
        this.inputDiv.appendChild(this.emoticonAutocompleteModal.element)
        this.emoticonAutocompleteModal.afterDOMConstructedIncludingChildren()

        this.autocompleteModalCollection.push(this.emoticonAutocompleteModal)
    }

    private initUserMentionModal(): void {
        if (this.isPmChatContents) {
            return
        }

        this.userMentionAutocompleteModal = this.addChild(new MobileUserMentionAutocompleteModal({
            inputElement: this.customInputField,
            leftOffset: 10,
            rightOffset: 167,
            inputDivHeight: inputDivHeight(),
        }))
    }

    private initCssClasses(): void {
        if (this.isPmChatContents) {
            this.element.classList.add("MobileChatDivPm")
            this.element.dataset.testid ="pm-chat-base"
            this.customInputField.element.classList.add("mobileInputFieldPm")
            this.emoticonAutocompleteModal.element.classList.add("mobileEmoticonAutocompleteModalPm")
        } else {
            this.element.classList.add("MobileChatDivChat")
            this.element.dataset.testid = "chat-base"
            this.customInputField.element.classList.add("mobileInputFieldChat")
            this.emoticonAutocompleteModal.element.classList.add("mobileEmoticonAutocompleteModalChat")
        }
        this.emoticonAutocompleteModal.element.dataset.testid = "emoticon-autocomplete-modal"
    }

    private toggleActionButtons(): void {
        if (this.inputFieldHasFocus) {
            if (this.actionButtonsContainer !== undefined) {
                this.actionButtonsContainer.style.display = "none"
            }
        } else {
            if (this.actionButtonsContainer !== undefined) {
                this.actionButtonsContainer.style.display = "flex"
            }
        }
    }

    private initToggleActionButtons(): void {
        sendMessageInputFocus.listen(() => {
            this.toggleActionButtons()
        }).addTo(this.listenerGroup)

        sendMessageInputBlur.listen(() => {
            // timeout because the quick display changes causes the send button click
            // to sometimes register as a request private button click
            window.setTimeout(() => {
                this.toggleActionButtons()
            }, 200)
        }).addTo(this.listenerGroup)
    }

    private bindInputObserver(): void {
        // watch for input changes coming from both the user and `CustomInput` input mutation methods
        // that don't fire a regular `input` event listener
        const observer = new MutationObserver((mutationList) => {
            mutationList.forEach(() => {
                this.updateInputButtonHighlight()

                const inputText = this.customInputField.getText()

                if (inputText.length > 0) {
                    this.inputPlaceholder.style.display = "none"
                } else {
                    this.inputPlaceholder.style.display = "block"
                }
            })
        })

        observer.observe(this.customInputField.element, {
            characterData: true,
            subtree: true,
            childList: true,
        })
    }

    private updateInputButtonHighlight() {
        const inputText = this.customInputField.getText()

        if (inputText.length > 0) {
            this.inputPlaceholder.style.display = "none"
        } else {
            this.inputPlaceholder.style.display = "block"
        }
        const highlight = (inputText.trim().length > 0 || this.hasMediaFiles()) && !this.isUploadInProgress()
        this.sendButton.update({ "highlight": highlight })
    }

    private isUploadInProgress(): boolean {
        return this.mobileMediaDock?.isUploading() ?? false
    }

    private showInputForm(): void {
        this.inputForm.style.display = "flex"
    }

    private setLineHeight(): void {
        const lineHeight = Number(this.messageListWrapper.style.fontSize.slice(0, -2))
        this.messageListWrapper.style.lineHeight = `${lineHeight + 7}pt`
    }

    protected shouldSendMessageFromInput(): boolean {
        if (isNotLoggedIn(i18n.loggedInToSendAMessage)) {
            return false
        }
        if (this.isWatchingPrivate && !this.isPmChatContents) {
            modalAlert(`${i18n.privateShowChatActive} ${i18n.goToPrivateTabToChat}`)
            return false
        }
        return true
    }

    // Don't call this directly, go through this.inputField.submit() instead
    protected sendMessageFromInput(): boolean {
        if (!this.shouldSendMessageFromInput()) {
            return false
        }
        this.scrollToBottom()

        if (this.hasNonEmptyMessageDraft()) {
            this.processMessage(this.customInputField.getText())
        }
        return true
    }

    private initPrivateOverlay(): void {
        if (this.isPmChatContents) {
            return
        }

        this.privateOverlay = document.createElement("div")
        addColorClass(this.privateOverlay, "privateOverlay")
        applyStyles(this.privateOverlay, {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
            display: "none",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "15px",
            boxSizing: "border-box",
        })
        this.element.appendChild(this.privateOverlay)
    }

    public showPrivateOverlay(username: string, onOverlayClick: () => void): void {
        if (this.isPmChatContents || this.privateOverlay === undefined) {
            return
        }

        this.isWatchingPrivate = true
        this.privateOverlay.innerText = `${i18n.privateShowChatActive} ${username !== "" ? i18n.tapToChatWithUser(username) : i18n.tapToChatWithBroadcaster}`
        this.privateOverlay.onclick = onOverlayClick
        this.privateOverlay.style.display = "flex"
    }

    public hidePrivateOverlay(): void {
        if (this.isPmChatContents || this.privateOverlay === undefined) {
            return
        }

        this.isWatchingPrivate = false
        this.privateOverlay.style.display = "none"
    }

    protected shortcodeErrorMsg(shortcodeMessage: IShortcodeMessage, raw: string): string | undefined {
        if (this.isPmChatContents) {
            return i18n.shortcodeNotSupportedInPMs
        } else if (roomDossierContext.getState().privateShowId !== "") {
            return i18n.shortcodeNotSupportedInPrivates
        } else if (shortcodeMessage.shortcodes.length === 0) {
            // Recognized as shortcode syntax, but does not contain valid shortcodes
            return ShortcodeParser.errorBehindShortcode(raw)
        }
    }

    protected processMessage(val: string): void {
        const outgoingMessage = parseOutgoingMessage(val)
        switch (outgoingMessage.messageType) {
            case OutgoingMessageType.Shortcode:
                const shortcode = outgoingMessage as IShortcodeMessage
                const errorMsg = this.shortcodeErrorMsg(shortcode, val)
                if (errorMsg !== undefined) {
                    this.appendMessageDiv(createLogMessage(errorMsg))
                } else if (this.outgoingHandlers.onShortcode) {
                    this.outgoingHandlers.onShortcode(shortcode)
                }
                break
            case OutgoingMessageType.ToggleDebugMode:
                this.outgoingHandlers.onToggleDebugMode()
                break
            case OutgoingMessageType.TipRequest:
                // `clearText` gets called in CustomInput.submit() but clear it early
                // here so the one in CustomInput doesn't mess up tip callout input focus
                this.customInputField.clearText()
                const tipMessage = outgoingMessage as ITipRequestMessage
                this.outgoingHandlers.onTipRequest(tipMessage.messageData)
                break
            default:
                this.outgoingHandlers.onChatMessage(val)
                break
        }
    }

    public repositionChatOnInputFocus(isInputFocused: boolean, playerTop?: number): void {
        // helper method to position chat above player when input is focused and the mobile keyboard is open
        const vp = visualViewport
        if (vp === null) {
            return
        }
        const scrollTop = this.messageListWrapper.scrollTop
        if (isInputFocused) {
            if (playerTop === undefined) {
                error("playerTop cannot be undefined when input is focused.")
                return
            }
            const oldHeight = this.messageListWrapper.offsetHeight
            this.messageListWrapper.style.position = "fixed"
            this.messageListWrapper.style.top = ""
            this.messageListWrapper.style.bottom = `${playerTop}px`
            this.messageListWrapper.style.height = `${vp.height - playerTop}px`
            // sync scroll position to bottom when shrinking
            this.messageListWrapper.scrollTop = scrollTop + (oldHeight - this.messageListWrapper.offsetHeight)
        } else {
            this.messageListWrapper.style.position = ""
            this.messageListWrapper.style.top = ""
            this.messageListWrapper.style.bottom = ""
            // messageListWrapper height is restored in repositionChildren calls
        }
    }

    protected repositionChildren(): void {
        if (this.inputFieldHasFocus && !isPortrait()) {
            this.customInputField.blur()
        }

        this.inputDiv.style.width = `${getViewportWidth()}px`
        this.emoticonAutocompleteModal.element.style.bottom = `${this.inputDiv.offsetHeight - 8}px`
        if (this.shortcodeAutocompleteModal !== undefined) {
            this.shortcodeAutocompleteModal.element.style.bottom = `${this.inputDiv.offsetHeight - 8}px`
        }

        if (!this.isScrolledUp()) {
            this.scrollToBottom()
        }
    }

    public isScrolledUp(): boolean {
        return this.messageListWrapper.scrollTop <= this.messageListWrapper.scrollHeight - (this.messageListWrapper.offsetHeight + 20)
    }

    public scrollToBottom(): void {
        const wasScrolledUp = this.isScrolledUp()

        if (isScrollDownNoticeActive()) {
            stopScrollMomentum(this.messageListWrapper)
        }
        this.messageListWrapper.scrollTop = this.messageListWrapper.scrollHeight

        this.scrollDownButton?.hideElement()

        if (wasScrolledUp) {
            this.scrolledToBottom.fire()
        }
    }

    public getScrollTop(): number {
        return this.messageListWrapper.scrollTop
    }

    public setScrollTop(top: number): void {
        this.messageListWrapper.scrollTo({ top: top })
    }

    // Assumes original and clone have identical structure
    private cloneClickListeners(original: HTMLElement, clone: HTMLElement): void {
        for (let i = 0; i < original.children.length; i += 1) {
            this.cloneClickListeners((original.children[i] as HTMLElement), (clone.children[i] as HTMLElement))
        }
        clone.onclick = original.onclick
    }

    private toBottom: EventHandler = () => { this.scrollToBottom() }

    public appendNoticeDiv(c: HTMLDivElement): HTMLDivElement {
        c.style.fontSize = ""
        this.noticeList.appendChild(c)
        return c
    }

    public appendMessageDiv(message: HTMLDivElement): HTMLDivElement {
        const publishToPureChat = () => {
            this.addMessageHTMLEvent.fire({
                makeByCloning: () => {
                    // we know it's a DIV because we just created it
                    const clone = message.cloneNode(true) as HTMLDivElement
                    this.cloneClickListeners(message, clone)
                    return clone
                },
            })
        }
        return this.appendMessage(message, publishToPureChat)
    }

    public appendNoticeMessage(notice: RoomNotice, countsForUnread = true): void {
        const publishToPureChat = () => {
            this.addNoticeEvent.fire(notice.roomNoticeData)
        }
        this.appendMessage(notice.element, publishToPureChat, countsForUnread)
    }

    public appendPhotoMessage(message: HTMLDivElement, photoMessageData: IPrivateMessage): void {
        const publishToPureChat = () => {
            this.addPhotoMessageEvent.fire(photoMessageData)
        }
        this.appendMessage(message, publishToPureChat, false)
    }

    private appendMessage(c: HTMLDivElement, publishToPureChat: () => void, countsForUnread = true): HTMLDivElement {
        const oldScrollTop = this.messageListWrapper.scrollTop
        const wasScrolledUp = this.isScrolledUp()
        if (!wasScrolledUp) {
            c.querySelectorAll("img").forEach((img) => {
                const src = img.src
                img.src = ""
                img.onload = this.toBottom
                img.src = src
            })
        } else if (countsForUnread) {
            this.scrollDownButton?.incUnread()
            this.maybeAppendNewMessageNotice()
        }

        c.style.fontSize = ""
        c.style.lineHeight = ""
        insertByTimestamp(c, this.messageList)
        let overflow = this.messageList.childElementCount - maxMessageHistory
        for (; overflow > 0 ; overflow -= 1) {
            const firstNode = this.messageList.firstElementChild
            if (firstNode !== null) {
                this.messageList.removeChild(firstNode)
            }
        }
        if (!wasScrolledUp) {
            this.scrollToBottom()
        } else if (this.scrollJumpNeeded) {
            // If new message notice line is present above current current scroll view, 
            // removing the previous line and inserting it DOM again causes the chat to jump.
            // Need to subtract the new notice's height from original scrollTop to maintain chat position.
            this.setScrollTop(oldScrollTop - this.newMessageNotice.offsetHeight)
            this.scrollJumpNeeded = false
        }
        publishToPureChat()
        this.messageCounter += 1
        return c
    }

    private maybeAppendNewMessageNotice(): void {
        if (isScrollDownNoticeActive()) {
            // Reposition the newline for the first unread message after the user reaches the bottom(reads all unread messages)
            // and the user scrolls up and the newline goes out of visible area
            if (this.isScrolledUp() && this.scrollDownButton?.getUnreadCount() === 1) {
                if (!isScrolledIntoView(this.newMessageNotice, this.messageListWrapper)) {
                    if (this.messageListWrapper.contains(this.newMessageNotice)) {
                        this.scrollJumpNeeded = this.newMessageNotice.offsetTop < this.messageListWrapper.scrollTop
                    }
                    insertByTimestamp(this.newMessageNotice, this.messageList)
                }
            }
        }
    }

    public removeMessageDiv(c: HTMLDivElement): void {
        this.messageList.removeChild(c)
    }

    public getLastMessageId(): number {
        return this.messageCounter
    }

    public getEarliestMessageId(): string | undefined {
        return this.earliestMessageId
    }

    public setEarliestMessageId(newMessageId: string): void {
        this.earliestMessageId = newMessageId
    }

    public messagesSinceId(id: number): number {
        return this.messageCounter - id
    }

    private hasMediaFiles(): boolean {
        return this.mobileMediaDock !== undefined && !this.mobileMediaDock.isEmpty()
    }

    public handleRemoveMessages(u: string): void {
        const removeList: HTMLElement[] = []
        for (const div of this.messageList.childNodes) {
            const casted = div as HTMLElement
            if (casted.getAttribute("data-nick") === u) {
                removeList.push(casted)
            }
        }
        for (const msg of removeList) {
            this.messageList.removeChild(msg)
        }
        this.removeMessagesForUserEvent.fire(u)
    }

    public clear(): void {
        this.messageCounter = 0
        while (this.messageList.firstChild !== null) {
            this.messageList.removeChild(this.messageList.firstChild)
        }
    }

    public dispose(): void {
        this.emoticonAutocompleteModal.dispose()
        this.listenerGroup.removeAll()
        this.customInputField.dispose()
        this.rulesModal?.dispose()
        this.userMentionAutocompleteModal?.dispose()
        this.mobileMediaDock?.dispose()
        this.shortcodeAutocompleteModal?.dispose()
    }
}
