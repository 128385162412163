import { addColorClass, removeColorClass } from "../../../cb/colorClasses"
import { DjangoForm } from "../../../cb/ui/djangoForm"
import { CustomField } from "../../../cb/ui/fields"
import { FieldStyles } from "../../../cb/ui/fieldStyles"
import { normalizeResource } from "../../api"
import { Gender } from "../../genders"
import { i18n } from "../../translation"
import { largePopupLinkFeatures, safeWindowOpen } from "../../windowUtils"
import type { IDjangoFormData, IPartialDjangoFormOptions } from "../../../cb/ui/djangoForm";

export class EditBroadcastBioForm extends DjangoForm {
    constructor(data: IDjangoFormData, endpoint: string, options: IPartialDjangoFormOptions, private roomName: string,
                private canUpload: boolean) {
        super(data, endpoint, {
            ...options,
            styles: {
                field: new EditBroadcastBioFieldStyles(),
                input: new EditBroadcastBioFieldStyles(),
                select: new EditBroadcastBioSelectFieldStyles(),
                multipleSelect: new EditBroadcastBioCheckboxFieldStyles(),
            },
        })
        this.element.style.marginLeft = "50px"
        const genderSelect = this.getField("gender")
        const subgenderSelect = this.getField("subgender")

        const makeSubgenderVisibileOnTrans = (genderInput: HTMLSelectElement) => {
            if (subgenderSelect !== undefined) {
                    const subgenderInput = subgenderSelect.getWidget() as HTMLSelectElement
                    if (genderInput.value === Gender.OldTrans || genderInput.value === Gender.Trans) {
                        subgenderInput.style.display = "inline"
                    } else {
                        subgenderInput.style.display = "none"
                        subgenderInput.value = ""
                    }
                }
        }

        this.placeSubgenderField()

        if (genderSelect !== undefined) {
            const genderInput = genderSelect.getWidget() as HTMLSelectElement
            makeSubgenderVisibileOnTrans(genderInput)
            genderSelect.setOnChange(() => {
                if (genderInput.value === Gender.Couple) {
                    genderSelect.updateLabelText(i18n.sexTextCouple)
                } else {
                    genderSelect.updateLabelText(i18n.sexText)
                }
                makeSubgenderVisibileOnTrans(genderInput)
            })
        }
        this.addField(
            new CustomField({
                name: "pics_and_videos",
                labelText: i18n.picsAndVideosText,
                customElement: this.createPicsAndVideos(),
            }),
        )
        this.setSubmitText(i18n.updateBio)
        this.getSubmitButton().dataset.testid = "update-bio-button"
        this.setTestIds()
    }

    private setTestIds() {
        const testIds: Record<string, string> = {
            "real_name": "bio-tab-real-name",
            "gender": "bio-tab-gender",
            "subgender": "bio-tab-subgender",
            "location": "bio-tab-location",
            "spoken_languages": "bio-tab-spoken-languages",
            "body_type": "bio-tab-body-type",
            "smoke_drink": "bio-tab-smoke-drink",
            "body_decorations": "bio-tab-body-decorations",
            "about_me": "bio-tab-about-me",
            "wish_list": "bio-tab-wish-list",
        }
        const interestedInCheckbox: HTMLCollection = this.getField("interested_in")!.getField().getElementsByClassName("checkboxFieldLabel")
        for (const elem in testIds) {
            const field = this.getField(elem)
            if (field !== undefined) {
                field.getWidget().dataset.testid = testIds[elem]
            }
        }

        for(const elem of interestedInCheckbox){
            // Set testid of div containing input
            const div = elem.children[0] as HTMLElement
            div.dataset.testid = `bio-tab-interested-in-${elem.lastChild?.nodeValue?.toLowerCase()}-container`

            // Set testid of input
            const checkbox = elem.children[0].children[0] as HTMLElement
            checkbox.dataset.testid = `bio-tab-interested-in-${elem.lastChild?.nodeValue?.toLowerCase()}`
        }

        this.getField("spoken_languages_strict")?.getWidgetContainer().querySelectorAll(".checkboxFieldLabel").forEach(elem => {
            // Set testid of div containing input
            const div = elem.children[0] as HTMLElement
            const checkbox = div.children[0] as HTMLInputElement
            div.dataset.testid = `bio-tab-spoken-languages-${checkbox.value}-container`
        })
    }

    private placeSubgenderField(): void {
        const genderSelect = this.getField("gender")
        const subgenderSelect = this.getField("subgender")

        if (genderSelect !== undefined && subgenderSelect !== undefined) {
            const genderDataCell = genderSelect.getField().querySelector("td")
            const subgenderDataCell = subgenderSelect.getField().querySelector("td")

            if (genderDataCell !== null && subgenderDataCell !== null) {
                subgenderDataCell.childNodes.forEach(node => genderDataCell.appendChild(node))
            }
        }
    }

    private createPicsAndVideos(): HTMLDivElement {
        const container = document.createElement("div")
        container.style.paddingTop = "9px"

        const uploadNewPics = document.createElement("a")
        const uploadNewVideos = document.createElement("a")
        const managePhotoSets = document.createElement("a")

        uploadNewPics.innerText = i18n.uploadNewPics
        uploadNewPics.href = normalizeResource("/photo_videos/photoset/create/")
        uploadNewPics.onclick = (event: Event) => {
            event.preventDefault()
            safeWindowOpen(uploadNewPics.href, "_blank", largePopupLinkFeatures)
        }
        uploadNewPics.dataset.testid="upload-new-pics"
        uploadNewVideos.innerText = i18n.uploadNewVideos
        uploadNewVideos.href = normalizeResource("/photo_videos/photoset/create/video/")
        uploadNewVideos.target = "_blank"
        uploadNewVideos.onclick = (event: Event) => {
            event.preventDefault()
            safeWindowOpen(uploadNewVideos.href, "_blank", largePopupLinkFeatures)
        }
        uploadNewVideos.dataset.testid="upload-new-videos"
        managePhotoSets.innerText = i18n.managePhotoSet
        managePhotoSets.href = normalizeResource(`/photo_videos/photoset/list_popup/${this.roomName}/`)
        managePhotoSets.onclick = (event: Event) => {
            event.preventDefault()
            safeWindowOpen(managePhotoSets.href, "_blank", largePopupLinkFeatures)
        }
        managePhotoSets.dataset.testid="manage-photo-sets"

        if (this.canUpload) {
            container.appendChild(uploadNewPics)
            container.appendChild(this.createSeparator())
            container.appendChild(uploadNewVideos)
        } else {
            const mustBeVerifiedorTokens = document.createElement("span")
            mustBeVerifiedorTokens.style.fontSize = "14px"
            mustBeVerifiedorTokens.style.color = "black"
            mustBeVerifiedorTokens.innerHTML = i18n.mustBeAgeVerifiedToUploadContent() // eslint-disable-line @multimediallc/no-inner-html
            container.appendChild(mustBeVerifiedorTokens)
        }
        container.appendChild(this.createSeparator())
        container.appendChild(managePhotoSets)
        return container
    }

    private createSeparator(): HTMLSpanElement {
        const separator = document.createElement("span")
        addColorClass(separator, "separator")
        separator.style.fontSize = "12px"
        separator.innerText = " | "
        return separator
    }
}

class EditBroadcastBioFieldStyles extends FieldStyles {
    public styleField(field: HTMLElement): void {
        super.styleField(field)
        removeColorClass(field, "field")
        addColorClass(field, "fieldInput")
    }
}

class EditBroadcastBioSelectFieldStyles extends EditBroadcastBioFieldStyles {
    public styleField(field: HTMLElement): void {
        super.styleField(field)
        field.style.width = ""
    }
}

class EditBroadcastBioCheckboxFieldStyles extends EditBroadcastBioFieldStyles {
    public styleField(field: HTMLElement): void {
        addColorClass(field, "fieldInput")
    }
}
