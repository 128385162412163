import { i18n } from "../../../common/translation"
import { BaseSuggestedItem } from "./baseSuggestedItem"

export class OfflineSuggestedItem extends BaseSuggestedItem {
    protected getIconClassName(): string {
        return "icon-offline"
    }

    protected getStatusText(): string {
        return i18n.recentlyOnline
    }

    protected getRowClassName(): string {
        return "broadcaster_suggestion"
    }
}