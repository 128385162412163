import { roomLoaded } from "../../../common/context"
import { isRecommendedFollowRoomsActive } from "../../../common/featureFlagUtil"
import { loadRoomRequest } from "../../../common/fullvideolib/userActionEvents"
import { pageContext } from "../../interfaces/context"
import { UserFollowerTopic } from "../../pushservicelib/topics/user"
import { resizeDebounceEvent } from "../../ui/responsiveUtil"
import {
    clearLocalStorageUnseen,
    followedDropdownClickedEvent,
    getOnlineFollowed,
    getOnlineFollowedStorage,
    newFollowedOnlineEvent,
    onlineFollowedStorageEvent,
    setDropdownClickedStorage,
    setOnlineFollowedStorage,
    updateStorageTimestamp,
    watchOnlineFollowedStorage,
} from "./follow"
import { RoomElement } from "./followedDropdown"
import { getFollowTabRecommendations } from "./followRecommendedData"
import type {
    IFollowedRoom,
    IOnlineFollowed,
    IOnlineFollowedList,
    IOnlineFollowedStorage,
} from "./follow"
import type { FollowedDropdown, INewRooms } from "./followedDropdown"
import type { FollowedTab } from "./followedTab"
import type { IFollowerTopic } from "../../../common/messageInterfaces"

const DISABLE_RESIZE_DELAY_MS = 300


export class FollowedOptions {
    dropdown: FollowedDropdown
    username: string
    isAnonymous: boolean
    updateFromCBCallback: (unseen: INewRooms) => void
    updateViaStorageCallback: (ev: IOnlineFollowedStorage, ignoreFlash: boolean) => void
    followedDropdownClickedCallback: () => void
    followedTab: FollowedTab
}

export class FollowedData {
    private static _instance = new FollowedData()
    private first = true
    private currentShownRooms: RoomElement[] = []
    private currentRecommendations: RoomElement[] = []
    private lastUnseen: IFollowedRoom[] = []
    private seenRooms: Record<string, boolean> = {}
    private forceSeen: Record<string, boolean> = {}
    private onlineFollowed: IOnlineFollowed = { online: 0, total: 0 }
    private lastUpdate: number
    private updateInterval: number | undefined
    private currentOptions: FollowedOptions
    private disableResizeDebounce: boolean

    constructor() {
    }

    public static getInstance(): FollowedData {
        return FollowedData._instance
    }

    public init(options: FollowedOptions): void {
        this.currentOptions = options

        loadRoomRequest.listen(() => {
            this.currentOptions.dropdown.hideElement()
        })
        roomLoaded.listen((context) => {
            this.currentOptions.dropdown.replaceRooms()
            this.setSeen([context.dossier.room]) // Moved from FollowedTab
        })
        resizeDebounceEvent.listen(() => {
            if (this.currentOptions.dropdown.isShown()) {
                // fix for Samsung keyboard firing the resize event on opening and closing
                if (!this.disableResizeDebounce) {
                    this.currentOptions.dropdown.hideElement()
                }
            }
        })

        this.currentOptions.dropdown.toggleEvent.listen(() => {
            if (this.currentOptions.dropdown.isShown()) {
                this.disableResizeDebounceEvent()
            }
        })

        this.currentOptions.dropdown.replaceRooms()

        if (!this.currentOptions.isAnonymous) {
            watchOnlineFollowedStorage()

            onlineFollowedStorageEvent.listen((ev: IOnlineFollowedStorage) => {
                this.updateViaStorageCallback(ev, false, this.currentOptions.username, this.currentOptions.updateViaStorageCallback)
            })

            followedDropdownClickedEvent.listen((ev: IOnlineFollowedStorage) => {
                this.updateViaStorageCallback(ev, false, undefined, this.currentOptions.followedDropdownClickedCallback)
            })

            const isFollowedCams = window.location.pathname.indexOf("/followed-cams/", 0) === 0
            const storedUpdates = (isFollowedCams) ? undefined : getOnlineFollowedStorage()

            // Force reset of localStorage updates if starting on followed cams page.
            // If this is true, another tab is open and already fetching followed updates from CB
            // This can be simplified when followed updates come from push service
            if (storedUpdates !== undefined && storedUpdates.username === this.currentOptions.username && Date.now() - storedUpdates.timestamp < 30000) {
                this.notFirst()
                this.updateViaStorageCallback(storedUpdates, true, this.currentOptions.username, this.currentOptions.updateViaStorageCallback)
                updateStorageTimestamp()
                this.getUpdatesFromCB(true, this.currentOptions.username, this.currentOptions.updateFromCBCallback)
            } else {
                this.getUpdatesFromCB(false, this.currentOptions.username, this.currentOptions.updateFromCBCallback)
            }

            // Slows down fetching updates over time.
            // This can be simplified when followed updates come from push service
            const setUpdateInterval = (timeout: number) => {
                clearInterval(this.updateInterval)
                this.updateInterval = window.setInterval(() => {
                    if (!this.currentOptions.dropdown.isShown()) {
                        this.getUpdatesFromCB(false, this.currentOptions.username, this.currentOptions.updateFromCBCallback)
                    }
                }, timeout)
            }
            setUpdateInterval(20 * 1000)
            window.setTimeout(() => {
                setUpdateInterval(60 * 1000)
            }, 10 * 60 * 1000)
            window.setTimeout(() => {
                setUpdateInterval(20 * 60 * 1000)
            }, 30 * 60 * 1000)
            window.setTimeout(() => {
                clearInterval(this.updateInterval)
            }, 6 * 60 * 60 * 1000)

            newFollowedOnlineEvent.listen(() => {
                this.currentOptions.followedTab.flash()
            })
            const loggedInUser = pageContext.current.loggedInUser
            const userUid = loggedInUser?.userUid ?? ""
            new UserFollowerTopic(userUid).onMessage.listen((data: IFollowerTopic) => {
                if (data.followerUsername !== loggedInUser?.username) {
                    return
                }
                this.updateFollowedTab(data.followedUsername)
            })
        } else {
            this.currentOptions.dropdown.replaceRooms()
        }
    }

    public getSeenRooms(): Record<string, boolean> {
        return this.seenRooms
    }

    public getlastUnseen(): IFollowedRoom[] {
        return this.lastUnseen
    }

    public getforceSeen(): Record<string, boolean> {
        return this.forceSeen
    }

    public getOnlineFollowed(): IOnlineFollowed {
        return this.onlineFollowed
    }

    public setOnlineFollowed(onlineFollowed: IOnlineFollowed): void {
        this.onlineFollowed = onlineFollowed
    }

    public setSeenRoom(slug: string): void {
        this.seenRooms[slug] = true
    }

    public setForceSeen(slug: string): void {
        this.forceSeen[slug] = true
    }

    public getCurrentShownRooms(): RoomElement[] {
        return this.currentShownRooms
    }

    public notFirst(): void {
        this.first = false
    }

    public resetLastUnseen(): void {
        this.lastUnseen = []
    }

    public setSeen(slugs: string[]): void {
        for (const slug of slugs) {
            this.setSeenRoom(slug)
            this.setForceSeen(slug)
        }
    }

    public setDropdownViewed(): void {
        clearLocalStorageUnseen()
        setDropdownClickedStorage()
    }

    public updateViaStorageCallback(ev: IOnlineFollowedStorage, ignoreFlash = false,
                                    username?: string,
                                    callback?: (ev: IOnlineFollowedStorage, ignoreFlash: boolean) => void): void {
        if (ev.timestamp <= this.lastUpdate || ev.username !== username) {
            return
        }

        this.onlineFollowed = ev.onlineFollowedList.onlineFollowed
        this.seenRooms = ev.seenRooms
        this.lastUnseen = ev.unseenRooms
        this.update(ev.onlineFollowedList, true)

        if (callback !== undefined) {
            callback(ev, ignoreFlash)
        }

        this.lastUpdate = ev.timestamp
    }

    update(res: IOnlineFollowedList, ignoreFlash = false): INewRooms { // eslint-disable-line complexity
        const allRooms: Record<string, IFollowedRoom> = {}
        const unseenRooms: IFollowedRoom[] = []
        const seenRooms: IFollowedRoom[] = []
        for (const room of res.roomList) {
            if (this.seenRooms[room.room] === undefined && !this.first) {
                unseenRooms.push(room)
            } else {
                seenRooms.push(room)
            }
            this.seenRooms[room.room] = true
            allRooms[room.room] = room
        }
        const newUnseen = unseenRooms.length > 0
        this.lastUnseen = this.lastUnseen.reduce((acc: IFollowedRoom[], currVal) => {
            if (allRooms[currVal.room] !== undefined && !this.forceSeen[currVal.room]) {
                acc.push(allRooms[currVal.room])
            }
            return acc
        }, [])
        for (const room of unseenRooms) {
            this.lastUnseen.push(room)
        }
        const shownNames: Record<string, boolean> = {}
        this.currentShownRooms = []
        for (const room of this.lastUnseen) {
            shownNames[room.room] = true
            this.currentShownRooms.push(new RoomElement(room, true, this.currentShownRooms.length + 1))
        }
        for (const room of seenRooms) {
            if (shownNames[room.room] !== undefined) {
                continue
            }
            this.currentShownRooms.push(new RoomElement(room, false, this.currentShownRooms.length + 1))
        }

        this.currentOptions.dropdown.replaceRooms()

        const unseenNotFirst = !this.first && newUnseen
        if (unseenNotFirst && !ignoreFlash) {
            newFollowedOnlineEvent.fire(undefined)
        }
        this.first = false
        return {
            flash: unseenNotFirst,
            rooms: this.lastUnseen,
        }
    }

    public getUpdatesFromCB(force = false, username: string, callback: (unseen: INewRooms) => void): void {
        if (!force && Date.now() - this.lastUpdate < 10000) {
            return
        }
        getOnlineFollowed().then((res) => {
            this.lastUpdate = Date.now()
            this.onlineFollowed = res.onlineFollowed
            const unseen = this.update(res)
            callback(unseen)
            setOnlineFollowedStorage(username, res, this.seenRooms, unseen.flash, unseen.rooms)
        }).catch(() => {})
        this.updateRecommendations()
    }

    public getCurrentRecommendations(): RoomElement[] {
        return this.currentRecommendations
    }

    private updateRecommendations(): void {
        if (isRecommendedFollowRoomsActive()) {
            getFollowTabRecommendations().then((room_elements) => {
                this.currentRecommendations = room_elements
            }).catch(()=>{})
        }
    }

    private updateFollowedTab(roomName: string): void {
        this.setSeen([roomName])
        this.getUpdatesFromCB(true, this.currentOptions.username, this.currentOptions.updateFromCBCallback)
    }

    private disableResizeDebounceEvent(): void {
        this.disableResizeDebounce = true
        window.setTimeout(() => {
            this.disableResizeDebounce = false
        }, DISABLE_RESIZE_DELAY_MS)
    }
}
