import { isHomepageFiltersActive } from "@multimediallc/cb-roomlist-prefetch"
import { addPageAction } from "../../../common/newrelic"
import { cacheHomepageFilters } from "../../components/roomlist/filters/homepageFiltersUtil"
import { spaNavigation } from "../../components/roomlist/spaHelpers"
import { BaseSuggestedItem } from "./baseSuggestedItem"
import { ResultType } from "./inputSuggestions"
import { saveSearchHistory } from "./searchHistory"
import type { ISuggestionProps } from "./baseSuggestedItem"

export class HashtagSuggestedItem extends BaseSuggestedItem {
    protected getIconClassName(): string {
        return "icon-hashtag"
    }

    protected getStatusText(): string {
        return ""
    }

    protected getRowClassName(): string {
        return "hashtag_suggestion"
    }

    protected onClickHandler(props: ISuggestionProps, evt?: MouseEvent): void {
        const hashtag = `#${props.labelText}`
        saveSearchHistory(hashtag, ResultType.Hashtag)
        const attributes = { "resultType": "hashtag", "resultString": hashtag }
        addPageAction("SearchBarResultsClick", attributes)
        if (evt !== undefined) {
            spaNavigation.fire(evt)
            if (isHomepageFiltersActive()) {
                cacheHomepageFilters()
            }
        }
    }
}
