import { ArgJSONMap, type NewrelicAttributes } from "@multimediallc/web-utils"
import { unloadEventName } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { EventRouter } from "../../../common/events"
import { PushServiceClient,
    resolveConnectionState, resolveSubscriptionState,
} from "../baseClient"
import { ConnectionState, ErrorCode, SubscriptionState } from "../states"
import {
    AblyAuthProvider, AuthCapabilityType,
    normalizeRejection, resolveErrorInfo,
} from "./index"
import type { IAblyContextSettings } from "./index"
import type {
    IClientCallbacks, IDuplicateMessageMeta,
    IPushContextSettings } from "../baseClient"
import type { IConnectionStateChange } from "../states"
import type { IPushPresencePayload } from "../topics/topicManager"

export const ABLY_CLIENT_NAME = "a"

const enum AblyLogLevel {
    /** No logging. */
    suppress = 0,

    /** Errors only */
    error = 1,

    /** Errors and changes to connection/channel state. */
    info = 2,

    /** Abbreviated debug output */
    debug = 3,

    /** Full debug output */
    verbose = 4,
}

// noinspection JSUnusedGlobalSymbols
export class AblyPushServiceClient extends PushServiceClient {
    public readonly clientName = ABLY_CLIENT_NAME
    private readonly realtime: Ably.RealtimePromise
    public readonly auth: AblyAuthProvider
    private readonly settings: IAblyContextSettings
    private readonly shouldAutoDisconnect: boolean
    private readonly shouldRewind = new Set<string>()

    constructor(callback: IClientCallbacks, settings: IPushContextSettings, isBroadcaster=false) {
        super(callback)
        this.settings = settings as IAblyContextSettings
        this.connectionChange = new EventRouter<IConnectionStateChange>("AblyClientConnection")
        // Broadcast page should not auto-disconnect. Auto-disconnect causes problems when the page asks
        // "Are you sure you want to leave?".
        this.shouldAutoDisconnect = !isBroadcaster
        // Setup auth
        try {
            this.realtime = new Ably.Realtime.Promise(this.getClientOptions())
        } catch (e) {
            // normalize error from ably so newrelic reports attributes
            const err = normalizeRejection(ErrorCode.connect, e.toString())
            throw err
        }
        this.auth = new AblyAuthProvider(this.realtime)

        // Setup our connection state change event
        const failedStates = [ConnectionState.disconnected, ConnectionState.failed, ConnectionState.suspended]
        this.realtime.connection.on((stateChange: Ably.ConnectionStateChange) => {
            if (stateChange.previous !== stateChange.current) {
                const previous = resolveConnectionState(stateChange.previous)
                const current = resolveConnectionState(stateChange.current)
                const error = resolveErrorInfo(ErrorCode.connect, stateChange.reason)
                // ignore 204 - transport is being upgraded/replaced, and not actually client disconnecting fully
                if ((failedStates.includes(current) && stateChange.reason?.statusCode !== 204) || current === ConnectionState.connected) {
                    this.connectionChange.fire({
                        previous: previous,
                        current: current,
                        reason: error,
                        client: "a",
                    })
                }
            }
        })

        if (!this.shouldAutoDisconnect) {
            addEventListenerPoly(unloadEventName(), window, () => {
                // Manually use the less-reliable "onunload" if we can't auto disconnect
                this.close()
            })
        }
    }

    private getClientOptions(): Ably.ClientOptions {
        const options =  {
            autoConnect: false,
            closeOnUnload: this.shouldAutoDisconnect,
            log: { level: PRODUCTION ? AblyLogLevel.error : AblyLogLevel.info },
            authCallback: (_params: Ably.TokenParams, callback: Ably.AuthCallbackResolver) => {
                this.auth.fetchTokenRequest().then((ablyContext) => {
                    if (ablyContext.isValid()) {
                        this.context = ablyContext
                        callback(undefined, ablyContext.getTokenRequest())
                    } else {
                        callback("Invalid token request")
                    }
                }).catch((err: Error) => {
                    callback(err.message)
                })
            },
            restHost: this.settings.restHost,
            realtimeHost: this.settings.realtimeHost,
            transportParams: {
                "remainPresentFor": "0",
            },
        } as Ably.ClientOptions
        if (this.settings.fallbackHosts.length > 0) {
            options["fallbackHosts"] = this.settings.fallbackHosts
        }
        return options
    }

    protected _connect(): void {
        this.realtime.connect()
    }

    public close(): void {
        this.realtime.close()
    }

    public getConnectionState(): ConnectionState {
        return resolveConnectionState(this.realtime.connection.state)
    }

    protected getChannelState(channelName: string): SubscriptionState {
        const channel = this.realtime.channels.get(channelName)
        return resolveSubscriptionState(channel.state)
    }

    public getConnectionType(): string {
        // @ts-ignore hacky way to inspect inner connection details
        return this.realtime.connection?.["connectionManager"]?.["activeProtocol"]?.["transport"]?.["shortName"] ?? ""
    }

    public getConnectionId(): string {
        return this.realtime.connection.id
    }

    public getClientId(): string {
        // @ts-ignore hacky way to inspect inner connection details
        return this.realtime.connection?.["connectionManager"]?.["connectionDetails"]?.["clientId"] ?? ""
    }

    public getConnectionHost(): string {
        // @ts-ignore hacky way to inspect inner connection details
        return this.realtime.connection?.["connectionManager"]?.["host"] ?? ""
    }

    public getConnectionServer(): string {
        // @ts-ignore hacky way to inspect inner connection details
        return this.realtime.connection?.["connectionManager"]?.["connectionDetails"]?.["serverId"] ?? ""
    }

    public getConnectionSerial(): string {
        // @ts-ignore hacky way to inspect inner connection details
        return this.realtime.connection?.["connectionManager"]?.["connectionSerial"] ?? ""
    }

    // Not currently supported with ably's client
    public getReconnectCount(): number {
        return -1
    }

    protected duplicateMessageAttributes(channelName: string, messageData: ArgJSONMap, tid: string, meta: IDuplicateMessageMeta[]): NewrelicAttributes {
        const atts = super.duplicateMessageAttributes(channelName, messageData, tid, meta)
        atts["connection_id"] = this.getConnectionId()
        atts["recovery_key"] = this.realtime.connection.recoveryKey
        atts["client_id"] = this.getClientId()
        atts["server_id"] = this.getConnectionServer()
        atts["connection_serial"] = this.getConnectionSerial()
        // @ts-ignore hacky way to inspect inner connection details
        atts["connection_key"] = this.realtime.connection?.["connectionManager"]?.["connectionDetails"]?.["connectionKey"] ?? ""
        // @ts-ignore hacky way to inspect inner connection details
        atts["msg_serial"] = this.realtime.connection?.["connectionManager"]?.["msgSerial"] ?? ""
        atts["recovery_key"] = this.realtime.connection?.["recoveryKey"] ?? ""
        return atts
    }

    protected _subscribe(topicKey: string): Promise<void> {
        const channelName = this.getChannelName(topicKey)
        if (channelName === undefined) {
            return Promise.reject(normalizeRejection(ErrorCode.topic_error, "Invalid topic key reached subscribe"))
        }
        const channel = this.realtime.channels.get(channelName)
        const topicPromise = new Promise<void>((resolve, reject) => {
            this.ensureConnectedAndAuthed(topicKey).then(() => {
                const shouldRewind = this.shouldRewind.delete(topicKey)
                return channel.setOptions({
                    cipher: undefined,
                    params: shouldRewind ? {
                        rewind: "30s",
                    }: {},
                    modes: ["SUBSCRIBE", "PRESENCE"],
                })
            }).then(() => {
                return channel.attach()
            }).then(() => {
                channel.unsubscribe() // remove all attached listeners
                return channel.subscribe((message: Ably.Message) => {
                    message.data["providerData"] = {
                        "id": message.id,
                        "ts": message.timestamp,
                    }
                    const messageData = new ArgJSONMap(message.data as string)
                    this.checkForReauth(messageData)
                    const topicIdToKeyMap = this.getChannelTopicMap(channelName)
                    let topicKeyToFire
                    if (topicIdToKeyMap !== undefined) {
                        topicKeyToFire = topicIdToKeyMap.get(messageData.getString("_topic"))
                    }
                    if (topicKeyToFire !== undefined) {
                        if (this.handleMessageDuplicate(channelName, messageData, messageData.getString("tid"))) {
                            return
                        }
                        this.callbacks.onMessage(this.clientName, topicKeyToFire, messageData)
                    } else if (messageData.getStringOrUndefined("_sm") !== "o"){
                        warn("Received message for unknown topic", {
                            "topic": messageData.getString("_topic"),
                            "client": this.clientName,
                        })
                    }
                })
            }).then(() => {
                resolve()
            }).catch((reason: Ably.ErrorInfo | string | undefined) => {
                if (channel.state === SubscriptionState.suspended) {
                    // Detach to prevent the channel from attempting to automatically reconnect
                    channel.detach().then().catch(() => {}) // This detach should resolve immediately, it's a pseudo promise
                }
                reject(normalizeRejection(ErrorCode.subscribe, reason))
            })
        })
        this.topicPromises.set(topicKey, topicPromise)
        return topicPromise
    }

    protected _unsubscribe(topicKey: string): Promise<void> {
        const channelName = this.getChannelName(topicKey)
        if (channelName === undefined) {
            return Promise.reject(normalizeRejection(ErrorCode.topic_error, "Invalid topic key reached unsubscribe"))
        }
        const channel = this.realtime.channels.get(channelName)
        const subscriptionState = resolveSubscriptionState(channel.state)
        channel.unsubscribe() // removes all MessageListeners from the channel

        // Check if the channel is already unsubscribed or critical
        if ([SubscriptionState.unsubscribed, SubscriptionState.initialized, SubscriptionState.failed].includes(subscriptionState)) {
            return Promise.resolve()
        }

        const topicPromise = new Promise<void>((resolve, reject) => {
            channel.detach().then(() => {
                // Ably automatically leaves any presence channels, so we do not need to explicitly do it
                resolve()
            }).catch((err) => {
                // Even if the detach fails, we will simply be left attached to a channel with no MessageListeners.
                // Ably will warn that we are attached to a channel without proper capabilities, but no errors will happen.
                reject(normalizeRejection(ErrorCode.unsubscribe, err))
            })
        })
        this.topicPromises.set(topicKey, topicPromise)
        return topicPromise
    }

    public enterPresence(topicKey: string, payload?: IPushPresencePayload): Promise<void> {
        let topicPromise = this.topicPromises.get(topicKey)
        if (topicPromise !== undefined) {
            return Promise.reject(normalizeRejection(ErrorCode.topic_error, "Called subscribe while channel is busy"))
        }

        const channelName = this.getChannelName(topicKey)
        if (channelName === undefined) {
            return Promise.reject(normalizeRejection(ErrorCode.topic_error, "Unknown topic key"))
        }
        const channel = this.realtime.channels.get(channelName)

        topicPromise = new Promise((resolve, reject) => {
            this.ensureConnectedAndAuthed(topicKey).then(() => {
                return channel.setOptions({
                    cipher: undefined,
                    params: {},
                    modes: ["SUBSCRIBE", "PRESENCE"],
                })
            }).then(() => {
                return channel.attach()
            }).then(() => {
                const topicCapabilities: (AuthCapabilityType[] | undefined) = this.auth.getCapabilities()[channelName]
                if (Array.isArray(topicCapabilities) && topicCapabilities.includes(AuthCapabilityType.presence)) {
                    if (payload !== undefined && payload.changed) {
                        return channel.presence.update(payload.data)
                    }
                    // already entered presence with no payload changes
                    return Promise.resolve()
                } else {
                    return Promise.reject(normalizeRejection(ErrorCode.presence, "Token does not have capability for presence"))
                }
            }).then(() => {
                this.handleSubscribed(topicKey)
                resolve()
            }).catch((reason: Ably.ErrorInfo | string | undefined) => {
                this.topicPromises.delete(topicKey)
                this.handleTopicFailure(topicKey)
                reject(normalizeRejection(ErrorCode.presence, reason))
            })
        })

        return topicPromise
    }

    public leavePresence(topicKey: string): Promise<void> {
        const channelName = this.getChannelName(topicKey)
        if (channelName === undefined) {
            return Promise.reject(normalizeRejection(ErrorCode.topic_error, "Unknown topic key"))
        }
        const channel = this.realtime.channels.get(channelName)

        return channel.presence.leave().then(() => {
            this.handleUnsubscribed(topicKey)
        }).catch(reason => {
            this.handleUnsubscribed(topicKey)
            return Promise.reject(normalizeRejection(ErrorCode.presence, reason))
        })
    }

    public getSubscriptionState(topicKey: string): SubscriptionState {
        const channelName = this.getChannelName(topicKey)
        if (channelName === undefined) {
            return SubscriptionState.unknown
        }
        return resolveSubscriptionState(this.realtime.channels.get(channelName).state)
    }

    public isSubscribedTo(topicKey: string): boolean {
        return this.getSubscriptionState(topicKey) === SubscriptionState.subscribed
    }
}
