import { isiOS, isLocalStorageSupported, isMobileDevice, isSamsungBrowser } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly, removeEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { Component } from "../../../common/defui/component"
import { EventRouter } from "../../../common/events"
import { isViewportZoomed } from "../../../common/mobilelib/viewportDimension"
import { isPortrait } from "../../../common/mobilelib/windowOrientation"
import { enableChatInputAutoFocus } from "../../../common/theatermodelib/eventListeners"
import { dom } from "../../../common/tsxrender/dom"
import { userModeratorStatusChanged } from "../../../common/userActionEvents"
import { loadIgnoreList } from "../../api/ignore"
import { pageContext } from "../../interfaces/context"
import { registerScrollDebouncer } from "../../ui/scrollUtil"
import { ConversationListData } from "./conversationListData"
import { requestDmInputFocus } from "./dmUtil"
import { DmWindow } from "./dmWindow"
import { bindDmWindowsPushEvents } from "./dmWindowUtils"
import { directMessage } from "./userActionEvents"
import type { DmInputFocusSource } from "./dmUtil"
import type { IPushPrivateMessage } from "../../../common/messageInterfaces"
import type { IUserModeratorStatus } from "../../../common/userActionEvents"

export const createDmWindowRequest = new EventRouter<string>("createDmWindowRequest")
export const removeDmWindowRequest = new EventRouter<{ username: string, deleteWindow?: boolean }>("removeDMWindowRequest")
export const updateWindowIsOpenEvent = new EventRouter<IDmWindow>("updateWindowIsOpenEvent")
export const dmsHeightChanged = new EventRouter<undefined>("dmsHeightChanged")

const localStorageDmWindowKey = "pmChatWindow"

export interface IDmWindow {
    username: string,
    isOpen: boolean,
}

const enum FocusState {
    Focusing,
    Focused,
    Blurred,
}

export class DmWindowsManager extends Component {
    private shownDmContainer: HTMLDivElement
    private shownWindow?: DmWindow
    private allDmWindowsMap = new Map<string, DmWindow>()  // Keyed on username
    private myUsername: string
    private focusingTablet = false
    private manuallyHandlingTabletKeyboard: boolean
    private tabletFocusState = FocusState.Blurred
    private maxZoomedTabletTop: number
    private static instance: DmWindowsManager

    private constructor(myUsername: string) {
        super()

        this.myUsername = myUsername

        this.bindListeners()

        addEventListenerPoly("mousedown", this.element, () => enableChatInputAutoFocus(false))
        addEventListenerPoly("mouseup", window, () => enableChatInputAutoFocus(true))
    }

    public static getOrCreateInstance(myUsername: string): DmWindowsManager {
        if (DmWindowsManager.instance === undefined) {
            DmWindowsManager.instance = new DmWindowsManager(myUsername)
        }
        return DmWindowsManager.instance
    }

    public static getInstance(): DmWindowsManager | undefined {
        return DmWindowsManager.instance
    }

    protected initData(): void {
        this.manuallyHandlingTabletKeyboard = isMobileDevice() && (window.visualViewport ? true : false) && navigator["virtualKeyboard"] === undefined
    }

    protected initUI(): void {
        super.initUI()

        const style: CSSX.Properties = {
            position: "fixed",
            bottom: "0px",
            right: "10px",
            minWidth: "350px",
            width: "100vw",
            height: "0px",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 1005,
        }

        this.shownDmContainer = <div style={{ display: "inline-block", cssFloat: "right" }}/>
        this.element = <div id="DmWindowBar" style={style}>
            {this.shownDmContainer}
        </div>

        requestDmInputFocus.listen((source: DmInputFocusSource) => this.handleInputFocus(source))

        if (this.manuallyHandlingTabletKeyboard) {
            // Nightmare code, I'm so sorry. Please anyone if you have a better way..
            // Tablet virtual keyboards see that the input is underneath where the keyboard will go and try to
            // scroll the page to put it above the keyboard. This code does its best to prevent that
            addEventListenerPoly("focus", this.element, () => this.onTabletFocus(), true)
            addEventListenerPoly("blur", this.element, () => this.onTabletBlur(), true)

            // If the user scrolls the page when the virtual keyboard is up, the dm window does not want to
            // stay positioned correctly. We avoid this problem by blurring the input in those cases
            registerScrollDebouncer(document, () => {
                if (!this.focusingTablet) {
                    this.blur()
                }
            })
            // Samsung doesn't always fire scroll if you scroll with the keyboard up, so use touchstart as a fallback
            addEventListenerPoly("touchstart", document, (e) => {
                if (e.target instanceof Element && !this.element.contains(e.target)) {
                    this.blur()
                }
            })
        } else if (navigator["virtualKeyboard"] !== undefined) {
            // virtualKeyboard is an experimental API, so browsers may or may not have it implemented or enabled,
            // but when present it really just works perfectly and easily
            const virtualKeyboard = navigator["virtualKeyboard"]
            addEventListenerPoly("focus", document, (e) => {
                virtualKeyboard.overlaysContent = e.target instanceof Element && this.element.contains(e.target)
            }, true)
            addEventListenerPoly("blur", this.element, () => {
                this.element.style.bottom = "0px"
            }, true)

            virtualKeyboard.addEventListener("geometrychange", (event: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                if (this.element.contains(document.activeElement)) {
                    this.element.style.bottom = `${event.target.boundingRect.height}px`
                }
            })
        }

        if (isMobileDevice()) {
            // For inputs besides DM window input, the DM window above the keyboard can block the input and cause
            // some visual bugs since browsers don't expect to handle that. So just hide the dm window in that case
            addEventListenerPoly("focusin", document, (e) => {
                if (e.target instanceof HTMLElement) {
                    const targetIsInput = ["input", "textarea"].includes(e.target.tagName.toLowerCase()) || e.target.contentEditable === "true"
                    if (targetIsInput && !this.element.contains(e.target)) {
                        // Use visibility rather than hideElement so that the DmWindow still scrolls for new messages
                        this.element.style.visibility = "hidden"
                    }
                }
            })
            addEventListenerPoly("focusout", document, () => { this.element.style.visibility = "" })

            // Zooming while focused messes up positioning, so blur on zoom
            let currentScale: number | undefined
            window.visualViewport?.addEventListener("resize", () => {
                // round scale for simple float comparison
                const newScale = window.visualViewport ? Math.round(window.visualViewport.scale * 10) : undefined
                if (currentScale !== undefined && newScale !== currentScale) {
                    this.blur()
                }
                currentScale = newScale
            })
        }
    }

    private handleInputFocus(source: DmInputFocusSource): void {
        if (!this.manuallyHandlingTabletKeyboard) {
            if (source instanceof HTMLElement) {
                source.focus()
            }
            return
        }

        let input: HTMLElement
        if (source instanceof HTMLElement) {
            input = source
        } else {
            if (!(source.target instanceof HTMLElement)) {
                return
            }
            // Non ios tablets effectively refocus even if already focused, so we still have to take over the event then
            if (document.activeElement === source.target && isiOS() && this.isPositionedForKeyboard()) {
                return
            }
            source.preventDefault()
            input = source.target
        }

        this.handleTabletInputFocus(input)
    }

    private handleTabletInputFocus(input: HTMLElement) {
        input.blur()

        // This scroll listening is mostly for samsung browser, but also helps on other browsers/devices
        // when zoomed in
        const x = window.scrollX
        const y = window.scrollY

        this.maxZoomedTabletTop = document.body.offsetHeight
        this.focusingTablet = true
        const onScroll = () => {
            window.scrollTo(x, y)
            if (isViewportZoomed()) {
                this.setTabletStyleForZoomFocus()
            }
        }
        addEventListenerPoly("scroll", document, onScroll)
        window.setTimeout(() => {
            removeEventListenerPoly("scroll", document, onScroll)
            this.focusingTablet = false
        }, 1000) // Samsung browser can take up to a second to decide the keyboard is done displaying

        this.setTabletStyleForFocus(FocusState.Focusing)
        input.focus()
    }

    private onTabletFocus(): void {
        // Ugly timeout but needed because if this runs too early then the virtual keyboard may not be done
        // displaying and will try to scroll the page to keep the input above the keyboard
        window.setTimeout(() => {
            this.setTabletStyleForFocus(FocusState.Focused)
        }, isiOS() ? 75 : 350)
    }

    private onTabletBlur(): void {
        // Initial timeout needed for the style change to avoid interrupting refocusing when we have
        // blur->immediate refocus (eg tap on message list while input is focused)
        window.setTimeout(() => {
            this.setTabletStyleForFocus(FocusState.Focusing)

            // Second timeout lets enough time pass for isInputFocused to return true in case of blur->immediate refocus.
            // Otherwise setting blurred style while refocusing will cause the keyboard to scroll the page
            window.setTimeout(() => {
                if (this.element.contains(document.activeElement)) {
                    if (isiOS()) {
                        // ios fires blur for manually minimizing the keyboard but the input still retains focus
                        this.setTabletStyleForFocus(FocusState.Focused)
                    }
                    return
                }
                this.setTabletStyleForFocus(FocusState.Blurred)
            }, 0)
        }, 0)
    }

    private setTabletStyleForFocus(focusState: FocusState): void {
        if (focusState === this.tabletFocusState) {
            return
        }
        this.tabletFocusState = focusState

        if (isViewportZoomed()) {
            this.setTabletStyleForZoomFocus()
            return
        }

        if (isSamsungBrowser()) {
            // unzoomed Samsung browser only needs the scroll listener and can/should skip setting style for focus
            return
        }

        const isNotPositionedForKeyboard = !this.isPositionedForKeyboard()
        this.element.style.top = "unset"
        this.element.style.bottom = "unset"
        this.element.style.visibility = ""

        switch (focusState) {
            case FocusState.Focusing:
                if (isNotPositionedForKeyboard) {
                    if (isiOS()) {
                        this.element.style.visibility = "none"
                        this.element.style.bottom = "60vh"
                    } else {
                        this.element.style.bottom = isPortrait() ? "35vh" : "60vh"
                    }
                } else {
                    this.element.style.top = "calc(var(--vh, 1vh) * 100 - 2px)"
                }
                break
            case FocusState.Focused:
                this.element.style.top = "calc(var(--vh, 1vh) * 100)"
                break
            case FocusState.Blurred:
                this.element.style.bottom = "0px"
                break
            default:
                error("DmWindowManager - invalid focusState")
                this.element.style.bottom = "0px"
        }
    }

    private setTabletStyleForZoomFocus(): void {
        if (!isiOS()) {
            this.element.style.position = "fixed"
            this.element.style.bottom = "0px"
            return
        }

        this.element.style.top = "unset"
        this.element.style.bottom = "unset"

        switch(this.tabletFocusState) {
            case FocusState.Focusing:
            case FocusState.Focused:
                if (window.visualViewport) {
                    this.element.style.position = "absolute"
                    const top = Math.min(window.scrollY + window.visualViewport.height, this.maxZoomedTabletTop)
                    this.element.style.top = `${top}px`
                }
                break
            case FocusState.Blurred:
                this.element.style.position = "fixed"
                this.element.style.bottom = "0px"
                break
            default:
                error("DmWindowManager - invalid zoom focusState")
                this.element.style.position = "fixed"
                this.element.style.bottom = "0px"
        }
    }

    private isPositionedForKeyboard(): boolean {
        return this.element.getBoundingClientRect().bottom <= window.innerHeight - 5
    }

    private blur(): void {
        if (document.activeElement instanceof HTMLElement && this.element.contains(document.activeElement)) {
            document.activeElement.blur()
        }
    }

    private bindListeners(): void {
        removeDmWindowRequest.listen(({ username, deleteWindow }) => {
            if (this.shownWindow?.username === username) {
                this.removeShownWindow()
            }
            if (deleteWindow === true) {
                this.allDmWindowsMap.delete(username)
            }
        })

        updateWindowIsOpenEvent.listen(() => {
            this.updateLocalStorage()
        })

        directMessage.listen((dmData: IPushPrivateMessage) => {
            const receivingWindow = this.allDmWindowsMap.get(dmData.otherUsername)
            receivingWindow?.handleNewMessage(dmData)
        })

        ConversationListData.conversationRead.listen(({ username, isDm }) => {
            if (isDm) {
                const receivingWindow = this.allDmWindowsMap.get(username)
                receivingWindow?.markRead()
            }
        })

        if (pageContext.current.isBroadcast) {
            userModeratorStatusChanged.listen((userModeratorStatus: IUserModeratorStatus) => {
                const receivingWindow = this.allDmWindowsMap.get(userModeratorStatus.username)
                receivingWindow?.setUserInfo({ isMod: userModeratorStatus.isMod })
            })
        }

        bindDmWindowsPushEvents(username => this.allDmWindowsMap.get(username))
    }

    public shownWindowUsername(): string | undefined {
        return this.shownWindow?.username
    }

    public showUserConversation(username: string, open: boolean, fromUserInteraction: boolean): void {
        // Use existing window if any
        if (this.transferToShown(username, fromUserInteraction)) {
            return
        }

        // Else create new window
        const newDmWindow = new DmWindow({
            username: username,
            myUsername: this.myUsername,
            open: open,
            markAsRead: fromUserInteraction,
            raiseWindowZIndexToTop: this.raiseWindowZIndexToTop,
        })
        this.allDmWindowsMap.set(username, newDmWindow)
        this.shownDmContainer.appendChild(newDmWindow.element)
        this.showWindow(newDmWindow, open, fromUserInteraction)
    }

    private raiseWindowZIndexToTop = (otherUser: string): void => {
        this.allDmWindowsMap.forEach((dmWindow, dmUsername) => {
            if (dmUsername === otherUser) {
                dmWindow.changeWindowZIndex(2) // `z-index: 2`: Focused Window
            } else {
                dmWindow.changeWindowZIndex(1)
            }
        })
    }

    private removeShownWindow(): void {
        if (this.shownWindow === undefined) {
            return
        }
        // Note we keep the window in this.allDmWindowsMap and never dispose old DM windows. This way we don't have to
        // reload convos from scratch every time they're reopened, since we now only show one convo at a time.
        this.shownWindow.removeFromDOM()
        this.shownWindow = undefined
        this.updateLocalStorage()
        dmsHeightChanged.fire(undefined)
    }

    private transferToShown(username: string, fromUserInteraction: boolean): boolean {
        const transferWindow = this.allDmWindowsMap.get(username)
        if (transferWindow === undefined) {
            return false
        }
        this.shownDmContainer.appendChild(transferWindow.element)
        this.showWindow(transferWindow, true, fromUserInteraction)
        return true
    }

    private showWindow(dmWindow: DmWindow, open: boolean, fromUserInteraction: boolean): void {
        this.removeShownWindow()
        this.shownDmContainer.appendChild(dmWindow.element)
        dmWindow.setIsShowing(true)
        dmWindow.openOrCollapseWindow(open, fromUserInteraction, !fromUserInteraction)
        if (fromUserInteraction) {
            dmWindow.highlightHeaderForShow()
        }
        this.shownWindow = dmWindow
        this.updateLocalStorage()
        dmsHeightChanged.fire(undefined)
    }

    private updateLocalStorage(): void {
        if (!isLocalStorageSupported()) {
            return
        }

        let windowStorage: IDmWindow | undefined
        if (this.shownWindow !== undefined) {
            windowStorage = {
                "username": this.shownWindow.username,
                "isOpen": this.shownWindow.isWindowOpen(),
            }
        }

        const currentDmWindowStorage = window.localStorage.getItem(localStorageDmWindowKey)
        const dmWindowObjectForStorage = currentDmWindowStorage !== null ? JSON.parse(currentDmWindowStorage) : {}
        dmWindowObjectForStorage[this.myUsername] = JSON.stringify(windowStorage)
        window.localStorage.setItem(localStorageDmWindowKey, JSON.stringify(dmWindowObjectForStorage))
    }
}

export function setupDmWindow(myUsername: string): void {
    let dmWindowsManager: DmWindowsManager | undefined
    loadIgnoreList()

    const createDmWindowOrBar = (username: string, isOpen: boolean, fromUserInteraction: boolean) => {
        if (myUsername === username) {
            return
        }
        if (dmWindowsManager === undefined) {
            dmWindowsManager = DmWindowsManager.getOrCreateInstance(myUsername)
            document.body.appendChild(dmWindowsManager.element)
        }
        dmWindowsManager.showUserConversation(username, isOpen, fromUserInteraction)
    }

    const restoreDmsFromLocalStorage = () => {
        if (!isLocalStorageSupported()) {
            return
        }
        const localStorage = window.localStorage.getItem(localStorageDmWindowKey)
        if (localStorage !== null) {
            const parsedStorage = JSON.parse(localStorage)
            const dmWindow = parsedStorage[myUsername]
            if (dmWindow === undefined) {
                return
            }

            const parsedDmWindow: IDmWindow = JSON.parse(dmWindow)
            createDmWindowOrBar(parsedDmWindow["username"], parsedDmWindow["isOpen"], false)
            dmsHeightChanged.fire(undefined)
        }
    }

    restoreDmsFromLocalStorage()

    createDmWindowRequest.listen((username: string) => {
        createDmWindowOrBar(username, true, true)
    })
}
