import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { DropDownComponent } from "../../../common/dropDownComponent"
import { dom } from "../../../common/tsxrender/dom"
import { Field } from "../../ui/fields"
import { TransparentCheckbox } from "../toggle"
import type { IChoices, IMultipleCheckboxOptions } from "../../ui/fields"

export class MultiSelectDropdown extends Field {
    protected choices: IChoices[]
    private activeChoicesContainer: HTMLDivElement
    public checkboxesDropdown: CheckboxesDropdown
    public currentValues: string[]

    constructor(options: IMultipleCheckboxOptions) {
        super(options)
    }

    protected initializeData(options: IMultipleCheckboxOptions): void {
        super.initializeData(options)
        this.choices = (options.choices === undefined ? [] : options.choices)
        this.currentValues = (options.defaultValue === undefined ? [] : (options.defaultValue as string[]))
    }

    public getValue(): string {
        return this.currentValues.join(",")
    }

    public createField(): HTMLElement {
        this.activeChoicesContainer =
            <div
                className="activeChoicesContainer"
                data-testid="multiSelectActiveChoices"
                tabIndex={0}
            >
                <div className="dropdownMenuIcon"/> {/* Background set in css */}
                {this.currentValues.map((value) => {
                    return this.createActiveChoice(value)
                })}
            </div>
        return this.activeChoicesContainer
    }

    private initCheckboxesDropdown(): void {
        this.checkboxesDropdown = new CheckboxesDropdown(
            this.activeChoicesContainer,
            {
                choices: this.choices,
                defaultValue: this.currentValues,
                fieldName: this.name,
                disabled: this.disabled,
                handleCheckboxChange: (event: Event, value: string) => {
                    this.handleCheckboxChange(event, value)
                },
            })
    }

    private handleCheckboxChange(event: Event, value: string) {
        const checkboxElement = event.target as HTMLInputElement
        if (checkboxElement.checked) {
            this.currentValues.push(value)
            this.activeChoicesContainer.appendChild(this.createActiveChoice(value))
        } else {
            this.currentValues = this.currentValues.filter(v => v !== value)
            this.removeActiveChoice(value)
        }
    }

    private createActiveChoice(value: string): HTMLDivElement {
        return <div className="activeChoiceElement" data-testid="multiSelectActiveChoice" data-value={value}>
            {this.choices.find((choice) => String(choice.value) === value)?.label}
        </div>
    }

    private removeActiveChoice(value: string): void {
        const activeChoice = this.activeChoicesContainer.querySelector(`[data-value="${value}"]`)
        if (activeChoice) {
            this.activeChoicesContainer.removeChild(activeChoice)
        }
    }

    protected assembleField(): void {
        this.widget = this.createField()
        this.initCheckboxesDropdown()
        this.widgetContainer.appendChild(this.widget)
        this.widgetContainer.appendChild(this.checkboxesDropdown.element)
    }

    protected afterAssembleField(): void {
        super.afterAssembleField()
        this.getHelpTextElement().classList.add("helpText")
        this.getWidgetContainer().classList.add("multiSelectDropdown")
        addEventListenerPoly("keydown", this.widgetContainer, (evt: KeyboardEvent) => {
            switch (evt.code) {
                case "Escape":
                case "Tab":
                    if (this.checkboxesDropdown.isShown()) {
                        evt.preventDefault()
                        this.checkboxesDropdown.hideElement()
                        this.activeChoicesContainer.focus()
                    }
                    break
                case "ArrowDown":
                    evt.preventDefault()
                    this.checkboxesDropdown.focus()
                    break
                case "ArrowUp":
                    evt.preventDefault()
                    this.checkboxesDropdown.focus(true)
                    break
            }
        })
    }
}

interface ICheckboxesDropdownProps {
    /** choices to be rendered inside the checkbox */
    choices: IChoices[]
    /** default values that needs to be checked */
    defaultValue: string[]
    /** field name of the toggle/parent element */
    fieldName: string
    /** whether the field is disabled */
    disabled: boolean
    /** what should the checkbox do when it is checked */
    handleCheckboxChange?: (event: Event, value: string) => void
}

class CheckboxesDropdown extends DropDownComponent {
    private choices: IChoices[]
    private checkboxes: TransparentCheckbox[]
    private defaultValue: string[]
    private fieldName: string
    private disabled: boolean
    private focusedIndex = 0

    constructor(toggleElement: HTMLElement, props: ICheckboxesDropdownProps) {
        super(toggleElement, true, props)
    }

    protected initData(props: ICheckboxesDropdownProps): void {
        this.checkboxes = []
        this.choices = props.choices
        this.defaultValue = props.defaultValue
        this.fieldName = props.fieldName
        this.disabled = props.disabled
    }

    protected initUI(props: ICheckboxesDropdownProps): void {
        super.initUI(props)
        this.element =
            <div className="checkboxesDropdown"
                 data-testid="multiSelectCheckboxesDropdown"
                 style={{ display: "none" }}
                 onKeyDown={(e) => this.onKeyDown(e)}>
                {this.choices.map((value) => {
                    return (<label className="checkboxFieldLabel">
                        {this.createCheckbox(value.value, props.handleCheckboxChange)}
                        {String(value.label)}
                    </label>)
                })}
            </div>
    }

    private onKeyDown(evt: KeyboardEvent): void {
        switch (evt.code) {
            case "ArrowDown":
                evt.preventDefault()
                evt.stopPropagation()
                this.focusNext()
                break
            case "ArrowUp":
                evt.preventDefault()
                evt.stopPropagation()
                this.focusPrev()
                break
        }
    }

    private createCheckbox(value: string | number | boolean, handleCheckboxChange?: (event: Event, value: string) => void): HTMLElement {
        const initiallyChecked = this.defaultValue.indexOf(String(value)) !== -1
        const transparentCheckbox = new TransparentCheckbox(16, initiallyChecked)
        transparentCheckbox.setName(this.fieldName)
        transparentCheckbox.setCheckboxValue(String(value))
        if (this.disabled) {
            transparentCheckbox.disable()
        }
        this.checkboxes.push(transparentCheckbox)
        if (handleCheckboxChange !== undefined) {
            transparentCheckbox.element.addEventListener("change", (event) => {
                handleCheckboxChange(event, String(value))
            })
        }
        return transparentCheckbox.element
    }

    private focusNext(): void {
        const index = (this.focusedIndex + 1) % this.checkboxes.length
        this.focusIndex(index)
    }

    private focusPrev(): void {
        const index = (this.focusedIndex > 0 ? this.focusedIndex : this.checkboxes.length) - 1
        this.focusIndex(index)
    }

    private focusIndex(index: number): void {
        this.checkboxes[index].focus()
        this.focusedIndex = index
    }

    public showElement(): boolean {
        const toggleElement = this.toggleElement
        if (toggleElement !== null) {
            toggleElement?.classList.add("dropdown-shown")
        }
        return super.showElement("flex")
    }

    public hideElement(): boolean {
        const toggleElement = this.toggleElement
        if (toggleElement !== null) {
            toggleElement?.classList.remove("dropdown-shown")
        }
        return super.hideElement()
    }

    public focus(focusLast = false): void {
        this.focusIndex(focusLast ? this.checkboxes.length - 1 : 0)
    }
}
