import { usernameTitleCase } from "@multimediallc/web-utils"
import { isiPad, isSamsungTablet } from "@multimediallc/web-utils/modernizr"
import { currentSiteSettings } from "../../cb/siteSettings"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { getCoords } from "../DOMutils"
import { featureFlagIsActive } from "../featureFlag"
import { isPortrait } from "../mobilelib/windowOrientation"
import { OverlayComponent } from "../overlayComponent"
import { leavePrivateOrSpyShowAlertChain } from "../privateShow"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import type { IChatConnection } from "../context"
import type { IPrivateShowRequirements } from "../privateShow"

export const PrivateShowRequestFeatureFlag = "SplitModePrivateShowUX"
export const isPrivateShowRequestFeatureActive = (): boolean =>
    featureFlagIsActive(PrivateShowRequestFeatureFlag)

export interface IPrivateShowRequest {
    requirements: IPrivateShowRequirements,
    makeRequest: () => void,
    stopRequest: () => void,
    showConfirmation: boolean,
    chatConnection: IChatConnection,
}

export class PrivateShowRequestModal extends OverlayComponent {
    private showRate: HTMLSpanElement
    private minimumShowTime: HTMLSpanElement
    private videoRecordingAllowed: HTMLDivElement
    private privateShowDescription: HTMLDivElement
    private confirmationButtonText: HTMLParagraphElement
    private confirmationButton: HTMLDivElement
    private closeIcon: HTMLImageElement
    private wrapper: HTMLDivElement
    private subwrapper: HTMLDivElement

    private requestPending = false

    private stopRequestCallback?: () => void

    constructor() {
        super()

        this.element.dataset.testid = "private-show-request-modal"
        this.element.style.position = "absolute"
        this.element.style.display = "none"
        this.element.style.top = "0"
        this.element.style.left = "0"

        this.overlayClick.listen(() => {
            this.hide()
        })

        addEventListenerPoly("keydown", document, (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                if (this.requestPending) {
                    return
                }
                this.hide()
            }
        })
    }

    protected initUI(props?: object): void {
        super.initUI(props)

        this.privateShowDescription = this.setupPrivateShowBody()
        this.confirmationButtonText = <p>
            {i18n.request}
        </p>

        this.confirmationButton = (
            <div
                className="actionButton"
                data-testid="private-show-request-action-btn"
            >
                {this.confirmationButtonText}
            </div>
        )
        this.closeIcon = <div
            data-testid="private-show-request-close-btn"
            height="16"
            width="16"
            onClick={() => this.hide()}
            className="closeIcon"></div>

        const modal = (
            <div className="tooltip">
                <h1
                    className="header"
                    data-testid="private-show-header"
                    style={
                        currentSiteSettings.isWhiteLabel
                            ? { color: currentSiteSettings.h1Color }
                            : {}
                    }
                >
                    {i18n.privateShowText}
                </h1>
                {this.setUpPrivateShowRateUI()}
                {this.privateShowDescription}
                {this.confirmationButton}
            </div>
        )
        this.subwrapper = <div className="subwrapper">
            <div className="privateShowRequestInner">
                {this.closeIcon}
                {modal}
            </div>
        </div>
        this.wrapper = <div className="wrapper">
            {this.subwrapper}
        </div>
        this.element = <div style={{
            fontFamily: "Ubuntu, Helvetica, Arial, sans-serif",
        }}>
            {this.wrapper}
        </div>
    }

    private setUpPrivateShowRateUI(): HTMLDivElement {
        this.showRate = <span></span>
        this.minimumShowTime = <span></span>

        return <div className="rateContainer">
            {/* Private Show Rate */}
            <div className="bluePill">
                <div className="clockIcon" data-testid="private-show-rate"></div>
                {this.showRate}
            </div>
            {/* Minimum Time */}
            <div className="bluePill" data-testid="min-show-time">
                {this.minimumShowTime}
            </div>
        </div>
    }

    private setupPrivateShowBody(): HTMLDivElement {
        this.videoRecordingAllowed = (
            <div className="itemDescription" data-testid="video-recording-allowed">
                <img src={`${STATIC_URL_ROOT}tsdefaultassets/ic_videocollection.svg`}
                    height="12"
                    width="12"/>
                <p style={
                    currentSiteSettings.isWhiteLabel
                        ? { color: currentSiteSettings.textColor }
                        : {}
                }>{i18n.recordedVideo}</p>
            </div>
        )

        return <div className="privateShowBodyDescriptionWrapper">
            <hr className="separator"/>
            <div className="privateShowBodyDescription">
                {/* item description */}
                <div className="itemDescription" data-testid="private-time-broadcaster">
                    <img src={`${STATIC_URL_ROOT}tsdefaultassets/ic_privatetime.svg`}
                        height="12"
                        width="12"/>
                    <p style={
                        currentSiteSettings.isWhiteLabel
                            ? { color: currentSiteSettings.textColor }
                            : {}
                    }>{i18n.privateTimeWithBroadcaster}</p>
                </div>
                {/* item description */}
                {this.videoRecordingAllowed}
            </div>
        </div>
    }

    showPending(chatConnection: IChatConnection): void {
        this.requestPending = true

        this.privateShowDescription.style.display = "none"
        this.closeIcon.style.display = "none"

        this.showElement()
        this.repositionChildren()

        // Hide overlay to prevent user from dismissing request window.
        this.hideOverlay()
        this.requestPending = true

        this.confirmationButtonText.textContent = i18n.cancelRequest
        this.confirmationButton.style.marginTop = "10px"
        this.confirmationButton.onclick = () => {
            leavePrivateOrSpyShowAlertChain(chatConnection, false)
            this.hide()
            this.requestPending = false
        }
    }

    show(privateShowRequest: IPrivateShowRequest): void {
        // Integrate broadcaster's private show settings
        const { chatConnection, requirements } = privateShowRequest
        this.requestPending = false
        this.showRate.textContent = i18n.priceTokensPerMinuteMessage(
            requirements.price,
            true,
        )
        this.minimumShowTime.textContent = usernameTitleCase(
            i18n.privateShowMinimumMinuteMessageV2(
                parseInt(requirements.minimumMinutes),
            ),
        )

        this.privateShowDescription.style.display = "block"
        this.closeIcon.style.display = "block"
        this.videoRecordingAllowed.style.display =
            requirements.recordingsAllowed ? "flex" : "none"
        this.confirmationButton.style.marginTop = "0px"

        this.confirmationButtonText.textContent = i18n.request
        this.stopRequestCallback = privateShowRequest.stopRequest

        const initRequest = () => {
            this.privateShowDescription.style.display = "none"
            this.closeIcon.style.display = "none"

            privateShowRequest.makeRequest()

            // Hide overlay to prevent user from dismissing request window.
            this.hideOverlay()
            this.requestPending = true

            this.confirmationButtonText.textContent = i18n.cancelRequest
            this.confirmationButton.style.marginTop = "10px"
            this.confirmationButton.onclick = () => {
                leavePrivateOrSpyShowAlertChain(chatConnection, false)
                this.hide()
                this.requestPending = false
            }
        }

        if (privateShowRequest.showConfirmation) {
            this.confirmationButton.onclick = () => {
                initRequest()
            }
        } else {
            initRequest()
        }

        this.showElement()
        this.showOverlay()
        this.repositionChildren()

        this.wrapper.style.height = `${this.subwrapper.clientHeight}px`
    }

    hide(): void {
        this.hideElement()
        this.hideOverlay()

        if (this.stopRequestCallback !== undefined) {
            this.stopRequestCallback()
        }
    }

    repositionChildren(): void {
        const tipButtonRef = document.getElementById("sendTipButton")
        const chatTabContainerRef = document.getElementById("ChatTabContainer")

        if (tipButtonRef && chatTabContainerRef) {
            const tipRect = getCoords(tipButtonRef)
            this.element.style.top = `${tipRect.top - 90}px`
            this.element.style.left = `${tipRect.right + 15}px`
            this.element.style.zIndex = "1001"

            if (this.isShown()) {
                const subRect = this.subwrapper.getBoundingClientRect()
                const scrollBarPadding = 100
                const isTabletPortrait = isPortrait() && (isSamsungTablet() || isiPad())

                if (isTabletPortrait) {
                    // Check if overlap with chat tab container with left position of subwrapper
                    const isCutoff = (tipRect.x + subRect.width) > chatTabContainerRef.getBoundingClientRect().x
                    if (isCutoff) {
                        this.element.style.top = `${tipRect.top + tipRect.height + 10}px`
                        this.element.style.left = `${tipRect.left - (scrollBarPadding*2.2)}px`
                    }
                } else {
                    // Check if cutoff is happening with edge of browser window
                    const isCutoff = ((tipRect.x + subRect.width + scrollBarPadding) > window.innerWidth) || (tipRect.x < 0)
                    if (isCutoff) {
                        this.element.style.left = `${tipRect.left - scrollBarPadding/2}px`
                    }
                }
            }
        }
    }
}
