import { createDmWindowRequest } from "../../cb/components/pm/dmWindowsManager"
import { pageContext } from "../../cb/interfaces/context"
import { currentSiteSettings } from "../../cb/siteSettings"
import { normalizeResource } from "../api"
import { isNotLoggedIn } from "../auth"
import {
    addPhotoSetClickedPageAction,
    getBioContentPromise,
    sanitizeUserCreatedContent,
} from "../bioContent"
import { roomCleanup, roomLoaded } from "../context"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { RoomStatus } from "../roomStatus"
import { i18n } from "../translation"
import { dom, Fragment } from "../tsxrender/dom"
import { RoomTabs } from "./roomTabs"
import { TabName } from "./tabList"
import { toggleDms, userSwitchedTab } from "./userActionEvents"
import { getViewportWidth } from "./viewportDimension"
import type {
    IBioGame, IBioParsed,
    IBioPhotoset, IBioSocialMedia,
    IInfoSection,
} from "../bioContent"

const enum LoadingState {
    UNLOADED,
    LOADING,
    LOADED,
}

export class BioContent extends Component {
    private username: string
    private offline = false
    private loadingState: LoadingState

    constructor() {
        super()
        roomLoaded.listen((context) => {
            this.username = context.dossier.room

            if (RoomTabs.currentTab === TabName.Bio) {
                this.load()
            }
            this.offline = context.dossier.roomStatus === RoomStatus.Offline
        })

        userSwitchedTab.listen((tabName) => {
            if (tabName === TabName.Bio) {
                this.load()
            }
        })

        roomCleanup.listen(() => {
            this.removeAllDOMChildren()
            this.loadingState = LoadingState.UNLOADED
        })
    }

    protected initUI(): void {
        applyStyles(this.element, {
            padding: "19px",
            boxSizing: "border-box",
            overflowY: "scroll",
        })
    }

    private load(): void {
        if (this.isLoadingOrLoaded()) {
            return
        }

        this.removeAllDOMChildren()
        const loadingDiv = <LoadingMsg />
        this.element.appendChild(loadingDiv)
        this.loadingState = LoadingState.LOADING

        getBioContentPromise(this.username)
            .then((content: IBioParsed) => {
                this.element.appendChild(<BioContainer content={content} offline={this.offline} />)
                this.loadingState = LoadingState.LOADED
            })
            .catch((err) => {
                error("Bio Content error", err)
                this.element.appendChild(<ErrorMsg />)
                this.loadingState = LoadingState.UNLOADED
            })
            .finally(() => {this.element.removeChild(loadingDiv)})
    }

    private isLoadingOrLoaded(): boolean {
        return this.loadingState === LoadingState.LOADING || this.loadingState === LoadingState.LOADED
    }
}

const LoadingMsg = (): HTMLDivElement => <div>{`${i18n.loadingText}...`}</div>
const ErrorMsg = (): HTMLDivElement => <div>{i18n.bioUnavailableText}</div>
const SectionTitle = (props: { text: string, style?: CSSX.Properties }): HTMLSpanElement => {
    const style: CSSX.Properties = {
        fontFamily: "'UbuntuBold', Arial, Helvetica, sans-serif",
        display: "inline-block",
        color: "#49494F",
        fontWeight: "bold",
        ...props.style,
    }

    return <span style={style}>{props.text}</span>
}

const BioContainer = (props: { content: IBioParsed, offline: boolean }): HTMLDivElement => {
    const { gameSelection, infoSection, socialMedias, photoSets, aboutMe, wishList } = props.content
    const username = infoSection.username
    const showDMButton = props.offline && username !== pageContext.current.loggedInUser?.username
    const headerStyle: CSSX.Properties = {
        fontFamily: "'UbuntuBold', Arial, Helvetica, sans-serif",
        margin: 0,
        color: "#222",
        fontSize: "16px",
        marginBottom: "12px",
    }

    return (
        <div style={{ fontSize: "14px" }} data-testid="mobile-bio-tab-contents">
            <h2 style={headerStyle}>{i18n.bioAndFreeWebCamText(username)}</h2>
            {showDMButton && <SendDMButton username={username} />}
            {gameSelection !== undefined && gameSelection !== null
                && <GameInfoBar gameSelection={gameSelection} username={username} />}
            <InfoSection info={infoSection} />
            {socialMedias.length > 0
                && <SocialMediaSection socialMedias={socialMedias} username={username} />}
            {photoSets.length > 0
                && <PhotoVideoSection photosets={photoSets} username={username} />}
            {aboutMe !== ""
                && <UserContentSection htmlString={aboutMe} sectionTitle={i18n.aboutMeText} />}
            {wishList !== ""
                && <UserContentSection htmlString={wishList} sectionTitle={i18n.wishListText} />}
        </div>
    )
}

const SendDMButton = (props: { username: string }): HTMLButtonElement => {
    const style: CSSX.Properties = {
        width: "100%",
        fontFamily: "UbuntuMedium, Arial, Helvetica, sans-serif",
        fontSize: "16px",
        padding: "12px",
        color: "#FFF",
        border: "1px solid #CD5D26",
        backgroundColor: currentSiteSettings.isWhiteLabel ? currentSiteSettings.signupBgColor : "#F47321",
        textAlign: "center",
        borderRadius: "4px",
        boxSizing: "border-box",
        margin: "0px 0 16px 0",
        display: "inline-block",
    }

    const onClick = () => {
        if (isNotLoggedIn(i18n.loggedInToDm)) {
            return
        }

        toggleDms.fire(true)
        createDmWindowRequest.fire(props.username)
    }

    return <button style={style} onClick={onClick} data-testid="send-dm">{i18n.dmMenuTitle}</button>
}

const GameInfoBar = (props: { gameSelection: IBioGame, username?: string }): HTMLDivElement => {
    const containerStyle: CSSX.Properties = {
        border: "1px solid #eee",
        borderRadius: "3px",
        boxShadow: "0 3px 6px 0 rgba(90, 90, 90, 0.2), 0 6px 20px 0 rgba(90, 90, 90, 0.19)",
        margin: "4px 0 12px 0",
        padding: "4px",
        position: "relative",
    }
    const logoStyle: CSSX.Properties = {
        width: "42px",
        height: "42px",
        borderRadius: "5px",
        objectFit: "cover",
        overflow: "hidden",
    }
    const rightSideStyle: CSSX.Properties = {
        borderLeft: "2px solid #eee",
        bottom: 0,
        display: "inline-block",
        height: "100%",
        overflow: "ellipsis",
        paddingLeft: "4px",
        position: "absolute",
        top: 0,
    }
    const textBlockStyle: CSSX.Properties = {
        display: "inline-block",
        marginTop: "-0.5em",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
        verticalAlign: "middle",
    }

    return (
        <div style={containerStyle}>
            <div style={{ display: "inline-block", paddingRight: "4px" }}>
                <img style={logoStyle} src={props.gameSelection.image} />
            </div>
            <div style={rightSideStyle}>
                <div style={textBlockStyle}>
                    {`${props.username ?? "User"} is playing `}
                    <a href={props.gameSelection.gameUrl} target="_blank" rel="noopener">{props.gameSelection.name}</a>
                </div>
            </div>
        </div>
    )
}

const InfoSection = (props: { info: IInfoSection }): HTMLUListElement => {
    const { realName, followersCount, displayBirthday, displayAge, bodyType,
            bodyDecorations, sex: userCategory, subgender: subCategory, interestedIn, location,
            lastBroadcast, languages, smokeDrink, hasFanClub, isInFanClub, fanClubJoinUrl } = props.info
    const interestedInStr = interestedIn !== "" ? JSON.parse(interestedIn).join(", ") : ""
    const joinFanClubStyle: CSSX.Properties = {
        width: "100%",
        fontFamily: "UbuntuMedium, Arial, Helvetica, sans-serif",
        fontSize: "16px",
        padding: "12px",
        color: "#FFF",
        border: "1px solid #006800",
        backgroundColor: "#009900",
        textAlign: "center",
        borderRadius: "4px",
        boxSizing: "border-box",
        margin: "12px 0 16px 0",
        display: "inline-block",
    }

    const isNoninteractiveUser = pageContext.current.isNoninteractiveUser

    const UserCategoryRow = (): HTMLLIElement | "" => {
        if (userCategory === "") {
            return userCategory
        } else if (userCategory === "A Couple") {
            return <InfoRow title={i18n.sexTextCouple} info={userCategory} />
        } else if (userCategory === "Trans" && subCategory !== "") {
            return <InfoRow title={i18n.sexText} info={`${userCategory} (${subCategory})`} />
        } else {
            return <InfoRow title={i18n.sexText} info={userCategory} />
        }
    }

    return (
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            {<InfoRow title={i18n.realNameText} info={realName} />}
            {!isNaN(followersCount) && <InfoRow title={i18n.followersText} info={followersCount} />}
            {<UserCategoryRow />}
            {<InfoRow title={i18n.birthdayText} info={displayBirthday} />}
            {!isNaN(displayAge) && <InfoRow title={i18n.ageText} info={displayAge} />}
            {<InfoRow title={i18n.bodyTypeText} info={bodyType} />}
            {<InfoRow title={i18n.bodyDecorationsText} info={bodyDecorations} />}
            {<InfoRow title={i18n.interstedInText} info={interestedInStr} />}
            {<InfoRow title={i18n.locationText} info={location} />}
            {<InfoRow title={i18n.lastBroadcastText} info={lastBroadcast} />}
            {<InfoRow title={i18n.languagesText} info={languages} />}
            {<InfoRow title={i18n.smokeDrinkText} info={smokeDrink} />}
            {!isNoninteractiveUser && hasFanClub && !isInFanClub && <a style={joinFanClubStyle} href={fanClubJoinUrl} rel="noopener">{i18n.joinFanClub}</a>}
            {hasFanClub && isInFanClub && <a style={joinFanClubStyle} href={fanClubJoinUrl} rel="noopener">{i18n.inFanClub}</a>}
        </ul>
    )
}

const InfoRow = (props: { title: string, info: string | number | undefined }): HTMLLIElement | "" => {
    if (props.info === "" || props.info === undefined) {
        return ""
    }

    const infoStyle: CSSX.Properties = {
        textAlign: "right",
        width: "50%",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        hyphens: "auto",
    }

    return (
        <li style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
            <SectionTitle text={`${props.title}:`} style={{ width: "50%" }} />
            <span style={infoStyle}>{props.info}</span>
        </li>
    )
}

const SocialMediaSection = (props: { socialMedias: IBioSocialMedia[], username: string }): HTMLDivElement => {
    const containerStyle: CSSX.Properties = {
        position: "relative",
        paddingTop: "5px",
        paddingBottom: "25px",
    }
    const showMoreLinkStyle: CSSX.Properties = {
        display: "block",
        paddingTop: "10px",
        color: "#156b95",
        textDecoration: "none",
    }

    const socialMediaLimit = 8

    return (
        <div style={containerStyle}>
            <SectionTitle text={`${i18n.socialMediaText}:`} />
            <div style={{ textAlign: "center" }}>
                {props.socialMedias
                    .slice(0, socialMediaLimit)
                    .map(socialMedia => <SocialMediaThumbnail socialMedia={socialMedia} username={props.username} />)}
                {props.socialMedias.length > socialMediaLimit
                    && <a style={showMoreLinkStyle} href={`/socials/social_media/list_popup/${props.username}/`} target={`${props.username} social media`}>{i18n.showMoreText}</a>}
            </div>
        </div>
    )
}

const SocialMediaThumbnail = (props: { socialMedia: IBioSocialMedia, username: string }): HTMLAnchorElement => {
    const thumbnailWidth = (getViewportWidth() / 2) - 40
    const thumbnailStyle: CSSX.Properties = {
        textDecoration: "none",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "10px",
        position: "relative",
        display: "inline-block",
        height: "100px",
        border: "1px solid #ACACAC",
        borderRadius: "3%",
        maxWidth: "150px",
        width: `${thumbnailWidth}px`,
    }
    const imageStyle: CSSX.Properties = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "3%",
    }
    const tokensStyle: CSSX.Properties = {
        backgroundColor: props.socialMedia.labelColor,
        color: "#ffffff",
        position: "absolute",
        top: "80px",
        right: "8px",
        fontSize: "9px",
        borderRadius: "2px",
        padding: "1px 3px",
        lineHeight: "12px",
    }
    const titleStyle: CSSX.Properties = {
        color: "#156b95",
        maxWidth: "145px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    }

    const mediaTitle = <div style={titleStyle} data-testid="social-media-title">{props.socialMedia.titleName}</div>
    let label = ""
    if (props.socialMedia.labelText !== "") {
        label = <span style={tokensStyle} data-testid="social-media-tokens-badge">{props.socialMedia.labelText}</span>
    }
    return (
        <a
            style={thumbnailStyle}
            href={normalizeResource(props.socialMedia.link)}
            target="_self"
            className="userUpload"
            data-testid="social-media-item"
            title={props.socialMedia.titleName}
            onMouseEnter={() => mediaTitle.style.textDecoration = "underline"}
            onMouseLeave={() => mediaTitle.style.textDecoration = "none"}
        >
            <img style={imageStyle} src={props.socialMedia.imageUrl} data-testid="social-media-preview" />
            {label}
            {mediaTitle}
        </a>
    )
}

const PhotoVideoSection = (props: { photosets: IBioPhotoset[], username: string }): HTMLDivElement => {
    const containerStyle: CSSX.Properties = {
        position: "relative",
        paddingTop: "5px",
        paddingBottom: "25px",
    }
    const showMoreLinkStyle: CSSX.Properties = {
        display: "block",
        paddingTop: "10px",
        color: "#156b95",
        textDecoration: "none",
    }

    const photosetLimit = 8

    return (
        <div style={containerStyle}>
            <SectionTitle text={`${i18n.photosAndVideosText}:`} style={{ padding: "5px 10px 5px 0" }} />
            <div style={{ textAlign: "center" }}>
            {props.photosets
                .slice(0, photosetLimit)
                .map(photoset => <PhotoVideoThumbnail photoset={photoset} username={props.username} />)}
            {props.photosets.length > photosetLimit
                && <a style={showMoreLinkStyle} href={`/photo_videos/photoset/list_popup/${props.username}/`} target={`${props.username}_photosets`}>{i18n.showMoreText}</a>}
            </div>
        </div>
    )
}

const PhotoVideoThumbnail = (props: { photoset: IBioPhotoset, username: string }): HTMLAnchorElement => {
    const thumbnailWidth = (getViewportWidth() / 2) - 40
    const thumbnailStyle: CSSX.Properties = {
        textDecoration: "none",
        margin: "10px 5px 10px 5px",
        position: "relative",
        display: "inline-block",
        height: "100px",
        maxWidth: "150px",
        width: `${thumbnailWidth}px`,
    }
    const imageStyle: CSSX.Properties = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "3%",
        borderColor: "#ACACAC",
        display: "block",
    }
    const purchaseOverlayStyle: CSSX.Properties = {
        width: `${thumbnailWidth}px`,
        maxWidth: "150px",
        height: "100px",
        padding: "1px",
        borderRadius: "3%",
        position: "absolute",
        left: "0px",
        top: "0px",
        backgroundColor: "rgba(0, 0, 0, .55)",
    }
    const lockedImageStyle: CSSX.Properties = {
        marginLeft: `${-18 / 2}px`,
        marginTop: `${-18 / 2}px`,
        position: "absolute",
        left: "50%",
        top: "40%",
        padding: "4px",
        border: "none",
    }
    const tokenLabelStyle: CSSX.Properties = {
        backgroundColor: props.photoset.labelColor,
        color: "#fff",
        position: "absolute",
        bottom: "0",
        right: "0",
        margin: "8px",
        fontSize: "9px",
        padding: "1px 3px",
        lineHeight: "12px",
        borderRadius: "2px",
    }
    const purchasedBadgeStyle: CSSX.Properties = {
        backgroundColor: "#6d85b5",
        color: "#fff",
        position: "absolute",
        top: "80px",
        right: "8px",
        padding: "1px 3px",
        lineHeight: "12px",
        borderRadius: "2px",
        fontSize: "9px",
    }
    const noSoundImageStyle: CSSX.Properties = {
        height: "18px",
        position: "absolute",
        top: "8px",
        right: "32px",
    }
    const videoIconStyle: CSSX.Properties = {
        height: "18px",
        position: "absolute",
        top: "8px",
        right: "8px",
    }
    const titleStyle: CSSX.Properties = {
        color: "#156b95",
        maxWidth: "145px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    }

    const mediaTitle = <div style={titleStyle} data-testid="title">{props.photoset.name}</div>
    const purchaseOverlay = (
        <Fragment>
            <div style={purchaseOverlayStyle}></div>
            <img data-testid="lock-icon" style={lockedImageStyle} src={`${STATIC_URL}lock.svg`} />
            {props.photoset.labelText !== "" && <span style={tokenLabelStyle} colorClass="tokenText">{props.photoset.labelText}</span>}
        </Fragment>
    )
    const purchasedBage = <span style={purchasedBadgeStyle} colorClass="purchasedBadge" data-testid="token-badge">{i18n.purchasedCAPS}</span>
    const noSoundIcon = <img style={noSoundImageStyle} src={`${STATIC_URL}no-audio.svg`} />
    const videoIcon = <img style={videoIconStyle} src={`${STATIC_URL}video.svg`} />

    return (
        <a
            style={thumbnailStyle}
            href={normalizeResource(`/photo_videos/photoset/detail/${props.username}/${props.photoset.id}`)}
            target="_blank"
            className="userUpload"
            title={props.photoset.name}
            data-testid="photo-video-preview"
            onMouseEnter={() => mediaTitle.style.textDecoration = "underline"}
            onMouseLeave={() => mediaTitle.style.textDecoration = "none"}
            onClick={(e) => {
                addPhotoSetClickedPageAction(props.photoset, "BioTab")
            }}
        >
            <img style={imageStyle} src={props.photoset.coverUrl} data-testid="photo-video-preview-image" />
            {!props.photoset.userCanAccess && purchaseOverlay}
            {props.photoset.userCanAccess && props.photoset.userHasPurchased && purchasedBage}
            {props.photoset.isVideo && !props.photoset.videoHasSound && !props.photoset.pendingApproval && noSoundIcon}
            {props.photoset.isVideo && videoIcon}
            {mediaTitle}
        </a>
    )
}

const UserContentSection = (props: { htmlString: string, sectionTitle: string }): HTMLDivElement => {
    const contentContainer: HTMLDivElement = <div />
    contentContainer.innerHTML = props.htmlString // eslint-disable-line @multimediallc/no-inner-html
    sanitizeUserCreatedContent(contentContainer, contentContainer, true, true)

    const handleTouchStart = (event: TouchEvent) => {
        const maybeStopPropagation = (target: EventTarget) => {
            const targetElem = target as HTMLElement
            const hasHorizontalScrollbar = targetElem.scrollWidth > targetElem.clientWidth

            if (hasHorizontalScrollbar) {
                // prevent horizontal scroll from swiping tabs
                event.stopPropagation()
            }
        }

        if (event.target !== null) {
            maybeStopPropagation(event.target)
        }
        if (event.currentTarget !== null) {
            maybeStopPropagation(event.currentTarget)
        }
    }

    return (
        <div>
            <SectionTitle text={`${props.sectionTitle}:`} />
            <div style={{ width: "100%", overflow: "scroll" }} onTouchStart={handleTouchStart}>
                <div style={{ maxWidth: "800px", display: "inline-block" }}>
                    {contentContainer}
                </div>
            </div>
        </div>
    )
}
