import { getElapsedTimeString } from "@multimediallc/web-utils"
import { createBroadcasterConversationItem } from "../../cb/api/pm"
import { adjustedUserList, ConversationListData } from "../../cb/components/pm/conversationListData"
import { pageContext } from "../../cb/interfaces/context"
import { currentSiteSettings } from "../../cb/siteSettings"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { isAnonymous } from "../auth"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { addPageAction } from "../newrelic"
import { ParsedEmoticonMessage } from "../parseemoticons"
import { getUsernameColorClass } from "../roomUtil"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import { userInitiatedPm } from "../userActionEvents"
import type { IConversationListItem } from "../../cb/api/pm"
import type { SearchBar } from "../../cb/components/pm/searchBar"
import type { IUpdateSearchBarInfo } from "../../cb/components/pm/userActionEvents"
import type { EventRouter } from "../events"
import type { IUserInfo } from "../messageInterfaces"

interface IMobileConversationListProps {
    onItemRemoved: (username: string) => void
    openConversationEvent: EventRouter<string>
    isDropdown: boolean
    searchBar?: SearchBar
}

export class MobileConversationList extends Component<HTMLDivElement, IMobileConversationListProps> {
    public openConversationEvent: EventRouter<string>
    private conversationListData: ConversationListData | undefined
    private room: string
    private startedTimeUpdates: boolean
    private onItemRemoved: (username: string) => void
    private isDropdown: boolean
    private emptyListMessage?: HTMLDivElement
    private searchBar?: SearchBar

    constructor(props: IMobileConversationListProps) {
        super("div", props)
    }

    protected initData(props: IMobileConversationListProps): void {
        this.openConversationEvent = props.openConversationEvent
        this.room = ""
        this.startedTimeUpdates = false
        this.onItemRemoved = props.onItemRemoved
        this.isDropdown = props.isDropdown
        this.searchBar = props.searchBar

        if (!isAnonymous()) {
            this.conversationListData = ConversationListData.getInstance()
        }
    }

    protected initUI(props: IMobileConversationListProps): void {
        applyStyles(this.element, {
            position: "relative",
            overflowX: "hidden",
            overflowY: "scroll",
        })

        if (this.isDropdown) {
            this.emptyListMessage = <EmptyListMessage /> as HTMLDivElement
            this.element.appendChild(this.emptyListMessage)
        } else {
            userInitiatedPm.listen((pm) => {
                const pmData = this.conversationListData?.getConversation(pm.username)

                if (pmData !== undefined) {
                    this.updateList(pmData)
                }
            })
        }

        roomLoaded.listen((context) => {
            this.room = context.chatConnection.room()

            if (this.isDropdown) {
                this.updateUserColors()
            } else {
                this.clearItems()

                if (pageContext.current.loggedInUser?.username !== this.room) {
                    this.addBroadcasterToTop()
                }
            }
        })

        ConversationListData.conversationDataChanged.listen(() => {
            if (!isAnonymous() && this.conversationListData === undefined) {
                this.conversationListData = ConversationListData.getInstance()
            }
        })

        ConversationListData.conversationItemAdded.listen((newConversation) => {
            if ((this.isDropdown && newConversation.room !== "") || (!this.isDropdown && newConversation.room === "")) {
                return
            }

            this.updateList(newConversation)
        })

        ConversationListData.conversationRead.listen(({ username, isDm }) => {
            if (isDm === this.isDropdown) {
                this.markItemRead(username)
            }
        })

        this.startTimeContainerUpdates()

        if (this.searchBar !== undefined) {
            this.initSearchBarListeners(this.searchBar)
        }
    }

    private initSearchBarListeners(searchBar: SearchBar): void {
        // iOS has lots of bugs regarding scrolling with keyboard open + fixed elements,
        // so remove keybaord before start of scroll
        addEventListenerPoly("touchstart", window, (event: TouchEvent) => {
            const searchBarInput = searchBar.getInput()

            if (document.activeElement === searchBarInput && !(event.target === searchBarInput)) {
                searchBarInput.blur()
            }
        })

        this.openConversationEvent.listen(() => {
            this.filterConversations("")
        })

        searchBar.events.inputChange.listen((info: IUpdateSearchBarInfo) => {
            if (info.isValid) {
                this.filterConversations(info.prefix)
            } else {
                this.clearItems()
            }
        })
    }

    private filterConversations(prefix: string): void {
        if (this.conversationListData !== undefined) {
            this.clearItems()
            const conversationList = this.isDropdown
                ? this.conversationListData.getDms()
                : this.conversationListData.getPms()
            const filterPrefix = (conversation: IConversationListItem) => prefix.length === 0 || conversation.otherUser.username.startsWith(prefix)
            const filteredConversations = conversationList.filter(filterPrefix)

            filteredConversations.reverse().forEach((conversation) => {this.updateList(conversation)})
        }
    }

    private updateList(conversation: IConversationListItem, moveToTop = true): void {
        if (this.emptyListMessage !== undefined && this.element.contains(this.emptyListMessage)) {
            this.element.removeChild(this.emptyListMessage)
        }

        let item = this.findItem(conversation.otherUser.username)

        if (item !== undefined) {
            item.updateItem(conversation)

            if (adjustedUserList.isHiding(conversation.otherUser.username)) {
                this.addChild(item)
            }
        } else {
            item = new MobileConversationListItem({
                conversationListItem: conversation,
                onClick: () => {this.openConversationEvent.fire(conversation.otherUser.username)},
                onCloseButtonClick: () => {this.removeItem(conversation.otherUser.username)},
                isDropdown: this.isDropdown,
            })
            this.addChild(item)
            addPageAction("PmListItemAdded", {
                "rendered_pm_count": this.getAllItems().length,
                "other_user": conversation.otherUser.username,
            })
        }

        adjustedUserList.remove(conversation.otherUser.username)

        if (moveToTop) {
            this.moveToTop(item)
        }
    }

    private updateUserColors(): void {
        if (!this.isDropdown) {
            return
        }

        this.conversationListData?.getDms().forEach((conversation: IConversationListItem) => {
            const currItem = this.findItem(conversation.otherUser.username)

            if (currItem !== undefined) {
                currItem.updateColors(conversation.otherUser)
            }
        })
    }

    private moveToTop(conversation: MobileConversationListItem) {
        if (this.isDropdown) {
            if (this.element.firstChild !== null) {
                this.element.insertBefore(conversation.element, this.element.firstChild)
            } else {
                this.element.appendChild(conversation.element)
            }
        } else {
            if (this.element.firstChild !== null && this.element.firstChild.nextSibling !== null) {
                this.element.insertBefore(conversation.element, this.element.firstChild.nextSibling)
            } else {
                this.element.appendChild(conversation.element)
            }
        }
    }

    private findItem(username: string): MobileConversationListItem | undefined {
        return this.getAllItems().find((item) => item.getItemInfo().otherUser.username === username)
    }

    private markItemRead(username: string): void {
        const existingElem = this.findItem(username)

        if (existingElem !== undefined) {
            existingElem.setReadStyle()
        }
    }

    private addBroadcasterToTop(): void {
        const broadcasterItem = this.conversationListData?.getConversation(this.room) ?? createBroadcasterConversationItem(this.room)
        let item = this.findItem(this.room)

        if (item === undefined) {
            item = new MobileConversationListItem({
                conversationListItem: broadcasterItem,
                onClick: () => {this.openConversationEvent.fire(this.room)},
                onCloseButtonClick: () => {this.removeItem(broadcasterItem.otherUser.username)},
                isDropdown: this.isDropdown,
            })
        }

        if (this.getAllItems().length > 0) {
            this.addChildBefore(item, this.getAllItems()[0])
        } else {
            this.addChild(item)
        }
    }

    public getAllItems(): MobileConversationListItem[] {
        return super.children() as MobileConversationListItem[]
    }

    public getNumUnread(): number {
        // get num unread from rendered items
        return this.getAllItems().filter(item => item.getItemInfo().numUnread > 0).length
    }

    private removeItem(username: string): void {
        const item = this.findItem(username)

        if (item !== undefined && this.element.contains(item.element)) {
            this.removeChild(item)
            adjustedUserList.hide(username)
            this.onItemRemoved(item.getItemInfo().otherUser.username)
            addPageAction("PmListItemRemoved", {
                "rendered_pm_count": this.getAllItems().length,
                "other_user": username,
            })
        }
    }

    private clearItems(): void {
        this.removeAllChildren()
    }

    private startTimeContainerUpdates(): void {
        if (this.startedTimeUpdates) {
            return
        }

        this.startedTimeUpdates = true
        const intervalMS = 10000
        window.setInterval(() => {
            for (const item of this.getAllItems()) {
                item.setTimestamp()
            }
        }, intervalMS)
    }
}

interface IMobileConversationListItemProps {
    conversationListItem: IConversationListItem
    onClick: () => void
    onCloseButtonClick: () => void
    isDropdown: boolean
}

class MobileConversationListItem extends Component<HTMLDivElement, IMobileConversationListItemProps> {
    private conversationListItem: IConversationListItem
    private containerElement: HTMLDivElement
    private replyIcon: HTMLImageElement
    private timestampElement: HTMLSpanElement
    private msgElement: HTMLSpanElement
    private closeButton: HTMLImageElement
    private avatarBubble: HTMLDivElement
    private usernameLabel: HTMLSpanElement

    constructor(props: IMobileConversationListItemProps) {
        super("div", props)
    }

    protected initData(props: IMobileConversationListItemProps): void {
        this.conversationListItem = props.conversationListItem
    }

    protected initUI(props: IMobileConversationListItemProps): void {
        const containerStyle: CSSX.Properties = {
            width: "100%",
            height: "90px",
            boxSizing: "border-box",
            borderBottom: "1px solid #EFEFEF",
            padding: "20px",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
        }
        const contentWrapperStyle: CSSX.Properties = {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "100%",
        }
        const usernameLabelStyle: CSSX.Properties = {
            display: "block",
            fontWeight: "bold",
            marginBottom: "5px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            flex: 1,
            width: 0,
            marginRight: "10px",
        }
        const timestampStyle: CSSX.Properties = {
            color: "#6C6C78",
        }
        const messageWrapperStyle: CSSX.Properties = {
            display: "flex",
            flex: 1,
            alignItems: "center",
        }
        const messageContainerStyle: CSSX.Properties = {
            display: "flex",
        }
        const messageHeaderStyle: CSSX.Properties = {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
        }
        const messageStyle: CSSX.Properties = {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            flex: "1",
            width: "0px",
        }
        const closeButtonContainerStyle: CSSX.Properties = {
            width: "18px",
            height: "18px",
            marginLeft: "20px",
        }
        const closeButtonStyle: CSSX.Properties = {
            width: "18px",
            height: "18px",
            display: "inline-block",
        }

        this.replyIcon = <ReplyIcon />
        this.closeButton = <img src={`${STATIC_URL_MOBILE}close.svg`} style={closeButtonStyle} onClick={props.onCloseButtonClick} />
        this.avatarBubble = (
            <MobileAvatarBubble
                name={props.conversationListItem.otherUser.username}
                userColorClass={getUsernameColorClass(props.conversationListItem.otherUser)}
            />
        )

        this.element = (
            <div style={containerStyle} ref={(el: HTMLDivElement) => this.containerElement = el} data-testid="conversation-list-item">
                <div style={messageWrapperStyle} onClick={props.onClick}>
                    {this.avatarBubble}
                    <div style={contentWrapperStyle}>
                        <div style={messageHeaderStyle}>
                            <span
                                style={usernameLabelStyle}
                                colorClass={getUsernameColorClass(props.conversationListItem.otherUser)}
                                ref={(el: HTMLSpanElement) => {this.usernameLabel = el}}
                                data-testid="conversation-list-username"
                            >
                                {props.conversationListItem.otherUser.username}
                            </span>
                            <span style={timestampStyle} ref={(el: HTMLSpanElement) => {this.timestampElement = el}} data-testid="message-timestamp"></span>
                        </div>
                        <span style={messageContainerStyle}>
                            {this.replyIcon}
                            <span style={messageStyle} ref={(el: HTMLSpanElement) => {this.msgElement = el}} data-testid="message-preview"></span>
                        </span>
                    </div>
                </div>
                {!props.isDropdown && <div style={closeButtonContainerStyle}>
                    {!props.conversationListItem.otherUser.isBroadcaster && this.closeButton}
                </div>}
            </div>
        )
        this.updateItem(props.conversationListItem)
    }

    public updateItem(newItem: IConversationListItem): void {
        this.conversationListItem = newItem
        this.setReplyIcon()
        this.setMessage()
        this.setTimestamp()
        this.conversationListItem.numUnread > 0 ? this.setUnreadStyle() : this.setReadStyle()
    }

    public updateColors(user: IUserInfo): void {
        const newColorClass = getUsernameColorClass(user)
        this.usernameLabel.className = newColorClass

        if (this.avatarBubble.firstChild instanceof HTMLElement) {
            this.avatarBubble.firstChild.className = newColorClass
        }
    }

    public getItemInfo(): IConversationListItem {
        return this.conversationListItem
    }

    private setReplyIcon(): void {
        const showReplyIcon = this.isReply() && !this.isEmptyMessage()
        this.replyIcon.style.display = showReplyIcon ? "block" : "none"
    }

    private setMessage(): void {
        if (this.msgElement.firstChild !== null) {
            this.msgElement.removeChild(this.msgElement.firstChild)
        }
        if (this.conversationListItem.message !== "") {
            this.msgElement.appendChild(this.renderConversationEmoticons(this.conversationListItem.message))
        } else if (this.conversationListItem.hasMedia) {
            this.msgElement.appendChild(<span>{i18n.imageAttached}</span>)
        }
    }

    public setTimestamp(): void {
        if (!this.isEmptyMessage()) {
            const time = this.conversationListItem.time
            const elapsedTimeString = time === undefined ? "" : getElapsedTimeString(new Date(time))
            this.timestampElement.innerText = `${elapsedTimeString}`
        }
    }

    private renderConversationEmoticons(message: string): HTMLSpanElement {
        const parsedEmoticonMessage: ParsedEmoticonMessage = new ParsedEmoticonMessage(message)
        const span = <span></span>
        const firstStringPart = parsedEmoticonMessage.stringParts()[0]
        span.innerText = firstStringPart.split("\n")[0]

        for (let i = 1; i < parsedEmoticonMessage.stringParts().length; i += 1) {
            const noEmoticonSpan = <span></span>
            noEmoticonSpan.innerText = `:${parsedEmoticonMessage.emoticons()[i - 1].name}`
            span.appendChild(noEmoticonSpan)

            if (parsedEmoticonMessage.stringParts()[i] !== "") {
                const stringPartSpan = <span></span>
                stringPartSpan.innerText = parsedEmoticonMessage.stringParts()[i]
                span.appendChild(stringPartSpan)
            }
        }
        return span
    }

    private isReply(): boolean {
        return this.conversationListItem.otherUser.username !== this.conversationListItem.fromUsername
    }

    private isEmptyMessage(): boolean {
        return this.conversationListItem.message === "" && !this.conversationListItem.hasMedia
    }

    public setReadStyle(): void {
        this.containerElement.style.backgroundColor = "#FFF"
    }

    public setUnreadStyle(): void {
        this.containerElement.style.backgroundColor = "#FCEADC"
    }
}

const ReplyIcon = () => {
    return <img src={`${STATIC_URL_MOBILE}reply-icon.svg`} />
}

const MobileAvatarBubble = (props: { name: string, userColorClass: string, style?: CSSX.Properties }): HTMLDivElement => {
    const avatarBubbleStyle: CSSX.Properties = {
        width: "45px",
        height: "45px",
        borderRadius: "50%",
        textAlign: "center",
        color: "#FFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "UbuntuLight, Tahoma, Arial, Helvetica, sans-serif",
        fontSize: "20px",
        margin: "auto 10px auto auto",
        verticalAlign: "middle",
    }

    return (
        <div colorClass="avatarBubble" style={{ ...{ position: "relative" }, ...props.style }}>
            <div colorClass={props.userColorClass} style={avatarBubbleStyle}>{props.name.charAt(0).toLocaleUpperCase()}</div>
        </div>
    )
}

const EmptyListMessage = (): HTMLDivElement => {
    const containerStyle: CSSX.Properties = {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        lineHeight: "20px",
        padding: "50px",
        boxSizing: "border-box",
        fontSize: "12px",
        fontFamily: "UbuntuRegular, Helvetica, Arial, sans-serif",
    }
    const imageStyle: CSSX.Properties = {
        margin: "13px",
        width: "30px",
    }
    const sendMessageStyle: CSSX.Properties = {
        fontFamily: "UbuntuBold, Arial, Helvetica, sans-serif",
        fontSize: "14px",
    }

    return (
        <div style={containerStyle}>
            <img src={`${STATIC_URL_MOBILE}empty-chat-state.svg`} style={imageStyle} alt="empty-chat-state" />
            <span style={sendMessageStyle}>{i18n.sendDirectMessage}</span>
            <span>{i18n.conversationCautionMessage(currentSiteSettings.siteName)}</span>
        </div>
    )
}
