import {
    FILTER_PANEL_OPEN_CACHE_KEY,
    FILTER_PANEL_SELECTED_PARAMS_CACHE_KEY,
    getFilterPanelPartialState,
    getPageHashtag,
    N_DAYS_HOMEPAGE_FILTERS_CACHE_EXP, TAG_FILTER_KEY, UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { Gender, getCurrentGender } from "@multimediallc/gender-utils"
import { setLocalStorageWithExpiration } from "@multimediallc/web-utils/storage"
import { isFilterInPathActive } from "../../../../common/featureFlagUtil"
import { buildQueryString, parseQueryString } from "../../../../common/urlUtil"

export function setFilterPanelOpenCached(isOpen: boolean): void {
    setLocalStorageWithExpiration(FILTER_PANEL_OPEN_CACHE_KEY, String(isOpen), N_DAYS_HOMEPAGE_FILTERS_CACHE_EXP)
}

export function getGenderForTagsApi(): Gender {
    const gender = getCurrentGender()
    return (gender === Gender.Trans) ? Gender.OldTrans : gender
}

export function getFilterPanelSelectionsQueryString(): string {
    // Constructs and returns the url query string representing the current state of the homepage regions,
    // private prices, tags, ages, and room size filters.  Other current URL state properties which may have
    // query param representations (such as keywords and page params) are ignored.
    const url = new URL(UrlState.current.convertStateToUrl(getFilterPanelPartialState(UrlState.current.state), false))
    const queryParams = parseQueryString(url.search)

    const currentTag = getPageHashtag()
    if (currentTag !== undefined) {
        queryParams[TAG_FILTER_KEY] = currentTag
    }
    return Object.keys(queryParams).length === 0 ? "" : `?${buildQueryString(queryParams)}`
}

/**
 * Caches the homepagefilter into session storage and/or local storage
 * @param sessionStorageOnly only cache in session storage
 */
export function cacheHomepageFilters(sessionStorageOnly = false): void {
    if (isFilterInPathActive()) {
        return
    }
    const filterPanelAllQueryParams = getFilterPanelSelectionsQueryString()
    if (window.sessionStorage !== undefined) {
        window.sessionStorage.setItem(FILTER_PANEL_SELECTED_PARAMS_CACHE_KEY, filterPanelAllQueryParams)
    }
    if (!sessionStorageOnly) {
        setLocalStorageWithExpiration(FILTER_PANEL_SELECTED_PARAMS_CACHE_KEY, filterPanelAllQueryParams, N_DAYS_HOMEPAGE_FILTERS_CACHE_EXP)
    }
}
