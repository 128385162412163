import { isWebRTCNotYetSupported, isWebRTCSupported } from "@multimediallc/web-utils/modernizr"
import { OBSBroadcastStartStop } from "../../cb/broadcastStatus"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { modalConfirm } from "../alerts"
import { postCb } from "../api"
import { Component } from "../defui/component"
import { EventRouter } from "../events"
import { returnFromAway } from "../privateShow"
import { ignoreCatch } from "../promiseUtils"
import { styleTransition } from "../safeStyle"
import { i18n } from "../translation"
import { OriginWatcher } from "./originWatcher"
import {
    audio_codec_map,
    FeedbackType,
    getValue,
    ResolutionType,
    video_codec_map,
} from "./streamWatcher"
import type { IPollingStatusUpdate, IStreamDataUpdate } from "./streamWatcher"

export class OBSOverlay extends Component {
    private roomStatus = "offline"
    private obsInfo = this.createLink("Show RTMP/OBS broadcast information")
    private backToWebRTC = this.createLink("Use In-Browser Broadcaster")
    private originWatcher = new OriginWatcher(this.originWSEndpoint)
    private monitorTimeout: number | undefined
    private obsRoomStatusBlock = document.createElement("div")
    private obsRoomStatusContainer = document.createElement("div")
    private obsRoomStatus = document.createElement("span")
    private obsExitAway = document.createElement("div")
    private obsStreamStatsBlock = document.createElement("div")
    private obsStreamStatsOther = document.createElement("div")
    private obsAlternativeStreamType = document.createElement("p")
    private obsStreamRegion = document.createElement("div")
    private obsStreamGraphFPS = document.createElement("div")
    private obsStreamGraphBitrate = document.createElement("div")
    private obsStreamGraphFPSDisp = document.createElement("span")
    private obsStreamGraphBitrateDisp = document.createElement("span")
    private obsDetailsBoxTimeDisp = document.createElement("span")
    private obsDetailsBoxResDisp = document.createElement("span")
    private obsDetailsBoxCodecVideo = document.createElement("span")
    private obsDetailsBoxCodecAudio = document.createElement("span")
    private statsLoadingMessage = document.createElement("div")
    private resolutionFloatBox = document.createElement("div")
    private resolutionFloatBoxText = document.createElement("div")
    private obsStreamFeedbackBox = document.createElement("div")
    private obsFeedbackBoxHeader = document.createElement("h4")
    private obsFeedbackBoxMessages = document.createElement("div")
    private obsStreamRejected = document.createElement("div")
    private obsStreamRejectedReason = document.createElement("p")
    private obsControl = document.createElement("div")
    public obsStarted = new EventRouter<undefined>("obsStarted")

    constructor(private originWSEndpoint: string, private roomNameStaffView?: string) {
        super()
        this.element.id = "OBSOverlay"
        const isStaffView = roomNameStaffView !== undefined

        this.createOBSVideo()
        if (!isStaffView) {
            this.hide()
            this.createOtherStreamOptions()
        }

        this.originWatcher.alternativeStreamEvent.listen((streamType: string) => {
            this.obsStreamStatsOther.style.display = ""
            this.updateAlternativeStreamType(streamType)
            this.obsStreamStatsBlock.style.display = "none"
            this.statsLoadingMessage.style.display = "none"
        })
        this.originWatcher.getStreamWatcher().closeEvent.listen(() => {
            this.obsStreamStatsBlock.style.display = "none"
            this.statsLoadingMessage.style.display = "none"

            // Reset OBS stats
            this.styleGraph(this.obsStreamGraphFPS, FeedbackType.none)
            this.styleGraph(this.obsStreamGraphBitrate, FeedbackType.none)
            this.styleFeedbackBox(FeedbackType.none)
            this.obsStreamGraphFPSDisp.innerText = ""
            this.obsStreamGraphBitrateDisp.innerText = ""
            this.resolutionFloatBox.title = ""
            this.obsDetailsBoxCodecVideo.innerText = ""
            this.obsDetailsBoxCodecAudio.innerText = ""
            this.obsDetailsBoxTimeDisp.innerText = ""
            this.obsDetailsBoxResDisp.innerText = ""
        })
        this.originWatcher.getStreamWatcher().startPollingEvent.listen(() => {
            this.statsLoadingMessage.style.display = "none"
            this.styleFeedbackBox(FeedbackType.none)
            this.obsStreamStatsBlock.style.display = ""
        })
        this.originWatcher.getStreamWatcher().pollingStatusUpdate.listen((update: IPollingStatusUpdate) => {
            this.resolutionFloatBox.title = i18n.obsStreamClass(update.resolutionTitle)
            this.resolutionFloatBoxText.innerText = update.resolutionText
            this.styleResolutionFloatBox(update.resolutionType)
            this.styleFeedbackBox(update.feedbackType)
            this.styleGraph(this.obsStreamGraphFPS, update.fpsFeedback)
            this.styleGraph(this.obsStreamGraphBitrate, update.bitrateFeedback)
            this.obsFeedbackBoxHeader.innerText = update.feedbackHeader
            this.obsFeedbackBoxMessages.replaceChildren() // clear the feedback messages before adding new ones
            let first = true
            for (const message of update.feedbackMessages) {
                if (!first) {
                    this.obsFeedbackBoxMessages.appendChild(document.createElement("br"))
                }
                first = false
                this.obsFeedbackBoxMessages.appendChild(message)
            }
        })
        this.originWatcher.getStreamWatcher().streamDataUpdate.listen((update: IStreamDataUpdate) => {
            this.obsStreamStatsBlock.style.display = ""
            this.obsStreamRegion.innerText = i18n.obsConnectionDescription(update.host, update.region, update.streamType)
            this.obsStreamGraphFPSDisp.innerText = i18n.obsFPS(Math.round(update.fps))
            this.obsStreamGraphBitrateDisp.innerText = i18n.obsBitrate(Math.round(update.bitrate))
            this.obsDetailsBoxTimeDisp.innerText = `${update.streamTime}`
            this.obsDetailsBoxResDisp.innerText = `${update.videoWidth}x${update.videoHeight}`
            this.obsDetailsBoxCodecVideo.innerText = `${update.videoCodec}`
            this.obsDetailsBoxCodecAudio.innerText = `${update.audioCodec}`
        })
        this.originWatcher.roomStatusUpdate.listen((status: string) => {
            this.updateRoomStatus(status)
        })
    }

    public monitorRoomStatus(): void {
        if (this.monitorTimeout !== undefined) {
            clearTimeout(this.monitorTimeout)
        }
        const url = `api/get_my_broadcast_status/${(this.roomNameStaffView !== undefined) ?
            `${this.roomNameStaffView}/` : ""}${this.roomStatus === "offline" ? "?auth=1" : ""}`
        // eslint-disable-next-line complexity
        postCb(url, {}).then((xhr) => {
            type StreamRejectError = {
                audio_codec: keyof typeof audio_codec_map,
                audio_codec_hash: string,
                reasons: string[],
                video_codec: keyof typeof video_codec_map,
                video_codec_hash: string,
                height: string,
                video_fps: number,
            }
            const data: {
                "status": string | undefined,
                "username": string | undefined,
                "errors": StreamRejectError | undefined,
                "originPassword": string | undefined,
                "origin": string | undefined,
            } = JSON.parse(
                xhr.responseText,
            )
            if (!this.originWatcher.isConnected()) {
                this.updateRoomStatus(data["status"])
            }
            const errors = data["errors"]
            if (errors !== null && errors !== undefined && data["status"] === "offline") {
                this.obsStreamRejected.style.display = ""
                let content = "Your stream was recently rejected for the following reasons:<br />"
                if (errors["reasons"] !== undefined) {
                    errors["reasons"].forEach((reason: string) => {
                        switch (reason) {
                            case "ratio":
                                content = `${content}Having an incorrect ratio, please either broadcast at 4:3 or 16:9.<br />`
                                break
                            case "height":
                                content = `${content}Having an invalid height of ${getValue(errors["height"], "Unknown")} pixels, the stream height must be between 240 and 2160 pixels.<br />`
                                break
                            case "codec_video":
                                // @ts-ignore undefined check is okay here
                                content = `${content}Having an invalid video codec of ${getValue(video_codec_map[getValue(errors["video_codec"], "")], "Unknown")}, the stream must use the H.264 video codec baseline, main or high 4:2:0.<br />`
                                break
                            case "codec_audio":
                                // @ts-ignore undefined check is okay here
                                content = `${content}Having an invalid video codec of ${getValue(audio_codec_map[getValue(errors["audio_codec"], "")], "Unknown")} the stream must use the AAC audio codec.<br />`
                                break
                            case "high_res_fps":
                                content = `${content}Having a high frame rate and resolution. Resolutions above 1080p are limited to 30fps.<br />`
                                break
                            case "video_fps":
                                content = `${content}Having an invalid video fps of ${Math.round(getValue(errors["video_fps"], 0))} the stream must be at least 24 fps.<br />`
                                break
                            default:
                                content = `${content}An unknown reason.<br />`
                        }
                    })
                }
                this.obsStreamRejectedReason.innerHTML = content // eslint-disable-line @multimediallc/no-inner-html
            }
            const origin = data["origin"]
            const username = data["username"]
            const originPassword = data["originPassword"]
            const status = data["status"]
            if (origin !== undefined && username !== undefined && originPassword !== undefined && status !== undefined) {
                if (!this.originWatcher.isConnected() || (this.originWatcher.getOrigin() !== origin && status !== "offline")) {
                    this.originWatcher.update(origin, username, originPassword)
                }
            }
        }).catch(ignoreCatch)
        this.monitorTimeout = window.setTimeout(() => {
            this.monitorRoomStatus()
        }, 10 * 1000)
    }

    private createOBSVideo(): void {
        const obsVideo = document.createElement("div")
        const obsRoomStatusLabel = document.createElement("span")
        const obsStatusDisplay = document.createElement("div")
        const obsStreamStats = document.createElement("div")
        const obsStreamFeedback = document.createElement("div")
        const obsStreamDetails = document.createElement("div")
        const obsDetailsBoxResolution = this.createDetailsBox()
        const obsDetailsBoxStreamTime = this.createDetailsBox()
        const obsDetailsBoxCodec = this.createDetailsBox()
        const obsStreamGraphs = document.createElement("div")

        obsVideo.appendChild(obsStatusDisplay)
        obsStatusDisplay.appendChild(this.obsRoomStatusBlock)
        this.obsRoomStatusContainer.dataset.testid = "obs-room-status"
        this.obsRoomStatusContainer.appendChild(obsRoomStatusLabel)
        this.obsRoomStatusContainer.appendChild(this.obsRoomStatus)
        this.obsRoomStatusBlock.appendChild(this.obsRoomStatusContainer)
        this.obsRoomStatusBlock.appendChild(this.obsExitAway)
        obsStatusDisplay.appendChild(obsStreamStats)
        obsStreamStats.appendChild(this.obsStreamRejected)
        obsStreamStats.appendChild(this.statsLoadingMessage)
        obsStreamStats.appendChild(this.obsStreamStatsBlock)
        this.obsStreamStatsBlock.appendChild(obsStreamFeedback)
        obsStreamFeedback.appendChild(this.obsStreamFeedbackBox)
        this.obsStreamStatsBlock.appendChild(obsStreamDetails)
        obsStreamDetails.appendChild(obsDetailsBoxResolution)
        this.resolutionFloatBox.appendChild(this.resolutionFloatBoxText)
        obsStreamDetails.appendChild(obsDetailsBoxStreamTime)
        obsStreamDetails.appendChild(obsDetailsBoxCodec)
        this.obsStreamStatsBlock.appendChild(obsStreamGraphs)
        obsStreamGraphs.appendChild(this.obsStreamGraphFPS)
        obsStreamGraphs.appendChild(this.obsStreamGraphBitrate)
        this.obsStreamStatsBlock.appendChild(this.obsStreamRegion)
        obsStreamStats.appendChild(this.obsStreamStatsOther)
        this.element.appendChild(obsVideo)

        obsStreamDetails.style.lineHeight = "18px"

        addColorClass(obsRoomStatusLabel, "roomStatusLabel")
        obsRoomStatusLabel.innerText = i18n.yourStreamIsCurrently

        this.obsRoomStatusContainer.style.textAlign = "center"
        this.obsRoomStatusContainer.style.display = "none"
        this.obsRoomStatusContainer.style.fontSize = "1.1em"
        this.obsRoomStatusContainer.style.paddingTop = "20px"

        addColorClass(this.obsRoomStatus, "roomStatus")
        this.obsRoomStatus.style.fontWeight = "bolder"
        obsStatusDisplay.style.font = "1.2em/1.4em 'UbuntuRegular', Arial, Helvetica, sans-serif"
        obsStatusDisplay.style.height = "460px"

        this.obsStreamStatsBlock.style.display = "none"
        this.obsRoomStatusBlock.style.height = "145px"
        this.obsRoomStatusBlock.style.lineHeight = "1em"
        this.obsRoomStatusBlock.style.textAlign = "center"

        addColorClass(this.obsStreamRegion, "streamRegion")
        this.obsStreamRegion.style.textAlign = "left"
        this.obsStreamRegion.style.fontSize = "9px"
        this.obsStreamRegion.style.paddingLeft = "5px"

        obsStreamStats.style.fontWeight = "normal"

        obsStreamDetails.style.width = "100%"
        obsStreamDetails.style.padding = "8px"
        obsStreamDetails.style.height = "55px"
        obsStreamDetails.style.boxSizing = "border-box"
        obsStreamDetails.style.textAlign = "center"

        obsStreamGraphs.style.width = "100%"
        obsStreamGraphs.style.height = "110px"
        obsStreamGraphs.style.padding = "3px"
        obsStreamGraphs.style.boxSizing = "border-box"
        obsStreamGraphs.style.marginTop = "5px"

        this.obsStreamGraphFPS.style.cssFloat = "left"
        this.obsStreamGraphFPS.style.width = "240px"
        this.obsStreamGraphFPS.style.textAlign = "center"
        styleTransition(this.obsStreamGraphFPS, "background 500ms ease-in-out")
        this.obsStreamGraphBitrate.style.cssFloat = "right"
        this.obsStreamGraphBitrate.style.width = "240px"
        this.obsStreamGraphBitrate.style.textAlign = "center"
        styleTransition(this.obsStreamGraphBitrate, "background 500ms ease-in-out")

        this.obsExitAway.style.fontSize = "0.9em"
        this.obsExitAway.style.lineHeight = "1.4em"
        this.obsExitAway.style.maxWidth = "90%"
        this.obsExitAway.style.margin = "10px auto 0"
        this.obsExitAway.style.display = "none"

        obsStreamFeedback.style.width = "100%"
        obsStreamFeedback.style.padding = "5px"
        obsStreamFeedback.style.boxSizing = "border-box"

        this.obsStreamFeedbackBox.style.width = "85%"
        this.obsStreamFeedbackBox.style.padding = "5px"
        this.obsStreamFeedbackBox.style.textAlign = "center"
        this.obsStreamFeedbackBox.style.fontSize = "12px"
        this.obsStreamFeedbackBox.style.margin = "4px auto 0"

        addColorClass(this.obsStreamRejected, "streamRejected")
        this.obsStreamRejected.style.display = "none"
        this.obsStreamRejected.style.width = "85%"
        this.obsStreamRejected.style.padding = "5px"
        this.obsStreamRejected.style.textAlign = "center"
        this.obsStreamRejected.style.color = "#ffffff"
        this.obsStreamRejected.style.fontSize = "14px"
        this.obsStreamRejected.style.margin = "15px auto 0"
        this.obsStreamRejected.style.borderWidth = "1px"
        this.obsStreamRejected.style.borderStyle = "solid"

        this.resolutionFloatBox.style.display = "none"
        this.resolutionFloatBox.style.fontSize = "16px"
        this.resolutionFloatBox.style.width = "33px"
        this.resolutionFloatBox.style.height = "23px"
        this.resolutionFloatBox.style.borderRadius = "3px"
        this.resolutionFloatBox.style.position = "absolute"
        this.resolutionFloatBox.style.right = "2px"
        this.resolutionFloatBox.style.top = "10px"
        this.resolutionFloatBox.style.fontWeight = "bold"
        this.resolutionFloatBox.style.textAlign = "center"
        this.resolutionFloatBoxText.style.padding = "10% 0"

        const awayMessage = document.createElement("p")
        addColorClass(awayMessage, "obsAwayMessage")
        awayMessage.innerText = i18n.obsAwayMessage
        awayMessage.style.margin = "3px"
        const exitAwayLink = this.createLink("Exit Away Mode")
        addColorClass(exitAwayLink, "exitAwayLink")
        exitAwayLink.style.fontSize = "1.6em"
        exitAwayLink.onclick = (event: Event) => {
            event.preventDefault()
            returnFromAway()
        }
        this.obsExitAway.appendChild(awayMessage)
        this.obsExitAway.appendChild(exitAwayLink)

        const streamRejectedMessage = document.createElement("h4")
        streamRejectedMessage.innerText = i18n.streamRejected
        this.obsStreamRejected.appendChild(streamRejectedMessage)
        this.obsStreamRejected.appendChild(this.obsStreamRejectedReason)

        const streamWaitingMessage = document.createElement("p")
        addColorClass(streamWaitingMessage, "waitingMessage")
        streamWaitingMessage.innerText = i18n.waitingforStreamStats
        streamWaitingMessage.style.textAlign = "center"

        const streamWaitingImg = document.createElement("img")
        streamWaitingImg.src = `${STATIC_URL}ellipsis.svg`
        streamWaitingImg.style.height = "110px"
        streamWaitingImg.style.width = "auto"
        streamWaitingImg.style.opacity = "0.5"
        streamWaitingImg.style.margin = "0 auto"
        streamWaitingImg.style.display = "block"
        this.statsLoadingMessage.appendChild(streamWaitingMessage)
        this.statsLoadingMessage.appendChild(streamWaitingImg)

        this.obsFeedbackBoxHeader.innerText = i18n.streamStatusUnknown
        this.obsFeedbackBoxHeader.style.color = "inherit"
        this.obsFeedbackBoxHeader.style.fontWeight = "bold"
        this.obsFeedbackBoxHeader.style.fontSize = "15px"
        this.obsFeedbackBoxHeader.style.margin = "3px"
        this.obsStreamFeedbackBox.appendChild(this.obsFeedbackBoxHeader)
        this.obsStreamFeedbackBox.appendChild(this.obsFeedbackBoxMessages)
        this.obsStreamFeedbackBox.style.display = "none"

        addColorClass(obsDetailsBoxResolution, "resolutionText")
        const span1 = document.createElement("span")
        span1.innerText = i18n.resolutionLabel
        obsDetailsBoxResolution.appendChild(span1)
        obsDetailsBoxResolution.appendChild(document.createElement("br"))
        this.obsDetailsBoxResDisp = document.createElement("span")
        this.obsDetailsBoxResDisp.style.fontSize = "0.8em"
        obsDetailsBoxResolution.appendChild(this.obsDetailsBoxResDisp)
        obsDetailsBoxResolution.appendChild(this.resolutionFloatBox)

        const span2 = document.createElement("span")
        span2.innerText = i18n.streamTime
        obsDetailsBoxStreamTime.appendChild(span2)
        obsDetailsBoxStreamTime.appendChild(document.createElement("br"))
        this.obsDetailsBoxTimeDisp = document.createElement("span")
        this.obsDetailsBoxTimeDisp.style.fontSize = "0.8em"
        obsDetailsBoxStreamTime.appendChild(this.obsDetailsBoxTimeDisp)

        addColorClass(obsDetailsBoxCodec, "codecText")
        const span3 = document.createElement("span")
        span3.innerText = i18n.codec
        obsDetailsBoxCodec.appendChild(span3)
        obsDetailsBoxCodec.appendChild(document.createElement("br"))
        this.obsDetailsBoxCodecVideo = document.createElement("span")
        this.obsDetailsBoxCodecVideo.style.fontSize = "10px"
        this.obsDetailsBoxCodecVideo.style.lineHeight = "1"
        this.obsDetailsBoxCodecVideo.style.display = "block"
        this.obsDetailsBoxCodecVideo.style.marginTop = "2px"
        this.obsDetailsBoxCodecAudio = document.createElement("span")
        this.obsDetailsBoxCodecAudio.style.fontSize = "10px"
        this.obsDetailsBoxCodecAudio.style.lineHeight = "1"
        this.obsDetailsBoxCodecAudio.style.display = "block"
        this.obsDetailsBoxCodecAudio.style.marginTop = "2px"
        obsDetailsBoxCodec.appendChild(this.obsDetailsBoxCodecVideo)
        obsDetailsBoxCodec.appendChild(this.obsDetailsBoxCodecAudio)

        const frameRateSpan = document.createElement("span")
        frameRateSpan.innerText = i18n.frameRate
        this.obsStreamGraphFPSDisp.style.fontWeight = "bold"
        const fpsCanvas = document.createElement("canvas")
        fpsCanvas.style.width = "235px"
        fpsCanvas.style.height = "80px"
        fpsCanvas.id = "fps_chart"
        this.obsStreamGraphFPS.appendChild(frameRateSpan)
        this.obsStreamGraphFPS.appendChild(this.obsStreamGraphFPSDisp)
        this.obsStreamGraphFPS.appendChild(fpsCanvas)

        const bitrateSpan = document.createElement("span")
        bitrateSpan.innerText = i18n.bitrate
        this.obsStreamGraphBitrateDisp.style.fontWeight = "bold"
        const bitrateCanvas = document.createElement("canvas")
        bitrateCanvas.style.width = "235px"
        bitrateCanvas.style.height = "80px"
        bitrateCanvas.id = "bitrate_chart"
        this.obsStreamGraphBitrate.appendChild(bitrateSpan)
        this.obsStreamGraphBitrate.appendChild(this.obsStreamGraphBitrateDisp)
        this.obsStreamGraphBitrate.appendChild(bitrateCanvas)

        this.obsStreamStatsOther.appendChild(this.obsAlternativeStreamType)
        this.obsStreamStatsOther.style.display = "none"
        this.obsAlternativeStreamType.style.textAlign = "center"
        this.obsAlternativeStreamType.style.height = "260px"
        this.obsAlternativeStreamType.style.marginTop = "40px"
        this.updateAlternativeStreamType("Unknown")
    }

    private updateAlternativeStreamType(streamType: string): void {
        this.obsAlternativeStreamType.innerText = i18n.obsAlternativeStreamType(streamType)
    }

    private createDetailsBox(): HTMLDivElement {
        const detailsBox = document.createElement("div")
        detailsBox.style.width = "33%"
        detailsBox.style.cssFloat = "left"
        detailsBox.style.height = "30px"
        detailsBox.style.lineHeight = "18px"
        detailsBox.style.position = "relative"

        return detailsBox
    }

    private styleResolutionFloatBox(resolution: ResolutionType): void {
        this.resolutionFloatBox.style.display = ""
        switch (resolution) {
            case ResolutionType.obsSD:
                this.resolutionFloatBox.style.backgroundColor = "#e67e22"
                this.resolutionFloatBox.style.color = "#fff"
                break
            case ResolutionType.obsHD:
                this.resolutionFloatBox.style.backgroundColor = "#27ae60"
                this.resolutionFloatBox.style.color = "#fff"
                break
            case ResolutionType.obsHDPlus:
                this.resolutionFloatBox.style.backgroundColor = "#16a085"
                this.resolutionFloatBox.style.color = "#fff"
                break
            case ResolutionType.obs4k:
                this.resolutionFloatBox.style.backgroundColor = "#2980b9"
                this.resolutionFloatBox.style.color = "#f1c40f"
                break
        }
    }

    private styleFeedbackBox(feedback: FeedbackType): void {
        this.obsStreamFeedbackBox.className = "" // Clear classes so only one is active at a time
        this.obsStreamFeedbackBox.style.display = ""
        this.obsStreamFeedbackBox.style.borderWidth = "1px"
        this.obsStreamFeedbackBox.style.borderStyle = "solid"

        switch (feedback) {
            case FeedbackType.none:
                this.obsStreamFeedbackBox.style.display = "none"
                break
            case FeedbackType.good:
                addColorClass(this.obsStreamFeedbackBox, "goodFeedback")
                break
            case FeedbackType.warning:
                addColorClass(this.obsStreamFeedbackBox, "warningFeedback")
                break
            case FeedbackType.bad:
                addColorClass(this.obsStreamFeedbackBox, "badFeedback")
                break
        }
    }

    private styleGraph(graph: HTMLDivElement, feedback: FeedbackType): void {
        switch (feedback) {
            case FeedbackType.none:
                graph.style.backgroundColor = ""
                break
            case FeedbackType.good:
                graph.style.backgroundColor = "rgba(46, 204, 113, 0.49)"
                break
            case FeedbackType.warning:
                graph.style.backgroundColor = "rgba(230, 126, 34, 0.61)"
                break
            case FeedbackType.bad:
                graph.style.backgroundColor = "rgba(231, 76, 60, 0.58)"
                break
        }
    }

    private updateRoomStatus(status = ""): void {
        if (["offline", "public", "private", "group", "away", "hidden", "password protected"].indexOf(status) !== -1) {
            if (status !== "offline") {
                this.obsControl.style.marginTop = "0"
                this.obsStreamRejected.style.display = "none"
                this.backToWebRTC.style.display = "none"
                if (this.roomStatus === "offline") {
                    OBSBroadcastStartStop.fire(true)
                    this.obsStarted.fire(undefined)
                }
            } else {
                this.obsControl.style.marginTop = "-12px"
                if (isWebRTCSupported() && !isWebRTCNotYetSupported()) {
                    this.backToWebRTC.style.display = ""
                }
                if (this.roomStatus !== "offline") {
                    OBSBroadcastStartStop.fire(false)
                    clearTimeout(this.monitorTimeout)
                    this.monitorTimeout = window.setTimeout(() => {
                        this.monitorRoomStatus()
                    }, 2000)
                }
            }
            this.roomStatus = status
            this.obsRoomStatus.innerText = status
            this.styleOBSRoomStatus(status)
        } else {
            this.roomStatus = "unknown"
        }

        if (status === "away") {
            this.obsExitAway.style.display = ""
        } else {
            this.obsExitAway.style.display = "none"
        }
    }

    private styleOBSRoomStatus(status: string): void {
        this.obsRoomStatusContainer.style.display = ""
        this.obsStreamStatsOther.style.display = "none"
        this.obsRoomStatusBlock.className = ""

        switch (status) {
            case "offline":
                addColorClass(this.obsRoomStatusBlock, "offlineBlock")
                break
            case "public":
                addColorClass(this.obsRoomStatusBlock, "publicBlock")
                break
            case "private":
                addColorClass(this.obsRoomStatusBlock, "privateBlock")
                break
            case "group":
                addColorClass(this.obsRoomStatusBlock, "groupBlock")
                break
            case "away":
                addColorClass(this.obsRoomStatusBlock, "awayBlock")
                break
            case "hidden":
                addColorClass(this.obsRoomStatusBlock, "hiddenBlock")
                break
            case "password protected":
                addColorClass(this.obsRoomStatusBlock, "passwordProtectedBlock")
                break
        }
    }

    private createOtherStreamOptions(): void {
        this.element.appendChild(this.obsControl)
        this.obsControl.appendChild(this.backToWebRTC)
        this.obsControl.appendChild(this.obsInfo)
        this.obsControl.style.paddingTop = "7px"
        this.obsControl.style.width = "100%"
        this.obsControl.style.height = "8%"
        this.obsControl.style.marginTop = "-12px"
        this.obsControl.style.textAlign = "center"

        this.backToWebRTC.style.paddingLeft = "5px"
        this.obsInfo.style.display = "block"

        this.backToWebRTC.onclick = () => {
            this.webBroadcaster()
        }
    }

    private webBroadcaster(): void {
        const newSearch = ""
        if (this.roomStatus === "private" || this.roomStatus === "group") {
            modalConfirm(`Warning! This will end your ${this.roomStatus} show.  Are you sure?`, () => {
                if (window.location.search === newSearch) {
                    window.location.reload()
                } else {
                    window.location.search = newSearch
                }
            })
        } else {
            if (window.location.search === newSearch) {
                window.location.reload()
            } else {
                window.location.search = newSearch
            }
        }
    }

    public show(): void {
        this.element.style.display = "block"
    }

    public hide(): void {
        this.element.style.display = "none"
    }

    private createLink(text: string): HTMLAnchorElement {
        const link = document.createElement("a")
        addColorClass(link, colorClass.hrefColor)
        link.innerText = text
        link.style.padding = "0 10px"
        link.style.fontWeight = "bold"
        link.style.font = "1.1em/1.2em 'UbuntuRegular', Arial, Helvetica, sans-serif"
        link.style.cursor = "pointer"

        return link
    }

    public getOBSInfoLink(): HTMLAnchorElement {
        return this.obsInfo
    }

    public isOffline(): boolean {
        return (this.roomStatus === "offline")
    }
}

export const showOBSOverlay = new EventRouter<string>("showOBSOverlay")
