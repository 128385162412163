import { addPageAction } from "../../../common/newrelic"
import { createAuthRequest } from "../auth"
import { ReportedActions } from "../baseClient"
import { PUSHER_CLIENT_NAME, PusherContext } from "./index"
import type { PusherClient } from "./index"
import type { IAuthProvider } from "../auth"
import type { ArgJSONMap } from "@multimediallc/web-utils"

export class PusherAuthProvider implements IAuthProvider {
    // tokens has channel names: auth string
    private tokens = new Map<string, string>()
    public channelDataString?: string
    private consecutiveAuthFails = 0
    constructor(private realtime: PusherClient) {
    }

    serialize(): string {
        return JSON.stringify(this.tokens)
    }

    getTopicKeys(): string[] {
        const channelNames = Array.from(this.tokens.keys())
        const allTopicKeys: string[] = []
        channelNames.forEach(channelName => {
            const topicKeys = this.realtime.context?.getTopicKeys(channelName)
            if (topicKeys !== undefined) {
                allTopicKeys.push(...topicKeys)
            }
        })
        return allTopicKeys
    }

    reset(): void {
        this.tokens.clear()
        this.consecutiveAuthFails = 0
    }

    removeTopicAuth(channelName: string): void {
        this.tokens.delete(channelName)
    }

    getAuthToken(channelName: string): string | undefined {
        return this.tokens.get(channelName)
    }

    // Determine if the provider currently has access to a particular topic.
    canAccessTopic(topicKey: string): boolean {
        return this.realtime.context?.getChannelName(topicKey) !== undefined
    }

    updateAuthToken(): Promise<void> {
        return this.realtime.ensureConnected().then(() => {
            const socketId = this.realtime.getConnectionId()
            const pusherAuthData = { "socket_id": socketId }
            return createAuthRequest(PUSHER_CLIENT_NAME, pusherAuthData).then(authCtx => {
                this.consecutiveAuthFails = 0
                this.reportFailedTopics(authCtx)
                const tokenMap = authCtx.getParsedSubMap("tokens")
                const tokens = authCtx.getObject("tokens")
                if (Object.keys(tokens).length === 0) {
                    warn("Pusher auth has no tokens", {
                        "req_socket_id": socketId,
                        "socket_id": this.realtime.getConnectionId(),
                        "authCtx": authCtx.stringMessage,
                    })
                }
                this.channelDataString = authCtx.getStringOrUndefined("channel_data", true)
                Array.from(Object.keys(tokens)).forEach(key => {
                    this.tokens.set(key, tokenMap.getString(key))
                })
                this.realtime.context = new PusherContext(authCtx)
                return Promise.resolve()
            }).catch(err => {
                this.consecutiveAuthFails += 1
                if (this.consecutiveAuthFails >= 3) {
                    this.realtime.close()
                }
                return Promise.reject(err)
            })
        })
    }

    reportFailedTopics(authContext: ArgJSONMap): void {
        const failures = authContext.getObjectOrUndefined("failures")
        if (typeof failures === "object" && Object.keys(failures).length !== 0) {
            addPageAction("PushServiceClient", {
                "action": ReportedActions.token_request_failed_topics,
                "topics": JSON.stringify(failures),
                "client": PUSHER_CLIENT_NAME,
            })
        }
    }
}
