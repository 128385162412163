import { isiPad } from "@multimediallc/web-utils/modernizr"
import { getCookieOrEmptyString, setCookieWithExpiration } from "@multimediallc/web-utils/storage"
import { addEventListenerPoly } from "../../../../common/addEventListenerPolyfill"
import { modalConfirm } from "../../../../common/alerts"
import { normalizeResource, postCb } from "../../../../common/api"
import { isAnonymous } from "../../../../common/auth"
import { applyStyles } from "../../../../common/DOMutils"
import { DropDownComponent } from "../../../../common/dropDownComponent"
import {
    colorModeChangeRequest,
    roomChangeColorModeUpdateRequest,
} from "../../../../common/fullvideolib/userActionEvents"
import { MobileDmsMenu } from "../../../../common/mobilelib/mobileDmsMenu"
import { isPushmenuOpen } from "../../../../common/mobilelib/mobileRoot"
import { siteHeaderMenuOpened, toggleDms } from "../../../../common/mobilelib/userActionEvents"
import { addPageAction } from "../../../../common/newrelic"
import { addAccountRedirectAttribute, LOGIN_BASE_PATH, SIGN_UP_BASE_PATH } from "../../../../common/redirectParamUtils"
import { styleTransition, styleUserSelect } from "../../../../common/safeStyle"
import { i18n } from "../../../../common/translation"
import { safeWindowOpen } from "../../../../common/windowUtils"
import { addColorClass, colorClass, removeColorClass } from "../../../colorClasses"
import { currentSiteSettings } from "../../../siteSettings"
import { DmListDropdown } from "../../pm/dmListDropdown"
import { openFeedbackForm } from "./userFeedbackFormEvents"
import { MobileUserUpdatesPanel, UserUpdatesPanel } from "./userUpdatesPanel"

export class UserMenuDropDown extends DropDownComponent {
    private profileLink?: HTMLAnchorElement
    private myCollectionLink: HTMLAnchorElement
    private isDarkMode: boolean
    private dmSwitchCircle: HTMLSpanElement

    constructor(toggleElement: HTMLElement, private username?: string, private darkModeEnabled?: boolean) {
        super(toggleElement)

        const userMenuDropdownStyles: CSSX.Properties = {
            display: "none",
            width: "100%",
            height: "auto",
            boxSizing: "border-box",
            top: "24px",
            left: "0px",
            zIndex: 1001, // less than over 18 overlay more than others
            borderRadius: "0 0 5px 5px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderTop: "none",
            fontFamily: "UbuntuBold, Helvetica, Arial, sans-serif",
            padding: "5px 0",
            position: "absolute",
            right: "auto",
        }

        this.element.id = "UserMenuDropDown"
        this.element.dataset.testid = "user-menu-drop-down"
        addColorClass(this.element, "userMenuDropDown")
        addColorClass(this.element, colorClass.userInfoDropdownBgColor)
        applyStyles(this.element, userMenuDropdownStyles)

        if (!currentSiteSettings.isWhiteLabel) {
            this.setupDarkmode()
        }

        if (username !== "") {
            this.profileLink = this.createDropdownLink(i18n.myProfileTextLc)
            this.profileLink.dataset.testid = "profile-link"
            this.element.appendChild(this.profileLink)

            this.myCollectionLink = this.createDropdownLink(i18n.myCollectionText)
            this.myCollectionLink.dataset.testid= "my-collection-link"
            this.myCollectionLink.href = normalizeResource("/my_collection/")
            this.element.appendChild(this.myCollectionLink)

            if (window["live_chat_support"] !== undefined) {
                const liveChatLink = this.createDropdownLink(i18n.liveChatSupportTextLc)
                liveChatLink.dataset.testid = "live-chat-link"
                liveChatLink.href = normalizeResource("/accounts/support_chat/")
                liveChatLink.onclick = (event: MouseEvent) => {
                    event.preventDefault()
                    safeWindowOpen("/accounts/support_chat/", "support_chat", "height=630,width=375,resizable=0,location=0")
                }
                this.element.appendChild(liveChatLink)
            }

            const logoutForm = document.createElement("form")
            logoutForm.method = "post"
            logoutForm.action = normalizeResource("/auth/logout/")
            logoutForm.target = "_top"
            logoutForm.style.display = "none"

            const csrfField = document.createElement("input")
            csrfField.type = "hidden"
            csrfField.name = "csrfmiddlewaretoken"
            csrfField.value = getCookieOrEmptyString("csrftoken")
            logoutForm.appendChild(csrfField)
            this.element.appendChild(logoutForm)

            const feedbackLink = this.createDropdownLink(i18n.sendFeedbackTextLc)
            feedbackLink.dataset.testid = "feedback-link"
            feedbackLink.style.cursor = "pointer"
            feedbackLink.onclick = (event: MouseEvent) => {
                event.preventDefault()
                this.hideElement()
                openFeedbackForm.fire({ source: "user_menu" })
            }
            this.element.appendChild(feedbackLink)

            const logoutLink = this.createDropdownLink(i18n.logOutTextLc)
            logoutLink.dataset.testid = "logout-link"
            logoutLink.href = normalizeResource("/auth/logout/")
            logoutLink.onclick = (event: MouseEvent) => {
                event.preventDefault()
                this.hideElement()
                modalConfirm(i18n.logoutModalText, () => {
                    csrfField.value = getCookieOrEmptyString("csrftoken")
                    logoutForm.submit()
                })
            }
            this.element.appendChild(logoutLink)

            if (username !== undefined) {
                this.setUsername(username)
            }
        } else {
            const loginLink = this.createDropdownLink(i18n.loginVerbText)
            loginLink.dataset.testid = "login-link"
            loginLink.classList.add("login-link")
            loginLink.classList.add("nooverlay")
            loginLink.href = normalizeResource(LOGIN_BASE_PATH)
            addAccountRedirectAttribute(loginLink)
            this.element.appendChild(loginLink)

            const signupLink = this.createDropdownLink(i18n.signUpText)
            signupLink.dataset.testid = "signup-link"
            signupLink.href = normalizeResource(`${SIGN_UP_BASE_PATH}?src=header`)
            addAccountRedirectAttribute(signupLink)
            this.element.appendChild(signupLink)

            const feedbackLink = this.createDropdownLink(i18n.sendFeedbackTextLc)
            feedbackLink.dataset.testid = "feedback-link"
            feedbackLink.style.cursor = "pointer"
            feedbackLink.href = "#"
            feedbackLink.onclick = (event: MouseEvent) => {
                event.preventDefault()
                this.hideElement()
                openFeedbackForm.fire({ source: "user_menu" })
            }
            this.element.appendChild(feedbackLink)
        }
    }

    private setupDarkmode(): void {
        if (this.darkModeEnabled !== undefined) {
            this.isDarkMode = document.body.classList.contains("darkmode")

            const darkModeSwitch = this.createDarkModeSwitch()
            this.element.insertBefore(darkModeSwitch, this.element.firstChild)

            // Needed for input select's appearance to be styled correctly in darkmode
            // Please avoid using this class as much as possible
            if (!document.body.classList.contains("isIpad") && isiPad()) {
                document.body.classList.add("isIpad")
            }
        }
    }

    private createDarkModeSwitch(): HTMLDivElement {
        const dmSwitchContainer = document.createElement("div")
        const dmSwitch = document.createElement("span")
        this.dmSwitchCircle = document.createElement("span")
        this.dmSwitchCircle.dataset.testid = "dark-mode-switch"
        dmSwitchContainer.innerText = i18n.darkMode
        dmSwitch.appendChild(this.dmSwitchCircle)
        dmSwitchContainer.appendChild(dmSwitch)

        const lmDmSwitchStyle: CSSX.Properties = {
            borderRadius: "25px",
            display: "inline-block",
            height: "20px",
            marginLeft: "10px",
            position: "relative",
            verticalAlign: "bottom",
            width: "44px",
            cssFloat: "right",
            right: "6px",
            top: "-2px",
        }

        const lmDmSwitchCircleStyle: CSSX.Properties = {
            borderRadius: "50%",
            display: "inline-block",
            height: "16px",
            width: "16px",
            position: "relative",
            top: "2px",
            left: "3px",
        }

        addColorClass(this.dmSwitchCircle, "dmSwitchCircle")
        applyStyles(this.dmSwitchCircle, lmDmSwitchCircleStyle)
        applyStyles(dmSwitch, lmDmSwitchStyle)
        addColorClass(dmSwitch, "dmSwitch")
        styleUserSelect(dmSwitchContainer, "none")
        styleTransition(this.dmSwitchCircle, `left 100ms`)

        addColorClass(dmSwitchContainer, colorClass.userInfoDropdownTextColor)
        addColorClass(dmSwitchContainer, colorClass.userInfoDropdownHighlightColor)
        dmSwitchContainer.style.fontSize = "14px"
        dmSwitchContainer.style.fontWeight = "normal"
        dmSwitchContainer.style.cursor = "pointer"
        dmSwitchContainer.style.fontFamily = "UbuntuMedium, Arial, Helvetica, sans-serif"
        dmSwitchContainer.style.padding = "5px 0 5px 10px"

        dmSwitchContainer.onclick = () => {
            this.setColorModeFn()
        }

        roomChangeColorModeUpdateRequest.listen(() => {
            this.setColorModeFn()
        })

        // Update colormode switch to darkmode side if the page is loaded and classname is already on body
        if (this.isDarkMode) {
            this.dmSwitchCircle.style.left = "25px"
        }

        return dmSwitchContainer
    }

    public setColorModeFn(): void {
        if (currentSiteSettings.isWhiteLabel) {
            return
        }

        if (this.isDarkMode) {
            this.dmSwitchCircle.style.left = "3px"
            this.isDarkMode = false
            removeColorClass(document.body, "darkmode")
            addColorClass(document.body, "lightmode")
            setCookieWithExpiration("theme_name", "lightmode", { days: 60 })
        } else {
            this.dmSwitchCircle.style.left = "25px"
            this.isDarkMode = true
            removeColorClass(document.body, "lightmode")
            addColorClass(document.body, "darkmode")
            setCookieWithExpiration("theme_name", "darkmode", { days: 60 })
        }
        this.updateMenuColors()
        addPageAction("colorModeChanged",
            {
                "username": this.username === undefined ? "__anon__" : this.username,
                "color_mode": this.isDarkMode ? "darkmode" : "lightmode",
            })

        colorModeChangeRequest.fire(this.isDarkMode ? "darkmode" : "lightmode")

        if (!isAnonymous()) {
            postCb("api/ts/accounts/userpreferredtheme/", { "theme": this.isDarkMode ? "darkmode" : "lightmode" })  // eslint-disable-line @typescript-eslint/no-floating-promises
        }
    }

    private updateMenuColors(): void {
        if (currentSiteSettings.isWhiteLabel) {
            this.element.style.backgroundColor = currentSiteSettings.userInfoDropdownBgColor
            this.element.style.border = `1px solid ${currentSiteSettings.tabBorderColor}`
        }
    }

    private createDropdownLink(text: string): HTMLAnchorElement {
        const link = document.createElement("a")
        link.style.textDecoration = "none"
        link.innerText = text
        link.style.display = "block"
        link.style.fontSize = "14px"
        link.style.padding = "5px 0 5px 10px"
        addColorClass(link, colorClass.userInfoDropdownTextColor)
        addColorClass(link, colorClass.userInfoDropdownHighlightColor)
        link.onclick = () => {
            this.hideElement()
        }
        return link
    }

    public setUsername(username: string): void {
        if (this.profileLink !== undefined) {
            this.profileLink.href = normalizeResource(`/p/${username}/?tab=bio`)
        }
    }

    showElement(defaultDisplay = "block", evt?: Event): boolean {
        return super.showElement()
    }

    hideElement(evt?: Event): boolean {
        return super.hideElement()
    }
}

export function bindUserMenuDropdown(userInfoPanelMenuDropdownRoot: HTMLDivElement | null,
    userInformationProfileDropDownToggle: HTMLElement | null,
    username: string,
    darkModeEnabled?: boolean): UserMenuDropDown | undefined {
    let userMenuDropDown: UserMenuDropDown | undefined
    if (userInfoPanelMenuDropdownRoot !== null && userInformationProfileDropDownToggle !== null) {
        userMenuDropDown = new UserMenuDropDown(userInformationProfileDropDownToggle, username, darkModeEnabled)
        userInfoPanelMenuDropdownRoot.appendChild(userMenuDropDown.element)
        userMenuDropDown.repositionChildrenRecursive()
    }

    return userMenuDropDown
}

export function bindUserUpdatesPanel(userUpdatesBellRoot: HTMLDivElement | null,
    userUpdatesDropDownRoot: HTMLDivElement | null,
    mobile = false,
    hamburgerIcon?: HTMLDivElement | null): UserUpdatesPanel | undefined {
    // for whitelabels we don't show the bell
    let userUpdatesPanel: UserUpdatesPanel | undefined
    if (!currentSiteSettings.isWhiteLabel && userUpdatesBellRoot !== null && userUpdatesDropDownRoot !== null) {
        if (mobile) {
            userUpdatesPanel = new MobileUserUpdatesPanel(userUpdatesBellRoot)
            if (hamburgerIcon !== undefined && hamburgerIcon !== null) {
                userUpdatesPanel.initAdditionalToggleElement(hamburgerIcon)
            }
        }
        else {
            userUpdatesPanel = new UserUpdatesPanel(userUpdatesBellRoot)
        }

        userUpdatesDropDownRoot.appendChild(userUpdatesPanel.dropDownComponent.element)
        userUpdatesPanel.element.dataset.testid = "user-updates-panel"
    }
    return userUpdatesPanel
}

export function bindDmListDropDown(
    dmListIconRoot: HTMLDivElement | null,
    dmListDropDownRoot: HTMLDivElement | null,
): DmListDropdown | undefined {
    let dmListDropdown: DmListDropdown | undefined
    if (dmListIconRoot !== null && dmListDropDownRoot !== null) {
        dmListDropdown = new DmListDropdown({ toggleElement: dmListIconRoot })
        dmListDropdown.element.dataset.testid = "dm-convo-list"
        dmListDropDownRoot.appendChild(dmListDropdown.element)
    }
    return dmListDropdown
}

export function bindMobileDmsMenu(
    dmListIconRoot: HTMLDivElement | null,
    userUpdatesBellRoot: HTMLDivElement | null,
    hamburgerIcon: HTMLDivElement | null | undefined,
    dmListDropDownRoot: HTMLDivElement | null,
): void {
    if (dmListIconRoot !== null && dmListDropDownRoot !== null) {
        dmListDropDownRoot.appendChild(new MobileDmsMenu({ dmListIconRoot: dmListIconRoot, userUpdatesBellRoot, hamburgerIcon }).element)
    }
}

export function bindHeaderOpenedEvents(): void {
    const menuNavigation = document.querySelector<HTMLElement>(".nav_list")
    const pushMenuOverlay = document.querySelector<HTMLElement>(".push-overlay")

    if (pushMenuOverlay !== null && menuNavigation !== null) {
        addEventListenerPoly("click", menuNavigation, () => {
            siteHeaderMenuOpened.fire(true)
        })

        addEventListenerPoly("click", pushMenuOverlay, () => {
            siteHeaderMenuOpened.fire(false)
        })

        toggleDms.listen((shouldOpen: boolean) => {
            if (shouldOpen && !isPushmenuOpen()) {
                menuNavigation.click()
            } else if (!shouldOpen && isPushmenuOpen()) {
                pushMenuOverlay.click()
            }
        })
    }
}
