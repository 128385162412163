import { dom } from "../tsxrender/dom"
import { PrivateTabButton } from "./actionButtons"
import type { PrivateShowInfo } from "./privateShowInfo"

export const PrivateContainer = (props: {
    privateShowInfo: PrivateShowInfo,
    onPrivateRequestInitiationChange?: (isInitiating: boolean) => void,
}): HTMLDivElement => {
    const privateContainerStyle: CSSX.Properties = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px",
    }
    const privateTabButton = new PrivateTabButton({
        onPrivateRequestInitiationChange: props.onPrivateRequestInitiationChange,
    })

    return (
        <div colorClass="PrivateContainer" style={privateContainerStyle} data-testid="private-spy-notice">
            {props.privateShowInfo.element}
            {privateTabButton.element}
        </div>
    )
}
