import {
    ConversationType,
    getRoomHistoryMessages,
    pmHistoryBatchSize,
    PrivateMessageSource,
    sendPrivateMessage,
} from "../../cb/api/pm"
import { addColorClass } from "../../cb/colorClasses"
import { MobileChatLink } from "../../cb/components/pm/chatLinks"
import { ConversationListData } from "../../cb/components/pm/conversationListData"
import { mobileMediaDockHeight, SelectedMobileMediaDock, showMobileMediaDock } from "../../cb/components/pm/mediaDock"
import { LoadHistoryMessagesDOM } from "../../cb/components/pm/pmControlBar"
import { allPmsRead, privateMessage } from "../../cb/components/pm/userActionEvents"
import { pageContext } from "../../cb/interfaces/context"
import { currentSiteSettings } from "../../cb/siteSettings"
import { roomCleanup, roomLoaded, userViewedPm } from "../context"
import { applyStyles } from "../DOMutils"
import { EventRouter, ListenerGroup } from "../events"
import { MobileRoomNotice } from "../roomNotice"
import { createRoomPhotoMessage } from "../theatermodelib/messageToDOM"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import { BaseRoomTab } from "./baseRoomTab"
import { ChatContents, inputDivHeight } from "./chatContents"
import { createLogMessage, createRoomMessage, createSupporterSignupMessage } from "./messageToDOM"
import { otherUserInitiatedPm } from "./mobilePmWindow"
import { PmManager } from "./pmManager"
import { PrivateContainer } from "./privateContainer"
import { PrivateShowInfo } from "./privateShowInfo"
import { RoomTabs } from "./roomTabs"
import { TabName } from "./tabList"
import { openTipCalloutRequest, userSwitchedTab } from "./userActionEvents"
import type { IBaseRoomTabProps } from "./baseRoomTab"
import type { IPMError, ISendPrivateMessage } from "../../cb/api/pm"
import type { IChatConnection } from "../context"
import type { IPrivateMessage } from "../messageInterfaces"
import type { IOutgoingMessageHandlers } from "../specialoutgoingmessages"

export class PrivateTab extends BaseRoomTab {
    private chatConnection?: IChatConnection
    private room: string
    private privateContainer: HTMLDivElement
    private chatHeading: HTMLDivElement
    private chatContents: ChatContents
    private privateShowInfo: PrivateShowInfo
    private isLoadingHistory: boolean
    private isInitialHistoryLoaded: boolean
    private isAllHistoryLoaded: boolean
    private mobileMediaDock: SelectedMobileMediaDock
    private loadHistoryMessagesDOM: LoadHistoryMessagesDOM
    public numUnreadChanged: EventRouter<number>
    private mediaDockHeight = 0
    private pmManager: PmManager

    constructor() {
        super({ tabName: TabName.Private, tabLabel: i18n.privateText, pageActionName: "PrivateOpened" })
        const listenerGroup = new ListenerGroup()
        const isViewerAgeVerified = pageContext.current.loggedInUser?.isAgeVerified ?? false

        roomLoaded.listen((context) => {
            this.chatConnection = context.chatConnection
            this.room = context.chatConnection.room()
            const showBroadcasterContents = context.chatConnection.isBroadcasting
            this.toggleBroadcasterContents(showBroadcasterContents)

            if (showBroadcasterContents) {
                return
            }

            this.mobileMediaDock.setRoom(this.room)
            this.chatHeading.innerText = i18n.privateConversationWithText(this.room)
            this.chatContents.appendNoticeDiv(this.chatHeading)
            applyStyles(this.chatHeading, { fontSize: "14px" }) // appendNoticeDiv() clears font size

            this.chatContents.appendNoticeDiv(this.loadHistoryMessagesDOM.getElement())
            this.chatContents.appendMessageDiv(createLogMessage(i18n.conversationCautionMessage(currentSiteSettings.siteName)))

            if (context.dossier.needsSupporterToPm) {
                this.chatContents.appendMessageDiv(createSupporterSignupMessage(this.room, isViewerAgeVerified))
            }

            this.loadHistoryMessages(true)

            context.chatConnection.event.roomNotice.listen((roomNoticeData) => {
                if (!roomNoticeData.showInPrivateMessage) {
                    return
                }

                this.chatContents.appendMessageDiv(new MobileRoomNotice({ roomNoticeData, neverCollapse: true }).element)
            }).addTo(listenerGroup)
        })

        showMobileMediaDock.listen((dockVisible) => {
            this.mediaDockHeight = dockVisible ? mobileMediaDockHeight() + 10 : 0
            this.repositionChildren()
            this.chatContents.scrollToBottom()
        })

        privateMessage.listen((pm) => {this.onConversationMessage(pm)})

        ConversationListData.conversationDataChanged.listen(() => {this.updateUnreadCount()})

        ConversationListData.unreadConversationsCountUpdate.listen(() => this.updateUnreadCount())

        this.pmManager.getPmWindow().numUnreadChanged.listen((numUnread) => {
            if (this.chatConnection?.isBroadcasting === true) {
                this.numUnreadChanged.fire(numUnread)
            }
        })

        userSwitchedTab.listen((tabName) => {
            if (tabName !== this.getTabName()) {
                return
            }

            this.maybeMarkRead(tabName)
        })

        this.chatContents.scrolledToBottom.listen(() => {this.maybeMarkRead()})

        this.privateShowInfo.didRepositionEvent.listen(() => {this.repositionChildren()})

        roomCleanup.listen(() => {
            this.chatContents.clear()
            this.resetHistoryData()
            listenerGroup.removeAll()
        })
    }

    protected initData(props: IBaseRoomTabProps): void {
        super.initData(props)
        this.resetHistoryData()
        this.room = ""
        this.loadHistoryMessagesDOM = new LoadHistoryMessagesDOM()
        this.numUnreadChanged = new EventRouter<number>("numUnreadChanged")
        const baseOutgoingHandlers: IOutgoingMessageHandlers = {
            onTipRequest: (tipRequest) => {
                openTipCalloutRequest.fire(tipRequest)
            },
            onToggleDebugMode: () => {
                this.chatConnection?.toggleAppDebugging()
            },
            onChatMessage: (msg) => {
                const pm: ISendPrivateMessage = {
                    message: msg,
                    username: this.room,
                    source: PrivateMessageSource.MobilePM,
                    roomName: this.room,
                }
                pm.media = this.mobileMediaDock.mobileMediaList()
                sendPrivateMessage(pm).catch((error: IPMError) => {
                    this.chatContents.appendMessageDiv(createLogMessage(error.errorMessage))
                })
                if (!this.mobileMediaDock.isEmpty()) {
                    this.mobileMediaDock.clear()
                    this.mediaDockHeight = 0
                    this.repositionChildren()
                }
            },
        }

        this.chatContents = new ChatContents(baseOutgoingHandlers, true)

        this.mobileMediaDock = new SelectedMobileMediaDock(this.chatContents)
        this.chatContents.initMediaDock(this.mobileMediaDock)
    }

    protected initUI(): void {
        super.initUI()
        addColorClass(this.element, "PrivateTab")
        applyStyles(this.element, {
            display: "flex",
            flexDirection: "column",
            position: "relative",
        })
        applyStyles(this.chatContents.element, {
            flex: 1,
        })
        const chatHeadingStyle: CSSX.Properties = {
            fontSize: "14px",
            padding: "4px 5px 0 5px",
            display: "block",
            fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
        }

        this.chatHeading = <div colorClass="chatHeading" style={chatHeadingStyle} />

        this.privateShowInfo = new PrivateShowInfo()
        this.privateContainer = (
            <PrivateContainer
                privateShowInfo={this.privateShowInfo}
                onPrivateRequestInitiationChange={(isInitiating) => {
                    this.privateShowInfo.onPrivateRequestInitiationChange(isInitiating)
                }}
            />
        )
        this.element.appendChild(this.privateContainer)
        this.addChild(this.chatContents)

        // set up PMs for broadcasters who are in their own room
        this.pmManager = new PmManager()
        this.pmManager.hideElement()
        this.addChild(this.pmManager)
    }

    private toggleBroadcasterContents(show = true): void {
        if (show) {
            this.privateContainer.style.display = "none"
            this.chatContents.hideElement()
            this.pmManager.showElement()
        } else {
            this.privateContainer.style.display = "flex"
            this.chatContents.showElement()
            this.pmManager.hideElement()
        }
    }

    private onConversationMessage(message: IPrivateMessage, fromHistory = false): void {
        const fromUsername = message.fromUser.username
        const isBroadcasterConversation = message.otherUsername === this.room || fromUsername === this.room

        if (!isBroadcasterConversation) {
            return
        }

        this.appendBroadcasterMessageDiv(message)
        this.firePMChatLink(fromHistory)
        this.maybeMarkRead()
    }

    private appendBroadcasterMessageDiv(message: IPrivateMessage) {
        if (!this.isInitialHistoryLoaded) {
            // Try to load history again if it failed the first time.
            // The new message will not be appended in this call but will show up in the retrieved history.
            this.loadHistoryMessages(true)
            return
        }

        this.chatContents.appendMessageDiv(createRoomMessage(message))
        const photoMessage = createRoomPhotoMessage(message)
        if (photoMessage !== undefined) {
            this.chatContents.appendPhotoMessage(photoMessage, message)
        }
    }

    private firePMChatLink(fromHistory: boolean): void {
        if (fromHistory) {
            return
        }

        otherUserInitiatedPm.fire({
            username: this.room,
            PMChatLink: new MobileChatLink({
                onClick: () => {userSwitchedTab.fire(TabName.Private)},
                conversationType: ConversationType.PM,
            }),
        })
    }

    private updateUnreadCount(): void {
        if (this.chatConnection?.isBroadcasting === true) {
            return
        }

        const numUnread = ConversationListData.getInstance().getConversation(this.room)?.numUnread ?? 0
        this.numUnreadChanged.fire(numUnread)
    }

    /**
     * @param currentTab The most accurate representation of what tab the user is on.
     * `RoomTabs.currentTab` is accurate in most instances except inside the `userSwitchedTab` listener,
     * so we need to pass in the tab name given from that event in that case.
     */
    private maybeMarkRead(currentTab = RoomTabs.currentTab): void {
        if (currentTab !== this.getTabName() || this.chatContents.isScrolledUp() || this.chatConnection?.isBroadcasting === true) {
            return
        }

        userViewedPm.fire(this.room)
        allPmsRead.fire(this.room)
    }

    private loadHistoryMessages(initialHistory = false): void {
        if (this.isAllHistoryLoaded || this.isLoadingHistory) {
            return
        }

        const oldListHeight = this.chatContents.messageList.clientHeight
        const offset = initialHistory ? "0" : this.chatContents.getEarliestMessageId()
        this.setIsLoadingHistory(true)

        getRoomHistoryMessages(this.room, this.room, offset).then((pmList) => {
            const messages = pmList.messages

            if (initialHistory && !this.isInitialHistoryLoaded) {
                this.isInitialHistoryLoaded = true
            }
            if (messages.length < pmHistoryBatchSize) {
                this.isAllHistoryLoaded = true
            }

            messages.forEach((pm: IPrivateMessage) => {
                this.onConversationMessage(pm, true)
            })

            if (messages.length > 0) {
                this.chatContents.setEarliestMessageId(messages[0].messageID)
            }

            const newListHeight = this.chatContents.messageList.clientHeight
            this.chatContents.messageListWrapper.scrollTop = newListHeight - oldListHeight
        }).catch((err) => {
            error(err)
        }).finally(() => {
            this.setIsLoadingHistory(false)
        })
    }

    private setIsLoadingHistory(isLoading: boolean): void {
        this.isLoadingHistory = isLoading

        if (isLoading) {
            this.loadHistoryMessagesDOM.showLoading()
        } else {
            this.loadHistoryMessagesDOM.hideLoading()
        }
    }

    private resetHistoryData(): void {
        this.isLoadingHistory = false
        this.isInitialHistoryLoaded = false
        this.isAllHistoryLoaded = false
    }

    protected repositionChildren(): void {
        const messageListWrapperHeight =
            this.element.offsetHeight
            - inputDivHeight()
            - this.privateContainer.offsetHeight
            - this.mediaDockHeight
        this.chatContents.messageListWrapper.style.height = `${messageListWrapperHeight}px`
    }

    public getChatContents(): ChatContents {
        if (this.chatConnection?.isBroadcasting === true) {
            const privateShowUser = this.chatConnection.getPrivateShowUser()
            const privateShowContents = this.pmManager.getPmWindow().getSession(privateShowUser)?.chatContents
            const pmContents = this.pmManager.getPmWindow().currentlyDisplayedPmSession()?.chatContents
            return privateShowContents ?? pmContents ?? this.chatContents
        }
        return this.chatContents
    }

    public getPrivateShowChatContents(): ChatContents | undefined {
        if (this.chatConnection?.isBroadcasting === true) {
            const privateShowUser = this.chatConnection.getPrivateShowUser()
            return this.pmManager.getPmWindow().getOrCreateSession(privateShowUser).chatContents
        }
        return this.chatContents
    }

    public getPrivateContainerHeight(): number {
        return this.privateContainer.offsetHeight
    }
}
