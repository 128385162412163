import { getScrollingDocumentElement } from "@multimediallc/web-utils/modernizr"
import { rulesModalVisible } from "../../cb/components/userMenus/ui/rulesModal"
import { IChatTabContainer } from "../../cb/ui/iChatTab"
import { hideLoginOverlay, loginOverlayVisible } from "../../cb/ui/loginOverlay"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { isNotLoggedIn } from "../auth"
import { focusingInputFromKeypress } from "../customInput"
import { DraggableCanvas } from "../fullvideolib/draggableCanvas"
import { openTipCalloutRequest } from "../fullvideolib/userActionEvents"
import { isCharacterKey } from "../ischaracterkey"
import { KeyboardEventHandlerChain } from "../keyboardEventHandler"
import { modalBlockChatFocus } from "../modalComponent"
import { IVideoModeChangeNotification, VideoMode, videoModeHandler } from "../videoModeHandler"
import { ChatTabContainer } from "./chatTabContainer"
import { openDefaultTipCalloutRequest } from "./userActionEvents"
import Key = JQuery.Key

let lastScrollTop: number | undefined
let focusedScrollTop: number

let allowChatInputAutoFocus = true
export function enableChatInputAutoFocus(enable: boolean): void {
    allowChatInputAutoFocus = enable
}
export function chatInputAutoFocusEnabled(): boolean {
    return allowChatInputAutoFocus
}

export function isElementInForm(el: Element): boolean {
    return Boolean((el as HTMLInputElement).form) || el.closest("form") !== null
}

export function isNonChatInputFocused(): boolean {
    return (
        document.activeElement !== undefined && document.activeElement !== null // IE
        && ["input", "textarea"].includes(document.activeElement.tagName.toLowerCase())
        && !loginOverlayVisible()
    )
}

export function bindEventListeners(draggableCanvas: DraggableCanvas, defaultModeChatTabContainer: ChatTabContainer): void {
    videoModeHandler.changeVideoMode.listen((videoModeChangeNotification: IVideoModeChangeNotification) => {
        lastScrollTop = undefined
    })

    const handlers = new KeyboardEventHandlerChain()

    const getChatTabContainer = () => {
        if (videoModeHandler.getVideoMode() === VideoMode.Split) {
            return defaultModeChatTabContainer
        } else {
            return draggableCanvas.chatWindow.chatTabContainer
        }
    }

    const focusChatInput = () => {
        getChatTabContainer().getCurrentTab().focusCurrentChatInput()
    }

    const isChatInputFocused = () => {
        return draggableCanvas.chatWindow.chatTabContainer.getCurrentTab().isInputFocused() || defaultModeChatTabContainer.getCurrentTab().isInputFocused()
    }

    let blockingModalsCnt = 0
    modalBlockChatFocus.listen((blocking) => {
        if (blocking) {
            blockingModalsCnt += 1
        } else {
            blockingModalsCnt -= 1
        }
    })

    handlers.addHandler({
        keyCode: Key.Tab,
        requiresCtrlOrMeta: false,
        handle: (event: KeyboardEvent) => {
            if (!isChatInputFocused() && isNonChatInputFocused() && draggableCanvas.focusedWindow !== draggableCanvas.tipWindow || loginOverlayVisible() || rulesModalVisible()) {
                return
            }
            if (!loginOverlayVisible()) {
                draggableCanvas.setFocusedWindow(draggableCanvas.chatWindow)
                const cycleChat = (chatTabContainer: IChatTabContainer) => {
                    if (event.shiftKey) {
                        chatTabContainer.cycleToPrevWindow()
                    } else {
                        chatTabContainer.cycleToNextWindow()
                    }
                }

                if (videoModeHandler.getVideoMode() === VideoMode.Split) {
                    cycleChat(draggableCanvas.chatWindow.chatTabContainer)
                    cycleChat(defaultModeChatTabContainer)
                } else {
                    cycleChat(defaultModeChatTabContainer)
                    cycleChat(draggableCanvas.chatWindow.chatTabContainer)
                }
                event.preventDefault()
                event.stopPropagation()
            }
        },
    })

    handlers.addHandler({
        keyCode: Key.Enter,
        requiresCtrlOrMeta: false,
        handle: event => {
            if (isChatInputFocused()) {
                if (isNotLoggedIn()) {
                    event.preventDefault()
                    return
                }
                if (getScrollingDocumentElement().scrollTop === focusedScrollTop && lastScrollTop !== undefined) {
                    getScrollingDocumentElement().scrollTop = lastScrollTop
                    lastScrollTop = undefined
                }
                return
            }
            draggableCanvas.setFocusedWindow(draggableCanvas.chatWindow)
        },
    })

    handlers.addHandler({
        keyCode: Key.Escape,
        requiresCtrlOrMeta: false,
        handle: event => {
            if (loginOverlayVisible()) {
                hideLoginOverlay()
                draggableCanvas.setFocusedWindow(undefined)
                lastScrollTop = undefined
            }
            else {
                event.preventDefault()
                if (draggableCanvas.focusedWindow !== undefined && draggableCanvas.focusedWindow.closable) {
                    draggableCanvas.removeChild(draggableCanvas.focusedWindow)
                }
                draggableCanvas.setFocusedWindow(undefined)

                if (getScrollingDocumentElement().scrollTop === focusedScrollTop && lastScrollTop !== undefined) {
                    defaultModeChatTabContainer.getCurrentTab().blurCurrentChatInput()
                    getScrollingDocumentElement().scrollTop = lastScrollTop
                    lastScrollTop = undefined
                }
            }
        },
    })

    handlers.addHandler({
        keyCode: Key.L,
        requiresCtrlOrMeta: true,
        handle: (event) => {
            if (videoModeHandler.getVideoMode() !== VideoMode.Split && draggableCanvas.focusedWindow !== draggableCanvas.chatWindow) {
                return
            }
            if (videoModeHandler.getVideoMode() === VideoMode.Split && !defaultModeChatTabContainer.pmTab.tabHasFocus()) {
                return
            }
            event.stopPropagation()
            event.preventDefault()
            draggableCanvas.chatWindow.chatTabContainer.closeCurrentPMSession()
            defaultModeChatTabContainer.closeCurrentPMSession()
        },
    })

    handlers.addHandler({
        keyCode: Key.S,
        requiresCtrlOrMeta: true,
        handle: (event) => {
            event.stopPropagation()
            event.preventDefault()
            if (videoModeHandler.getVideoMode() === VideoMode.Split || draggableCanvas.element.style.visibility === "hidden") {
                openDefaultTipCalloutRequest.fire({ usedCtrlS: true })
            } else {
                openTipCalloutRequest.fire({ usedCtrlS: true })
            }
        },
    })

    const ignoredKeys = [Key.Space, Key.PageDown, Key.PageUp]

    // eslint-disable-next-line complexity
    const shouldIgnoreKeyDown = (event: KeyboardEvent) => {
        if (!chatInputAutoFocusEnabled()) {
            return true
        }
        if (event.keyCode === Key.Escape || event.ctrlKey || event.metaKey) {
            return false
        }
        if (event.target !== null && isElementInForm(event.target as Element) && !isChatInputFocused()) {
            return true
        }
        if (loginOverlayVisible() || ignoredKeys.indexOf(event.keyCode) !== -1 || blockingModalsCnt !== 0) {
            return true
        }
        return !isChatInputFocused() && isNonChatInputFocused()
    }

    addEventListenerPoly("keydown", document, (event: KeyboardEvent) => {
        if (shouldIgnoreKeyDown(event)) {
            return
        }
        if (draggableCanvas.focusedWindow !== undefined) {
            if (draggableCanvas.focusedWindow.handleKeyEvent(event)) {
                return
            }
        }
        if (!handlers.execute(event)) {
            if (!event.ctrlKey && !event.metaKey && isCharacterKey(event.which)) {
                lastScrollTop = getScrollingDocumentElement().scrollTop
                if (videoModeHandler.getVideoMode() !== VideoMode.Split) {
                    draggableCanvas.setFocusedWindow(draggableCanvas.chatWindow)
                }
                focusingInputFromKeypress.fire(event)
                focusChatInput()
                window.setTimeout(() => {
                    focusedScrollTop = getScrollingDocumentElement().scrollTop
                }, 0)
            }
        }
    }, true)
}
