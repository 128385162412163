import { RoomFollowStar } from "../../cb/components/followStar"
import { ReactWrapper } from "../../cb/components/ReactWrapper"
import {
    determineRoomLabel,
    getRecommendedDisplayLabel,
    useRecommendedLabel,
} from "../../cb/components/roomlist/moreRooms/moreRoomRecommendationSources"
import { MoreRoomsCard } from "../../cb/components/roomlist/moreRooms/moreRoomsCard"
import { startStreaming, stopStreaming } from "../../cb/roomList"
import { normalizeResource } from "../api"
import { applyStyles } from "../DOMutils"
import { preventMultiClicks } from "../eventsUtil"
import { getGenderIconUrl } from "../roomUtil"
import { dom } from "../tsxrender/dom"
import type { IRoomInfo } from "../../cb/components/roomlist/IRoomInfo"
import type { IRoomCardProps } from "../../cb/components/roomlist/roomCard"


export const roomCardInfoHeight = (): number => 33

export const roomImageHeightRatio = (): number => 111 / 180

export const roomPadding = (): number => 2

export const roomMargin = (): number => 4

/**
 * @styles: scss/theme/mobile/mobileMoreRoomsCard.scss
 */
export class MobileMoreRoomsCard extends MoreRoomsCard {
    private roomInfo: IRoomInfo
    private roomIndex: number

    protected initData(props: IRoomCardProps): void {
        super.initData(props)
        this.roomInfo = props.roomInfo
        this.roomIndex = props.roomIndex
    }

    protected createElement(props: IRoomCardProps): HTMLLIElement {
        const containerStyle: CSSX.Properties = {
            position: "relative",
            width: "auto",
            height: "auto",
            overflow: "hidden",
            display: "block",
            marginBottom: `${roomMargin()}px`,
            borderRadius: "8px",
            borderWidth: "1px",
            borderStyle: "solid",
        }

        this.img = <RoomImage showLocation={props.roomInfo.location !== undefined} imgUrl={props.roomInfo.img}/>

        return preventMultiClicks(<li
            style={containerStyle}
            colorClass="MobileMoreRoomsCard"
            data-testid="room-card"
            onClick={e => this.fireRoomClick(e)}>
        </li>) as HTMLLIElement
    }

    protected initUI(props: IRoomCardProps): void {
        super.initUI(props)

        this.followStar = new RoomFollowStar({ slug: props.roomInfo.room, isFollowing: props.roomInfo.isFollowing })
        applyStyles(this.followStar.element, { width: "", height: "" })

        this.element.appendChild(
            <RoomLink
                roomInfo={this.roomInfo}
                roomIndex={this.roomIndex}
                roomImage={this.img}
                followStar={this.followStar}
            />,
        )
    }

    public setImageStreamer(): void {
        return
    }

    public stopStreaming(): void {
        stopStreaming(this.roomName, false)
    }

    public startStreaming(): void {
        startStreaming(this.roomName, this.img, true, false)
    }

    public getLocation(): string | undefined {
        return this.roomInfo.location
    }
}

const RoomLink = (props: {
    roomInfo: IRoomInfo
    roomIndex: number,
    roomImage: HTMLImageElement,
    followStar: RoomFollowStar
}): HTMLAnchorElement => {
    const { room, label, sourceInfo } = props.roomInfo
    const showRecommendedLabel = useRecommendedLabel(sourceInfo)
    const updatedLabel = showRecommendedLabel ? determineRoomLabel(sourceInfo, label) : label
    const statusLabel = <RoomLabel roomLabel={updatedLabel}/>

    const element = (
        <a href={normalizeResource(`/${room}/`)} data-testid="room-card" data-room-nav="true" onClick={e => e.preventDefault()}>
            {props.roomImage}
            <RoomInfo roomInfo={props.roomInfo} />
            {props.followStar.element}
            {showRecommendedLabel && statusLabel}
        </a>
    )

    return element
}

const RoomImage = (props: { showLocation: boolean, imgUrl: string }): HTMLImageElement => {
    const style: CSSX.Properties = {
        width: "100%",
        height: `calc(100% - ${roomCardInfoHeight()}px)`,
        cursor: "pointer",
        display: "block",
        objectFit: "fill",
    }

    return (
        <img
            src={props.imgUrl}
            colorClass="roomImage"
            className="moreRooms"
            style={style}
            data-room-nav="true"
        />
    )
}

const RoomTitle = (props: { roomName: string, style?: CSSX.Properties }): HTMLDivElement => {
    const style: CSSX.Properties = {
        position: "absolute",
        width: "100%",
        textAlign: "center",
        bottom: "12px",
        fontSize: "15px",
    }

    return (
        <div
            style={props.style !== undefined ? props.style : style}
            data-room={props.roomName}
            data-room-nav="true"
            data-testid="room-card-username"
        >
            {props.roomName}
        </div>
    )
}

const RoomInfo = (props: { roomInfo: IRoomInfo }): HTMLDivElement => {
    const { room, displayAge, gender, countryCode, countryName } = props.roomInfo

    const containerStyle: CSSX.Properties = {
        display: "flex",
        justifyContent: "space-between",
        padding: "7px 8px",
        position: "absolute",
        bottom: 0,
        boxSizing: "border-box",
        width: "100%",
        fontSize: "16px",
        fontFamily: "'UbuntuBold', Arial, Helvetica, sans-serif",
        maxHeight: `${roomCardInfoHeight()}px`,
    }

    const ageGenderContainerStyle: CSSX.Properties = {
        display: "flex",
        alignItems: "center",
        justifyContent: displayAge !== undefined ? "space-between" : "flex-end",
        width: "",
    }

    const genderIconStyle: CSSX.Properties = {
        verticalAlign: "middle",
        cursor: "pointer",
        width: "16px",
        height: "16px",
    }

    const flagIconStyle: CSSX.Properties = {
        cursor: "pointer",
        borderRadius: "4px",
        paddingLeft: "4px",
    }

    const roomNameStyle: CSSX.Properties = {
        maxWidth: "calc(100% - 45px)",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    }

    return (
        <div style={containerStyle} colorClass="roomInfo">
            <RoomTitle style={roomNameStyle} roomName={room}/>
            <div style={ageGenderContainerStyle}>
                {displayAge !== undefined && <span colorClass="age" data-testid="room-card-age">{displayAge}</span>}
                <img style={genderIconStyle} data-testid="room-card-gender" src={getGenderIconUrl(gender)}/>
                {countryCode && (
                    <span data-testid="room-card-thumbnail-flag">
                        <ReactWrapper
                            component="FlagIcon"
                            componentProps={{
                                countryCode: countryCode,
                                countryName: countryName,
                                style: flagIconStyle,
                                "data-testid": "mobile-room-card-country",
                            }}
                        />
                    </span>
                )}
            </div>
        </div>
    )
}

const RoomLabel = (props: { roomLabel: string }): HTMLDivElement => {
    // Currently intended for internal use and testing purpose
    const style: CSSX.Properties = {
        position: "absolute",
        width: "100%",
        textAlign: "center",
        top: "12px",
        color: "#FFF",
        fontSize: "12px",
        textShadow: "1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000",
    }

    return <div style={style} className="thumbnail_label_recommender">{getRecommendedDisplayLabel(props.roomLabel)}</div>
}
