import { isFilterInPathActive as isFilterInPathActivePrefetch } from "@multimediallc/cb-roomlist-prefetch"
import { featureFlagIsActive } from "./featureFlag"

export const isTagFilterPaginationActive = (): boolean => featureFlagIsActive("HmpgTagsPag")

export const isBroadcasterTagsUpdateActive = (): boolean => featureFlagIsActive("BTagUpdate")

export const isPgEmoticonsAutocompleteActive = (): boolean => featureFlagIsActive("PGEmoticonsAC")

export const isLanguageSubdomainActive = (): boolean => featureFlagIsActive("LanguageSubdomain")

export const isMobileUnmuteClickabilityActive = (): boolean => featureFlagIsActive("MobileUnmuteClickability")

export const isDelayPrivateRequestActive = (): boolean => featureFlagIsActive("DelayPrivateRequest")

export const isPSRequestPMTabRedirectActive = (): boolean => featureFlagIsActive("PSRequestPMTabRedirect")

export const isFilterInPathActive = (): boolean => isFilterInPathActivePrefetch()

export const isScrollDownNoticeActive = (): boolean => featureFlagIsActive("ScrollDownNotice")

export const isPrivateSpyBadgesActive = (): boolean => featureFlagIsActive("PvtSpyBdgs")

export const isBlockMetaDataActive = (): boolean => featureFlagIsActive("BlckMtUpdt")

export const isRecommendedFollowRoomsActive = (): boolean => featureFlagIsActive("RcmFlwRm")
