import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { ModalComponent } from "../../../common/modalComponent"
import { dom } from "../../../common/tsxrender/dom"
import { pmMediaDeleted, pmMediaRejected, setMediaOpenedStatus } from "../../api/pmMedia"
import { pageContext } from "../../interfaces/context"
import { ArrowContainers } from "../../photovideos/arrowContainers"
import { createCloseButton } from "../../ui/svg/closeButton"
import { MediasetThumbnails } from "./mediasetThumbnails"
import type { IChatMedia } from "../../../common/messageInterfaces"

export interface IMediasetProps {
    outgoing: boolean
    messageId: string,
    mediaList: IChatMedia[],
}

export class ChatMediaCarousel extends ModalComponent {
    private mediasetProps: IMediasetProps
    private arrowContainers?: ArrowContainers
    private currentIndex = 0
    private get mediaList(): IChatMedia[] { return this.mediasetProps.mediaList }
    private get currentItem(): IChatMedia | undefined { return this.mediaList[this.currentIndex] }

    private static instance: ChatMediaCarousel | undefined

    private constructor() {
        super({
            onShow: () => {
                // use data-hj-surpress to surpress recordings for private messages due to privacy concern
                // read more about data-hj-surpress on :
                // https://help.hotjar.com/hc/en-us/articles/115012439167-How-to-Suppress-Text-Images-and-User-Input-from-Collected-Data
                this.element.classList.add("data-hj-suppress")

                this.element.style.display = "block"
                this.repositionChildren()
            },
            easyExit: true,
        })

        pmMediaDeleted.listen((mediaID) => {
            this.deleteMedia(mediaID)
        }, false)
        pmMediaRejected.listen((mediaID) => {
            this.deleteMedia(mediaID)
        }, false)
    }

    public static loadMedia(mediaSetProps: IMediasetProps, initialMedia: IChatMedia): void {
        if (ChatMediaCarousel.instance === undefined) {
            ChatMediaCarousel.instance = new ChatMediaCarousel()
        }
        if (ChatMediaCarousel.instance.mediasetProps !== mediaSetProps) {
            ChatMediaCarousel.instance.init(mediaSetProps)
        }
        ChatMediaCarousel.instance.displayImage(ChatMediaCarousel.instance.mediaList.indexOf(initialMedia))
    }

    private init(mediasetProps: IMediasetProps): void {
        this.mediasetProps = mediasetProps
        this.mediasetProps.outgoing = this.mediasetProps.outgoing ?? false

        const isPortraitViewport = (visualViewport?.height ?? window.innerHeight) > (visualViewport?.width ?? window.innerWidth)
        const maxImgHeight = `calc(100% - ${isPortraitViewport ? 72 : 20}px)`
        let maxImgWidth = `calc(100% - ${isPortraitViewport ? 20 : 72}px)`
        if (this.mediaList.length > 1) {
            maxImgWidth = `calc(100% - ${pageContext.current.isMobile ? 92 : 180}px)`
        }

        for (const item of this.mediaList) {
            if (item.imageElement !== undefined) {
                break
            }
            item.imageElement = (
                <img src={item.url}
                     style={{ position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              maxHeight: maxImgHeight,
                              maxWidth: maxImgWidth }}
                     onLoad={() => {
                         ChatMediaCarousel.instance?.repositionChildren()
                     }}
                />
            )
        }
        this.rebuildUI()
    }

    private rebuildUI(): void {
        const ChatMediaCarouselStyle: CSSX.Properties = {
            position: "fixed",
            zIndex: 1101,
            outline: "none",
            width: "80vw",
            height: "80vh",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxSizing: "border-box",
        }
        this.arrowContainers = undefined
        if (this.mediaList.filter(media => media.thumbnailUrl !== "").length > 1) {
            this.arrowContainers = new ArrowContainers(() => { this.previous() }, () => { this.next() })
            this.arrowContainers.shiftArrows(pageContext.current.isMobile ? 8 : 25)
        }
        this.element = <div tabIndex={-1} style={ChatMediaCarouselStyle} colorClass="ChatMediaCarousel">
            {createCloseButton(() => { this.hide() })}
            {this.arrowContainers !== undefined ? this.arrowContainers.leftContainer : undefined}
            {this.arrowContainers !== undefined ? this.arrowContainers.rightContainer : undefined}
        </div>

        addEventListenerPoly("keydown", this.element, (ev: KeyboardEvent) => {
            switch (ev.key) {
                case "Left":
                case "ArrowLeft":
                    this.previous()
                    break
                case "Right":
                case "ArrowRight":
                    this.next()
                    break
                case "Esc":
                case "Escape":
                    this.hide()
                    break
            }
        })
    }

    private clearCurrent(): void {
        this.currentItem?.imageElement?.parentElement?.removeChild(this.currentItem.imageElement)
    }

    private displayImage(index = 0): void {
        this.show()
        this.clearCurrent()
        this.currentIndex = index
        if (this.currentItem !== undefined) {
            if (this.currentItem.imageElement !== undefined) {
                this.element.appendChild(this.currentItem.imageElement)
                if (!this.mediasetProps.outgoing && this.currentItem.opened === false) {
                    this.currentItem.opened = true
                    setMediaOpenedStatus(this.currentItem.fromUserUID, this.mediasetProps.messageId, this.currentItem.mediaId) // eslint-disable-line @typescript-eslint/no-floating-promises
                    MediasetThumbnails.markOpened(this.mediasetProps.messageId, this.currentItem.mediaId)
                }
            }
        }
        this.repositionChildren()
    }

    public hide(shouldAddPageAction = true): void {
        super.hide(shouldAddPageAction)
        this.clearCurrent()
    }

    private deleteMedia(mediaID: number): void {
        const index = this.mediaList.findIndex(m => m.mediaId === mediaID)
        if (index === -1) {
            return
        }
        if (this.currentIndex === index) {
            this.hide()
        }
        this.mediaList.splice(index, 1)
        if (this.currentIndex > index) {
            this.currentIndex -= 1
        }
        if (this.mediaList.length === 1) {
            this.arrowContainers?.hideArrows()
        }
    }

    private next(): void {
        let nextMediaIndex = (this.currentIndex + 1) % this.mediaList.length
        while (this.mediaList[nextMediaIndex].thumbnailUrl === "") {
             nextMediaIndex = (nextMediaIndex + 1) % this.mediaList.length
        }
        this.displayImage(nextMediaIndex)
    }

    private previous(): void {
        let previousMediaIndex = this.currentIndex > 0 ? this.currentIndex - 1 : this.mediaList.length - 1
        while (this.mediaList[previousMediaIndex].thumbnailUrl === "") {
            previousMediaIndex = previousMediaIndex > 0 ? previousMediaIndex - 1 : this.mediaList.length - 1
        }
        this.displayImage(previousMediaIndex)
    }

    protected repositionChildren(): void {
        this.arrowContainers?.repositionArrows(this.element.offsetHeight)
    }
}

export const chatMediaThumbnailSize = 64
export const thumbnailStyle: CSSX.Properties = {
    width: `${chatMediaThumbnailSize}px`,
    height: `${chatMediaThumbnailSize}px`,
    margin: "0px 1px",
    display: "inline-block",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    cursor: "pointer",
    borderRadius: "4px",
    overflow: "hidden",
    zIndex: 0,
}
