import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { EventRouter, eventsPmSessionsCount } from "./events"

const key = {
    "fullscreenEnabled": 0,
    "fullscreenElement": 1,
    "requestFullscreen": 2,
    "exitFullscreen": 3,
    "fullscreenchange": 4,
    "fullscreenerror": 5,
}

const webkit = [
    "webkitFullscreenEnabled",
    "webkitFullscreenElement",
    "webkitRequestFullscreen",
    "webkitExitFullscreen",
    "webkitfullscreenchange",
    "webkitfullscreenerror",
]

const moz = [
    "mozFullScreenEnabled",
    "mozFullScreenElement",
    "mozRequestFullScreen",
    "mozCancelFullScreen",
    "mozfullscreenchange",
    "mozfullscreenerror",
]

const ms = [
    "msFullscreenEnabled",
    "msFullscreenElement",
    "msRequestFullscreen",
    "msExitFullscreen",
    "MSFullscreenChange",
    "MSFullscreenError",
]

let vendor: string[] | undefined
if ("fullscreenEnabled" in document) {
    vendor = Object.keys(key)
} else if (webkit[0] in document) {
    vendor = webkit
} else if (moz[0] in document) {
    vendor = moz
} else if (ms[0] in document) {
    vendor = ms
}

export const FULLSCREEN_CLOSING_TIME_TO_WAIT = 500

export function requestFullscreen(element: HTMLElement): void {
    if (vendor !== undefined) {
        // @ts-ignore using vendor prefix
        element[vendor[key["requestFullscreen"]]]()
    } else if ("webkitEnterFullScreen" in element) {
        (element["webkitEnterFullScreen"] as () => void)()
    }
}

export function exitFullscreen(): void {
    if (vendor === undefined) {
        return
    }
    // @ts-ignore using vendor prefix
    document[vendor[key["exitFullscreen"]]].bind(document)()
}

export function fullscreenEnabled(): boolean {
    if (vendor === undefined) {
        return false
    }
    // @ts-ignore using vendor prefix
    return Boolean(document[vendor[key["fullscreenEnabled"]]])
}

export function fullscreenElement(): HTMLElement | undefined {
    if (vendor === undefined) {
        return undefined
    }
    // @ts-ignore using vendor prefix
    return document[vendor[key["fullscreenElement"]]]
}

// event name for addEventListenerPoly
function fullscreenchangeName(): string {
    if (vendor === undefined) {
        return ""
    }
    return vendor[key["fullscreenchange"]]
}

export function onfullscreenerror(): string {
    if (vendor === undefined) {
        return ""
    }
    return `on${vendor[key["fullscreenerror"]]}`.toLowerCase()
}

export function fullscreenErrorEventName(): string {
    if (vendor === undefined) {
        return ""
    }
    return `${vendor[key["fullscreenerror"]]}`.toLowerCase()
}

export function isFullscreen(): boolean {
    return Boolean(fullscreenElement())
}

export const fullscreenChange = new EventRouter<void>("fullscreenChange", {
    listenersWarningThreshold: () => 50 + eventsPmSessionsCount, // Base limit, plus 1 per PM chatContents
    maxHistorySize: 0,
})
export function initFullscreenChangeEvent(): void {
    const fullscreenEventName = fullscreenchangeName()
    if (fullscreenEventName !== "") {
        addEventListenerPoly(fullscreenchangeName(), document, () => fullscreenChange.fire())
    } else if (!PRODUCTION) {
        // iphones do this
        warn("fullscreenchangeName is \"\", fullscreenChange events will not be fired")
    }
}
