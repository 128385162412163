import { addEventListenerMultiPoly } from "../../common/addEventListenerPolyfill"
import { Component } from "../../common/defui/component"
import { applyStyles } from "../../common/DOMutils"
import { addColorClass, colorClass, removeColorClass } from "../colorClasses"
import { CollapsibleComponent } from "./expandableDropDownMenu"
import { Wrapper } from "./wrapper"

export abstract class BaseTabsContainer extends Component {
    protected window: HTMLDivElement
    public tabHandlesRow: Wrapper
    protected currentTab: BaseTab

    tabHandleStyle: CSSX.Properties = {
        position: "relative",
        padding: "5px",
        margin: "2px 0 0 2px",
        borderRadius: "4px 4px 0 0",
        minWidth: "16px",
        width: "auto",
        height: "100%",
        fontSize: "10px",
        cursor: "pointer",
        cssFloat: "left",
        textSizeAdjust: "none",
        WebkitTextSizeAdjust: "none",
        userSelect: "none",
    }
    tabHandleActiveStyle: CSSX.Properties = {
        cursor: "default",
    }

    constructor() {
        super()
        addColorClass(this.element, "BaseTabsContainer")
        this.tabHandlesRow = new Wrapper()
        this.tabHandlesRow.element.id = "tab-row"
        this.tabHandlesRow.element.style.overflow = "hidden"
        this.tabHandlesRow.element.style.width = "100%"
        this.tabHandlesRow.element.style.borderRadius = "2px 2px 0 0"

        super.addChild(this.tabHandlesRow)
        this.window = document.createElement("div")
        this.window.style.width = "100%"
        this.window.style.position = "absolute"
        addColorClass(this.window, "window")
        this.element.appendChild(this.window)
    }

    public addFooter(footer: HTMLElement): void {
        this.element.appendChild(footer)
    }

    public getTabRow(): Component {
        return this.tabHandlesRow
    }

    protected repositionChildren(): void {
        const h = this.element.clientHeight - this.tabHandlesRow.element.offsetHeight
        this.window.style.height = `${h}px`
    }

    public addChild<T extends Component>(c: T): T {
        throw new Error("addChild not implemented")
    }

    // addChild saves the child plus creates a tab for it
    public addTab<T extends BaseTab>(c: T): T {
        c.element.style.width = "100%"
        c.element.style.height = "100%"
        c.element.style.display = "none"
        super.addChild(c, this.window)
        this.attachTabToDOM(c)
        return c
    }

    protected attachTabToDOM(c: BaseTab): Component {
        const tab = c.constructTabHandle()
        this.tabHandlesRow.addChild(tab)
        c.adjustTab()
        addColorClass(tab, "tab")
        addColorClass(tab, colorClass.chatAreaTabColor)
        return tab
    }

    public children(): BaseTab[] {
        return <BaseTab[]>super.children().filter(c => c instanceof BaseTab)
    }

    public changeToTab(t: BaseTab): void {
        if (t !== this.currentTab) {
            this.currentTab = t
            t.setAsCurrentTab()
        }
        this.showCurrentTab()
    }

    public changeToFirstTab(): void {
        this.changeToTab(this.children()[0])
    }

    public getCurrentTab(): BaseTab {
        return this.currentTab
    }

    protected showCurrentTab(): void {
        const tabs = this.children()
        for (let i = tabs.length - 1; i >= 0; i = i - 1) {
            const t = tabs[i]
            if (t !== this.currentTab) {
                t.hideElement()
            } else {
                if (!this.currentTab.active) {
                    t.showElement()
                }
            }
        }
        this.repositionChildrenRecursive()
        this.afterDOMConstructedIncludingChildren()
    }

    public refreshTabs(): void {
        let isChanged = false
        for (const c of this.children()) {
            isChanged = c.adjustTab() || isChanged
        }
        if (isChanged) {
            this.tabHandlesRow.repositionChildrenRecursive()
        }
    }

    public getHandleStyle(t: BaseTab): CSSX.Properties {
        if (t.active) {
            addColorClass(t.handle, "active")
        } else {
            removeColorClass(t.handle, "active")
            if (t instanceof CollapsibleTab) {
                if (t.collapsed) {
                    addColorClass(t.handle, "collapsed")
                } else {
                    removeColorClass(t.handle, "collapsed")
                }
            }
        }
        return {
            ...this.tabHandleStyle,
            ...(t instanceof CollapsibleTab && t.collapsed ? t.handleCollapseStyle : {}),
            ...(t.active ? this.tabHandleActiveStyle : {}),
        }
    }
}

// BaseTab controls the tab and the window
export abstract class BaseTab extends Component {
    public parent?: BaseTabsContainer
    public handle: Component
    public active = false

    protected constructor() {
        super()
        this.element.style.position = "relative"
    }

    protected tabHandleClicked(_: MouseEvent): void {
        this.changeToThisTab()
    }

    private shouldHideTabHandle(): boolean {
        return !this.handle.isShown()
    }

    protected getTabHandleContent(): Node[] {
        return [document.createTextNode("\u00a0")]
    }

    protected getTabId(): string {
        return ""
    }

    public setAsCurrentTab(): void {}

    public adjustTab(skipTextChange = false): boolean {
        const prevText = this.handle.element.textContent
        if (!skipTextChange) {
            let span = document.createElement("span")
            if (this.handle.element.firstChild !== null) {
                span = <HTMLSpanElement>this.handle.element.firstChild
                span.innerHTML = "" // eslint-disable-line @multimediallc/no-inner-html
            }
            for (const childNode of this.getTabHandleContent()) {
                span.appendChild(childNode)
            }
            this.handle.element.appendChild(span)
        }
        this.adjustHandleStyle()
        const isHidden = this.handle.element.style.display === "none"
        if (this.shouldHideTabHandle()) {
            this.handle.element.style.display = "none"
        } else {
            this.handle.element.style.display = "inline-block"
        }
        return prevText !== this.handle.element.textContent ||
            isHidden !== (this.handle.element.style.display === "none")
    }

    public constructTabHandle(): Component {
        this.handle = this.constructTabHandleComponent()
        addEventListenerMultiPoly(["click", "touchstart"], this.handle.element, (ev: MouseEvent) => {
            this.tabHandleClicked(ev)
        })
        while (this.handle.element.firstChild !== null) {
            this.handle.element.removeChild(this.handle.element.firstChild)
        }
        const span = document.createElement("span")
        for (const childNode of this.getTabHandleContent()) {
            span.appendChild(childNode)
        }
        this.handle.element.appendChild(span)
        this.handle.element.id = this.getTabId()
        this.handle.element.dataset["testid"] = this.getTabId()
        this.adjustHandleStyle()
        return this.handle
    }

    protected constructTabHandleComponent(): Component {
        return new Component(document.createElement("div"))
    }

    protected changeToThisTab(): void {
        if (this.parent === undefined) {
            error("no parent")
            return
        }
        this.parent.changeToTab(this)
    }

    protected isCurrentTab(): boolean {
        if (this.parent === undefined) {
            error("no parent")
            return false
        }
        return this.parent.getCurrentTab() === this
    }

    protected refreshTabs(): void {
        if (this.parent === undefined) {
            error("no parent")
            return
        }
        this.parent.refreshTabs()
    }

    protected getHandleStyle(): CSSX.Properties {
        return this.parent !== undefined ? this.parent.getHandleStyle(this) : {}
    }

    private adjustHandleStyle(): void {
        applyStyles(this.handle, this.getHandleStyle())
    }

    public hideElement(): void {
        super.hideElement()
        this.active = false
        this.adjustHandleStyle()
    }

    public showElement(defaultDisplay = "block"): void {
        super.showElement(defaultDisplay)
        this.active = true
        this.adjustHandleStyle()
    }
}

export abstract class CollapsibleTab extends BaseTab {
    handleCollapseStyle: CSSX.Properties = {
        display: "block",
        cssFloat: "none",
        padding: "9px 12px",
        margin: 0,
        borderRadius: "",
        border: "none",
        boxSizing: "border-box",
        width: "100%",
        height: "auto",
    }
    onCollapse: (collapsed: boolean) => void
    collapsed: boolean

    protected constructTabHandleComponent(): Component {
        const handle = new CollapsibleComponent(document.createElement("div"))
        handle.onCollapseEvent.listen(collapsed => {
            this.collapsed = collapsed
            if (this.onCollapse !== undefined) {
                this.onCollapse(collapsed)
            }
            this.adjustTab()
        })
        return handle
    }
}
