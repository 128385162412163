import { firstNotEmpty } from "@multimediallc/web-utils"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { Component } from "./defui/component"
import { EventRouter } from "./events"

// getFirstElementByTypeAndSelector should only be used on legacy HTML
// noinspection JSDeprecatedSymbols
export function getFirstElementByTypeAndSelector<K extends keyof ElementTagNameMap>(selector: string): ElementTagNameMap[K] | undefined {
    const elements = document.querySelectorAll(`${selector}`)
    if (elements.length < 1) {
        return undefined
    }
    // noinspection JSDeprecatedSymbols
    return elements[0] as ElementTagNameMap[K]
}

export function overlap(el1: HTMLElement, el2: HTMLElement): boolean {
    const rect1 = el1.getBoundingClientRect()
    const rect2 = el2.getBoundingClientRect()
    return !(rect1.right < rect2.left || rect1.left > rect2.right ||
        rect1.bottom < rect2.top || rect1.top > rect2.bottom)
}

export function getMaxZIndexOfOverlappingElements(target: HTMLElement): number {
    const zIndexElements = Array.from(document.querySelectorAll("*[style*=z-index]"))
    let zIndex = 0
    zIndexElements.forEach(el => {
        if (el instanceof HTMLElement && !target.contains(el) && overlap(target, el)) {
            try {
                zIndex = Math.max(zIndex, parseInt(firstNotEmpty(el.style.zIndex, "0")))
            } catch (e) {
                // skip invalid zIndex value
            }
        }
    })
    return zIndex
}

export function applyStyles(el: HTMLElement | Component,
                            styles: CSSX.Properties | ((handle: Component | HTMLElement) => CSSX.Properties)): void {
    let element: HTMLElement
    if (el instanceof Component) {
        element = el.element
    } else {
        element = el
    }
    let cssStyles = styles
    if (styles instanceof Function) {
        cssStyles = styles(el)
    }
    for (const key of Object.keys(cssStyles)) {
        // @ts-ignore - we know CSSX.Properties is "close enough" to CSSStyleDeclaration
        element.style[key] = styles[key]
    }
}

export function wrap(el: HTMLElement): HTMLDivElement {
    const wrapper = document.createElement("div")
    wrapper.appendChild(el)
    return wrapper
}

export function underlineOnHover(el: HTMLElement): void {
    addEventListenerPoly("mouseenter", el, () => {
        el.style.textDecoration = "underline"
    })
    addEventListenerPoly("mouseleave", el, () => {
        el.style.textDecoration = ""
    })
}

export function getBackgroundColor(elem: HTMLElement | null): string {
    const transparent = "rgba(0, 0, 0, 0)"
    const transparentIE11 = "transparent"
    if (elem === null) {
        return transparent
    }

    const bg = getComputedStyle(elem).backgroundColor
    if (bg === "" || bg === transparent || bg === transparentIE11) {
        return getBackgroundColor(elem.parentElement)
    } else {
        return bg
    }
}

export function getCoords(elem: HTMLElement): DOMRect {
    function firstNonZero(a: number, b: number, c?: number): number {
        return a === 0 ? (b === 0 ? (c === undefined ? 0 : c) : b) : a
    }

    const box = elem.getBoundingClientRect()

    const body = document.body
    const docEl = document.documentElement

    const scrollTop = firstNonZero(window.pageYOffset, docEl.scrollTop, body.scrollTop)
    const scrollLeft = firstNonZero(window.pageXOffset, docEl.scrollLeft, body.scrollLeft)

    const clientTop = firstNonZero(docEl.clientTop, body.clientTop)
    const clientLeft = firstNonZero(docEl.clientLeft, body.clientLeft)

    const top = box.top + scrollTop - clientTop
    const left = box.left + scrollLeft - clientLeft

    return new DOMRect(left, top, box.width, box.height)
}

export function numberFromStyle(style: string | null): number {
    if (style === null) {
        return 0
    }
    try {
        const n = parseFloat(style.substring(0, style.indexOf("px")))
        return isNaN(n) ? 0 : n
    } catch (e) {
        // invalid style returning 0
        return 0
    }
}

export function offsetOuterWidth(el: HTMLElement): number {
    return el.offsetWidth + numberFromStyle(getComputedStyle(el).marginLeft) +
        numberFromStyle(getComputedStyle(el).marginRight)
}

// Return width of element excluding padding, border, and margin
export function contentWidth(el: HTMLElement): number {
    const computedStyle = getComputedStyle(el)
    const horizontalPadding = numberFromStyle(computedStyle.paddingLeft) + numberFromStyle(computedStyle.paddingRight)
    const bordersWidth = numberFromStyle(computedStyle.borderLeftWidth) + numberFromStyle(computedStyle.borderRightWidth)
    return el.getBoundingClientRect().width - horizontalPadding - bordersWidth
}

interface touchOptionsProps {
    handleTouch?: boolean,
    ignoreTouch?: boolean,
}

export function hoverEvent(c: Component | HTMLElement, touchOptions?: touchOptionsProps): EventRouter<boolean> {
    const hoverEvent = new EventRouter<boolean>("hover")
    const el = c instanceof Component ? c.element : c

    if (touchOptions?.handleTouch === true && touchOptions?.ignoreTouch === true) {
        error("handleTouch and ignoreTouch cannot both be true")
    }

    addEventListenerPoly("pointerenter", el, (evt: PointerEvent) => {
        if (touchOptions?.ignoreTouch === true) {
            if (evt.pointerType === "mouse") {
                hoverEvent.fire(true)
            }
        } else {
            hoverEvent.fire(true)
        }
    })
    addEventListenerPoly("pointerleave", el, () => {
        hoverEvent.fire(false)
    })

    if (touchOptions?.handleTouch === true) {
        // For touch devices, handle click/tap as hover and remove hover when touch happens anywhere on the document.
        addEventListenerPoly("click", el, () => {
            hoverEvent.fire(true)
        })
        addEventListenerPoly("touchstart", document, () => {
            hoverEvent.fire(false)
        })
        addEventListenerPoly("touchmove", document, () => {
            hoverEvent.fire(false)
        })
        addEventListenerPoly("touchend", document, () => {
            hoverEvent.fire(false)
        })
    }

    return hoverEvent
}

export function setHoverStyle(c: Component | HTMLElement, fn: (hover: boolean) => CSSX.Properties): void {
    hoverEvent(c).listen((hover) => {
        applyStyles(c, fn(hover))
    })
}

let scrollBarWidth: number | undefined

export function getScrollbarWidth(): number {
    if (scrollBarWidth !== undefined) {
        return scrollBarWidth
    }
    // http://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
    const outer = document.createElement("div")
    outer.style.visibility = "hidden"
    outer.style.width = "100px"
    outer.style["msOverflowStyle"] = "scrollbar" // needed for WinJS apps

    document.body.appendChild(outer)

    const widthNoScroll = outer.offsetWidth
    // force scrollbars
    outer.style.overflow = "scroll"

    // add innerdiv
    const inner = document.createElement("div")
    inner.style.width = "100%"
    outer.appendChild(inner)

    const widthWithScroll = inner.offsetWidth

    // remove divs
    document.body.removeChild(outer)

    scrollBarWidth = widthNoScroll - widthWithScroll
    return scrollBarWidth
}

export function addCSS(cssText: string, className?: string): HTMLStyleElement {
    const style = document.createElement("style")
    style.type = "text/css"
    if (className !== undefined) {
        style.id = className
    }
    style.innerText = cssText
    return document.getElementsByTagName("head")[0].appendChild(style)
}

export function firstNonNull<T extends HTMLElement>(el1: T | null, el2: T | null): T | undefined {
    return el1 === null ? (el2 === null ? undefined : el2) : el1
}

const canvas = document.createElement("canvas")
export function getTextWidth(text: string, element: HTMLElement): number {
    // re-use canvas object for better performance
    const context = canvas.getContext("2d")
    if (context !== null) {
        context.font = getComputedStyle(element).font
        return context.measureText(text).width
    }
    return 0
}

export function getTextWidthFont(text: string, font: string): number {
    const context = canvas.getContext("2d")
    if (context !== null) {
        context.font = font
        return context.measureText(text).width
    }
    return 0
}

type elementOrElementGetter = HTMLElement | (() => HTMLElement | undefined)
export function tabListenerFactory(tabFocus: elementOrElementGetter, shiftTabFocus: elementOrElementGetter): ((e: KeyboardEvent) => void) {
    return (e: KeyboardEvent) => {
        if (e.key === "Tab") {
            e.preventDefault()
            let focusEl
            if (!e.shiftKey) {
                focusEl = tabFocus instanceof HTMLElement ? tabFocus : tabFocus()
            } else {
                focusEl = shiftTabFocus instanceof HTMLElement ? shiftTabFocus : shiftTabFocus()
            }
            if (focusEl !== undefined) {
                focusEl.focus()
                e.stopPropagation()
            }
        }
    }
}

// This will generally return {top: 0, left: 0}, but if you zoom in on safari then it will return negative values that
// we need to account for when positioning a fixed element relative to another element's boundingClientRect
let fixedElement: HTMLDivElement | undefined
export function getFixedOffset(): { top: number, left: number } {
    if (fixedElement === undefined) {
        fixedElement = document.createElement("div")
        applyStyles(fixedElement, {
            position: "fixed",
            top: "0px",
            left: "0px",
        })
        document.body.appendChild(fixedElement)
    }
    const boundingRect = fixedElement.getBoundingClientRect()
    return {
        top: boundingRect.top,
        left: boundingRect.left,
    }
}

export function isElementInViewport(elem: HTMLElement): boolean {
    const rect = elem.getBoundingClientRect()
    return (
        elem.style.display !== "none"
        && Math.round(rect.top) >= 0
        && Math.round(rect.left) >= 0
        && Math.round(rect.bottom) <= (window.innerHeight || document.documentElement.clientHeight)
        && Math.round(rect.right) <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

// Useful for restarting css animations without having to set a timout between removing and readding the animation class
export function triggerReflow(): void {
    void document.body.offsetWidth
}

export function getEligibleTargetAnchor(target: EventTarget | null): HTMLAnchorElement | undefined {
    /**
     * Returns a HTMLAnchorElement if a valid anchor can be found. Does so by exploring the ancestors of a provided target.
     * Valid anchors consists of anchors where the target must be the current browsing context and where "#" is not in use for their href.
     */
    let parent = target as HTMLElement | null;
    while (parent !== null && parent.tagName.toLowerCase() !== "a") {
        parent = parent.parentElement;
    }
    if (parent === null) {
        return undefined;
    }
    const anchorEl = parent as HTMLAnchorElement;
    // See https://stackoverflow.com/a/33209233 for potential complications on the use of getAttribute for accessing href.
    // If issues come up in the future, this could be something to look into
    const href = anchorEl.getAttribute("href");
    if (href === "#" || href === "" || href === null) {
        return undefined;
    }
    return anchorEl;
}

export function ignoreMetaClick(e: MouseEvent, fn: (e: MouseEvent) => void): void {
    if (e.ctrlKey || e.metaKey || e.shiftKey) {
        return
    }
    e.preventDefault()
    fn(e)
}

// Function to check if element is present in the current scrollview area of the parent container
export function isScrolledIntoView(element: HTMLDivElement, scrollDiv: HTMLDivElement): boolean {
    if (!scrollDiv.contains(element)) {
        return false
    }
    const parentViewTop = scrollDiv.scrollTop;
    const parentViewBottom = parentViewTop + scrollDiv.offsetHeight;

    const elemTop = element.offsetTop;
    const elemBottom = elemTop + element.offsetHeight;

    return ((elemBottom <= parentViewBottom) && (elemTop >= parentViewTop))
}

// Stops scroll momentum from eg flicking trackpad or touchscreen.
// ios gets upset if you set scrollTop while scroll momentum is still going, calling this first will fix the issue
// Note the default values restored for overflowY and overflowX - auto for Y and hidden for X
export function stopScrollMomentum(el: HTMLElement, overflowY?: string, overflowX?: string): void {
    el.style.overflow = "hidden"
    triggerReflow()
    el.style.overflowY = overflowY ?? "auto"
    el.style.overflowX = overflowX ?? "hidden"
}
